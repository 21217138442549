import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useMainCatDataFetch = () => {
  const user = useUserStore((state) => state.user);
  const [mainCatData, setMainCatData] = useState([]);
  const [isMainCatLoaded, setIsMainCatLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchMainCatData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + `/api/maincat/reguserid/${user?.id}`
      );
      setIsMainCatLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching MainCat data:", error);
      setIsMainCatLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchMainCatData().then((MainCatInitialData) => {
        setMainCatData(MainCatInitialData);
      });
    else setIsMainCatLoaded(true);
  }, [user]);

  // Return an object containing all the fetched data
  return {
    mainCatData,
    isMainCatLoaded,
  };
};

export default useMainCatDataFetch;
