import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useDebtDataFetch = () => {
  const user = useUserStore((state) => state.user);
  const [debtData, setDebtData] = useState([]);
  const [isDebtLoaded, setIsDebtLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchDebtData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          `/api/debt/rootuserid/${user?.RootID}`
      );
      setIsDebtLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching Debt data:", error);
      setIsDebtLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchDebtData().then((DebtInitialData) => {
        setDebtData(DebtInitialData);
      });
    else setIsDebtLoaded(true);
  }, [user]);

  // Return an object containing all the fetched data
  return {
    debtData,
    isDebtLoaded,
  };
};

export default useDebtDataFetch;
