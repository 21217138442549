import React, { useEffect, useState } from "react";
import "./HeaderOption.css";
import { NavLink, useLocation } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";

function HeaderOption({ Icon, title, subMenu, openSubMenu, onSubMenuToggle, setisDisplaySidebar }) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const handleSubMenuToggle = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    onSubMenuToggle(title);
  };

  const hideSidebar = () => {
    if (window.innerWidth <= 500) setisDisplaySidebar(false);
    // if (window.innerWidth > 500 && window.innerWidth <= 1470) setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(openSubMenu === title);
  }, [openSubMenu, title]);

  useEffect(() => {
    if (!subMenu) {
      setIsOpen(false); // Close the submenu if the current tab doesn't have a submenu
    }
  }, [subMenu]);
  // const submenuStyle = {
  //   display:
  //     window.innerWidth <= 1470 && window.innerWidth >= 500 && isOpen
  //       ? "block"
  //       : "none",
  // };

  return (
    <div className="headerOption" onClick={(e) => e.stopPropagation()}>
      <div className="headerOption_main">
        <div className="main_menuTab" onClick={subMenu ? handleSubMenuToggle : null}>
          {Icon && <Icon className="custom_icon" />}
          <h3 className="headerOption_title">{title}</h3>

          {subMenu && (
            <div className="dropdown_icon">
              <IoMdArrowDropright />
            </div>
          )}
        </div>

        {isOpen && subMenu && (
          <div className="subMenu">
            {subMenu.map((item, index) => (
              <div key={index} className="subMenuItem">
                <NavLink
                  to={item.LinkTo}
                  className="subLinks"
                  style={{
                    color: location.pathname === item.LinkTo ? " rgb(255, 233, 155)" : "gray",
                  }}
                  onClick={hideSidebar}>
                  {item.title}
                </NavLink>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default HeaderOption;
