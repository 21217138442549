import React from 'react'
import '../Sidebar.css'
import IncomeOption from './IncomeOption';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { NavLink } from 'react-router-dom';

function Income() {
  return (
    <div className='sidebar'>
        <NavLink to="incomedetails">{<IncomeOption Icon = {MonetizationOnIcon} title="Income Records"/>}</NavLink>
        <NavLink to="extraweeks">{<IncomeOption Icon = {InsertInvitationIcon} title= "Extra Pay Dates"/>}</NavLink>                
    </div>
  )
}
export default Income