import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useRetirementNameDataFetch = () => {
  const user = useUserStore((state) => state.user);

  const [retirementNameData, setRetirementNameData] = useState([]);
  const [isRetirementNameLoaded, setIsRetirementNameLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchRetirementNameData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/retirementname"
      );
      setIsRetirementNameLoaded(true);

      return response.data.items;
    } catch (error) {
      console.error("Error fetching RetirementName data:", error);
      setIsRetirementNameLoaded(true);

      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchRetirementNameData().then((RetirementNameInitialData) => {
        setRetirementNameData(RetirementNameInitialData);
      });
    else setIsRetirementNameLoaded(true);

    // Set data loading flag
  }, [user]);

  // Return an object containing all the fetched data
  return {
    retirementNameData,
    isRetirementNameLoaded,
  };
};

export default useRetirementNameDataFetch;
