import React from 'react'
import '../Sidebar.css'
import ExpenseOption from './ExpenseOption';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import { NavLink } from 'react-router-dom';

function Expense() {
  return (
    <div className='sidebar'>
        <NavLink to="expensedetails">{<ExpenseOption Icon = {ShoppingCartIcon} title="Expense Records"/>}</NavLink>
        <NavLink to="jointcontribution">{<ExpenseOption Icon = {JoinInnerIcon} title= "Joint Contribution"/>}</NavLink>                
    </div>
  )
}
export default Expense