import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useIncomeDataFetch = () => {
  const user = useUserStore((state) => state.user);
  const [incomeData, setIncomeData] = useState([]);
  const [isIncomeLoaded, setIsIncomeLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchIncomeData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          `/api/income/rootuserid/${user?.RootID}`
      );
      setIsIncomeLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching Income data:", error);
      setIsIncomeLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchIncomeData().then((IncomeInitialData) => {
        setIncomeData(IncomeInitialData);
      });
    else setIsIncomeLoaded(true);
  }, [user]);

  // Return an object containing all the fetched data
  return {
    incomeData,
    isIncomeLoaded,
  };
};

export default useIncomeDataFetch;
