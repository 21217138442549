import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import "./Dashboard.css";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";
import useAssetDataFetch from "../../shared/useAssetDataFetch";
import useRetirementDataFetch from "../../shared/useRetirementDataFetch";
import useSavingDataFetch from "../../shared/useSavingDataFetch";
import useDebtDataFetch from "../../shared/useDebtDataFetch";
import useExpenseDataFetch from "../../shared/useExpenseDataFetch";
import useIncomeDataFetch from "../../shared/useIncomeDataFetch";
import useBankAccountDataFetch from "../../shared/useBankAccountDataFetch";
import useMainCatDataFetch from "../../shared/useMainCatDataFetch";
import useSubCatDataFetch from "../../shared/useSubCatDataFetch";
import useAccountDataFetch from "../../shared/useAccountDataFetch";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import graph_vector from "../../../image/graph_vector.png";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  DataLabel,
  Category,
  Tooltip,
  ColumnSeries,
  BarSeries,
  MajorGridLines,
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationTooltip,
  AccumulationDataLabel,
  PieSeries,
  Legend,
  AccumulationLegend,
} from "@syncfusion/ej2-react-charts";

function Dashboard() {
  const user = useUserStore((state) => state.user);
  const { assetData, isAssetLoaded } = useAssetDataFetch();
  const { retirementData, isRetirementLoaded } = useRetirementDataFetch();
  const { savingData, isSavingLoaded } = useSavingDataFetch();
  const { debtData, isDebtLoaded } = useDebtDataFetch();
  const { expenseData, isExpenseLoaded } = useExpenseDataFetch();
  const { mainCatData, isMainCatLoaded } = useMainCatDataFetch();
  const { incomeData, isIncomeLoaded } = useIncomeDataFetch();
  const { bankAccountData, isBankAccountLoaded } = useBankAccountDataFetch();
  const { subCatData, isSubCatLoaded } = useSubCatDataFetch();
  const { accountData, isAccountLoaded } = useAccountDataFetch();
  //UPDATED DATA
  const [updatedRetirementData, setUpdatedRetirementData] = useState([]);
  const [updatedSavingData, setUpdatedSavingData] = useState([]);
  const [updatedDebtData, setUpdatedDebtData] = useState([]);
  const [updatedExpenseData, setUpdatedExpenseData] = useState([]);
  const [updatedBankAccountData, setUpdatedBankAccountData] = useState([]);
  //INCOME DATA
  const [grossYearlyIncomeTotal, setGrossYearlyIncomeTotal] = useState(0);
  const [netYearlyIncomeTotal, setNetYearlyIncomeTotal] = useState(0);
  const [grossMonthlyIncomeTotal, setGrossMonthlyIncomeTotal] = useState(0);
  const [netMonthlyIncomeTotal, setNetMonthlyIncomeTotal] = useState(0);
  //ASSET/LIABILITIES DATA
  const [allAssetTotal, setAllAssetTotal] = useState(0);
  const [allLiabilitiesTotal, setAllLiabilitiesTotal] = useState(0);
  const [actualNetWorth, setActualNetWorth] = useState(0);
  const [closingBalance, setClosingBalance] = useState(0);
  // EXPENSES DATA
  const [yearlyExpenseTotal, setYearlyExpenseTotal] = useState(0);
  const [monthlyExpenseTotal, setMonthlyExpenseTotal] = useState(0);
  const [actualExpenseGoal, setActualExpenseGoal] = useState(0);
  const [budgetExpenseGoal, setBudgetExpenseGoal] = useState(0);
  const [expenseDiffIcon, setExpenseDiffIcon] = useState("");
  const [expenseDiffLabel, setExpenseDiffLabel] = useState("Over/Under");
  const [expenseDifference, setExpenseDifference] = useState(0);
  //DEBTS DATA
  const [loanBalance, setLoanBalance] = useState(0);
  const [yearlyDebtTotal, setYearlyDebtTotal] = useState(0);
  const [monthlyDebtTotal, setMonthlyDebtTotal] = useState(0);
  const [actualDebtGoal, setActualDebtGoal] = useState(0);
  const [budgetDebtGoal, setBudgetDebtGoal] = useState(0);
  const [debtDiffIcon, setDebtDiffIcon] = useState("");
  const [debtDiffLabel, setDebtDiffLabel] = useState("Over/Under");
  const [debtDifference, setDebtDifference] = useState(0);
  //RETIREMENTS DATA
  const [retirementValue, setRetirementValue] = useState(0);
  const [yearlyRetirementTotal, setYearlyRetirementTotal] = useState(0);
  const [monthlyRetirementTotal, setMonthlyRetirementTotal] = useState(0);
  const [actualRetirementGoal, setActualRetirementGoal] = useState(0);
  const [budgetRetirementGoal, setBudgetRetirementGoal] = useState(0);
  const [retirementDiffIcon, setRetirementDiffIcon] = useState("");
  const [retirementDiffLabel, setRetirementDiffLabel] = useState("Over/Under");
  const [retirementDifference, setRetirementDifference] = useState(0);
  //SAVINGS DATA
  const [savingValue, setSavingValue] = useState(0);
  const [yearlySavingTotal, setYearlySavingTotal] = useState(0);
  const [monthlySavingTotal, setMonthlySavingTotal] = useState(0);
  const [actualSavingGoal, setActualSavingGoal] = useState(0);
  const [budgetSavingGoal, setBudgetSavingGoal] = useState(0);
  const [savingDiffIcon, setSavingDiffIcon] = useState("");
  const [savingDiffLabel, setSavingDiffLabel] = useState("Over/Under");
  const [savingDifference, setSavingDifference] = useState(0);
  const [dataFilter, setDataFilter] = useState("Household");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const usedCurrency = user?.Currency + " #,###.00";
  const userPackage = user?.Package;
  const [subCategories, setSubCategories] = useState();
  const [bankAccounts, setBankAccounts] = useState();
  const [otherIcon, setOtherIcon] = useState("");
  const [grossMonthlyLabel, setGrossMonthlyLabel] = useState(
    "Gross Monthly Budget Income"
  );
  const [grossYearlyLabel, setGrossYearlyLabel] = useState(
    "Gross Yearly Income"
  );
  const [netMonthlyLabel, setNetMonthlyLabel] = useState(
    "Net Monthly Budget Income"
  );
  const [netYearlyLabel, setNetYearlyLabel] = useState("Net Yearly Income");
  const navigate = useNavigate();

  const handleAccountOwnerChange = async (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setDataFilter(e.value);
      const accountOwner = e.value;
      let finalAsset;
      let finalRetirement;
      let finalSaving;
      let finaldebt;
      let finalExpenses;
      let finalIncome;
      let finalBankAccount;
      if (user?.Type !== "Root") {
        finalAsset = assetData.filter((item) => item.UserID === user?.id);
        finalRetirement = retirementData.filter(
          (item) => item.UserID === user?.id
        );
        finalSaving = savingData.filter((item) => item.UserID === user?.id);
        finaldebt = debtData.filter((item) => item.UserID === user?.id);
        finalExpenses = expenseData.filter((item) => item.UserID === user?.id);
        finalIncome = incomeData.filter((item) => item.UserID === user?.id);
        finalBankAccount = bankAccountData?.filter(
          (item) => item.UserID === user?.id
        );
      } else {
        finalAsset = assetData;
        finalRetirement = retirementData;
        finalSaving = savingData;
        finaldebt = debtData;
        finalExpenses = expenseData;
        finalIncome = incomeData;
        finalBankAccount = bankAccountData;
      }

      let filteredData;
      let filteredRetirement;
      let filteredSavings;
      let filteredDebts;
      let filteredExpenses;
      let filteredIncome;
      let filteredBankAccount;
      if (
        accountOwner === "Self" ||
        accountOwner === "Partner" ||
        accountOwner === "Joint"
      ) {
        filteredData = finalAsset.filter(
          (item) => item.AccountOwner === accountOwner
        );
        filteredRetirement = finalRetirement.filter(
          (item) => item.AccountOwner === accountOwner
        );
        filteredSavings = finalSaving.filter(
          (item) => item.AccountOwner === accountOwner
        );
        filteredDebts = finaldebt.filter(
          (item) => item.AccountOwner === accountOwner
        );
        filteredExpenses = finalExpenses.filter(
          (item) => item.AccountOwner === accountOwner
        );
        filteredIncome = finalIncome.filter(
          (item) => item.AccountOwner === accountOwner
        );
        filteredBankAccount = finalBankAccount.filter(
          (item) => item.AccountOwner === accountOwner
        );
      } else {
        filteredData = finalAsset;
        filteredRetirement = finalRetirement;
        filteredSavings = finalSaving;
        filteredDebts = finaldebt;
        filteredExpenses = finalExpenses.filter(
          (item) => item.BudgetItem !== "Joint Contribution"
        );
        filteredIncome = finalIncome;
        filteredBankAccount = finalBankAccount;
      }
      setUpdatedRetirementData(filteredRetirement);
      setUpdatedSavingData(filteredSavings);
      setUpdatedDebtData(filteredDebts);
      setUpdatedExpenseData(filteredExpenses);
      setUpdatedBankAccountData(filteredBankAccount);

      //GET EXPENSE DATA
      const totalExpenseSum = filteredExpenses?.reduce(
        (accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );
      setMonthlyExpenseTotal(totalExpenseSum);
      setYearlyExpenseTotal(12 * totalExpenseSum);

      const totalSelfContribution = finalExpenses?.reduce(
        (accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          const contribution = record?.BudgetItem || "";
          const contrOwner = record?.AccountOwner || "";
          if (contribution === "Joint Contribution" && contrOwner === "Self") {
            return accumulator + Number(monthlyPayment) + Number(adjustments);
          }
          return accumulator;
        },
        0
      );

      const totalPartnerContribution = finalExpenses?.reduce(
        (accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          const contribution = record?.BudgetItem || "";
          const contrOwner = record?.AccountOwner || "";
          if (
            contribution === "Joint Contribution" &&
            contrOwner === "Partner"
          ) {
            return accumulator + Number(monthlyPayment) + Number(adjustments);
          }
          return accumulator;
        },
        0
      );

      //GET DEBT DATA
      const totalDebtSum = filteredDebts?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);
      setMonthlyDebtTotal(totalDebtSum);
      setYearlyDebtTotal(12 * totalDebtSum);

      const totalLoanSum = filteredDebts?.reduce((accumulator, record) => {
        const totalLoan = record?.LoanBalance || 0;
        return accumulator + Number(totalLoan);
      }, 0);
      setLoanBalance(totalLoanSum);

      //GET RETIREMENT DATA
      const totalRetirementSum = filteredRetirement?.reduce(
        (accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );
      setMonthlyRetirementTotal(totalRetirementSum);
      setYearlyRetirementTotal(12 * totalRetirementSum);

      const totalRetirementMarketValue = filteredRetirement?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );
      setRetirementValue(totalRetirementMarketValue);

      //GET SAVING DATA
      const totalSavingSum = filteredSavings?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);
      setMonthlySavingTotal(totalSavingSum);
      setYearlySavingTotal(12 * totalSavingSum);

      const totalSavingMarketValue = filteredSavings?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );
      setSavingValue(totalSavingMarketValue);

      //Get total real estate value
      const filteredEstateData = filteredData?.filter(
        (item) => item.AssetCategory === "Real Estate"
      );
      const totalEstate = filteredEstateData?.reduce((accumulator, record) => {
        const MarketValue = record?.MarketValue || 0;
        return accumulator + Number(MarketValue);
      }, 0);

      //Get total real vehicle value
      const filteredVehicleData = filteredData?.filter(
        (item) => item.AssetCategory === "Vehicle"
      );
      const totalVehicle = filteredVehicleData?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );

      //Get total real Furnishings/Appliances value
      const filteredApplianceData = filteredData?.filter(
        (item) => item.AssetCategory === "Furnishing / Appliances"
      );
      const totalAppliance = filteredApplianceData?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );

      //Get total real Other value
      const filteredOtherData = filteredData?.filter(
        (item) => item.AssetCategory === "Other"
      );
      const totalOther = filteredOtherData?.reduce((accumulator, record) => {
        const MarketValue = record?.MarketValue || 0;
        return accumulator + Number(MarketValue);
      }, 0);

      //Get totalLiabilities value
      const totalLiabilities = filteredDebts?.reduce((accumulator, record) => {
        const loanBalance = record?.LoanBalance || 0;
        return accumulator + Number(loanBalance);
      }, 0);
      setAllLiabilitiesTotal(totalLiabilities);

      const totalGrossAnnualIncome = filteredIncome.reduce(
        (accumulator, record) => {
          const grossPay = record.GrossPay || 0;
          const payFrequency = record.PayFrequency || "";
          let annualGrossIncome = 0;
          switch (payFrequency) {
            case "Yearly":
              annualGrossIncome = grossPay;
              break;
            case "Monthly":
              annualGrossIncome = grossPay * 12;
              break;
            case "Semi-Monthly":
              annualGrossIncome = grossPay * 24;
              break;
            case "Weekly":
              annualGrossIncome = grossPay * 52;
              break;
            case "Bi-Weekly":
              annualGrossIncome = grossPay * 26;
              break;
            default:
              annualGrossIncome = 0;
          }
          // Add monthlyIncome to the accumulator
          return accumulator + Number(annualGrossIncome);
        },
        0
      ); // Initialize accumulator with 0

      const totalAnnualNetIncome = filteredIncome.reduce(
        (accumulator, record) => {
          const netPay = record.NetPay || 0;
          const payFrequency = record.PayFrequency || "";
          let annualNetIncome = 0;
          switch (payFrequency) {
            case "Yearly":
              annualNetIncome = netPay;
              break;
            case "Monthly":
              annualNetIncome = netPay * 12;
              break;
            case "Semi-Monthly":
              annualNetIncome = netPay * 24;
              break;
            case "Weekly":
              annualNetIncome = netPay * 52;
              break;
            case "Bi-Weekly":
              annualNetIncome = netPay * 26;
              break;
            default:
              annualNetIncome = 0;
          }
          // Add monthlyIncome to the accumulator
          return accumulator + Number(annualNetIncome);
        },
        0
      ); // Initialize accumulator with 0

      const totalMonthlyGrossIncome = filteredIncome.reduce(
        (accumulator, record) => {
          const grossPay = record.GrossPay || 0; // Handle the case where NetPay is undefined
          const payFrequency = record.PayFrequency || ""; // Handle the case where PayFrequency is undefined
          let monthlyGrossIncome = 0;
          // Determine monthlyIncome based on PayFrequency
          switch (payFrequency) {
            case "Yearly":
              monthlyGrossIncome = grossPay / 12;
              break;
            case "Monthly":
              monthlyGrossIncome = grossPay;
              break;
            case "Semi-Monthly":
              monthlyGrossIncome = grossPay * 2;
              break;
            case "Weekly":
              monthlyGrossIncome = grossPay * 4;
              break;
            case "Bi-Weekly":
              monthlyGrossIncome = grossPay * 2;
              break;
            default:
              monthlyGrossIncome = 0;
          }
          // Add monthlyIncome to the accumulator
          return accumulator + Number(monthlyGrossIncome);
        },
        0
      ); // Initialize accumulator with 0

      const totalMonthlyNetIncome = filteredIncome.reduce(
        (accumulator, record) => {
          const netPay = record.NetPay || 0; // Handle the case where NetPay is undefined
          const payFrequency = record.PayFrequency || ""; // Handle the case where PayFrequency is undefined
          let monthlyNetIncome = 0;
          // Determine monthlyIncome based on PayFrequency
          switch (payFrequency) {
            case "Yearly":
              monthlyNetIncome = netPay / 12;
              break;
            case "Monthly":
              monthlyNetIncome = netPay;
              break;
            case "Semi-Monthly":
              monthlyNetIncome = netPay * 2;
              break;
            case "Weekly":
              monthlyNetIncome = netPay * 4;
              break;
            case "Bi-Weekly":
              monthlyNetIncome = netPay * 2;
              break;
            default:
              monthlyNetIncome = 0;
          }
          // Add monthlyIncome to the accumulator
          return accumulator + Number(monthlyNetIncome);
        },
        0
      ); // Initialize accumulator with 0

      // Calculate closing balance
      const balanceAfter = filteredBankAccount.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);
      setClosingBalance(balanceAfter);
      const totalAssetsValue =
        Number(totalRetirementMarketValue) +
        Number(totalSavingMarketValue) +
        Number(balanceAfter) +
        Number(totalEstate) +
        Number(totalVehicle) +
        Number(totalAppliance) +
        Number(totalOther);
      setAllAssetTotal(totalAssetsValue);
      setActualNetWorth(Number(totalAssetsValue) - Number(totalLiabilities));

      if (accountOwner === "Joint") {
        setGrossMonthlyLabel("Self Monthly Contribution");
        setGrossMonthlyIncomeTotal(totalSelfContribution);
        setGrossYearlyLabel("Self Yearly Contribution");
        setGrossYearlyIncomeTotal(12 * Number(totalSelfContribution));
        setNetMonthlyLabel("Partner Monthly Contribution");
        setNetMonthlyIncomeTotal(totalPartnerContribution);
        setNetYearlyLabel("Partner Yearly Contribution");
        setNetYearlyIncomeTotal(12 * Number(totalPartnerContribution));
      } else {
        setGrossMonthlyLabel("Gross Monthly Budget Income");
        setGrossMonthlyIncomeTotal(totalMonthlyGrossIncome);
        setGrossYearlyLabel("Gross Yearly Income");
        setGrossYearlyIncomeTotal(totalGrossAnnualIncome);
        setNetMonthlyLabel("Net Monthly Budget Income");
        setNetMonthlyIncomeTotal(totalMonthlyNetIncome);
        setNetYearlyLabel("Net Yearly Income");
        setNetYearlyIncomeTotal(totalAnnualNetIncome);
      }
      setIsDataChanged(true);
    }
  };

  useEffect(() => {
    if (
      isExpenseLoaded &&
      isAssetLoaded &&
      isRetirementLoaded &&
      isSavingLoaded & isDebtLoaded & isIncomeLoaded & isBankAccountLoaded
    ) {
      let finalAsset;
      let finalRetirement;
      let finalSaving;
      let finaldebt;
      let finalExpenses;
      let finalIncome;
      let finalBankAccount;
      if (user?.Type !== "Root") {
        finalAsset = assetData.filter((item) => item.UserID === user?.id);
        finalRetirement = retirementData.filter(
          (item) => item.UserID === user?.id
        );
        finalSaving = savingData.filter((item) => item.UserID === user?.id);
        finaldebt = debtData.filter((item) => item.UserID === user?.id);
        finalExpenses = expenseData.filter((item) => item.UserID === user?.id);
        finalIncome = incomeData.filter((item) => item.UserID === user?.id);
        finalBankAccount = bankAccountData?.filter(
          (item) => item.UserID === user?.id
        );
      } else {
        finalAsset = assetData;
        finalRetirement = retirementData;
        finalSaving = savingData;
        finaldebt = debtData;
        finalExpenses = expenseData;
        finalIncome = incomeData;
        finalBankAccount = bankAccountData;
      }

      const filteredData = finalAsset;
      const filteredRetirement = finalRetirement;
      const filteredSavings = finalSaving;
      const filteredDebts = finaldebt;
      const filteredExpenses = finalExpenses.filter(
        (item) => item.BudgetItem !== "Joint Contribution"
      );
      const filteredIncome = finalIncome;
      const filteredBankAccount = finalBankAccount;

      setUpdatedRetirementData(filteredRetirement);
      setUpdatedSavingData(filteredSavings);
      setUpdatedDebtData(filteredDebts);
      setUpdatedExpenseData(filteredExpenses);
      setUpdatedBankAccountData(filteredBankAccount);

      //GET EXPENSE DATA
      const totalExpenseSum = filteredExpenses?.reduce(
        (accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );
      setMonthlyExpenseTotal(totalExpenseSum);
      setYearlyExpenseTotal(12 * totalExpenseSum);

      //GET DEBT DATA
      const totalDebtSum = filteredDebts?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);
      setMonthlyDebtTotal(totalDebtSum);
      setYearlyDebtTotal(12 * totalDebtSum);

      const totalLoanSum = filteredDebts?.reduce((accumulator, record) => {
        const totalLoan = record?.LoanBalance || 0;
        return accumulator + Number(totalLoan);
      }, 0);
      setLoanBalance(totalLoanSum);

      //GET RETIREMENT DATA
      const totalRetirementSum = filteredRetirement?.reduce(
        (accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );
      setMonthlyRetirementTotal(totalRetirementSum);
      setYearlyRetirementTotal(12 * totalRetirementSum);

      const totalRetirementMarketValue = filteredRetirement?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );
      setRetirementValue(totalRetirementMarketValue);

      //GET SAVING DATA
      const totalSavingSum = filteredSavings?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);
      setMonthlySavingTotal(totalSavingSum);
      setYearlySavingTotal(12 * totalSavingSum);

      const totalSavingMarketValue = filteredSavings?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );
      setSavingValue(totalSavingMarketValue);

      //Get total real estate value
      const filteredEstateData = filteredData?.filter(
        (item) => item.AssetCategory === "Real Estate"
      );
      const totalEstate = filteredEstateData?.reduce((accumulator, record) => {
        const MarketValue = record?.MarketValue || 0;
        return accumulator + Number(MarketValue);
      }, 0);

      //Get total real vehicle value
      const filteredVehicleData = filteredData?.filter(
        (item) => item.AssetCategory === "Vehicle"
      );
      const totalVehicle = filteredVehicleData?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );

      //Get total real Furnishings/Appliances value
      const filteredApplianceData = filteredData?.filter(
        (item) => item.AssetCategory === "Furnishing / Appliances"
      );
      const totalAppliance = filteredApplianceData?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );

      //Get total real Other value
      const filteredOtherData = filteredData?.filter(
        (item) => item.AssetCategory === "Other"
      );
      const totalOther = filteredOtherData?.reduce((accumulator, record) => {
        const MarketValue = record?.MarketValue || 0;
        return accumulator + Number(MarketValue);
      }, 0);

      //Get totalLiabilities value
      const totalLiabilities = filteredDebts?.reduce((accumulator, record) => {
        const loanBalance = record?.LoanBalance || 0;
        return accumulator + Number(loanBalance);
      }, 0);
      setAllLiabilitiesTotal(totalLiabilities);

      const totalGrossAnnualIncome = filteredIncome.reduce(
        (accumulator, record) => {
          const grossPay = record.GrossPay || 0;
          const payFrequency = record.PayFrequency || "";
          let annualGrossIncome = 0;
          switch (payFrequency) {
            case "Yearly":
              annualGrossIncome = grossPay;
              break;
            case "Monthly":
              annualGrossIncome = grossPay * 12;
              break;
            case "Semi-Monthly":
              annualGrossIncome = grossPay * 24;
              break;
            case "Weekly":
              annualGrossIncome = grossPay * 52;
              break;
            case "Bi-Weekly":
              annualGrossIncome = grossPay * 26;
              break;
            default:
              annualGrossIncome = 0;
          }
          // Add monthlyIncome to the accumulator
          return accumulator + Number(annualGrossIncome);
        },
        0
      ); // Initialize accumulator with 0
      setGrossYearlyIncomeTotal(totalGrossAnnualIncome);

      const totalAnnualNetIncome = filteredIncome.reduce(
        (accumulator, record) => {
          const netPay = record.NetPay || 0;
          const payFrequency = record.PayFrequency || "";
          let annualNetIncome = 0;
          switch (payFrequency) {
            case "Yearly":
              annualNetIncome = netPay;
              break;
            case "Monthly":
              annualNetIncome = netPay * 12;
              break;
            case "Semi-Monthly":
              annualNetIncome = netPay * 24;
              break;
            case "Weekly":
              annualNetIncome = netPay * 52;
              break;
            case "Bi-Weekly":
              annualNetIncome = netPay * 26;
              break;
            default:
              annualNetIncome = 0;
          }
          // Add monthlyIncome to the accumulator
          return accumulator + Number(annualNetIncome);
        },
        0
      ); // Initialize accumulator with 0
      setNetYearlyIncomeTotal(totalAnnualNetIncome);

      const totalMonthlyGrossIncome = filteredIncome.reduce(
        (accumulator, record) => {
          const grossPay = record.GrossPay || 0; // Handle the case where NetPay is undefined
          const payFrequency = record.PayFrequency || ""; // Handle the case where PayFrequency is undefined
          let monthlyGrossIncome = 0;
          // Determine monthlyIncome based on PayFrequency
          switch (payFrequency) {
            case "Yearly":
              monthlyGrossIncome = grossPay / 12;
              break;
            case "Monthly":
              monthlyGrossIncome = grossPay;
              break;
            case "Semi-Monthly":
              monthlyGrossIncome = grossPay * 2;
              break;
            case "Weekly":
              monthlyGrossIncome = grossPay * 4;
              break;
            case "Bi-Weekly":
              monthlyGrossIncome = grossPay * 2;
              break;
            default:
              monthlyGrossIncome = 0;
          }
          // Add monthlyIncome to the accumulator
          return accumulator + Number(monthlyGrossIncome);
        },
        0
      ); // Initialize accumulator with 0
      setGrossMonthlyIncomeTotal(totalMonthlyGrossIncome);

      const totalMonthlyNetIncome = filteredIncome.reduce(
        (accumulator, record) => {
          const netPay = record.NetPay || 0; // Handle the case where NetPay is undefined
          const payFrequency = record.PayFrequency || ""; // Handle the case where PayFrequency is undefined
          let monthlyNetIncome = 0;
          // Determine monthlyIncome based on PayFrequency
          switch (payFrequency) {
            case "Yearly":
              monthlyNetIncome = netPay / 12;
              break;
            case "Monthly":
              monthlyNetIncome = netPay;
              break;
            case "Semi-Monthly":
              monthlyNetIncome = netPay * 2;
              break;
            case "Weekly":
              monthlyNetIncome = netPay * 4;
              break;
            case "Bi-Weekly":
              monthlyNetIncome = netPay * 2;
              break;
            default:
              monthlyNetIncome = 0;
          }
          // Add monthlyIncome to the accumulator
          return accumulator + Number(monthlyNetIncome);
        },
        0
      ); // Initialize accumulator with 0
      setNetMonthlyIncomeTotal(totalMonthlyNetIncome);

      // Calculate closing balance
      const balanceAfter = filteredBankAccount.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);
      setClosingBalance(balanceAfter);
      const totalAssetsValue =
        Number(totalRetirementMarketValue) +
        Number(totalSavingMarketValue) +
        Number(balanceAfter) +
        Number(totalEstate) +
        Number(totalVehicle) +
        Number(totalAppliance) +
        Number(totalOther);
      setAllAssetTotal(totalAssetsValue);
      setActualNetWorth(Number(totalAssetsValue) - Number(totalLiabilities));

      setIsDataChanged(true);
      setIsDataLoaded(true);
    }
  }, [
    expenseData,
    assetData,
    isAssetLoaded,
    retirementData,
    isRetirementLoaded,
    savingData,
    isExpenseLoaded,
    isSavingLoaded,
    debtData,
    isDebtLoaded,
    incomeData,
    isIncomeLoaded,
    bankAccountData,
    isBankAccountLoaded,
    user,
  ]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded || !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  useEffect(() => {
    if (isMainCatLoaded) {
      //EXPENSE GOALS
      let expenseGoal;
      const filteredData = mainCatData.find(
        (item) => item.Category === "Expenses"
      );
      if (filteredData) {
        const uniqueGoal = filteredData.Goal;
        setBudgetExpenseGoal(Number(uniqueGoal) / 100);
        expenseGoal = Number(uniqueGoal) / 100;
      } else {
        setBudgetExpenseGoal(0);
        expenseGoal = 0;
      }
      const expensePerc =
        Number(monthlyExpenseTotal) / Number(netMonthlyIncomeTotal);
      const difference =
        (Number(expenseGoal) - Number(expensePerc)) *
        Number(netMonthlyIncomeTotal);
      if (Number(difference) < 0) {
        setExpenseDiffLabel("Budget Over by");
        setExpenseDiffIcon(
          <ThumbDownIcon
            style={{ color: "red", fontSize: "40px", paddingTop: "0px" }}
          />
        );
      } else if (Number(difference) > 0) {
        setExpenseDiffLabel("Budget Under by");
        setExpenseDiffIcon(
          <ThumbUpIcon
            style={{ color: "green", fontSize: "40px", paddingTop: "0px" }}
          />
        );
      } else {
        setExpenseDiffLabel("Budget Over/Under by");
        setExpenseDiffIcon(
          <ThumbUpIcon
            style={{ color: "orange", fontSize: "40px", paddingTop: "0px" }}
          />
        );
      }
      setActualExpenseGoal(expensePerc);
      setExpenseDifference(difference);
      if (dataFilter === "Joint") {
        setExpenseDiffIcon(
          <ThumbUpIcon
            style={{
              color: "rgba(255, 253, 208, 0.5)",
              fontSize: "40px",
              paddingTop: "0px",
            }}
          />
        );
      }

      //DEBT GOALS
      let DebtGoal;
      const filteredDebtData = mainCatData.find(
        (item) => item.Category === "Debts"
      );
      if (filteredDebtData) {
        const uniqueGoal = filteredDebtData.Goal;
        setBudgetDebtGoal(Number(uniqueGoal) / 100);
        DebtGoal = Number(uniqueGoal) / 100;
      } else {
        setBudgetDebtGoal(0);
        DebtGoal = 0;
      }
      const DebtPerc = Number(monthlyDebtTotal) / Number(netMonthlyIncomeTotal);
      const differenceDebt =
        (Number(DebtGoal) - Number(DebtPerc)) * Number(netMonthlyIncomeTotal);
      if (Number(differenceDebt) < 0) {
        setDebtDiffLabel("Budget Over by");
        setDebtDiffIcon(
          <ThumbDownIcon
            style={{ color: "red", fontSize: "40px", paddingTop: "0px" }}
          />
        );
      } else if (Number(differenceDebt) > 0) {
        setDebtDiffLabel("Budget Under by");
        setDebtDiffIcon(
          <ThumbUpIcon
            style={{ color: "green", fontSize: "40px", paddingTop: "0px" }}
          />
        );
      } else {
        setDebtDiffLabel("Budget Over/Under by");
        setDebtDiffIcon(
          <ThumbUpIcon
            style={{ color: "orange", fontSize: "40px", paddingTop: "0px" }}
          />
        );
      }
      setActualDebtGoal(DebtPerc);
      setDebtDifference(differenceDebt);
      if (dataFilter === "Joint") {
        setDebtDiffIcon(
          <ThumbUpIcon
            style={{
              color: "rgba(255, 253, 208, 0.5)",
              fontSize: "40px",
              paddingTop: "0px",
            }}
          />
        );
      }

      //RETIREMENT GOALS
      let RetirementGoal;
      const filteredRetirementData = mainCatData.find(
        (item) => item.Category === "Retirement"
      );
      if (filteredRetirementData) {
        const uniqueGoal = filteredRetirementData.Goal;
        setBudgetRetirementGoal(Number(uniqueGoal) / 100);
        RetirementGoal = Number(uniqueGoal) / 100;
      } else {
        setBudgetRetirementGoal(0);
        RetirementGoal = 0;
      }
      const RetirementPerc =
        Number(monthlyRetirementTotal) / Number(grossMonthlyIncomeTotal);
      const differenceRetirement =
        (-Number(RetirementGoal) + Number(RetirementPerc)) *
        Number(grossMonthlyIncomeTotal);
      if (Number(differenceRetirement) < 0) {
        setRetirementDiffLabel("Budget Under by");
        setRetirementDiffIcon(
          <ThumbDownIcon
            style={{ color: "red", fontSize: "40px", paddingTop: "0px" }}
          />
        );
      } else if (Number(differenceRetirement) > 0) {
        setRetirementDiffLabel("Budget Over by");
        setRetirementDiffIcon(
          <ThumbUpIcon
            style={{ color: "green", fontSize: "40px", paddingTop: "0px" }}
          />
        );
      } else {
        setRetirementDiffLabel("Budget Over/Under by");
        setRetirementDiffIcon(
          <ThumbUpIcon
            style={{ color: "orange", fontSize: "40px", paddingTop: "0px" }}
          />
        );
      }
      setActualRetirementGoal(RetirementPerc);
      setRetirementDifference(differenceRetirement);
      if (dataFilter === "Joint") {
        setRetirementDiffIcon(
          <ThumbUpIcon
            style={{
              color: "rgba(255, 253, 208, 0.5)",
              fontSize: "40px",
              paddingTop: "0px",
            }}
          />
        );
      }

      //SAVING GOALS
      let SavingGoal;
      const filteredSavingData = mainCatData.find(
        (item) => item.Category === "Savings"
      );
      if (filteredSavingData) {
        const uniqueGoal = filteredSavingData.Goal;
        setBudgetSavingGoal(Number(uniqueGoal) / 100);
        SavingGoal = Number(uniqueGoal) / 100;
      } else {
        setBudgetSavingGoal(0);
        SavingGoal = 0;
      }
      const SavingPerc =
        Number(monthlySavingTotal) / Number(netMonthlyIncomeTotal);
      const differenceSaving =
        (-Number(SavingGoal) + Number(SavingPerc)) *
        Number(netMonthlyIncomeTotal);
      if (Number(differenceSaving) < 0) {
        setSavingDiffLabel("Budget Under by");
        setSavingDiffIcon(
          <ThumbDownIcon
            style={{ color: "red", fontSize: "40px", paddingTop: "0px" }}
          />
        );
      } else if (Number(differenceSaving) > 0) {
        setSavingDiffLabel("Budget Over by");
        setSavingDiffIcon(
          <ThumbUpIcon
            style={{ color: "green", fontSize: "40px", paddingTop: "0px" }}
          />
        );
      } else {
        setSavingDiffLabel("Budget Over/Under by");
        setSavingDiffIcon(
          <ThumbUpIcon
            style={{ color: "orange", fontSize: "40px", paddingTop: "0px" }}
          />
        );
      }
      setActualSavingGoal(SavingPerc);
      setSavingDifference(differenceSaving);
      if (dataFilter === "Joint") {
        setSavingDiffIcon(
          <ThumbUpIcon
            style={{
              color: "rgba(255, 253, 208, 0.5)",
              fontSize: "40px",
              paddingTop: "0px",
            }}
          />
        );
      }
      setOtherIcon(
        <ThumbUpIcon
          style={{
            color: "rgba(255, 253, 208, 0.5)",
            fontSize: "40px",
            paddingTop: "0px",
          }}
        />
      );
    }
  }, [
    monthlyExpenseTotal,
    monthlyDebtTotal,
    monthlyRetirementTotal,
    monthlySavingTotal,
    grossMonthlyIncomeTotal,
    netMonthlyIncomeTotal,
    mainCatData,
    isMainCatLoaded,
  ]);

  useEffect(() => {
    if (isSubCatLoaded && isAccountLoaded) {
      const uniqueSubCats = [
        ...new Set(
          subCatData.map((item) => (item.Category ? item.Category : ""))
        ),
      ];
      setSubCategories(uniqueSubCats);

      const uniqueBankAccounts = [
        ...new Set(
          accountData.map((item) => (item.Account ? item.Account : ""))
        ),
      ];
      setBankAccounts(uniqueBankAccounts);
    }
  }, [subCatData, isSubCatLoaded, accountData, isAccountLoaded]);

  const prepareChartData = (data, subCategories) => {
    if (isDataLoaded) {
      const subCategoryColorMap = {
        "(S) Savings": "rgb(96,172,86)",
        "(N) Needs/Necessities": "#FF9800",
        "(W) Debt/Wants/Nonessentials": "#00B0F0",
      };

      const chartData = subCategories?.map((subCategory) => {
        const totalValue = data.reduce((accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          const recordSubCategory = record?.SubCategory || "";
          if (recordSubCategory === subCategory) {
            return accumulator + Number(monthlyPayment) + Number(adjustments);
          }
          return accumulator;
        }, 0);

        return {
          subCategory,
          totalValue,
          color: subCategoryColorMap[subCategory], // Corrected from statusColorMap
        };
      });

      return chartData;
    }
  };

  // Combine all datasets
  const combinedData = [
    ...updatedRetirementData,
    ...updatedSavingData,
    ...updatedDebtData,
    ...updatedExpenseData,
  ];

  // Example usage for combined data
  const combinedChartData = prepareChartData(combinedData, subCategories);
  const subCategoryColor = ["rgb(96,172,86)", "#FF9800", "#00B0F0"];

  const prepareBankChartData = (data, bankAccounts) => {
    if (isDataLoaded) {
      const chartData = bankAccounts?.map((bankAccount) => {
        const totalValue = data.reduce((accumulator, record) => {
          const deposit = record?.Deposit || 0;
          const withdrawal = record?.Withdrawal || 0;
          const recordBankAccount = record?.BankAccount || "";
          const recordCleared = record?.ClearedBank || "";
          const targetAccountData = accountData?.find(
            (item) => item.Account === bankAccount
          );
          const selAccountID = targetAccountData ? targetAccountData.id : "";
          if (
            recordBankAccount === selAccountID &&
            recordCleared === "Cleared"
          ) {
            return accumulator + Number(deposit) + Number(withdrawal);
          }
          return accumulator;
        }, 0);

        // Determine color based on totalValue
        const color = totalValue >= 0 ? "rgb(96,172,86)" : "red";

        return {
          bankAccount,
          totalValue,
          color,
        };
      });

      return chartData;
    }
  };
  // Example usage for combined data
  const bankChartData = prepareBankChartData(
    updatedBankAccountData,
    bankAccounts
  );

  const prepareActualVsBudgetChartData = (combinedData, bankAccountData) => {
    if (isDataLoaded) {
      // Get the current month
      const currentMonth = new Date().getMonth() + 1; // Months are zero-indexed

      // Calculate budget amount
      const budgetAmount = combinedData.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return (
          accumulator + Math.abs(Number(monthlyPayment) + Number(adjustments))
        );
      }, 0);

      // Calculate actual amount
      const actualAmount = bankAccountData.reduce((acc, bankRecord) => {
        const deposit = bankRecord?.Deposit || 0;
        const withdrawal = bankRecord?.Withdrawal || 0;
        const transType = bankRecord?.TransType || "";
        const recordCleared = bankRecord?.ClearedBank || "";
        const transDate = new Date(bankRecord?.TransDate);

        if (
          transType !== "Deposit" &&
          recordCleared === "Cleared" &&
          transDate.getMonth() + 1 === currentMonth
        ) {
          return acc + Math.abs(Number(deposit) + Number(withdrawal));
        }
        return acc;
      }, 0);

      // Define colors for each type
      const typeColorMap = {
        Actual: "#1565C0",
        Budget: "#474747",
      };

      // Separate entries for Actual and Budget with colors
      return [
        {
          type: "Actual",
          amount: actualAmount,
          color: typeColorMap["Actual"],
        },
        {
          type: "Budget",
          amount: budgetAmount,
          color: typeColorMap["Budget"],
        },
      ];
    }
  };
  // Example usage
  const actualVsBudgetChartData = prepareActualVsBudgetChartData(
    combinedData,
    updatedBankAccountData
  );
  const actualColors = ["#1565C0", "black"];

  const getCurrentMonthFormatted = () => {
    const currentDate = new Date();
    const options = { month: "short", year: "numeric" };
    const formattedMonth = new Intl.DateTimeFormat(
      user?.Separator,
      options
    ).format(currentDate);
    return formattedMonth;
  };
  // Example usage
  const currentMonthFormatted = getCurrentMonthFormatted();

  const chartWidth = "100%";
  const chartHeight = "100%";
  const chartWidthBar = "100%";
  const chartHeightBar = "100%";

  function actualChartTemplate(args) {
    return (
      <div id="actTemplateWrap">
        <div>{args.point.x}</div>
        <div>
          {user?.Currency}
          {args.point.y.toLocaleString(user?.Separator, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })}
        </div>
      </div>
    );
  }
  const actualTemplate = actualChartTemplate;

  function categoryChartTemplate(args) {
    return (
      <div id="catTemplateWrap">
        <div>
          {user?.Currency}
          {args.point.y.toLocaleString(user?.Separator, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })}
        </div>
      </div>
    );
  }
  const categoryTemplate = categoryChartTemplate;

  function accountChartTemplate(args) {
    return (
      <div id="accTemplateWrap">
        <div>
          {user?.Currency}
          {args.point.y.toLocaleString(user?.Separator, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })}
        </div>
      </div>
    );
  }
  const accountTemplate = accountChartTemplate;

  const markerBar = {
    dataLabel: {
      visible: true,
      template: accountTemplate,
      font: {
        color: "black",
        background: "white",
        fontWeight: "bold",
        size: "14px",
        fontFamily: "Segoe UI",
        zIndex: 99,
      },
    },
  };

  const primaryxAxisBar = {
    visible: true,
    isInversed: true,
    valueType: "Category",
    labelStyle: {
      color: "black",
      fontWeight: "normal",
      size: "14px",
      fontFamily: "Segoe UI",
    },
  };

  const primaryyAxisBar = {
    visible: true,
    MajorGridLines: { visible: true },
    //labelFormat: user?.Currency + " # ###",
    labelStyle: {
      color: "transparent", // Set the label color to transparent to hide the labels
      fontWeight: "normal",
      size: "14px",
      fontFamily: "Segoe UI",
    },
  };
  const tooltip = { enable: false, shared: false };

  const handleUpgradeButtonClick = () => {
    navigate("/packages");
  };

  return (
    <>
      <div className="projects_container">
        <div className="outer-container-dash dashboard-container">
          <div className="title-container-dash">
            <div className="mainTitle_dropdown">
              <h3>Budget Dashboard for {user?.FullName}</h3>
              <div className="_account_owner1">
                <ComboBoxComponent
                  id="comboelement"
                  value="Select Owner"
                  // headerTemplate={dropdownTemplate}
                  dataSource={["Self", "Partner", "Joint", "Household"]}
                  change={handleAccountOwnerChange}
                  style={{ color: "#FFE99B", backgroundColor: "black" }}
                />
              </div>
            </div>
          </div>
          <>
            {userPackage !== "Basic" &&
              userPackage !== "Standard" &&
              userPackage !== "Premium" && (
                <div className="title-container1">
                  <h2>This Feature is Only Available to Paid Users Only</h2>
                  <div className="button-container">
                    <div className="button-container-subA1">
                      <button
                        className="add-button"
                        onClick={handleUpgradeButtonClick}
                      >
                        <UpgradeIcon className="send-icon" />
                        Pay Now
                      </button>
                    </div>
                  </div>
                </div>
              )}
            {(userPackage === "Basic" ||
              userPackage === "Standard" ||
              userPackage === "Premium") && (
              <>
                <div className="summary-container-dash">
                  {dataFilter === "Joint" && (
                    <div className="right-summary-container-dash">
                      <div className="outer-right-summary-container-dash">
                        <div className="firstOuterRow">
                          <div className="inner-right-summary-container-dash">
                            <div className="title_icon">
                              <div className="title-summary-container-dash">
                                <h1>INCOME</h1>
                              </div>
                              <div className="_summary_icon">
                                <h1>{otherIcon}</h1>
                              </div>
                            </div>
                            <div className="InnerData">
                              <div className="marketValues">
                                {/* First Row */}
                                <div className="firstTwo">
                                  <div className="ind-right-summary-container-dash">
                                    <p className="joint-des">
                                      {grossYearlyLabel}
                                    </p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {grossYearlyIncomeTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>

                                  <div className="ind-right-summary-container-dash">
                                    <p className="joint-des">
                                      {netYearlyLabel}
                                    </p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {netYearlyIncomeTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>
                                {/* second row */}
                                <div className="secondRow">
                                  <div className="ind-right-summary-container-dash">
                                    <p className="joint-des">
                                      {grossMonthlyLabel}
                                    </p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {grossMonthlyIncomeTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p className="joint-des">
                                      {netMonthlyLabel}
                                    </p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {netMonthlyIncomeTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                              {/* goal-actual percentage */}
                              <div className="goalActual_per">
                                <div className="perc-summary-container-dash"></div>
                                <div className="perc-summary-container-dash"></div>
                              </div>
                            </div>
                          </div>

                          <div className="inner-right-summary-container-dash">
                            <div className="title_icon">
                              <div className="title-summary-container-dash">
                                <h1>EXPENSES</h1>
                              </div>
                              <div className="_summary_icon">
                                <h1>{expenseDiffIcon}</h1>
                              </div>
                            </div>

                            <div className="InnerData">
                              <div className="marketValues">
                                {/* First Row */}
                                <div className="firstTwo">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Total Market Value</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      N/A
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p>Yearly Budget Total</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {yearlyExpenseTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>

                                <div className="secondRow">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Monthly Budget Total</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {monthlyExpenseTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p>Budget Under By</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {expenseDifference.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>
                              </div>

                              <div className="goalActual_per">
                                <div className="perc-summary-container-dash">
                                  <p
                                    style={{
                                      fontSize: "14.5px",
                                      fontWeight: "bold",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Goal %
                                  </p>
                                  <div className="value">
                                    <strong style={{ fontWeight: "bold" }}>
                                      {(budgetExpenseGoal * 100).toFixed(2) +
                                        " %"}
                                    </strong>
                                  </div>
                                </div>
                                <div className="perc-summary-container-dash">
                                  <p
                                    style={{
                                      fontSize: "14.5px",
                                      fontWeight: "bold",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Actual %
                                  </p>
                                  <div className="value">
                                    <strong style={{ fontWeight: "bold" }}>
                                      {(actualExpenseGoal * 100).toFixed(2) +
                                        " %"}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* second outer row */}
                        <div className="firstOuterRow">
                          <div className="inner-right-summary-container-dash">
                            <div className="title_icon">
                              <div className="title-summary-container-dash">
                                <h1>DEBTS</h1>
                              </div>
                              <div className="_summary_icon">
                                <h1>{debtDiffIcon}</h1>
                              </div>
                            </div>

                            <div className="InnerData">
                              <div className="marketValues">
                                <div className="firstTwo">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Total Loan Balance(s)</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {loanBalance.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p>Yearly Budget Total</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {yearlyDebtTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>

                                <div className="secondRow">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Monthly Budget Total</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {monthlyDebtTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p>Budget Under By</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {debtDifference.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                              <div className="goalActual_per">
                                <div className="perc-summary-container-dash">
                                  <p
                                    style={{
                                      fontSize: "14.5px",
                                      fontWeight: "bold",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Actual %
                                  </p>
                                  <div className="value">
                                    <strong style={{ fontWeight: "bold" }}>
                                      {(actualDebtGoal * 100).toFixed(2) + " %"}
                                    </strong>
                                  </div>
                                </div>

                                <div className="perc-summary-container-dash">
                                  <p
                                    style={{
                                      fontSize: "14.5px",
                                      fontWeight: "bold",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Goal %
                                  </p>
                                  <div className="value">
                                    <strong style={{ fontWeight: "bold" }}>
                                      {(budgetDebtGoal * 100).toFixed(2) + " %"}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* retirement data */}
                          <div className="inner-right-summary-container-dash">
                            <div className="title_icon">
                              <div className="title-summary-container-dash">
                                <h1>RETIREMENT</h1>
                              </div>
                              <div className="_summary_icon">
                                <h1>{retirementDiffIcon}</h1>
                              </div>
                            </div>

                            <div className="InnerData">
                              <div className="marketValues">
                                <div className="firstTwo">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Total Market Value</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {retirementValue.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p>Yearly Budget Total</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {yearlyRetirementTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>

                                <div className="secondRow">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Monthly Budget Total</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {monthlyRetirementTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p>{retirementDiffLabel}</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {retirementDifference.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>
                              </div>

                              <div className="goalActual_per">
                                <div className="perc-summary-container-dash">
                                  <p
                                    style={{
                                      fontSize: "14.5px",
                                      fontWeight: "bold",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Actual %
                                  </p>
                                  <div className="value">
                                    <strong style={{ fontWeight: "bold" }}>
                                      {(actualRetirementGoal * 100).toFixed(2) +
                                        " %"}
                                    </strong>
                                  </div>
                                </div>

                                <div className="perc-summary-container-dash">
                                  <p
                                    style={{
                                      fontSize: "14.5px",
                                      fontWeight: "bold",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Goal %
                                  </p>
                                  <div className="value">
                                    <strong style={{ fontWeight: "bold" }}>
                                      {(budgetRetirementGoal * 100).toFixed(2) +
                                        " %"}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* ============== THIRED OUTER ROW START ================= */}
                        <div className="firstOuterRow">
                          <div className="inner-right-summary-container-dash">
                            <div className="title_icon">
                              <div className="title-summary-container-dash">
                                <h1>SAVINGS</h1>
                              </div>
                              <div className="_summary_icon">
                                <h1>{savingDiffIcon}</h1>
                              </div>
                            </div>

                            <div className="InnerData">
                              <div className="marketValues">
                                <div className="firstTwo">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Total Market Value</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {savingValue.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p>Yearly Budget Total</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {yearlySavingTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>

                                <div className="secondRow">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Monthly Budget Total</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {monthlySavingTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p>Budget Under By</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {savingDifference.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>
                              </div>

                              <div className="goalActual_per">
                                <div className="perc-summary-container-dash">
                                  <p
                                    style={{
                                      fontSize: "14.5px",
                                      fontWeight: "bold",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Actual %
                                  </p>
                                  <div className="value">
                                    <strong style={{ fontWeight: "bold" }}>
                                      {(actualSavingGoal * 100).toFixed(2) +
                                        " %"}
                                    </strong>
                                  </div>
                                </div>

                                <div className="perc-summary-container-dash">
                                  <p
                                    style={{
                                      fontSize: "14.5px",
                                      fontWeight: "bold",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Goal %
                                  </p>
                                  <div className="value">
                                    <strong style={{ fontWeight: "bold" }}>
                                      {(budgetSavingGoal * 100).toFixed(2) +
                                        " %"}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* retirement data */}
                          <div className="inner-right-summary-container-dash">
                            <div className="title_icon">
                              <div className="title-summary-container-dash">
                                <h1>NET WORTH</h1>
                              </div>
                              <div className="_summary_icon">
                                <h1>{otherIcon}</h1>
                              </div>
                            </div>

                            <div className="InnerData">
                              <div className="marketValues">
                                {userPackage === "Premium" && (
                                  <>
                                    <div className="firstTwo">
                                      <div className="ind-right-summary-container-dash">
                                        <p>Total Assets</p>
                                        {/* diagram img */}
                                        <img
                                          src={graph_vector}
                                          alt="graph-vector"
                                        />
                                        {/* amount */}
                                        <strong style={{ fontWeight: "bold" }}>
                                          {user?.Currency}
                                          {allAssetTotal.toLocaleString(
                                            "en-IN",
                                            {
                                              maximumFractionDigits: 2,
                                              minimumFractionDigits: 2,
                                            }
                                          )}
                                        </strong>
                                      </div>
                                      <div className="ind-right-summary-container-dash">
                                        <p>Total Liabilities</p>
                                        {/* diagram img */}
                                        <img
                                          src={graph_vector}
                                          alt="graph-vector"
                                        />
                                        {/* amount */}
                                        <strong style={{ fontWeight: "bold" }}>
                                          {user?.Currency}
                                          {allLiabilitiesTotal.toLocaleString(
                                            "en-IN",
                                            {
                                              maximumFractionDigits: 2,
                                              minimumFractionDigits: 2,
                                            }
                                          )}
                                        </strong>
                                      </div>
                                    </div>

                                    <div className="secondRow">
                                      <div className="ind-right-summary-container-dash">
                                        <p>Current Net Worth</p>
                                        {/* diagram img */}
                                        <img
                                          src={graph_vector}
                                          alt="graph-vector"
                                        />
                                        {/* amount */}
                                        <strong style={{ fontWeight: "bold" }}>
                                          {user?.Currency}
                                          {actualNetWorth.toLocaleString(
                                            "en-IN",
                                            {
                                              maximumFractionDigits: 2,
                                              minimumFractionDigits: 2,
                                            }
                                          )}
                                        </strong>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {userPackage !== "Premium" && (
                                  <>
                                    <div className="firstTwo">
                                      <div className="ind-right-summary-container-dash">
                                        <p>Total Assets</p>
                                        {/* diagram img */}
                                        <img
                                          src={graph_vector}
                                          alt="graph-vector"
                                        />
                                        {/* amount */}
                                        <strong style={{ fontWeight: "bold" }}>
                                          Not Available
                                        </strong>
                                      </div>
                                      <div className="ind-right-summary-container-dash">
                                        <p>Total Liabilities</p>
                                        {/* diagram img */}
                                        <img
                                          src={graph_vector}
                                          alt="graph-vector"
                                        />
                                        {/* amount */}
                                        <strong style={{ fontWeight: "bold" }}>
                                          Not Available
                                        </strong>
                                      </div>
                                    </div>

                                    <div className="secondRow">
                                      <div className="ind-right-summary-container-dash">
                                        <p>Current Net Worth</p>
                                        {/* diagram img */}
                                        <img
                                          src={graph_vector}
                                          alt="graph-vector"
                                        />
                                        {/* amount */}
                                        <strong style={{ fontWeight: "bold" }}>
                                          Not Available
                                        </strong>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* ============== THIRED OUTER ROW END  ================= */}
                      </div>
                    </div>
                  )}

                  {dataFilter !== "Joint" && (
                    <div className="right-summary-container-dash">
                      <div className="outer-right-summary-container-dash">
                        <div className="firstOuterRow">
                          <div className="inner-right-summary-container-dash">
                            <div className="title_icon">
                              <div className="title-summary-container-dash">
                                <h1>INCOME</h1>
                              </div>
                              <div className="_summary_icon">
                                <h1>{otherIcon}</h1>
                              </div>
                            </div>
                            <div className="InnerData">
                              <div className="marketValues">
                                {/* First Row */}
                                <div className="firstTwo">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Gross Yearly income</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {grossYearlyIncomeTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>

                                  <div className="ind-right-summary-container-dash">
                                    <p>Net Yearly income</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {netYearlyIncomeTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>
                                {/* second row */}
                                <div className="secondRow">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Gross Monthy income</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {grossMonthlyIncomeTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p>Net Monthy income</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {netMonthlyIncomeTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                              {/* goal-actual percentage */}
                              <div className="goalActual_per">
                                <div className="perc-summary-container-dash"></div>
                                <div className="perc-summary-container-dash"></div>
                              </div>
                            </div>
                          </div>

                          <div className="inner-right-summary-container-dash">
                            <div className="title_icon">
                              <div className="title-summary-container-dash">
                                <h1>EXPENSES</h1>
                              </div>
                              <div className="_summary_icon">
                                <h1>{expenseDiffIcon}</h1>
                              </div>
                            </div>

                            <div className="InnerData">
                              <div className="marketValues">
                                {/* First Row */}
                                <div className="firstTwo">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Total Market Value</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      N/A
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p>Yearly Budget Total</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {yearlyExpenseTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>

                                <div className="secondRow">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Monthly Budget Total</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {monthlyExpenseTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p>Budget Under By</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {expenseDifference.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>
                              </div>

                              <div className="goalActual_per">
                                <div className="perc-summary-container-dash">
                                  <p
                                    style={{
                                      fontSize: "14.5px",
                                      fontWeight: "bold",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Goal %
                                  </p>
                                  <div className="value">
                                    <strong style={{ fontWeight: "bold" }}>
                                      {(budgetExpenseGoal * 100).toFixed(2) +
                                        " %"}
                                    </strong>
                                  </div>
                                </div>
                                <div className="perc-summary-container-dash">
                                  <p
                                    style={{
                                      fontSize: "14.5px",
                                      fontWeight: "bold",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Actual %
                                  </p>
                                  <div className="value">
                                    <strong style={{ fontWeight: "bold" }}>
                                      {(actualExpenseGoal * 100).toFixed(2) +
                                        " %"}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* second outer row */}
                        <div className="firstOuterRow">
                          <div className="inner-right-summary-container-dash">
                            <div className="title_icon">
                              <div className="title-summary-container-dash">
                                <h1>DEBTS</h1>
                              </div>
                              <div className="_summary_icon">
                                <h1>{debtDiffIcon}</h1>
                              </div>
                            </div>

                            <div className="InnerData">
                              <div className="marketValues">
                                <div className="firstTwo">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Total Loan Balance(s)</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {loanBalance.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p>Yearly Budget Total</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {yearlyDebtTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>

                                <div className="secondRow">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Monthly Budget Total</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {monthlyDebtTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p>Budget Under By</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {debtDifference.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                              <div className="goalActual_per">
                                <div className="perc-summary-container-dash">
                                  <p
                                    style={{
                                      fontSize: "14.5px",
                                      fontWeight: "bold",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Actual %
                                  </p>
                                  <div className="value">
                                    <strong style={{ fontWeight: "bold" }}>
                                      {(actualDebtGoal * 100).toFixed(2) + " %"}
                                    </strong>
                                  </div>
                                </div>

                                <div className="perc-summary-container-dash">
                                  <p
                                    style={{
                                      fontSize: "14.5px",
                                      fontWeight: "bold",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Goal %
                                  </p>
                                  <div className="value">
                                    <strong style={{ fontWeight: "bold" }}>
                                      {(budgetDebtGoal * 100).toFixed(2) + " %"}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* retirement data */}
                          <div className="inner-right-summary-container-dash">
                            <div className="title_icon">
                              <div className="title-summary-container-dash">
                                <h1>RETIREMENT</h1>
                              </div>
                              <div className="_summary_icon">
                                <h1>{retirementDiffIcon}</h1>
                              </div>
                            </div>

                            <div className="InnerData">
                              <div className="marketValues">
                                <div className="firstTwo">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Total Market Value</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {retirementValue.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p>Yearly Budget Total</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {yearlyRetirementTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>

                                <div className="secondRow">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Monthly Budget Total</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {monthlyRetirementTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p>{retirementDiffLabel}</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {retirementDifference.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>
                              </div>

                              <div className="goalActual_per">
                                <div className="perc-summary-container-dash">
                                  <p
                                    style={{
                                      fontSize: "14.5px",
                                      fontWeight: "bold",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Actual %
                                  </p>
                                  <div className="value">
                                    <strong style={{ fontWeight: "bold" }}>
                                      {(actualRetirementGoal * 100).toFixed(2) +
                                        " %"}
                                    </strong>
                                  </div>
                                </div>

                                <div className="perc-summary-container-dash">
                                  <p
                                    style={{
                                      fontSize: "14.5px",
                                      fontWeight: "bold",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Goal %
                                  </p>
                                  <div className="value">
                                    <strong style={{ fontWeight: "bold" }}>
                                      {(budgetRetirementGoal * 100).toFixed(2) +
                                        " %"}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* ============== THIRED OUTER ROW START ================= */}
                        <div className="firstOuterRow">
                          <div className="inner-right-summary-container-dash">
                            <div className="title_icon">
                              <div className="title-summary-container-dash">
                                <h1>SAVINGS</h1>
                              </div>
                              <div className="_summary_icon">
                                <h1>{savingDiffIcon}</h1>
                              </div>
                            </div>

                            <div className="InnerData">
                              <div className="marketValues">
                                <div className="firstTwo">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Total Market Value</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {savingValue.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p>Yearly Budget Total</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {yearlySavingTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>

                                <div className="secondRow">
                                  <div className="ind-right-summary-container-dash">
                                    <p>Monthly Budget Total</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {monthlySavingTotal.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                  <div className="ind-right-summary-container-dash">
                                    <p>Budget Under By</p>
                                    {/* diagram img */}
                                    <img
                                      src={graph_vector}
                                      alt="graph-vector"
                                    />
                                    {/* amount */}
                                    <strong style={{ fontWeight: "bold" }}>
                                      {user?.Currency}
                                      {savingDifference.toLocaleString(
                                        user?.Separator,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </strong>
                                  </div>
                                </div>
                              </div>

                              <div className="goalActual_per">
                                <div className="perc-summary-container-dash">
                                  <p
                                    style={{
                                      fontSize: "14.5px",
                                      fontWeight: "bold",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Actual %
                                  </p>
                                  <div className="value">
                                    <strong style={{ fontWeight: "bold" }}>
                                      {(actualSavingGoal * 100).toFixed(2) +
                                        " %"}
                                    </strong>
                                  </div>
                                </div>

                                <div className="perc-summary-container-dash">
                                  <p
                                    style={{
                                      fontSize: "14.5px",
                                      fontWeight: "bold",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Goal %
                                  </p>
                                  <div className="value">
                                    <strong style={{ fontWeight: "bold" }}>
                                      {(budgetSavingGoal * 100).toFixed(2) +
                                        " %"}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* retirement data */}
                          <div className="inner-right-summary-container-dash">
                            <div className="title_icon">
                              <div className="title-summary-container-dash">
                                <h1>NET WORTH</h1>
                              </div>
                              <div className="_summary_icon">
                                <h1>{otherIcon}</h1>
                              </div>
                            </div>

                            <div className="InnerData">
                              <div className="marketValues">
                                {userPackage === "Premium" && (
                                  <>
                                    <div className="firstTwo">
                                      <div className="ind-right-summary-container-dash">
                                        <p>Total Assets</p>
                                        {/* diagram img */}
                                        <img
                                          src={graph_vector}
                                          alt="graph-vector"
                                        />
                                        {/* amount */}
                                        <strong style={{ fontWeight: "bold" }}>
                                          {user?.Currency}
                                          {allAssetTotal.toLocaleString(
                                            user?.Separator,
                                            {
                                              maximumFractionDigits: 2,
                                              minimumFractionDigits: 2,
                                            }
                                          )}
                                        </strong>
                                      </div>
                                      <div className="ind-right-summary-container-dash">
                                        <p>Total Liabilities</p>
                                        {/* diagram img */}
                                        <img
                                          src={graph_vector}
                                          alt="graph-vector"
                                        />
                                        {/* amount */}
                                        <strong style={{ fontWeight: "bold" }}>
                                          {user?.Currency}
                                          {allLiabilitiesTotal.toLocaleString(
                                            user?.Separator,
                                            {
                                              maximumFractionDigits: 2,
                                              minimumFractionDigits: 2,
                                            }
                                          )}
                                        </strong>
                                      </div>
                                    </div>

                                    <div className="secondRow">
                                      <div className="ind-right-summary-container-dash">
                                        <p>Current Net Worth</p>
                                        {/* diagram img */}
                                        <img
                                          src={graph_vector}
                                          alt="graph-vector"
                                        />
                                        {/* amount */}
                                        <strong style={{ fontWeight: "bold" }}>
                                          {user?.Currency}
                                          {actualNetWorth.toLocaleString(
                                            user?.Separator,
                                            {
                                              maximumFractionDigits: 2,
                                              minimumFractionDigits: 2,
                                            }
                                          )}
                                        </strong>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {userPackage !== "Premium" && (
                                  <>
                                    <div className="firstTwo">
                                      <div className="ind-right-summary-container-dash">
                                        <p>Total Assets</p>
                                        {/* diagram img */}
                                        <img
                                          src={graph_vector}
                                          alt="graph-vector"
                                        />
                                        {/* amount */}
                                        <strong style={{ fontWeight: "bold" }}>
                                          Not Available
                                        </strong>
                                      </div>
                                      <div className="ind-right-summary-container-dash">
                                        <p>Total Liabilities</p>
                                        {/* diagram img */}
                                        <img
                                          src={graph_vector}
                                          alt="graph-vector"
                                        />
                                        {/* amount */}
                                        <strong style={{ fontWeight: "bold" }}>
                                          Not Available
                                        </strong>
                                      </div>
                                    </div>

                                    <div className="secondRow">
                                      <div className="ind-right-summary-container-dash">
                                        <p>Current Net Worth</p>
                                        {/* diagram img */}
                                        <img
                                          src={graph_vector}
                                          alt="graph-vector"
                                        />
                                        {/* amount */}
                                        <strong style={{ fontWeight: "bold" }}>
                                          Not Available
                                        </strong>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* ============== THIRED OUTER ROW END  ================= */}
                      </div>
                    </div>
                  )}
                </div>
                <div className="chart-container">
                  <div className="chart-container-dash1">
                    <div className="ind-chart-container-overview">
                      <h2>Budget Overview</h2>
                      <AccumulationChartComponent
                        id="overview"
                        width={chartWidth}
                        height={chartHeight}
                        enableSmartLabels="true"
                        centerLabel={{
                          textStyle: {
                            size: "40px",
                            fontWeight: "900",
                            fontFamily: "Segoe UI",
                            textAlignment: "Center",
                          },
                        }}
                        legendSettings={{
                          visible: true,
                          position: "Bottom",
                        }}
                        tooltip={{
                          enable: true,
                        }}
                      >
                        <Inject
                          services={[
                            PieSeries,
                            AccumulationTooltip,
                            Category,
                            AccumulationDataLabel,
                            AccumulationLegend,
                          ]}
                        />
                        <AccumulationSeriesCollectionDirective>
                          <AccumulationSeriesDirective
                            dataSource={combinedChartData}
                            xName="subCategory"
                            yName="totalValue"
                            type="Pie"
                            name="Subcategory"
                            radius="80%"
                            //innerRadius="40%"
                            dataLabel={{
                              visible: true,
                              name: "text",
                              position: "Inside",
                              template: categoryTemplate,
                              fill: "whitesmoke",
                              textAlignment: "Center",
                              font: {
                                fontWeight: "600",
                                size: "14px",
                                color: "black",
                                fontFamily: "Segoe UI",
                                textAlignment: "Center",
                              },
                            }}
                            palettes={subCategoryColor}
                          />
                        </AccumulationSeriesCollectionDirective>
                      </AccumulationChartComponent>
                    </div>
                    <div className="ind-chart-container-actual">
                      <h2>Actual vs Budget - {currentMonthFormatted}</h2>
                      <>
                        {userPackage === "Basic" && (
                          <div className="title-container1">
                            <h2>
                              This Feature is Not Available on Basic Package
                            </h2>
                            <div className="button-container">
                              <div className="button-container-subA1">
                                <button
                                  className="add-button"
                                  onClick={handleUpgradeButtonClick}
                                >
                                  {" "}
                                  <UpgradeIcon className="send-icon" />
                                  Upgrade Now
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        {userPackage !== "Basic" && (
                          <>
                            <AccumulationChartComponent
                              id="actual-chart"
                              width={chartWidth}
                              height={chartHeight}
                              centerLabel={{
                                textStyle: {
                                  size: "40px",
                                  fontWeight: "900",
                                  fontFamily: "Segoe UI",
                                  textAlignment: "Center",
                                },
                              }}
                              legendSettings={{
                                visible: true,
                                position: "Bottom",
                              }}
                              tooltip={{
                                enable: false,
                              }}
                            >
                              <Inject
                                services={[
                                  PieSeries,
                                  AccumulationTooltip,
                                  Category,
                                  AccumulationDataLabel,
                                ]}
                              />
                              <AccumulationSeriesCollectionDirective>
                                <AccumulationSeriesDirective
                                  dataSource={actualVsBudgetChartData}
                                  xName="type"
                                  yName="amount"
                                  type="Pie"
                                  name="actual"
                                  radius="80%"
                                  innerRadius="40%"
                                  dataLabel={{
                                    visible: true,
                                    position: "Outside",
                                    format: "n2",
                                    template: actualTemplate,
                                    fill: "whitesmoke",
                                    textAlignment: "Center",
                                    font: {
                                      fontWeight: "600",
                                      size: "14px",
                                      color: "black",
                                      fontFamily: "Segoe UI",
                                      textAlignment: "Center",
                                    },
                                  }}
                                  palettes={actualColors}
                                />
                              </AccumulationSeriesCollectionDirective>
                            </AccumulationChartComponent>
                          </>
                        )}
                      </>
                    </div>
                  </div>
                  <div className="chart-container-dash2">
                    <div className="ind-chart-container-balance">
                      <div className="ind-chart-container-balance1">
                        <h2>Bank Account Balances</h2>
                        {userPackage !== "Basic" && (
                          <div className="closing-balance-dash">
                            <NumericTextBoxComponent
                              value={closingBalance}
                              format={usedCurrency}
                              showSpinButton={false}
                              disabled={false}
                              style={{
                                pointerEvents: "none",
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <>
                        {userPackage === "Basic" && (
                          <div className="title-container1">
                            <h2>
                              This Feature is Not Available on Basic Package
                            </h2>
                            <div className="button-container">
                              <div className="button-container-subA1">
                                <button
                                  className="add-button"
                                  onClick={handleUpgradeButtonClick}
                                >
                                  {" "}
                                  <UpgradeIcon className="send-icon" />
                                  Upgrade Now
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        {userPackage !== "Basic" && (
                          <>
                            <ChartComponent
                              id="balance-chart"
                              width={chartWidthBar}
                              height={chartHeightBar}
                              primaryXAxis={primaryxAxisBar}
                              primaryYAxis={primaryyAxisBar}
                              useGroupingSeparator={true}
                              tooltip={tooltip}
                            >
                              <Inject
                                services={[
                                  BarSeries,
                                  Tooltip,
                                  Category,
                                  DataLabel,
                                ]}
                              />
                              <SeriesCollectionDirective>
                                <SeriesDirective
                                  dataSource={bankChartData}
                                  xName="bankAccount"
                                  yName="totalValue"
                                  type="Bar"
                                  name="Budget"
                                  marker={markerBar}
                                  pointColorMapping="color"
                                />
                              </SeriesCollectionDirective>
                            </ChartComponent>
                          </>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
