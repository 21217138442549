import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useExpenseDataFetch = () => {
  const user = useUserStore((state) => state.user);
  const [expenseData, setExpenseData] = useState([]);
  const [isExpenseLoaded, setIsExpenseLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchExpenseData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          `/api/expense/rootuserid/${user?.RootID}`
      );
      setIsExpenseLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching Expense data:", error);
      setIsExpenseLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchExpenseData().then((ExpenseInitialData) => {
        setExpenseData(ExpenseInitialData);
      });
    else setIsExpenseLoaded(true);
  }, [user]);

  // Return an object containing all the fetched data
  return {
    expenseData,
    isExpenseLoaded,
  };
};

export default useExpenseDataFetch;
