import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const usePaymentMethodsDataFetch = () => {
  const user = useUserStore((state) => state.user);

  const [paymentMethodsData, setPaymentMethodsData] = useState([]);
  const [isPaymentMethodsLoaded, setIsPaymentMethodsLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchPaymentMethodsData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/paymentmethod"
      );
      setIsPaymentMethodsLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching PaymentMethods data:", error);
      setIsPaymentMethodsLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchPaymentMethodsData().then((PaymentMethodsInitialData) => {
        setPaymentMethodsData(PaymentMethodsInitialData);
      });
    else setIsPaymentMethodsLoaded(true);
  }, [user]);

  // Return an object containing all the fetched data
  return {
    paymentMethodsData,
    isPaymentMethodsLoaded,
  };
};

export default usePaymentMethodsDataFetch;
