import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
  Reorder,
  Resize,
  PdfExport,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";

import { DialogComponent } from "@syncfusion/ej2-react-popups";
import axios from "../../../config/axios";
import "../../shared/ListView.css";
import useAccountDataFetch from "../../shared/useAccountDataFetch";
import BankAccountNameDialog from "./BankAccountNameDialog";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";
import UpgradeIcon from "@mui/icons-material/Upgrade";

function BankAccountNames() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const userPackage = user?.Package;
  const { accountData, isAccountLoaded } = useAccountDataFetch();
  const GridRef = useRef(null);
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isAddDialogVisible, setIsAddDialogVisible] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [gridData, setGridData] = useState([]);

  useEffect(() => {
    if (isAccountLoaded) {
      accountData?.sort((a, b) => a.Account.localeCompare(b.Account));
      setGridData(accountData);
      setIsDataChanged(true);
      setIsDataLoaded(true);
    }
  }, [accountData, isAccountLoaded]);

  // Show toast when isIncomeLoaded becomes false
  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded || !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  const [selectedID, setSelectedID] = useState("");
  const [selectedRootID, setSelectedRootID] = useState("");
  const [selectedUserID, setSelectedUserID] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");

  const [newRootID, setNewRootID] = useState("");
  const [newUserID, setNewUserID] = useState("");
  const [newAccount, setNewAccount] = useState("");

  const accountNewData = {
    RootUserID: newRootID,
    RegUserID: newUserID,
    Account: newAccount,
  };

  const handleRowSelect = async (args) => {
    // Set the selected ID when a row is selected in the Grid
    setSelectedID(args.data.id);
    setSelectedRootID(args.data.RootUserID);
    setSelectedUserID(args.data.RegUserID);
    setSelectedAccount(args.data.Account);
  };

  const handleExcelButtonClick = () => {};

  const handlePdfButtonClick = () => {};

  const handleEditDialogClose = () => {
    setIsEditDialogVisible(false);
  };

  const handleAddDialogClose = () => {
    setIsAddDialogVisible(false);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    setIsEditDialogVisible(!isEditDialogVisible);
    setNewRootID(selectedRootID);
    setNewUserID(selectedUserID);
    setNewAccount(selectedAccount);
  };

  const handleAddButtonClick = () => {
    setIsAddDialogVisible(!isAddDialogVisible);
    setSelectedID("");
    setSelectedRootID("");
    setSelectedUserID("");
    setSelectedAccount("");
    if (GridRef.current) {
      GridRef.current.clearSelection();
    }
    setNewRootID(user?.RootID);
    setNewUserID(user?.id);
    setNewAccount("");
  };

  const handleDeleteButtonClick = () => {
    setIsDeleteDialogVisible(!isDeleteDialogVisible);
  };

  const handleAddRecordButton = async () => {
    // Make a POST request to add the Record record
    setIsDataChanged(false);
    try {
      const RecordResponse = await axios.post(process.env.REACT_APP_SERVER_URL + "/api/account", accountNewData);
      console.log("Record response:", RecordResponse.data);
      if (RecordResponse.data.status === "success") {
        toast.success("Added successfully.");
        // Get the UPDATED DATA by making a GET request
        const RecordResponseR = await axios.get(process.env.REACT_APP_SERVER_URL + `/api/account/reguserid/${user?.id}`);
        const Records = RecordResponseR.data.items;
        Records?.sort((a, b) => a.Account.localeCompare(b.Account));
        setGridData(Records);
      } else {
        console.error("Error adding the record record:", RecordResponse.data.message);
        toast.error("Error adding the record");
      }
    } catch (error) {
      console.error("Error adding the record:", error);
      toast.error("Error adding the record");
    }
    setIsDataChanged(true);
  };

  const handleUpdateRecordButton = async (selectedID) => {
    setIsDataChanged(false);
    const numericSelectedID = parseInt(selectedID);
    try {
      const RecordResponse = await axios.put(process.env.REACT_APP_SERVER_URL + `/api/account/${numericSelectedID}`, {
        RootUserID: newRootID,
        RegUserID: newUserID,
        Account: newAccount,
      });
      console.log("Record response:", RecordResponse.data);
      if (RecordResponse.data.status === "success") {
        toast.success("the record updated successfully.");
        // Get the UPDATED DATA by making a GET request
        const RecordResponseR = await axios.get(process.env.REACT_APP_SERVER_URL + `/api/account/reguserid/${user?.id}`);
        const Records = RecordResponseR.data.items;
        Records?.sort((a, b) => a.Account.localeCompare(b.Account));
        setGridData(Records);
      } else {
        console.error("Error updating the record record:", RecordResponse.data.message);
        toast.error("Error updating the record");
      }
    } catch (error) {
      console.error("Error updating the record:", error);
      toast.error("Error updating the record");
    }
    setIsDataChanged(true);
  };

  const handleDeleteRecordButton = async (selectedID) => {
    setIsDataChanged(false);
    const numericSelectedID = parseInt(selectedID);

    try {
      const deleteResponse = await axios.delete(process.env.REACT_APP_SERVER_URL + `/api/account/${numericSelectedID}`);

      console.log("Delete response:", deleteResponse.data);

      if (deleteResponse.data.status === "success") {
        toast.success("The record was deleted successfully.");

        try {
          const updatedResponse = await axios.get(process.env.REACT_APP_SERVER_URL + `/api/account/reguserid/${user?.id}`);
          const Records = updatedResponse.data.items;
          Records?.sort((a, b) => a.Account.localeCompare(b.Account));
          setGridData(Records);
        } catch (fetchError) {
          setGridData([]);
        }
      } else {
        console.error("Error deleting the record:", deleteResponse.data.message);
        toast.error("Error deleting item");
      }
    } catch (error) {
      console.error("Error deleting the record:", error);
      toast.error("Error deleting the record");
    }
    setIsDataChanged(true);
  };

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = [];
  const loadingIndicator = { indicatorType: "Spinner" };

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          <button className="add-button" onClick={handleAddButtonClick}>
            <AddIcon className="send-icon" /> Add New{" "}
          </button>
          <button className="edit-button" onClick={handleEditButtonClick} disabled={selectedID === ""}>
            <EditIcon className="send-icon" /> Edit{" "}
          </button>
          <button className="delete-button" onClick={handleDeleteButtonClick} disabled={selectedID === ""}>
            <DeleteIcon className="but-icon-whereabouts" /> Delete{" "}
          </button>
        </div>
        {/* <div className="button-container-subA2">
          <button className="excel-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          <button className="pdf-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button>
        </div> */}
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [...toolbarOptions, { template: renderButtons }];

  let addButtons = [
    {
      buttonModel: {
        content: "Save New",
        cssClass: "e-success update-btn",
        isPrimary: true,
      },
      click: () => {
        handleAddRecordButton(selectedID);
        setIsAddDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsAddDialogVisible(false);
      },
    },
  ];

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-success update-btn",
        isPrimary: true,
      },
      click: () => {
        handleUpdateRecordButton(selectedID);
        setIsEditDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsEditDialogVisible(false);
      },
    },
  ];

  let deleteButtons = [
    {
      buttonModel: {
        content: "Delete",
        cssClass: "e-danger update-btn",
        isPrimary: true,
      },
      click: () => {
        handleDeleteRecordButton(selectedID);
        setIsDeleteDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsDeleteDialogVisible(false);
      },
    },
  ];

  const handleUpgradeButtonClick = () => {
    navigate("/packages");
  };

  return (
    <div className="all-outer-project-containers">
      <div className="title-container">
        <div className="mainTitle_dropdown">
          <h3>Bank Account Names for {user?.FullName}</h3>
        </div>
      </div>
      <br />
      <>
        {userPackage !== "Basic" && userPackage !== "Standard" && userPackage !== "Premium" && (
          <div className="title-container1">
            <h2>This Feature is Only Available to Paid Users Only</h2>
            <div className="button-container">
              <div className="button-container-subA1">
                <button className="add-button" onClick={handleUpgradeButtonClick}>
                  {" "}
                  <UpgradeIcon className="send-icon" />
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        )}
        {(userPackage === "Basic" || userPackage === "Standard" || userPackage === "Premium") && (
          <div className="all-inner-project-containers">
            {isDataLoaded && (
              <GridComponent
                dataSource={gridData}
                childMapping="items"
                ref={GridRef}
                classClass="custom-treegrid-class"
                height="480"
                width="100%"
                allowReordering={true}
                allowResizing={true}
                allowExcelExport={true}
                allowPdfExport={true}
                allowPaging={true}
                allowSorting={true}
                allowFiltering={true}
                allowTextWrap={true}
                showColumnChooser={false}
                filterSettings={FilterOptions}
                pageSettings={pageSettings}
                editSettings={editOptions}
                toolbar={updatedToolbarOptions}
                loadingIndicator={loadingIndicator}
                allowSelection={true}
                rowSelected={handleRowSelect}
              >
                <ColumnsDirective>
                  <ColumnDirective field="Account" headerText="Bank Account Name" width="150" textAlign="Left" />
                </ColumnsDirective>
                <Inject services={[Page, Sort, Filter, Edit, Toolbar, ColumnChooser, Reorder, Resize, PdfExport, ExcelExport]} />
              </GridComponent>
            )}
          </div>
        )}
      </>
      <div>
        {isEditDialogVisible && (
          <DialogComponent
            visible={isEditDialogVisible}
            width="25%"
            height="220px"
            header="Edit Bank Account Name"
            allowDragging={true}
            showCloseIcon={true}
            close={handleEditDialogClose}
            buttons={editButtons}
          >
            <BankAccountNameDialog selectedAccount={selectedAccount} setNewAccount={setNewAccount} />
          </DialogComponent>
        )}
      </div>
      <div>
        {isAddDialogVisible && (
          <DialogComponent
            visible={isAddDialogVisible}
            width="25%"
            height="220px"
            header="Add Bank Account Name"
            allowDragging={true}
            showCloseIcon={true}
            close={handleAddDialogClose}
            buttons={addButtons}
          >
            <BankAccountNameDialog selectedAccount={selectedAccount} setNewAccount={setNewAccount} />
          </DialogComponent>
        )}
      </div>
      <div>
        {isDeleteDialogVisible && (
          <DialogComponent
            visible={isDeleteDialogVisible}
            width="25%"
            height="200px"
            header="Delete Bank Account Name"
            content={`Do you want to delete the selected Account (${selectedAccount})?`}
            allowDragging={true}
            showCloseIcon={true}
            close={handleDeleteDialogClose}
            buttons={deleteButtons}
          ></DialogComponent>
        )}
      </div>
    </div>
  );
}
export default BankAccountNames;
