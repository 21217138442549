import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useFrequencyDataFetch = () => {
  const user = useUserStore((state) => state.user);

  const [frequencyData, setFrequencyData] = useState([]);
  const [isFrequencyLoaded, setIsFrequencyLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchFrequencyData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/frequency"
      );
      setIsFrequencyLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching Frequency data:", error);
      setIsFrequencyLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchFrequencyData().then((FrequencyInitialData) => {
        setFrequencyData(FrequencyInitialData);
      });
    else setIsFrequencyLoaded(true);
  }, [user]);

  // Return an object containing all the fetched data
  return {
    frequencyData,
    isFrequencyLoaded,
  };
};

export default useFrequencyDataFetch;
