import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useFundTrackerDataFetch = () => {
  const user = useUserStore((state) => state.user);
  const [fundTrackerData, setFundTrackerData] = useState([]);
  const [isFundTrackerLoaded, setIsFundTrackerLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchFundTrackerData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          `/api/fundtracker/rootuserid/${user?.RootID}`
      );
      setIsFundTrackerLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching FundTracker data:", error);
      setIsFundTrackerLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchFundTrackerData().then((FundTrackerInitialData) => {
        setFundTrackerData(FundTrackerInitialData);
      });
    else setIsFundTrackerLoaded(true);
  }, [user]);

  // Return an object containing all the fetched data
  return {
    fundTrackerData,
    isFundTrackerLoaded,
  };
};

export default useFundTrackerDataFetch;
