import React, { useState } from "react";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";

import "./RetirementDialog.css";
import {
  customTemplate,
  dropdownTemplate,
  requiredNote,
} from "../../shared/HeaderTempate";

function DebtDialog({
  selectedAccountOwner,
  selectedBudgetItem,
  selectedDescription,
  selectedBudgetType,
  selectedDayDue,
  selectedPaymentMethod,
  selectedBankAccount,
  selectedSubCategory,
  selectedMonthlyPayment,
  selectedAdjustments,
  selectedMarketValue,
  bankAccounts,
  subCategories,
  paymentMethods,
  descriptions,
  accountData,
  usedCurrency,
  selectedContribution,
  setNewContribution,
  setNewAccountOwner,
  setNewBudgetItem,
  setNewDescription,
  setNewBudgetType,
  setNewDayDue,
  setNewPaymentMethod,
  setNewBankAccount,
  setNewSubCategory,
  setNewMonthlyPayment,
  setNewAdjustments,
  setNewMarketValue,
  isRequired,
}) {
  const [monthlyPay, setMonthlyPay] = useState(selectedMonthlyPayment);
  const [adjustment, setAdjustment] = useState(selectedAdjustments);

  const [adjustedMonthlyPay, setAdjustedMonthlyPay] = useState(
    Number(selectedMonthlyPayment) + Number(selectedAdjustments)
  );
  const [originalAnnualPay, setOriginalAnnualPay] = useState(
    Number(selectedMonthlyPayment) * 12
  );
  const [adjustedAnnualPay, setAdjustedAnnualPay] = useState(
    (Number(selectedMonthlyPayment) + Number(selectedAdjustments)) * 12
  );

  const handleDescriptionChange = (e) => {
    if (e && e.value) {
      setNewDescription(e.value);
    } else setNewDescription("");
  };

  const handleSubCategoriesChange = (e) => {
    if (e && e.value) {
      setNewSubCategory(e.value);
    } else setNewSubCategory("");
  };

  const handleAccountOwnerChange = (e) => {
    if (e && e.value) {
      setNewAccountOwner(e.value);
    } else setNewAccountOwner("");
  };

  const handleMarketValueChange = (e) => {
    if (e && e.value) {
      setNewMarketValue(e.value);
    } else setNewMarketValue("");
  };

  const handleBudgetItemChange = (e) => {
    if (e && e.value) {
      setNewBudgetItem(e.value);
    } else setNewBudgetItem("");
  };

  const handleContributionChange = (e) => {
    if (e && e.value) {
      setNewContribution(e.value);
    } else setNewContribution("");
  };

  const handleBudgetTypeChange = (e) => {
    if (e && e.value) {
      setNewBudgetType(e.value);
    } else setNewBudgetType("");
  };

  const handleBankAccountsChange = (e) => {
    if (e && e.value) {
      const accountName = e.value;
      const targetAccountData = accountData.find(
        (item) => item.Account === accountName
      );
      const selAccountID = targetAccountData ? targetAccountData.id : "";
      setNewBankAccount(selAccountID);
    } else setNewBankAccount("");
  };

  const handlePaymentMethodsChange = (e) => {
    if (e && e.value) {
      setNewPaymentMethod(e.value);
    } else setNewPaymentMethod("");
  };

  const handlePayDueChange = (e) => {
    if (e && e.value) {
      setNewDayDue(e.value);
    } else setNewDayDue("");
  };

  const handleOriginalMonthlyChange = (e) => {
    if (e && e.value) {
      setNewMonthlyPayment(e.value);
      setMonthlyPay(e.value);
      setAdjustedMonthlyPay(e.value + Number(adjustment));
      setOriginalAnnualPay(e.value * 12);
      setAdjustedAnnualPay((e.value + Number(adjustment)) * 12);
    } else setNewMonthlyPayment("");
  };

  const handleAdjustmentsChange = (e) => {
    if (e && e.value) {
      setNewAdjustments(e.value);
      setAdjustment(e.value);
      setAdjustedMonthlyPay(Number(monthlyPay) + e.value);
      setOriginalAnnualPay(Number(monthlyPay) * 12);
      setAdjustedAnnualPay((Number(monthlyPay) + e.value) * 12);
    } else setNewAdjustments(0);
  };

  return (
    <div className="main-ben-container-details1">
      <div className="beneficiary-field-container1">
        <div className="my-cap-ben-row1_ret">
          <div className="ret_budget_item">
            <ComboBoxComponent
              id="comboelement"
              placeholder="* Budget Item"
              autofill={true}
              headerTemplate={dropdownTemplate}
              value={selectedBudgetItem}
              dataSource={["Retirement Savings"]}
              change={handleBudgetItemChange}
              floatLabelType="Always"
              enabled={true}
              style={{ pointerEvents: "none" }}
            />
          </div>
          <div className="ret_description">
            <ComboBoxComponent
              id="comboelement"
              placeholder="* Retirement Description"
              headerTemplate={customTemplate}
              autofill={true}
              value={selectedDescription}
              dataSource={descriptions}
              change={handleDescriptionChange}
              floatLabelType="Always"
            />
          </div>
        </div>
        <div className="my-cap-ben-row2_ret">
          <div className="ret_account_owner">
            <ComboBoxComponent
              id="comboelement"
              value={selectedAccountOwner}
              placeholder="* Account Owner"
              autofill={true}
              headerTemplate={dropdownTemplate}
              floatLabelType="Always"
              dataSource={["Self", "Partner"]}
              change={handleAccountOwnerChange}
            />
          </div>
          <div className="ret_sub_cat">
            <ComboBoxComponent
              id="comboelement"
              value={selectedSubCategory}
              placeholder="* Subcategory"
              autofill={true}
              headerTemplate={dropdownTemplate}
              floatLabelType="Always"
              dataSource={subCategories}
              change={handleSubCategoriesChange}
            />
          </div>
          <div className="ret_budget_type">
            <ComboBoxComponent
              id="comboelement"
              value={selectedBudgetType}
              placeholder="* Budget Type"
              autofill={true}
              headerTemplate={dropdownTemplate}
              floatLabelType="Always"
              dataSource={["Committed", "Extra", "'Funds'"]}
              change={handleBudgetTypeChange}
            />
          </div>
          <div className="ret_bank_account">
            <ComboBoxComponent
              id="comboelement"
              placeholder="* Bank Account Name"
              autofill={true}
              headerTemplate={dropdownTemplate}
              value={selectedBankAccount}
              dataSource={bankAccounts}
              change={handleBankAccountsChange}
              floatLabelType="Always"
            />
          </div>
        </div>
        <div className="my-cap-ben-row4_ret">
          <div className="ret_payment_method">
            <ComboBoxComponent
              id="comboelement"
              placeholder="Payment Method"
              autofill={true}
              headerTemplate={dropdownTemplate}
              value={selectedPaymentMethod}
              dataSource={paymentMethods}
              change={handlePaymentMethodsChange}
              floatLabelType="Always"
            />
          </div>
          <div className="ret_payment_due">
            <ComboBoxComponent
              id="comboelement"
              value={selectedDayDue}
              placeholder="* Payment Due"
              autofill={true}
              headerTemplate={dropdownTemplate}
              floatLabelType="Always"
              dataSource={[
                "1st",
                "2nd",
                "3rd",
                "4th",
                "5th",
                "6th",
                "7th",
                "8th",
                "9th",
                "10th",
                "11th",
                "12th",
                "13th",
                "14th",
                "15th",
                "16th",
                "17th",
                "18th",
                "19th",
                "20th",
                "21st",
                "22nd",
                "23rd",
                "24th",
                "25th",
                "26th",
                "27th",
                "28th",
                "29th",
                "30th",
                "31st",
                "N/A",
              ]}
              change={handlePayDueChange}
            />
          </div>
          <div className="ret_budget_type">
            <ComboBoxComponent
              id="comboelement"
              value={selectedContribution}
              placeholder="* Contribution on Net Income"
              autofill={true}
              headerTemplate={dropdownTemplate}
              floatLabelType="Always"
              dataSource={["Yes", "No"]}
              change={handleContributionChange}
            />
          </div>
        </div>
        <div className="my-cap-ben-row4_ret">
          <div className="ret_loan_balance">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={selectedMarketValue}
              placeholder="* Market Value"
              floatLabelType="Always"
              showSpinButton={false}
              change={handleMarketValueChange}
            />
          </div>
          <div className="ret_monthly_pay">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={selectedMonthlyPayment}
              placeholder="* Original Monthly Payment"
              floatLabelType="Always"
              showSpinButton={false}
              change={handleOriginalMonthlyChange}
            />
          </div>
          <div className="ret_adjustment">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={selectedAdjustments}
              placeholder=" Adjustment"
              floatLabelType="Always"
              change={handleAdjustmentsChange}
            />
          </div>
        </div>
        <div className="my-cap-ben-row3_ret">
          <div className="ret_adjusted_pay">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={adjustedMonthlyPay}
              placeholder="Adjusted Monthly Payment"
              floatLabelType="Always"
              showSpinButton={false}
              disabled={false}
              style={{
                pointerEvents: "none",
              }}
            />
          </div>
          <div className="ret_original_deb">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={originalAnnualPay}
              placeholder="Original Annual Payment"
              floatLabelType="Always"
              showSpinButton={false}
              disabled={false}
              style={{
                pointerEvents: "none",
              }}
            />
          </div>
          <div className="ret_adjusted_deb">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={adjustedAnnualPay}
              placeholder="Adjusted Annual Payment"
              floatLabelType="Always"
              showSpinButton={false}
              disabled={false}
              style={{
                pointerEvents: "none",
              }}
            />
          </div>
        </div>
        {isRequired && <div className="noteContainer">{requiredNote()}</div>}
      </div>
    </div>
  );
}

export default DebtDialog;
