import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";

import "./BankRecon.css";
import useBankAccountDataFetch from "../../shared/useBankAccountDataFetch";
import useAccountDataFetch from "../../shared/useAccountDataFetch";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";
import UpgradeIcon from "@mui/icons-material/Upgrade";

function BankRecon() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const { bankAccountData, isBankAccountLoaded } = useBankAccountDataFetch();
  const { accountData, isAccountLoaded } = useAccountDataFetch();
  const [ownerFilter, setOwnerFilter] = useState("Household");
  const [bankFilter, setBankFilter] = useState("");
  const [yearFilter, setYearFilter] = useState("");
  const [monthFilter, setMonthFilter] = useState("");
  const [openingBalance, setOpeningBalance] = useState(0);
  const [closingBalance, setClosingBalance] = useState(0);
  const [endingBalance, setEndingBalance] = useState(0);
  const [yearData, setYearData] = useState("");
  const [unclearedDeposit, setUnclearedDeposit] = useState(0);
  const [unclearedWithdrawal, setUnclearedWidrawal] = useState(0);
  const [filterBankAccounts, setFilterBankAccounts] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [updatedBankAccountData, setUpdatedBankAccountData] = useState([]);
  const usedCurrency = user?.Currency + " #,###.00";
  const userPackage = user?.Package;

  const handleEndingBalanceChange = (e) => {
    if (e && e.value) {
      setEndingBalance(e.value);
    } else {
      setEndingBalance(0);
    }
  };

  const handleAccountOwnerChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setOwnerFilter(e.value);
      const accountOwner = e.value;
      let finalBankAccount;
      if (user?.Type !== "Root") {
        finalBankAccount = updatedBankAccountData?.filter(
          (item) => item.UserID === user?.id
        );
      } else {
        finalBankAccount = updatedBankAccountData;
      }
      let filteredData;
      if (
        accountOwner === "Self" ||
        accountOwner === "Partner" ||
        accountOwner === "Joint"
      ) {
        filteredData = finalBankAccount?.filter(
          (item) => item.AccountOwner === accountOwner
        );
      } else {
        filteredData = finalBankAccount;
      }

      let filteredBankData;
      if (bankFilter !== "" && bankFilter !== "All") {
        filteredBankData = filteredData.filter(
          (item) => item.BankAccount === bankFilter
        );
      } else {
        filteredBankData = filteredData;
      }

      let filteredYearData;
      if (yearFilter !== "" && yearFilter !== "All") {
        filteredYearData = filteredBankData.filter((item) => {
          const transDate = new Date(item.TransDate);
          return transDate.getFullYear() === parseInt(yearFilter);
        });
      } else {
        filteredYearData = filteredBankData;
      }

      let filteredMonthData;
      if (monthFilter !== "" && monthFilter !== "All") {
        filteredMonthData = filteredYearData.filter((item) => {
          const transDate = new Date(item.TransDate);
          return transDate.getMonth() + 1 === parseInt(monthFilter);
        });
      } else {
        filteredMonthData = filteredYearData;
      }

      // Get items in filteredBankData but not in filteredMonthData
      //const itemsNotInMonthData = filteredBankData.filter((item) => !filteredMonthData.includes(item));
      // Assuming TransDate is a Date object, adjust as needed
      const itemsNotInMonthData = filteredBankData.filter((item) =>
        filteredMonthData?.every(
          (monthItem) => item.TransDate < monthItem.TransDate
        )
      );

      // Calculate opening balance
      const balanceBefore = itemsNotInMonthData.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      // Calculate closing balance
      const balanceAfter = filteredMonthData.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      const totalBalance = Number(balanceBefore) + Number(balanceAfter);

      // Calculate Uncleared Deposit
      const unclearedDepositBefore = itemsNotInMonthData.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const deposit = item.Deposit || 0;
            return accumulator + Number(deposit);
          }
          return accumulator;
        },
        0
      );
      const unclearedDepositAfter = filteredMonthData.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const deposit = item.Deposit || 0;
            return accumulator + Number(deposit);
          }
          return accumulator;
        },
        0
      );
      const totalUnclearedDeposit =
        Number(unclearedDepositBefore) + Number(unclearedDepositAfter);

      // Calculate Uncleared Withdrawals
      const unclearedWithdrawalBefore = itemsNotInMonthData.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const withdrawal = item.Withdrawal || 0;
            return accumulator + Number(withdrawal);
          }
          return accumulator;
        },
        0
      );
      const unclearedWithdrawalAfter = filteredMonthData.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const withdrawal = item.Withdrawal || 0;
            return accumulator + Number(withdrawal);
          }
          return accumulator;
        },
        0
      );
      const totalUnclearedWithdrawal =
        Number(unclearedWithdrawalBefore) + Number(unclearedWithdrawalAfter);

      if (filteredMonthData.length > 0) {
        setOpeningBalance(balanceBefore);
        setClosingBalance(totalBalance);
        setUnclearedDeposit(totalUnclearedDeposit);
        setUnclearedWidrawal(totalUnclearedWithdrawal);
      } else {
        setOpeningBalance(0);
        setClosingBalance(0);
        setUnclearedDeposit(0);
        setUnclearedWidrawal(0);
      }
      setIsDataChanged(true);
    }
  };

  const handleBankAccountChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      const accountName = e.value;
      let bankAccount = "";
      if (accountName !== "" && accountName !== "All") {
        const targetAccountData = accountData.find(
          (item) => item.Account === accountName
        );
        const selAccountID = targetAccountData ? targetAccountData.id : "";
        setBankFilter(selAccountID);
        bankAccount = selAccountID;
      } else {
        setBankFilter("");
      }

      let finalBankAccount;
      if (user?.Type !== "Root") {
        finalBankAccount = updatedBankAccountData?.filter(
          (item) => item.UserID === user?.id
        );
      } else {
        finalBankAccount = updatedBankAccountData;
      }

      let filteredData;
      if (
        ownerFilter === "Self" ||
        ownerFilter === "Partner" ||
        ownerFilter === "Joint"
      ) {
        filteredData = finalBankAccount?.filter(
          (item) => item.AccountOwner === ownerFilter
        );
      } else {
        filteredData = finalBankAccount;
      }

      let filteredBankData;
      if (bankAccount !== "" && bankAccount !== "All") {
        filteredBankData = filteredData.filter(
          (item) => item.BankAccount === bankAccount
        );
      } else {
        filteredBankData = filteredData;
      }

      let filteredYearData;
      if (yearFilter !== "" && yearFilter !== "All") {
        filteredYearData = filteredBankData.filter((item) => {
          const transDate = new Date(item.TransDate);
          return transDate.getFullYear() === parseInt(yearFilter);
        });
      } else {
        filteredYearData = filteredBankData;
      }

      let filteredMonthData;
      if (monthFilter !== "" && monthFilter !== "All") {
        filteredMonthData = filteredYearData.filter((item) => {
          const transDate = new Date(item.TransDate);
          return transDate.getMonth() + 1 === parseInt(monthFilter);
        });
      } else {
        filteredMonthData = filteredYearData;
      }

      // Get items in filteredBankData but not in filteredMonthData
      //const itemsNotInMonthData = filteredBankData.filter((item) => !filteredMonthData.includes(item));
      // Assuming TransDate is a Date object, adjust as needed
      const itemsNotInMonthData = filteredBankData.filter((item) =>
        filteredMonthData?.every(
          (monthItem) => item.TransDate < monthItem.TransDate
        )
      );

      // Calculate opening balance
      const balanceBefore = itemsNotInMonthData.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      // Calculate closing balance
      const balanceAfter = filteredMonthData.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      const totalBalance = Number(balanceBefore) + Number(balanceAfter);

      // Calculate Uncleared Deposit
      const unclearedDepositBefore = itemsNotInMonthData.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const deposit = item.Deposit || 0;
            return accumulator + Number(deposit);
          }
          return accumulator;
        },
        0
      );
      const unclearedDepositAfter = filteredMonthData.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const deposit = item.Deposit || 0;
            return accumulator + Number(deposit);
          }
          return accumulator;
        },
        0
      );
      const totalUnclearedDeposit =
        Number(unclearedDepositBefore) + Number(unclearedDepositAfter);

      // Calculate Uncleared Withdrawals
      const unclearedWithdrawalBefore = itemsNotInMonthData.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const withdrawal = item.Withdrawal || 0;
            return accumulator + Number(withdrawal);
          }
          return accumulator;
        },
        0
      );
      const unclearedWithdrawalAfter = filteredMonthData.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const withdrawal = item.Withdrawal || 0;
            return accumulator + Number(withdrawal);
          }
          return accumulator;
        },
        0
      );
      const totalUnclearedWithdrawal =
        Number(unclearedWithdrawalBefore) + Number(unclearedWithdrawalAfter);

      if (filteredMonthData.length > 0) {
        setOpeningBalance(balanceBefore);
        setClosingBalance(totalBalance);
        setUnclearedDeposit(totalUnclearedDeposit);
        setUnclearedWidrawal(totalUnclearedWithdrawal);
      } else {
        setOpeningBalance(0);
        setClosingBalance(0);
        setUnclearedDeposit(0);
        setUnclearedWidrawal(0);
      }
      setIsDataChanged(true);
    }
  };

  const handleYearChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      const yearName = e.value;
      setYearFilter(e.value);
      let finalBankAccount;
      if (user?.Type !== "Root") {
        finalBankAccount = updatedBankAccountData?.filter(
          (item) => item.UserID === user?.id
        );
      } else {
        finalBankAccount = updatedBankAccountData;
      }

      let filteredData;
      if (
        ownerFilter === "Self" ||
        ownerFilter === "Partner" ||
        ownerFilter === "Joint"
      ) {
        filteredData = finalBankAccount?.filter(
          (item) => item.AccountOwner === ownerFilter
        );
      } else {
        filteredData = finalBankAccount;
      }

      let filteredBankData;
      if (bankFilter !== "" && bankFilter !== "All") {
        filteredBankData = filteredData.filter(
          (item) => item.BankAccount === bankFilter
        );
      } else {
        filteredBankData = filteredData;
      }

      let filteredYearData;
      if (yearName !== "" && yearName !== "All") {
        filteredYearData = filteredBankData.filter((item) => {
          const transDate = new Date(item.TransDate);
          return transDate.getFullYear() === parseInt(yearName);
        });
      } else {
        filteredYearData = filteredBankData;
      }

      let filteredMonthData;
      if (monthFilter !== "" && monthFilter !== "All") {
        filteredMonthData = filteredYearData.filter((item) => {
          const transDate = new Date(item.TransDate);
          return transDate.getMonth() + 1 === parseInt(monthFilter);
        });
      } else {
        filteredMonthData = filteredYearData;
      }

      // Get items in filteredBankData but not in filteredMonthData
      //const itemsNotInMonthData = filteredBankData.filter((item) => !filteredMonthData.includes(item));
      // Assuming TransDate is a Date object, adjust as needed
      const itemsNotInMonthData = filteredBankData.filter((item) =>
        filteredMonthData?.every(
          (monthItem) => item.TransDate < monthItem.TransDate
        )
      );

      // Calculate opening balance
      const balanceBefore = itemsNotInMonthData.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      // Calculate closing balance
      const balanceAfter = filteredMonthData.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      const totalBalance = Number(balanceBefore) + Number(balanceAfter);

      // Calculate Uncleared Deposit
      const unclearedDepositBefore = itemsNotInMonthData.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const deposit = item.Deposit || 0;
            return accumulator + Number(deposit);
          }
          return accumulator;
        },
        0
      );
      const unclearedDepositAfter = filteredMonthData.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const deposit = item.Deposit || 0;
            return accumulator + Number(deposit);
          }
          return accumulator;
        },
        0
      );
      const totalUnclearedDeposit =
        Number(unclearedDepositBefore) + Number(unclearedDepositAfter);

      // Calculate Uncleared Withdrawals
      const unclearedWithdrawalBefore = itemsNotInMonthData.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const withdrawal = item.Withdrawal || 0;
            return accumulator + Number(withdrawal);
          }
          return accumulator;
        },
        0
      );
      const unclearedWithdrawalAfter = filteredMonthData.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const withdrawal = item.Withdrawal || 0;
            return accumulator + Number(withdrawal);
          }
          return accumulator;
        },
        0
      );
      const totalUnclearedWithdrawal =
        Number(unclearedWithdrawalBefore) + Number(unclearedWithdrawalAfter);

      if (filteredMonthData.length > 0) {
        setOpeningBalance(balanceBefore);
        setClosingBalance(totalBalance);
        setUnclearedDeposit(totalUnclearedDeposit);
        setUnclearedWidrawal(totalUnclearedWithdrawal);
      } else {
        setOpeningBalance(0);
        setClosingBalance(0);
        setUnclearedDeposit(0);
        setUnclearedWidrawal(0);
      }
      setIsDataChanged(true);
    }
  };

  function getMonthNumber(monthName) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const normalizedMonthName = monthName.trim().toLowerCase();
    const monthIndex = months.findIndex(
      (month) => month.toLowerCase() === normalizedMonthName
    );
    // Adding 1 to convert from 0-based index to 1-based month number
    return monthIndex !== -1 ? monthIndex + 1 : null;
  }

  const handleMonthChange = (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      const monthName = e.value;
      let selMonthNumber = "";

      const validMonths = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (validMonths.includes(monthName)) {
        const monthNumber = getMonthNumber(monthName);
        setMonthFilter(monthNumber);
        selMonthNumber = monthNumber;
      } else {
        setMonthFilter("");
      }

      let finalBankAccount;
      if (user?.Type !== "Root") {
        finalBankAccount = updatedBankAccountData?.filter(
          (item) => item.UserID === user?.id
        );
      } else {
        finalBankAccount = updatedBankAccountData;
      }

      let filteredData;
      if (
        ownerFilter === "Self" ||
        ownerFilter === "Partner" ||
        ownerFilter === "Joint"
      ) {
        filteredData = finalBankAccount?.filter(
          (item) => item.AccountOwner === ownerFilter
        );
      } else {
        filteredData = finalBankAccount;
      }

      let filteredBankData;
      if (bankFilter !== "" && bankFilter !== "All") {
        filteredBankData = filteredData.filter(
          (item) => item.BankAccount === bankFilter
        );
      } else {
        filteredBankData = filteredData;
      }

      let filteredYearData;
      if (yearFilter !== "" && yearFilter !== "All") {
        filteredYearData = filteredBankData.filter((item) => {
          const transDate = new Date(item.TransDate);
          return transDate.getFullYear() === parseInt(yearFilter);
        });
      } else {
        filteredYearData = filteredBankData;
      }

      let filteredMonthData;
      if (selMonthNumber !== "" && selMonthNumber !== "All") {
        filteredMonthData = filteredYearData.filter((item) => {
          const transDate = new Date(item.TransDate);
          return transDate.getMonth() + 1 === parseInt(selMonthNumber);
        });
      } else {
        filteredMonthData = filteredYearData;
      }

      // Get items in filteredBankData but not in filteredMonthData
      //const itemsNotInMonthData = filteredBankData.filter((item) => !filteredMonthData.includes(item));
      // Assuming TransDate is a Date object, adjust as needed
      const itemsNotInMonthData = filteredBankData.filter((item) =>
        filteredMonthData?.every(
          (monthItem) => item.TransDate < monthItem.TransDate
        )
      );

      // Calculate opening balance
      const balanceBefore = itemsNotInMonthData.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      // Calculate closing balance
      const balanceAfter = filteredMonthData.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      const totalBalance = Number(balanceBefore) + Number(balanceAfter);

      // Calculate Uncleared Deposit
      const unclearedDepositBefore = itemsNotInMonthData.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const deposit = item.Deposit || 0;
            return accumulator + Number(deposit);
          }
          return accumulator;
        },
        0
      );
      const unclearedDepositAfter = filteredMonthData.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const deposit = item.Deposit || 0;
            return accumulator + Number(deposit);
          }
          return accumulator;
        },
        0
      );
      const totalUnclearedDeposit =
        Number(unclearedDepositBefore) + Number(unclearedDepositAfter);

      // Calculate Uncleared Withdrawals
      const unclearedWithdrawalBefore = itemsNotInMonthData.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const withdrawal = item.Withdrawal || 0;
            return accumulator + Number(withdrawal);
          }
          return accumulator;
        },
        0
      );
      const unclearedWithdrawalAfter = filteredMonthData.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const withdrawal = item.Withdrawal || 0;
            return accumulator + Number(withdrawal);
          }
          return accumulator;
        },
        0
      );
      const totalUnclearedWithdrawal =
        Number(unclearedWithdrawalBefore) + Number(unclearedWithdrawalAfter);

      if (filteredMonthData.length > 0) {
        setOpeningBalance(balanceBefore);
        setClosingBalance(totalBalance);
        setUnclearedDeposit(totalUnclearedDeposit);
        setUnclearedWidrawal(totalUnclearedWithdrawal);
      } else {
        setOpeningBalance(0);
        setClosingBalance(0);
        setUnclearedDeposit(0);
        setUnclearedWidrawal(0);
      }
      setIsDataChanged(true);
    }
  };

  useEffect(() => {
    if (isBankAccountLoaded && isAccountLoaded) {
      setUpdatedBankAccountData(bankAccountData);
      let finalBankAccount;
      if (user?.Type !== "Root") {
        finalBankAccount = bankAccountData.filter(
          (item) => item.UserID === user?.id
        );
      } else {
        finalBankAccount = bankAccountData;
      }
      finalBankAccount?.sort(
        (a, b) => new Date(a.TransDate) - new Date(b.TransDate)
      );

      // Calculate closing balance
      const balanceAfter = finalBankAccount.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      // Calculate Uncleared Deposit
      const unclearedDepositTotal = finalBankAccount.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const deposit = item.Deposit || 0;
            return accumulator + Number(deposit);
          }
          return accumulator;
        },
        0
      );

      // Calculate Uncleared Withdrawals
      const unclearedWithdrawalTotal = finalBankAccount.reduce(
        (accumulator, item) => {
          if (item.ClearedBank !== "Cleared") {
            const withdrawal = item.Withdrawal || 0;
            return accumulator + Number(withdrawal);
          }
          return accumulator;
        },
        0
      );

      // Get unique years
      const uniqueYears = [
        ...new Set(
          finalBankAccount.map((item) => {
            const date = new Date(item.TransDate);
            return isNaN(date) ? null : date.getFullYear()?.toString(); // Convert to string
          })
        ),
      ].filter((year) => year !== null);
      const formattedYearAccounts = ["All", ...uniqueYears];

      const uniqueBankAccounts = [
        ...new Set(
          accountData.map((item) => (item.Account ? item.Account : ""))
        ),
      ];
      const formattedBankAccounts = ["All", ...uniqueBankAccounts];
      setFilterBankAccounts(formattedBankAccounts);

      setYearData(formattedYearAccounts);
      setOpeningBalance(0);
      setClosingBalance(balanceAfter);
      setUnclearedDeposit(unclearedDepositTotal);
      setUnclearedWidrawal(unclearedWithdrawalTotal);
      setIsDataChanged(true);
      setIsDataLoaded(true);
    }
  }, [
    bankAccountData,
    accountData,
    isBankAccountLoaded,
    isAccountLoaded,
    user,
  ]);

  // Show toast when isIncomeLoaded becomes false
  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded || !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  const months = [
    "All",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleUpgradeButtonClick = () => {
    navigate("/packages");
  };

  return (
    <div className="all-outer-project-containers">
      <div className="title-container">
        <div className="mainTitle_dropdown">
          <h3>Bank Reconciliation for {user?.FullName}</h3>
          <div className="_account_owner1">
            <ComboBoxComponent
              id="comboelement"
              value="Select Owner"
              // placeholder="* Account Owner"
              floatLabelType="Always"
              dataSource={["Self", "Partner", "Joint", "Household"]}
              change={handleAccountOwnerChange}
              style={{ color: "#FFE99B", backgroundColor: "black" }}
            />
          </div>
          <div className="_account_owner1">
            <ComboBoxComponent
              id="comboelement"
              value="Select Year"
              // placeholder="* Year"
              floatLabelType="Always"
              dataSource={yearData}
              change={handleYearChange}
              style={{ color: "#FFE99B", backgroundColor: "black" }}
            />
          </div>
        </div>
      </div>
      <>
        {userPackage !== "Basic" &&
          userPackage !== "Standard" &&
          userPackage !== "Premium" && (
            <div className="title-container1">
              <h2>This Feature is Only Available to Paid Users Only</h2>
              <div className="button-container">
                <div className="button-container-subA1">
                  <button
                    className="add-button"
                    onClick={handleUpgradeButtonClick}
                  >
                    {" "}
                    <UpgradeIcon className="send-icon" />
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          )}
        {(userPackage === "Basic" ||
          userPackage === "Standard" ||
          userPackage === "Premium") && (
          <>
            {userPackage === "Basic" && (
              <div className="title-container1">
                <h2>This Feature is Not Available on Basic Package</h2>
                <div className="button-container">
                  <div className="button-container-subA1">
                    <button
                      className="add-button"
                      onClick={handleUpgradeButtonClick}
                    >
                      {" "}
                      <UpgradeIcon className="send-icon" />
                      Upgrade Now
                    </button>
                  </div>
                </div>
              </div>
            )}
            {userPackage !== "Basic" && (
              <>
                <br />
                <div className="inner-recorn-containers">
                  <div className="Bank-account-container">
                    <div className="bank1-content">
                      <div className="bank2-input-group">
                        <div className="recorn_input">
                          <ComboBoxComponent
                            value="All"
                            dataSource={filterBankAccounts}
                            change={handleBankAccountChange}
                            style={{
                              color: "#FFE99B",
                              backgroundColor: "black",
                            }}
                          />
                        </div>
                        <h2>
                          <KeyboardDoubleArrowLeftIcon /> Choose Bank Account *
                        </h2>
                      </div>
                      <div className="bank3-input-group">
                        <h2>
                          * Choose Statement Month{" "}
                          <KeyboardDoubleArrowRightIcon />
                        </h2>
                        <div className="_input">
                          <ComboBoxComponent
                            value="All"
                            dataSource={months}
                            change={handleMonthChange}
                            style={{
                              color: "#FFE99B",
                              backgroundColor: "black",
                            }}
                          />
                        </div>
                      </div>
                      <div className="bank3-input-group">
                        <h2>
                          * Type Bank Statement Ending Balance{" "}
                          <KeyboardDoubleArrowRightIcon />
                        </h2>
                        <div className="_input">
                          <NumericTextBoxComponent
                            value={endingBalance}
                            format={usedCurrency}
                            change={handleEndingBalanceChange}
                            showSpinButton={false}
                            style={{
                              color: "#FFE99B",
                              backgroundColor: "black",
                            }}
                          />
                        </div>
                      </div>
                      <div className="bank1-input-group">
                        <h2>Add Deposit(s) that haven't cleared the bank</h2>
                        <div className="_input">
                          <NumericTextBoxComponent
                            value={unclearedDeposit}
                            format={usedCurrency}
                            showSpinButton={false}
                            readOnly
                            style={{
                              color: "#FFE99B",
                              backgroundColor: "black",
                            }}
                          />
                        </div>
                      </div>
                      <div className="bank1-input-group">
                        <h2>
                          Subtract outstanding check(s) & withdrawal(s) that
                          haven't cleared the bank
                        </h2>
                        <div className="_input">
                          <NumericTextBoxComponent
                            value={unclearedWithdrawal}
                            format={usedCurrency}
                            showSpinButton={false}
                            readOnly
                            style={{
                              color: "#FFE99B",
                              backgroundColor: "black",
                            }}
                          />
                        </div>
                      </div>
                      <div className="bank1-input-group">
                        <h2>Reconciled Cash Balance</h2>
                        <div className="_input">
                          <NumericTextBoxComponent
                            value={
                              Number(endingBalance) +
                              Number(unclearedWithdrawal) +
                              Number(unclearedDeposit)
                            }
                            format={usedCurrency}
                            showSpinButton={false}
                            readOnly
                            style={{
                              color: "#FFE99B",
                              backgroundColor: "black",
                            }}
                          />
                        </div>
                      </div>
                      <div className="bank1-input-group">
                        <h2>
                          Difference between Reconciled Cash Balance and Check
                          Register Balance (MUST BE 0 TO BE RECONCILED)
                        </h2>
                        <div className="_input">
                          <NumericTextBoxComponent
                            value={
                              Number(endingBalance) +
                              Number(unclearedWithdrawal) +
                              Number(unclearedDeposit) -
                              Number(closingBalance)
                            }
                            format={usedCurrency}
                            showSpinButton={false}
                            readOnly
                            style={{
                              color: "#FFE99B",
                              backgroundColor: "black",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    </div>
  );
}
export default BankRecon;
