import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";

import axios from "../../../config/axios";
import "../../shared/ListView.css";
import "./UserProfile.css";
import UserDialog from "./UserDialog";
import EditIcon from "@mui/icons-material/Edit";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import UpgradeIcon from "@mui/icons-material/Upgrade";

function UserProfile() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const userPackage = user?.Package;
  const updateUserData = useUserStore((state) => state.updateUserData);
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(true);
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
  }
  const newCurrentDate = formatDate(new Date());

  // Show toast when isIncomeLoaded becomes false
  useEffect(() => {
    let loadingToastId;
    if (!isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataChanged]);

  const selectedID = user?.id;
  const selectedRootID = user?.RootID;
  const selectedFullName = user?.FullName;
  const selectedSelfDOB = user?.SelfDOB;
  const selectedPartnerDOB = user?.PartnerDOB;
  const selectedEmail = user?.Email;
  const selectedSeparator = user?.Separator;
  const selectedCity = user?.City;
  const selectedState = user?.State;
  const selectedCountry = user?.Country;
  const selectedPassword = "";
  const selectedStatus = user?.Status;
  const selectedType = user?.Type;
  const selectedCurrency = user?.Currency;
  const selectedDateFormat = user?.DateFormat;
  const selectedProfile = user?.Profile;

  const [newRootID, setNewRootID] = useState("");
  const [newFullName, setNewFullName] = useState("");
  const [newSelfDOB, setNewSelfDOB] = useState("");
  const [newPartnerDOB, setNewPartnerDOB] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newSeparator, setNewSeparator] = useState("");
  const [newCity, setNewCity] = useState("");
  const [newState, setNewState] = useState("");
  const [newCountry, setNewCountry] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [newType, setNewType] = useState("");
  const [newCurrency, setNewCurrency] = useState("");
  const [newDateFormat, setNewDateFormat] = useState("");
  const [newProfile, setNewProfile] = useState("");

  const handleEditDialogClose = () => {
    setIsEditDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    setIsEditDialogVisible(!isEditDialogVisible);
    setNewRootID(selectedRootID);
    setNewFullName(selectedFullName);
    setNewSelfDOB(selectedSelfDOB);
    setNewPartnerDOB(selectedPartnerDOB);
    setNewEmail(selectedEmail);
    setNewSeparator(selectedSeparator);
    setNewCity(selectedCity);
    setNewState(selectedState);
    setNewCountry(selectedCountry);
    setNewPassword(selectedPassword);
    setNewStatus(selectedStatus);
    setNewType(selectedType);
    setNewCurrency(selectedCurrency);
    setNewDateFormat(selectedDateFormat);
    setNewProfile(selectedProfile);
  };

  const handleUpdateRecordButton = async () => {
    if (newPassword && newPassword.length < 8) {
      toast.error("Password must be at least 8 characters logn");
      return;
    }

    setIsDataChanged(false);
    const numericSelectedID = parseInt(selectedID);

    try {
      const RecordResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/rootuser/${numericSelectedID}`,
        {
          RootID: newRootID,
          FullName: newFullName,
          SelfDOB: newSelfDOB,
          PartnerDOB: newPartnerDOB,
          Email: newEmail,
          Separator: newSeparator,
          City: newCity,
          State: newState,
          Country: newCountry,
          Password: newPassword,
          Status: newStatus,
          Type: newType,
          Currency: newCurrency,
          DateFormat: newDateFormat,
          UpdatedOn: newCurrentDate,
          Profile: newProfile,
        }
      );
      if (RecordResponse.data.status === "success") {
        toast.success("the record updated successfully.");
        // Get the UPDATED DATA by making a GET request
        const RecordResponseR = await axios.get(
          process.env.REACT_APP_SERVER_URL +
            `/api/reguser/rootuserid/${user?.id}`
        );
        const Records = RecordResponseR.data.items;
        Records.sort((a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn));
        updateUserData(RecordResponse.data.items);
        setIsEditDialogVisible(false);
      } else {
        console.error(
          "Error updating the record record:",
          RecordResponse.data.message
        );
        toast.error("Error updating the record");
      }
    } catch (error) {
      console.error("Error updating the record:", error);
      toast.error("Error updating the record");
    }
    setIsDataChanged(true);
  };

  // let editButtons = [
  //   {
  //     buttonModel: {
  //       content: "Update",
  //       cssClass: "e-flat",
  //       isPrimary: true,
  //     },
  //     click: () => {
  //       handleUpdateRecordButton(selectedID);
  //     },
  //   },
  //   {
  //     buttonModel: {
  //       content: "Cancel",
  //       cssClass: "e-flat",
  //     },
  //     click: () => {
  //       setIsEditDialogVisible(false);
  //     },
  //   },
  // ];

  const handleUpgradeButtonClick = () => {
    navigate("/packages");
  };

  return (
    <div className="outer-user-profile-containers">
      <div className="title-container">
        <div className="mainTitle_dropdown">
          <h3>Profile for {user?.FullName}</h3>
        </div>
      </div>
      <>
        {userPackage !== "Basic" &&
          userPackage !== "Standard" &&
          userPackage !== "Premium" && (
            <div className="title-container1">
              <h2>This Feature is Only Available to Paid Users Only</h2>
              <div className="button-container">
                <div className="button-container-subA1">
                  <button
                    className="add-button"
                    onClick={handleUpgradeButtonClick}
                  >
                    {" "}
                    <UpgradeIcon className="send-icon" />
                    Upgrade Now
                  </button>
                </div>
              </div>
            </div>
          )}
        {(userPackage === "Basic" ||
          userPackage === "Standard" ||
          userPackage === "Premium") && (
          <div className="all-inner-project-containers">
            <div className="user-profile-field-container1">
              <div className="button-container">
                <div className="button-container-subA1">
                  <button
                    className="edit-button"
                    onClick={handleEditButtonClick}
                    disabled={selectedID === ""}
                  >
                    <EditIcon className="send-icon" /> Edit{" "}
                  </button>
                </div>
              </div>
              <div className="my-cap-user-row1-prof">
                <div className="profile-pic-container">
                  <img
                    src={selectedProfile}
                    alt="profile"
                    className="profile-pic"
                  />
                </div>
                <div className="Profile-pic-right">
                  <div className="user_full_name">
                    <TextBoxComponent
                      placeholder="* Full Name"
                      value={selectedFullName}
                      floatLabelType="Always"
                      enabled={true}
                      style={{ pointerEvents: "none" }}
                    />
                  </div>
                  <div className="user_email">
                    <TextBoxComponent
                      placeholder="* Email"
                      value={selectedEmail}
                      floatLabelType="Always"
                      enabled={true}
                      style={{ pointerEvents: "none" }}
                    />
                  </div>
                  {/* <div className="user_password">
                <TextBoxComponent
                  placeholder="* Password"
                  value={selectedPassword}
                  floatLabelType="Always"
                  enabled={true}
                    style={{ pointerEvents: "none" }}
                />
              </div> */}
                </div>
              </div>
              <div className="my-cap-user-row2-prof">
                <div className="user_self_dob">
                  <DatePickerComponent
                    id="datepicker"
                    value={selectedSelfDOB}
                    placeholder="* Your D.O.B."
                    floatLabelType="Always"
                    format="MMM-yyyy"
                    enabled={true}
                    style={{ pointerEvents: "none" }}
                  />
                </div>
                <div className="user_partner_dob">
                  <DatePickerComponent
                    id="datepicker"
                    value={selectedPartnerDOB}
                    placeholder="* Your Partner D.O.B."
                    floatLabelType="Always"
                    format="MMM-yyyy"
                    enabled={true}
                    style={{ pointerEvents: "none" }}
                  />
                </div>
                <div className="user_currency">
                  <TextBoxComponent
                    value={selectedCurrency}
                    placeholder="Currency Symbol"
                    floatLabelType="Always"
                    enabled={true}
                    style={{ pointerEvents: "none" }}
                  />
                </div>
                <div className="user_format">
                  <TextBoxComponent
                    placeholder="Date Format"
                    value={selectedDateFormat}
                    floatLabelType="Always"
                    enabled={true}
                    style={{ pointerEvents: "none" }}
                  />
                </div>
              </div>
              <div className="my-cap-user-row4-prof">
                <div className="user_country">
                  <TextBoxComponent
                    id="comboelement"
                    placeholder="* Country"
                    value={selectedCountry}
                    floatLabelType="Always"
                    enabled={true}
                    style={{ pointerEvents: "none" }}
                  />
                </div>
                <div className="user_state">
                  <TextBoxComponent
                    placeholder="State"
                    value={selectedState}
                    floatLabelType="Always"
                    enabled={true}
                    style={{ pointerEvents: "none" }}
                  />
                </div>
                {/*<div className="user_city">
                  <TextBoxComponent
                    value={selectedCity}
                    placeholder="City"
                    floatLabelType="Always"
                    enabled={true}
                    style={{ pointerEvents: "none" }}
                  />
                </div>*/}
              </div>
              <div className="my-cap-user-row3">
                {/*<div className="user_physcal_address">
                  <TextBoxComponent
                    value={selectedSeparator}
                    placeholder="Phycal Address"
                    floatLabelType="Always"
                    enabled={true}
                    style={{ pointerEvents: "none" }}
                  />
                </div>*/}
              </div>
            </div>
            <br />
          </div>
        )}
      </>
      <div>
        <Modal
          open={isEditDialogVisible}
          onClose={handleEditDialogClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="user-dialog-box">
            <UserDialog
              handleUpdateRecordButton={handleUpdateRecordButton}
              handleEditDialogClose={handleEditDialogClose}
              selectedFullName={selectedFullName}
              selectedSelfDOB={selectedSelfDOB}
              selectedPartnerDOB={selectedPartnerDOB}
              selectedEmail={selectedEmail}
              selectedSeparator={selectedSeparator}
              selectedCity={selectedCity}
              selectedState={selectedState}
              selectedCountry={selectedCountry}
              selectedPassword={selectedPassword}
              selectedStatus={selectedStatus}
              selectedType={selectedType}
              selectedCurrency={selectedCurrency}
              selectedDateFormat={selectedDateFormat}
              selectedProfile={newProfile}
              setNewFullName={setNewFullName}
              setNewSelfDOB={setNewSelfDOB}
              setNewPartnerDOB={setNewPartnerDOB}
              setNewEmail={setNewEmail}
              setNewSeparator={setNewSeparator}
              setNewCity={setNewCity}
              setNewState={setNewState}
              setNewCountry={setNewCountry}
              setNewPassword={setNewPassword}
              setNewStatus={setNewStatus}
              setNewType={setNewType}
              setNewCurrency={setNewCurrency}
              setNewDateFormat={setNewDateFormat}
              setNewProfile={setNewProfile}
            />
          </Box>
        </Modal>
        {/* {isEditDialogVisible && (
          <DialogComponent
            visible={isEditDialogVisible}
            width="60%"
            height="780px"
            header="Edit User Profile"
            allowDragging={true}
            showCloseIcon={true}
            isModal={true}
            close={handleEditDialogClose}
            buttons={editButtons}>
            <UserDialog
              selectedFullName={selectedFullName}
              selectedSelfDOB={selectedSelfDOB}
              selectedPartnerDOB={selectedPartnerDOB}
              selectedEmail={selectedEmail}
              selectedSeparator={selectedSeparator}
              selectedCity={selectedCity}
              selectedState={selectedState}
              selectedCountry={selectedCountry}
              selectedPassword={selectedPassword}
              selectedStatus={selectedStatus}
              selectedType={selectedType}
              selectedCurrency={selectedCurrency}
              selectedDateFormat={selectedDateFormat}
              setNewFullName={setNewFullName}
              setNewSelfDOB={setNewSelfDOB}
              setNewPartnerDOB={setNewPartnerDOB}
              setNewEmail={setNewEmail}
              setNewSeparator={setNewSeparator}
              setNewCity={setNewCity}
              setNewState={setNewState}
              setNewCountry={setNewCountry}
              setNewPassword={setNewPassword}
              setNewStatus={setNewStatus}
              setNewType={setNewType}
              setNewCurrency={setNewCurrency}
              setNewDateFormat={setNewDateFormat}
            />
          </DialogComponent>
        )} */}
      </div>
    </div>
  );
}
export default UserProfile;
