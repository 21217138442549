import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useAssetDataFetch = () => {
  const user = useUserStore((state) => state.user);
  const [assetData, setAssetData] = useState([]);
  const [isAssetLoaded, setIsAssetLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchAssetData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          `/api/asset/rootuserid/${user?.RootID}`
      );
      setIsAssetLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching Asset data:", error);
      setIsAssetLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchAssetData().then((AssetInitialData) => {
        setAssetData(AssetInitialData);
      });
    else setIsAssetLoaded(true);
  }, [user]);

  // Return an object containing all the fetched data
  return {
    assetData,
    isAssetLoaded,
  };
};

export default useAssetDataFetch;
