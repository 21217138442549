import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NumericTextBoxComponent, TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import "./FinalBudget.css";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";
import useRetirementDataFetch from "../../shared/useRetirementDataFetch";
import useSavingDataFetch from "../../shared/useSavingDataFetch";
import useDebtDataFetch from "../../shared/useDebtDataFetch";
import useExpenseDataFetch from "../../shared/useExpenseDataFetch";
import useIncomeDataFetch from "../../shared/useIncomeDataFetch";
import useMainCatDataFetch from "../../shared/useMainCatDataFetch";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { dropdownTemplate } from "../../shared/HeaderTempate";
import { usePDF } from "react-to-pdf";
import { IconButton } from "@mui/material";
import { Print } from "@mui/icons-material";

function FinalBudget() {
  const user = useUserStore((state) => state.user);
  const { toPDF, targetRef } = usePDF({ filename: "budgetdetails.pdf" });

  const navigate = useNavigate();
  const userPackage = user?.Package;
  const { retirementData, isRetirementLoaded } = useRetirementDataFetch();
  const { savingData, isSavingLoaded } = useSavingDataFetch();
  const { debtData, isDebtLoaded } = useDebtDataFetch();
  const { expenseData, isExpenseLoaded } = useExpenseDataFetch();
  const { mainCatData, isMainCatLoaded } = useMainCatDataFetch();
  const { incomeData, isIncomeLoaded } = useIncomeDataFetch();
  //UPDATED DATA
  const [updatedDebtData, setUpdatedDebtData] = useState([]);
  const [updatedExpenseData, setUpdatedExpenseData] = useState([]);
  //INCOME DATA
  const [grossMonthlyIncomeTotal, setGrossMonthlyIncomeTotal] = useState(0);
  const [netMonthlyIncomeTotal, setNetMonthlyIncomeTotal] = useState(0);
  // EXPENSES DATA
  const [monthlyExpenseTotal, setMonthlyExpenseTotal] = useState(0);
  const [actualExpenseGoal, setActualExpenseGoal] = useState(0);
  const [expenseDiffIcon, setExpenseDiffIcon] = useState("");
  //DEBTS DATA
  const [monthlyDebtTotal, setMonthlyDebtTotal] = useState(0);
  const [actualDebtGoal, setActualDebtGoal] = useState(0);
  const [debtDiffIcon, setDebtDiffIcon] = useState("");
  //RETIREMENTS DATA
  const [yearlyRetirementTotal, setYearlyRetirementTotal] = useState(0);
  const [monthlyRetirementTotal, setMonthlyRetirementTotal] = useState(0);
  const [netRetirementTotal, setNetRetirementTotal] = useState(0);
  const [actualRetirementGoal, setActualRetirementGoal] = useState(0);
  const [budgetRetirementGoal, setBudgetRetirementGoal] = useState(0);
  const [retirementDiffIcon, setRetirementDiffIcon] = useState("");
  const [retirementDiffLabel, setRetirementDiffLabel] = useState("Over/Under");
  const [retirementDifference, setRetirementDifference] = useState(0);
  //SAVINGS DATA
  const [yearlySavingTotal, setYearlySavingTotal] = useState(0);
  const [monthlySavingTotal, setMonthlySavingTotal] = useState(0);
  const [actualSavingGoal, setActualSavingGoal] = useState(0);
  const [budgetSavingGoal, setBudgetSavingGoal] = useState(0);
  const [savingDiffIcon, setSavingDiffIcon] = useState("");
  const [savingDiffLabel, setSavingDiffLabel] = useState("Over/Under");
  const [savingDifference, setSavingDifference] = useState(0);

  const [summaryDiffLabel, setSummaryDiffLabel] = useState("OVER/UNDER BUDGET BY");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const usedCurrency = user?.Currency + " #,###.00";
  const [dataFilter, setDataFilter] = useState("Household");
  const [mainMonthlyLabel, setMainMonthlyLabel] = useState("MONTHLY INCOME");
  const [grossMonthlyLabel, setGrossMonthlyLabel] = useState("Budgeted Gross Monthly Income");
  const [netMonthlyLabel, setNetMonthlyLabel] = useState("Budgeted Net Monthly Income");

  const handleAccountOwnerChange = async (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setDataFilter(e.value);
      const accountOwner = e.value;
      let finalRetirement;
      let finalSaving;
      let finaldebt;
      let finalExpenses;
      let finalIncome;
      if (user?.Type !== "Root") {
        finalRetirement = retirementData.filter((item) => item.UserID === user?.id);
        finalSaving = savingData.filter((item) => item.UserID === user?.id);
        finaldebt = debtData.filter((item) => item.UserID === user?.id);
        finalExpenses = expenseData.filter((item) => item.UserID === user?.id);
        finalIncome = incomeData.filter((item) => item.UserID === user?.id);
      } else {
        finalRetirement = retirementData;
        finalSaving = savingData;
        finaldebt = debtData;
        finalExpenses = expenseData;
        finalIncome = incomeData;
      }

      let filteredRetirement;
      let filteredSavings;
      let filteredDebts;
      let filteredExpenses;
      let filteredIncome;
      if (accountOwner === "Self" || accountOwner === "Partner" || accountOwner === "Joint") {
        filteredRetirement = finalRetirement.filter((item) => item.AccountOwner === accountOwner);
        filteredSavings = finalSaving.filter((item) => item.AccountOwner === accountOwner);
        filteredDebts = finaldebt.filter((item) => item.AccountOwner === accountOwner);
        filteredExpenses = finalExpenses.filter((item) => item.AccountOwner === accountOwner);
        filteredIncome = finalIncome.filter((item) => item.AccountOwner === accountOwner);
      } else {
        filteredRetirement = finalRetirement;
        filteredSavings = finalSaving;
        filteredDebts = finaldebt;
        filteredExpenses = finalExpenses.filter((item) => item.BudgetItem !== "Joint Contribution");
        filteredIncome = finalIncome;
      }

      setUpdatedDebtData(filteredDebts);
      setUpdatedExpenseData(filteredExpenses);

      //GET EXPENSE DATA
      const totalExpenseSum = filteredExpenses?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);
      setMonthlyExpenseTotal(totalExpenseSum);

      const totalSelfContribution = finalExpenses?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        const contribution = record?.BudgetItem || "";
        const contrOwner = record?.AccountOwner || "";
        if (contribution === "Joint Contribution" && contrOwner === "Self") {
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        }
        return accumulator;
      }, 0);

      const totalPartnerContribution = finalExpenses?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        const contribution = record?.BudgetItem || "";
        const contrOwner = record?.AccountOwner || "";
        if (contribution === "Joint Contribution" && contrOwner === "Partner") {
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        }
        return accumulator;
      }, 0);

      //GET DEBT DATA
      const totalDebtSum = filteredDebts?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);
      setMonthlyDebtTotal(totalDebtSum);

      //GET RETIREMENT DATA
      const totalRetirementSum = filteredRetirement?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);
      setMonthlyRetirementTotal(totalRetirementSum);
      setYearlyRetirementTotal(12 * totalRetirementSum);

      const totalRetirementNet = filteredRetirement?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        const contribution = record?.Contribution || "";
        // Only add the amount if contribution is "Yes"
        if (contribution === "Yes") {
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        }
        return accumulator;
      }, 0);
      setNetRetirementTotal(totalRetirementNet);

      //GET SAVING DATA
      const totalSavingSum = filteredSavings?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);
      setMonthlySavingTotal(totalSavingSum);
      setYearlySavingTotal(12 * totalSavingSum);

      const totalMonthlyGrossIncome = filteredIncome.reduce((accumulator, record) => {
        const grossPay = record.GrossPay || 0; // Handle the case where NetPay is undefined
        const payFrequency = record.PayFrequency || ""; // Handle the case where PayFrequency is undefined
        let monthlyGrossIncome = 0;
        // Determine monthlyIncome based on PayFrequency
        switch (payFrequency) {
          case "Yearly":
            monthlyGrossIncome = grossPay / 12;
            break;
          case "Monthly":
            monthlyGrossIncome = grossPay;
            break;
          case "Semi-Monthly":
            monthlyGrossIncome = grossPay * 2;
            break;
          case "Weekly":
            monthlyGrossIncome = grossPay * 4;
            break;
          case "Bi-Weekly":
            monthlyGrossIncome = grossPay * 2;
            break;
          default:
            monthlyGrossIncome = 0;
        }
        // Add monthlyIncome to the accumulator
        return accumulator + Number(monthlyGrossIncome);
      }, 0); // Initialize accumulator with 0

      const totalMonthlyNetIncome = filteredIncome.reduce((accumulator, record) => {
        const netPay = record.NetPay || 0; // Handle the case where NetPay is undefined
        const payFrequency = record.PayFrequency || ""; // Handle the case where PayFrequency is undefined
        let monthlyNetIncome = 0;
        // Determine monthlyIncome based on PayFrequency
        switch (payFrequency) {
          case "Yearly":
            monthlyNetIncome = netPay / 12;
            break;
          case "Monthly":
            monthlyNetIncome = netPay;
            break;
          case "Semi-Monthly":
            monthlyNetIncome = netPay * 2;
            break;
          case "Weekly":
            monthlyNetIncome = netPay * 4;
            break;
          case "Bi-Weekly":
            monthlyNetIncome = netPay * 2;
            break;
          default:
            monthlyNetIncome = 0;
        }
        // Add monthlyIncome to the accumulator
        return accumulator + Number(monthlyNetIncome);
      }, 0); // Initialize accumulator with 0

      const totalAllNetExpenses = Number(totalDebtSum) + Number(totalExpenseSum) + Number(totalSavingSum) + Number(totalRetirementNet);
      const totalNetDiffence = Number(totalMonthlyNetIncome) - Number(totalAllNetExpenses);
      const totalNetDiffenceJoint = Number(totalPartnerContribution) + Number(totalSelfContribution) - Number(totalAllNetExpenses);

      if (accountOwner === "Joint") {
        setMainMonthlyLabel("MONTHLY JOINT CONTRIBUTION");
        setGrossMonthlyLabel("Self Monthly Contribution");
        setGrossMonthlyIncomeTotal(totalSelfContribution);
        setNetMonthlyLabel("Partner Monthly Contribution");
        setNetMonthlyIncomeTotal(totalPartnerContribution);
        if (totalNetDiffenceJoint > 0) {
          setSummaryDiffLabel("UNDER BUDGET BY");
        } else if (totalNetDiffenceJoint < 0) {
          setSummaryDiffLabel("OVER BUDGET BY");
        } else {
          setSummaryDiffLabel("OVER/UNDER BUDGET BY");
        }
      } else {
        setMainMonthlyLabel("MONTHLY INCOME");
        setGrossMonthlyLabel("Budgeted Gross Monthly Income");
        setGrossMonthlyIncomeTotal(totalMonthlyGrossIncome);
        setNetMonthlyLabel("Budgeted Net Monthly Income");
        setNetMonthlyIncomeTotal(totalMonthlyNetIncome);
        if (totalNetDiffence > 0) {
          setSummaryDiffLabel("UNDER BUDGET BY");
        } else if (totalNetDiffence < 0) {
          setSummaryDiffLabel("OVER BUDGET BY");
        } else {
          setSummaryDiffLabel("OVER/UNDER BUDGET BY");
        }
      }

      setIsDataChanged(true);
    }
  };

  useEffect(() => {
    if (isExpenseLoaded && isRetirementLoaded && isSavingLoaded && isDebtLoaded && isIncomeLoaded) {
      let finalRetirement;
      let finalSaving;
      let finaldebt;
      let finalExpenses;
      let finalIncome;
      if (user?.Type !== "Root") {
        finalRetirement = retirementData.filter((item) => item.UserID === user?.id);
        finalSaving = savingData.filter((item) => item.UserID === user?.id);
        finaldebt = debtData.filter((item) => item.UserID === user?.id);
        finalExpenses = expenseData.filter((item) => item.UserID === user?.id);
        finalIncome = incomeData.filter((item) => item.UserID === user?.id);
      } else {
        finalRetirement = retirementData;
        finalSaving = savingData;
        finaldebt = debtData;
        finalExpenses = expenseData;
        finalIncome = incomeData;
      }

      let filteredRetirement;
      let filteredSavings;
      let filteredDebts;
      let filteredExpenses;
      let filteredIncome;
      if (dataFilter === "Self" || dataFilter === "Partner" || dataFilter === "Joint") {
        filteredRetirement = finalRetirement.filter((item) => item.AccountOwner === dataFilter);
        filteredSavings = finalSaving.filter((item) => item.AccountOwner === dataFilter);
        filteredDebts = finaldebt.filter((item) => item.AccountOwner === dataFilter);
        filteredExpenses = finalExpenses.filter((item) => item.AccountOwner === dataFilter);
        filteredIncome = finalIncome.filter((item) => item.AccountOwner === dataFilter);
      } else {
        filteredRetirement = finalRetirement;
        filteredSavings = finalSaving;
        filteredDebts = finaldebt;
        filteredExpenses = finalExpenses.filter((item) => item.BudgetItem !== "Joint Contribution");
        filteredIncome = finalIncome;
      }
      setUpdatedDebtData(filteredDebts);
      setUpdatedExpenseData(filteredExpenses);

      //GET EXPENSE DATA
      const totalExpenseSum = filteredExpenses?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);
      setMonthlyExpenseTotal(totalExpenseSum);

      //GET DEBT DATA
      const totalDebtSum = filteredDebts?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);
      setMonthlyDebtTotal(totalDebtSum);

      //GET RETIREMENT DATA
      const totalRetirementSum = filteredRetirement?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);
      setMonthlyRetirementTotal(totalRetirementSum);
      setYearlyRetirementTotal(12 * totalRetirementSum);

      const totalRetirementNet = filteredRetirement?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        const contribution = record?.Contribution || "";
        // Only add the amount if contribution is "Yes"
        if (contribution === "Yes") {
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        }
        return accumulator;
      }, 0);
      setNetRetirementTotal(totalRetirementNet);

      //GET SAVING DATA
      const totalSavingSum = filteredSavings?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);
      setMonthlySavingTotal(totalSavingSum);
      setYearlySavingTotal(12 * totalSavingSum);

      const totalMonthlyGrossIncome = filteredIncome.reduce((accumulator, record) => {
        const grossPay = record.GrossPay || 0; // Handle the case where NetPay is undefined
        const payFrequency = record.PayFrequency || ""; // Handle the case where PayFrequency is undefined
        let monthlyGrossIncome = 0;
        // Determine monthlyIncome based on PayFrequency
        switch (payFrequency) {
          case "Yearly":
            monthlyGrossIncome = grossPay / 12;
            break;
          case "Monthly":
            monthlyGrossIncome = grossPay;
            break;
          case "Semi-Monthly":
            monthlyGrossIncome = grossPay * 2;
            break;
          case "Weekly":
            monthlyGrossIncome = grossPay * 4;
            break;
          case "Bi-Weekly":
            monthlyGrossIncome = grossPay * 2;
            break;
          default:
            monthlyGrossIncome = 0;
        }
        // Add monthlyIncome to the accumulator
        return accumulator + Number(monthlyGrossIncome);
      }, 0); // Initialize accumulator with 0
      setGrossMonthlyIncomeTotal(totalMonthlyGrossIncome);

      const totalMonthlyNetIncome = filteredIncome.reduce((accumulator, record) => {
        const netPay = record.NetPay || 0; // Handle the case where NetPay is undefined
        const payFrequency = record.PayFrequency || ""; // Handle the case where PayFrequency is undefined
        let monthlyNetIncome = 0;
        // Determine monthlyIncome based on PayFrequency
        switch (payFrequency) {
          case "Yearly":
            monthlyNetIncome = netPay / 12;
            break;
          case "Monthly":
            monthlyNetIncome = netPay;
            break;
          case "Semi-Monthly":
            monthlyNetIncome = netPay * 2;
            break;
          case "Weekly":
            monthlyNetIncome = netPay * 4;
            break;
          case "Bi-Weekly":
            monthlyNetIncome = netPay * 2;
            break;
          default:
            monthlyNetIncome = 0;
        }
        // Add monthlyIncome to the accumulator
        return accumulator + Number(monthlyNetIncome);
      }, 0); // Initialize accumulator with 0
      setNetMonthlyIncomeTotal(totalMonthlyNetIncome);

      const totalAllNetExpenses = Number(totalDebtSum) + Number(totalExpenseSum) + Number(totalSavingSum);
      const totalNetDiffence = Number(totalMonthlyNetIncome) - Number(totalAllNetExpenses);
      if (totalNetDiffence > 0) {
        setSummaryDiffLabel("UNDER BUDGET BY");
      } else if (totalNetDiffence < 0) {
        setSummaryDiffLabel("OVER BUDGET BY");
      } else {
        setSummaryDiffLabel("OVER/UNDER BUDGET BY");
      }

      setIsDataChanged(true);
      setIsDataLoaded(true);
    }
  }, [
    expenseData,
    retirementData,
    isRetirementLoaded,
    savingData,
    isExpenseLoaded,
    isSavingLoaded,
    debtData,
    isDebtLoaded,
    incomeData,
    isIncomeLoaded,
    user,
  ]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded || !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  useEffect(() => {
    if (isMainCatLoaded) {
      //EXPENSE GOALS
      let expenseGoal;
      const filteredData = mainCatData.find((item) => item.Category === "Expenses");
      if (filteredData) {
        const uniqueGoal = filteredData.Goal;
        expenseGoal = Number(uniqueGoal) / 100;
      } else {
        expenseGoal = 0;
      }
      const expensePerc = Number(monthlyExpenseTotal) / Number(netMonthlyIncomeTotal);
      const difference = (Number(expenseGoal) - Number(expensePerc)) * Number(netMonthlyIncomeTotal);
      if (Number(difference) < 0) {
        setExpenseDiffIcon(
          <ThumbDownIcon
            style={{
              color: "red",
              fontSize: "30px",
              paddingTop: "0px",
            }}
          />
        );
      } else if (Number(difference) > 0) {
        setExpenseDiffIcon(
          <ThumbUpIcon
            style={{
              color: "green",
              fontSize: "30px",
              paddingTop: "0px",
            }}
          />
        );
      } else {
        setExpenseDiffIcon(<ThumbUpIcon style={{ color: "black", fontSize: "30px", paddingTop: "0px" }} />);
      }
      setActualExpenseGoal(expensePerc);
      if (dataFilter === "Joint") {
        setExpenseDiffIcon(<ThumbUpIcon style={{ color: "black", fontSize: "30px", paddingTop: "0px" }} />);
      }

      //DEBT GOALS
      let DebtGoal;
      const filteredDebtData = mainCatData.find((item) => item.Category === "Debts");
      if (filteredDebtData) {
        const uniqueGoal = filteredDebtData.Goal;
        DebtGoal = Number(uniqueGoal) / 100;
      } else {
        DebtGoal = 0;
      }
      const DebtPerc = Number(monthlyDebtTotal) / Number(netMonthlyIncomeTotal);
      const differenceDebt = (Number(DebtGoal) - Number(DebtPerc)) * Number(netMonthlyIncomeTotal);
      if (Number(differenceDebt) < 0) {
        setDebtDiffIcon(
          <ThumbDownIcon
            style={{
              color: "red",
              fontSize: "30px",
              paddingTop: "0px",
            }}
          />
        );
      } else if (Number(differenceDebt) > 0) {
        setDebtDiffIcon(
          <ThumbUpIcon
            style={{
              color: "green",
              fontSize: "30px",
              paddingTop: "0px",
            }}
          />
        );
      } else {
        setDebtDiffIcon(<ThumbUpIcon style={{ color: "black", fontSize: "30px", paddingTop: "0px" }} />);
      }
      setActualDebtGoal(DebtPerc);
      if (dataFilter === "Joint") {
        setDebtDiffIcon(<ThumbUpIcon style={{ color: "black", fontSize: "30px", paddingTop: "0px" }} />);
      }

      //RETIREMENT GOALS
      let RetirementGoal;
      const filteredRetirementData = mainCatData.find((item) => item.Category === "Retirement");
      if (filteredRetirementData) {
        const uniqueGoal = filteredRetirementData.Goal;
        setBudgetRetirementGoal(Number(uniqueGoal) / 100);
        RetirementGoal = Number(uniqueGoal) / 100;
      } else {
        setBudgetRetirementGoal(0);
        RetirementGoal = 0;
      }
      const RetirementPerc = Number(monthlyRetirementTotal) / Number(grossMonthlyIncomeTotal);
      const differenceRetirement = (-Number(RetirementGoal) + Number(RetirementPerc)) * Number(grossMonthlyIncomeTotal);
      if (Number(differenceRetirement) < 0) {
        setRetirementDiffLabel("Under by");
        setRetirementDiffIcon(
          <ThumbDownIcon
            style={{
              color: "red",
              fontSize: "30px",
              paddingTop: "0px",
            }}
          />
        );
      } else if (Number(differenceRetirement) > 0) {
        setRetirementDiffLabel("Over by");
        setRetirementDiffIcon(
          <ThumbUpIcon
            style={{
              color: "green",
              fontSize: "30px",
              paddingTop: "0px",
            }}
          />
        );
      } else {
        setRetirementDiffLabel("Over/Under");
        setRetirementDiffIcon(<ThumbUpIcon style={{ color: "black", fontSize: "30px", paddingTop: "0px" }} />);
      }
      setActualRetirementGoal(RetirementPerc);
      setRetirementDifference(differenceRetirement);
      if (dataFilter === "Joint") {
        setRetirementDiffIcon(<ThumbUpIcon style={{ color: "black", fontSize: "30px", paddingTop: "0px" }} />);
      }

      //SAVING GOALS
      let SavingGoal;
      const filteredSavingData = mainCatData.find((item) => item.Category === "Savings");
      if (filteredSavingData) {
        const uniqueGoal = filteredSavingData.Goal;
        setBudgetSavingGoal(Number(uniqueGoal) / 100);
        SavingGoal = Number(uniqueGoal) / 100;
      } else {
        setBudgetSavingGoal(0);
        SavingGoal = 0;
      }
      const SavingPerc = Number(monthlySavingTotal) / Number(netMonthlyIncomeTotal);
      const differenceSaving = (-Number(SavingGoal) + Number(SavingPerc)) * Number(netMonthlyIncomeTotal);
      if (Number(differenceSaving) < 0) {
        setSavingDiffLabel("Under by");
        setSavingDiffIcon(
          <ThumbDownIcon
            style={{
              color: "red",
              fontSize: "30px",
              paddingTop: "0px",
            }}
          />
        );
      } else if (Number(differenceSaving) > 0) {
        setSavingDiffLabel("Over by");
        setSavingDiffIcon(
          <ThumbUpIcon
            style={{
              color: "green",
              fontSize: "30px",
              paddingTop: "0px",
            }}
          />
        );
      } else {
        setSavingDiffLabel("Over/Under");
        setSavingDiffIcon(<ThumbUpIcon style={{ color: "black", fontSize: "30px", paddingTop: "0px" }} />);
      }
      setActualSavingGoal(SavingPerc);
      setSavingDifference(differenceSaving);
      if (dataFilter === "Joint") {
        setSavingDiffIcon(<ThumbUpIcon style={{ color: "black", fontSize: "30px", paddingTop: "0px" }} />);
      }
    }
  }, [
    monthlyExpenseTotal,
    monthlyDebtTotal,
    monthlyRetirementTotal,
    monthlySavingTotal,
    grossMonthlyIncomeTotal,
    netMonthlyIncomeTotal,
    mainCatData,
    isMainCatLoaded,
  ]);

  const getBackgroundColor = () => {
    if (summaryDiffLabel === "UNDER BUDGET BY") {
      return "green";
    } else if (summaryDiffLabel === "OVER BUDGET BY") {
      return "red";
    } else {
      return "rgba(255,233,155,50%)";
    }
  };

  const getSavingsBackgroundColor = () => {
    if (savingDiffLabel === "Over by") {
      return "green";
    } else if (savingDiffLabel === "Under by") {
      return "red";
    } else {
      return "rgba(255,233,155,50%)";
    }
  };

  const getRetirementBackgroundColor = () => {
    if (retirementDiffLabel === "Over by") {
      return "green";
    } else if (retirementDiffLabel === "Under by") {
      return "red";
    } else {
      return "rgba(255,233,155,50%)";
    }
  };

  // Function to find unique budget items and their sum
  const getUniqueBudgetItemsWithSum = (data) => {
    if (isDataLoaded) {
      const uniqueBudgetItems = new Set();
      const budgetItemValue = {};
      data?.forEach((record) => {
        const budgetItem = record?.BudgetItem || "";
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        if (!uniqueBudgetItems.has(budgetItem)) {
          uniqueBudgetItems.add(budgetItem);
          budgetItemValue[budgetItem] = 0;
        }
        budgetItemValue[budgetItem] += Number(monthlyPayment) + Number(adjustments);
      });

      return {
        uniqueBudgetItems: Array.from(uniqueBudgetItems),
        budgetItemValue,
      };
    }
  };
  // Example usage
  const uniqueBudgetItemsWithSum = getUniqueBudgetItemsWithSum(updatedDebtData);
  const uniqueExpenseBudgetItemsWithSum = getUniqueBudgetItemsWithSum(updatedExpenseData);

  // Function to get unique descriptions and their sum values for each unique budget item
  const getUniqueDescriptionsWithSumForEachBudgetItem = (data) => {
    if (isDataLoaded) {
      const uniqueDescriptions = {};
      data?.forEach((record) => {
        const budgetItem = record?.BudgetItem || "";
        const description = record?.Description || "";
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        if (!uniqueDescriptions[budgetItem]) {
          uniqueDescriptions[budgetItem] = {};
        }
        if (!uniqueDescriptions[budgetItem][description]) {
          uniqueDescriptions[budgetItem][description] = 0;
        }
        uniqueDescriptions[budgetItem][description] += Number(monthlyPayment) + Number(adjustments);
      });
      return uniqueDescriptions;
    }
  };
  // Example usage
  const uniqueDescriptionsWithSum = getUniqueDescriptionsWithSumForEachBudgetItem(updatedDebtData);
  const uniqueExpenseDescriptionsWithSum = getUniqueDescriptionsWithSumForEachBudgetItem(updatedExpenseData);

  const handleUpgradeButtonClick = () => {
    navigate("/packages");
  };

  return (
    <div ref={targetRef} className="outer-container-fb">
      <div className="title-container-fb">
        <div className="mainTitle_dropdown">
          <h3>Budget Details for {user?.FullName}</h3>
          <div className="_account_owner1">
            <ComboBoxComponent
              id="comboelement"
              value="Select Owner"
              headerTemplate={dropdownTemplate}
              dataSource={["Self", "Partner", "Joint", "Household"]}
              change={handleAccountOwnerChange}
              style={{ backgroundColor: "black", color: "#FFE99B" }}
            />
          </div>
        </div>
      </div>
      <>
        {userPackage !== "Basic" && userPackage !== "Standard" && userPackage !== "Premium" && (
          <div className="title-container1">
            <h2>This Feature is Only Available to Paid Users Only</h2>
            <div className="button-container">
              <div className="button-container-subA1">
                <button className="add-button" onClick={handleUpgradeButtonClick}>
                  {" "}
                  <UpgradeIcon className="send-icon" />
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="print-container">
          <div className="print-container-inner">
            <IconButton onClick={toPDF}>
              <Print /> Print{" "}
            </IconButton>
          </div>
        </div>
        {(userPackage === "Basic" || userPackage === "Standard" || userPackage === "Premium") && (
          <>
            <div className="summary-container-fb">
              <div className="inner-summary-container-fb">
                <div className="inner-ind-summary-container-fb">
                  <div className="title-input-group">
                    <h2>{mainMonthlyLabel}</h2>
                  </div>
                  <div className="fb-input-group">
                    <h2>{grossMonthlyLabel}</h2>
                    <div className="fb_input">
                      <NumericTextBoxComponent
                        value={grossMonthlyIncomeTotal}
                        format={usedCurrency}
                        showSpinButton={false}
                        disabled={false}
                        style={{
                          backgroundColor: "#FFE99B",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                  </div>
                  <div className="fb-input-group">
                    <h2>{netMonthlyLabel}</h2>
                    <div className="fb_input">
                      <NumericTextBoxComponent
                        value={netMonthlyIncomeTotal}
                        format={usedCurrency}
                        showSpinButton={false}
                        disabled={false}
                        style={{
                          backgroundColor: "#FFE99B",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                  </div>
                </div>
                {dataFilter !== "Joint" && (
                  <div className="inner-ind-summary-container-fb">
                    <div className="title-input-group">
                      <h2>MONTHLY SAVINGS</h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={monthlySavingTotal}
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="perc-summary-container-fb">
                        <NumericTextBoxComponent
                          value={actualSavingGoal}
                          format="p2"
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="icon-summary-container-fb">
                        <h1>{savingDiffIcon}</h1>
                      </div>
                    </div>
                    <div className="fb-input-group">
                      <h2>Annual savings </h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={yearlySavingTotal}
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      {/* <div className="perc-summary-container-fb">
                
              </div>
              <div className="icon-summary-container-fb">
                
              </div> */}
                    </div>
                    <div className="fb-input-group">
                      <h2>Goal</h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={budgetSavingGoal}
                          format="p2"
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      {/* <div className="perc-summary-container-fb">
                
              </div>
              <div className="icon-summary-container-fb">
                
              </div> */}
                    </div>
                    <div className="fb-input-group">
                      <h2>{savingDiffLabel}</h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={savingDifference}
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      {/* <div className="perc-summary-container-fb">
                
              </div>
              <div className="icon-summary-container-fb">
                
              </div> */}
                    </div>
                  </div>
                )}
                {dataFilter === "Joint" && (
                  <div className="inner-ind-summary-container-fb">
                    <div className="title-input-group">
                      <h2>MONTHLY JOINT SAVINGS</h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={monthlySavingTotal}
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="perc-summary-container-fb">
                        <TextBoxComponent
                          value="N/A"
                          format="p2"
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="icon-summary-container-fb">
                        <h1>{savingDiffIcon}</h1>
                      </div>
                    </div>
                    <div className="fb-input-group">
                      <h2>Annual Joint Savings </h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={yearlySavingTotal}
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      {/* <div className="perc-summary-container-fb">
                
              </div>
              <div className="icon-summary-container-fb">
                
              </div> */}
                    </div>
                    <div className="fb-input-group">
                      <h2>Goal</h2>
                      <div className="fb_input">
                        <TextBoxComponent
                          value="N/A"
                          format="p2"
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      {/* <div className="perc-summary-container-fb">
                
              </div>
              <div className="icon-summary-container-fb">
                
              </div> */}
                    </div>
                    <div className="fb-input-group">
                      <h2>Over/Under</h2>
                      <div className="fb_input">
                        <TextBoxComponent
                          value="N/A"
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      {/* <div className="perc-summary-container-fb">
                
              </div>
              <div className="icon-summary-container-fb">
                
              </div> */}
                    </div>
                  </div>
                )}
                {dataFilter !== "Joint" && (
                  <div className="inner-ind-summary-container-fb">
                    <div className="title-input-group">
                      <h2>TOTAL MONTHLY RETIREMENT SAVINGS</h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={monthlyRetirementTotal}
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="perc-summary-container-fb">
                        <NumericTextBoxComponent
                          value={actualRetirementGoal}
                          format="p2"
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="icon-summary-container-fb">
                        <h1>{retirementDiffIcon}</h1>
                      </div>
                    </div>
                    <div className="fb-input-group">
                      <h2>Total Annual Retirement Savings </h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={yearlyRetirementTotal}
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      {/* <div className="perc-summary-container-fb">
                
              </div>
              <div className="icon-summary-container-fb">
                
              </div> */}
                    </div>
                    <div className="fb-input-group">
                      <h2>Goal</h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={budgetRetirementGoal}
                          format="p2"
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      {/* <div className="perc-summary-container-fb">
                
              </div>
              <div className="icon-summary-container-fb">
                
              </div> */}
                    </div>
                    <div className="fb-input-group">
                      <h2>{retirementDiffLabel}</h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={retirementDifference}
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      {/* <div className="perc-summary-container-fb">
                
              </div>
              <div className="icon-summary-container-fb">
                
              </div> */}
                    </div>
                  </div>
                )}
                {dataFilter !== "Joint" && (
                  <div className="inner-ind-summary-container-fb">
                    <div className="title-input-group">
                      <h2>MONTHLY RETIREMENT SAVINGS FROM GROSS INCOME</h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={Number(monthlyRetirementTotal) - Number(netRetirementTotal)}
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="perc-summary-container-fb">
                        <NumericTextBoxComponent
                          value={
                            (actualRetirementGoal * (Number(monthlyRetirementTotal) - Number(netRetirementTotal))) /
                            Number(monthlyRetirementTotal)
                          }
                          format="p2"
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="icon-summary-container-fb">
                        <h1>{retirementDiffIcon}</h1>
                      </div>
                    </div>
                    <div className="fb-input-group">
                      <h2>Annual Retirement Savings from Gross Income</h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={12 * (Number(monthlyRetirementTotal) - Number(netRetirementTotal))}
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      {/* <div className="perc-summary-container-fb">
                
              </div>
              <div className="icon-summary-container-fb">
                
              </div> */}
                    </div>
                    <div className="fb-input-group">
                      <h2>Goal</h2>
                      <div className="fb_input">
                        <TextBoxComponent
                          value="N/A"
                          format="p2"
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      {/* <div className="perc-summary-container-fb">
                
              </div>
              <div className="icon-summary-container-fb">
                
              </div> */}
                    </div>
                    <div className="fb-input-group">
                      <h2>{retirementDiffLabel}</h2>
                      <div className="fb_input">
                        <TextBoxComponent
                          value="N/A"
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      {/* <div className="perc-summary-container-fb">
                
              </div>
              <div className="icon-summary-container-fb">
                
              </div> */}
                    </div>
                  </div>
                )}
                {dataFilter !== "Joint" && (
                  <div className="inner-ind-summary-container-fb">
                    <div className="title-input-group">
                      <h2>MONTHLY RETIREMENT SAVINGS FROM NET INCOME</h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={Number(netRetirementTotal)}
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="perc-summary-container-fb">
                        <NumericTextBoxComponent
                          value={(actualRetirementGoal * Number(netRetirementTotal)) / Number(monthlyRetirementTotal)}
                          format="p2"
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="icon-summary-container-fb">
                        <h1>{retirementDiffIcon}</h1>
                      </div>
                    </div>
                    <div className="fb-input-group">
                      <h2>Annual Retirement Savings From Net Income</h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={12 * Number(netRetirementTotal)}
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      {/* <div className="perc-summary-container-fb">
                
              </div>
              <div className="icon-summary-container-fb">
                
              </div> */}
                    </div>
                    <div className="fb-input-group">
                      <h2>Goal</h2>
                      <div className="fb_input">
                        <TextBoxComponent
                          value="N/A"
                          format="p2"
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      {/* <div className="perc-summary-container-fb">
                
              </div>
              <div className="icon-summary-container-fb">
                
              </div> */}
                    </div>
                    <div className="fb-input-group">
                      <h2>{retirementDiffLabel}</h2>
                      <div className="fb_input">
                        <TextBoxComponent
                          value="N/A"
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      {/* <div className="perc-summary-container-fb">
                
              </div>
              <div className="icon-summary-container-fb">
                
              </div> */}
                    </div>
                  </div>
                )}
                {dataFilter !== "Joint" && (
                  <div className="inner-ind-summary-container-fb">
                    <div className="title-input-group">
                      <h2>MONTHLY DEBT PAYMENT</h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={monthlyDebtTotal}
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="perc-summary-container-fb">
                        <NumericTextBoxComponent
                          value={actualDebtGoal}
                          format="p2"
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="icon-summary-container-fb">
                        <h1>{debtDiffIcon}</h1>
                      </div>
                    </div>
                    {/* Unique Budget Items and Descriptions */}
                    {uniqueBudgetItemsWithSum?.uniqueBudgetItems?.map((budgetItem) => (
                      <div key={budgetItem}>
                        {/* Budget Item */}
                        <div className="budget-item-input-group">
                          <h2>{budgetItem}</h2>
                          <div className="fb_input">
                            <NumericTextBoxComponent
                              value={uniqueBudgetItemsWithSum.budgetItemValue[budgetItem]}
                              format={usedCurrency}
                              showSpinButton={false}
                              disabled={false}
                              style={{
                                backgroundColor: "#FFE99B",
                                pointerEvents: "none",
                              }}
                            />
                          </div>
                          <div className="perc-summary-container-fb">
                            <p></p>
                          </div>
                          <div className="icon-summary-container-fb">
                            <p></p>
                          </div>
                        </div>

                        {/* Descriptions for the Current Budget Item */}
                        {uniqueDescriptionsWithSum?.[budgetItem] &&
                          Object.entries(uniqueDescriptionsWithSum[budgetItem]).map(([description, descriptionValue]) => (
                            <div key={description} className="description-input-group">
                              <h2>{description}</h2>
                              <div className="fb_input">
                                <NumericTextBoxComponent
                                  value={descriptionValue}
                                  format={usedCurrency}
                                  showSpinButton={false}
                                  disabled={false}
                                  style={{
                                    backgroundColor: "#FFE99B",
                                    pointerEvents: "none",
                                  }}
                                />
                              </div>
                              <div className="perc-summary-container-fb">
                                <p></p>
                              </div>
                              <div className="icon-summary-container-fb">
                                <p></p>
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}
                  </div>
                )}
                {dataFilter === "Joint" && (
                  <div className="inner-ind-summary-container-fb">
                    <div className="title-input-group">
                      <h2>MONTHLY JOINT DEBT PAYMENT</h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={monthlyDebtTotal}
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="perc-summary-container-fb">
                        <TextBoxComponent
                          value="N/A"
                          format="p2"
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="icon-summary-container-fb">
                        <h1>{debtDiffIcon}</h1>
                      </div>
                    </div>
                    {/* Unique Budget Items and Descriptions */}
                    {uniqueBudgetItemsWithSum?.uniqueBudgetItems?.map((budgetItem) => (
                      <div key={budgetItem}>
                        {/* Budget Item */}
                        <div className="budget-item-input-group">
                          <h2>{budgetItem}</h2>
                          <div className="fb_input">
                            <NumericTextBoxComponent
                              value={uniqueBudgetItemsWithSum.budgetItemValue[budgetItem]}
                              format={usedCurrency}
                              showSpinButton={false}
                              disabled={false}
                              style={{
                                backgroundColor: "#FFE99B",
                                pointerEvents: "none",
                              }}
                            />
                          </div>
                          <div className="perc-summary-container-fb">
                            <p></p>
                          </div>
                          <div className="icon-summary-container-fb">
                            <p></p>
                          </div>
                        </div>

                        {/* Descriptions for the Current Budget Item */}
                        {uniqueDescriptionsWithSum?.[budgetItem] &&
                          Object.entries(uniqueDescriptionsWithSum[budgetItem]).map(([description, descriptionValue]) => (
                            <div key={description} className="description-input-group">
                              <h2>{description}</h2>
                              <div className="fb_input">
                                <NumericTextBoxComponent
                                  value={descriptionValue}
                                  format={usedCurrency}
                                  showSpinButton={false}
                                  disabled={false}
                                  style={{
                                    backgroundColor: "#FFE99B",
                                    pointerEvents: "none",
                                  }}
                                />
                              </div>
                              <div className="perc-summary-container-fb">
                                <p></p>
                              </div>
                              <div className="icon-summary-container-fb">
                                <p></p>
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="inner-summary-container-fb">
                {dataFilter !== "Joint" && (
                  <div className="inner-ind-summary-container-fb">
                    <div className="title-input-group">
                      <h2>MONTHLY EXPENSES</h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={monthlyExpenseTotal}
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="perc-summary-container-fb">
                        <NumericTextBoxComponent
                          value={actualExpenseGoal}
                          format="p2"
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="icon-summary-container-fb">
                        <h1>{expenseDiffIcon}</h1>
                      </div>
                    </div>
                    {/* Unique Budget Items and Descriptions */}
                    {uniqueExpenseBudgetItemsWithSum?.uniqueBudgetItems?.map((budgetItem) => (
                      <div key={budgetItem}>
                        {/* Budget Item */}
                        <div className="budget-item-input-group">
                          <h2>{budgetItem}</h2>
                          <div className="fb_input">
                            <NumericTextBoxComponent
                              value={uniqueExpenseBudgetItemsWithSum.budgetItemValue[budgetItem]}
                              format={usedCurrency}
                              showSpinButton={false}
                              disabled={false}
                              style={{
                                backgroundColor: "#FFE99B",
                                pointerEvents: "none",
                              }}
                            />
                          </div>
                          <div className="perc-summary-container-fb">
                            <p></p>
                          </div>
                          <div className="icon-summary-container-fb">
                            <p></p>
                          </div>
                        </div>

                        {/* Descriptions for the Current Budget Item */}
                        {uniqueExpenseDescriptionsWithSum?.[budgetItem] &&
                          Object.entries(uniqueExpenseDescriptionsWithSum[budgetItem]).map(([description, descriptionValue]) => (
                            <div key={description} className="description-input-group">
                              <h2>{description}</h2>
                              <div className="fb_input">
                                <NumericTextBoxComponent
                                  value={descriptionValue}
                                  format={usedCurrency}
                                  showSpinButton={false}
                                  disabled={false}
                                  style={{
                                    backgroundColor: "#FFE99B",
                                    pointerEvents: "none",
                                  }}
                                />
                              </div>
                              <div className="perc-summary-container-fb">
                                <p></p>
                              </div>
                              <div className="icon-summary-container-fb">
                                <p></p>
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}
                  </div>
                )}
                {dataFilter === "Joint" && (
                  <div className="inner-ind-summary-container-fb">
                    <div className="title-input-group">
                      <h2>MONTHLY JOINT EXPENSES</h2>
                      <div className="fb_input">
                        <NumericTextBoxComponent
                          value={monthlyExpenseTotal}
                          format={usedCurrency}
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="perc-summary-container-fb">
                        <TextBoxComponent
                          value="N/A"
                          format="p2"
                          showSpinButton={false}
                          disabled={false}
                          style={{
                            backgroundColor: "#FFE99B",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                      <div className="icon-summary-container-fb">
                        <h1>{expenseDiffIcon}</h1>
                      </div>
                    </div>
                    {/* Unique Budget Items and Descriptions */}
                    {uniqueExpenseBudgetItemsWithSum?.uniqueBudgetItems?.map((budgetItem) => (
                      <div key={budgetItem}>
                        {/* Budget Item */}
                        <div className="budget-item-input-group">
                          <h2>{budgetItem}</h2>
                          <div className="fb_input">
                            <NumericTextBoxComponent
                              value={uniqueExpenseBudgetItemsWithSum.budgetItemValue[budgetItem]}
                              format={usedCurrency}
                              showSpinButton={false}
                              disabled={false}
                              style={{
                                backgroundColor: "#FFE99B",
                                pointerEvents: "none",
                              }}
                            />
                          </div>
                          <div className="perc-summary-container-fb">
                            <p></p>
                          </div>
                          <div className="icon-summary-container-fb">
                            <p></p>
                          </div>
                        </div>

                        {/* Descriptions for the Current Budget Item */}
                        {uniqueExpenseDescriptionsWithSum?.[budgetItem] &&
                          Object.entries(uniqueExpenseDescriptionsWithSum[budgetItem]).map(([description, descriptionValue]) => (
                            <div key={description} className="description-input-group">
                              <h2>{description}</h2>
                              <div className="fb_input">
                                <NumericTextBoxComponent
                                  value={descriptionValue}
                                  format={usedCurrency}
                                  showSpinButton={false}
                                  disabled={false}
                                  style={{
                                    backgroundColor: "#FFE99B",
                                    pointerEvents: "none",
                                  }}
                                />
                              </div>
                              <div className="perc-summary-container-fb">
                                <p></p>
                              </div>
                              <div className="icon-summary-container-fb">
                                <p></p>
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="summary-container-fb">
              <div className="inner-summary-container-fb"></div>
              {dataFilter !== "Joint" && (
                <div className="inner-summary-container-fb">
                  <div className="title-input-group">
                    <h2>TOTAL MONTHLY EXPENSES</h2>
                    <div className="fb_input">
                      <NumericTextBoxComponent
                        value={
                          Number(monthlyDebtTotal) + Number(monthlyExpenseTotal) + Number(monthlySavingTotal) + Number(netRetirementTotal)
                        }
                        format={usedCurrency}
                        showSpinButton={false}
                        disabled={false}
                        style={{
                          backgroundColor: "#FFE99B",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {dataFilter === "Joint" && (
                <div className="inner-summary-container-fb">
                  <div className="title-input-group">
                    <h2>TOTAL MONTHLY JOINT EXPENSES</h2>
                    <div className="fb_input">
                      <NumericTextBoxComponent
                        value={
                          Number(monthlyDebtTotal) + Number(monthlyExpenseTotal) + Number(monthlySavingTotal) + Number(netRetirementTotal)
                        }
                        format={usedCurrency}
                        showSpinButton={false}
                        disabled={false}
                        style={{
                          backgroundColor: "#FFE99B",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="summary-container-fb">
              <div className="inner-summary-container-fb"></div>
              {dataFilter !== "Joint" && (
                <div className="inner-summary-container-fb">
                  <div className="fb-input-group">
                    <h2>MONTHLY INCOME</h2>
                    <div className="fb_input">
                      <NumericTextBoxComponent
                        value={netMonthlyIncomeTotal}
                        format={usedCurrency}
                        showSpinButton={false}
                        disabled={false}
                        style={{
                          backgroundColor: "#FFE99B",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                  </div>
                  <div className="fb-input-group">
                    <h2>LESS MONTHLY EXPENSES</h2>
                    <div className="fb_input">
                      <NumericTextBoxComponent
                        value={
                          Number(monthlyDebtTotal) + Number(monthlyExpenseTotal) + Number(monthlySavingTotal) + Number(netRetirementTotal)
                        }
                        format={usedCurrency}
                        showSpinButton={false}
                        disabled={false}
                        style={{
                          backgroundColor: "#FFE99B",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                  </div>
                  <div className="fb-input-group">
                    <h2>{summaryDiffLabel}</h2>
                    <div className="fb_input">
                      <NumericTextBoxComponent
                        value={
                          Number(netMonthlyIncomeTotal) -
                          (Number(monthlyDebtTotal) + Number(monthlyExpenseTotal) + Number(monthlySavingTotal) + Number(netRetirementTotal))
                        }
                        format={usedCurrency}
                        showSpinButton={false}
                        disabled={false}
                        style={{
                          backgroundColor: "#FFE99B",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {dataFilter === "Joint" && (
                <div className="inner-summary-container-fb">
                  <div className="fb-input-group">
                    <h2>TOTAL JOINT CONTRIBUTION</h2>
                    <div className="fb_input">
                      <NumericTextBoxComponent
                        value={Number(netMonthlyIncomeTotal) + Number(grossMonthlyIncomeTotal)}
                        format={usedCurrency}
                        showSpinButton={false}
                        disabled={false}
                        style={{
                          backgroundColor: "#FFE99B",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                  </div>
                  <div className="fb-input-group">
                    <h2>LESS TOTAL MONTHLY JOINT EXPENSES</h2>
                    <div className="fb_input">
                      <NumericTextBoxComponent
                        value={
                          Number(monthlyDebtTotal) + Number(monthlyExpenseTotal) + Number(monthlySavingTotal) + Number(netRetirementTotal)
                        }
                        format={usedCurrency}
                        showSpinButton={false}
                        disabled={false}
                        style={{
                          backgroundColor: "#FFE99B",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                  </div>
                  <div className="fb-input-group">
                    <h2>{summaryDiffLabel}</h2>
                    <div className="fb_input">
                      <NumericTextBoxComponent
                        value={
                          Number(netMonthlyIncomeTotal) +
                          Number(grossMonthlyIncomeTotal) -
                          (Number(monthlyDebtTotal) + Number(monthlyExpenseTotal) + Number(monthlySavingTotal) + Number(netRetirementTotal))
                        }
                        format={usedCurrency}
                        showSpinButton={false}
                        disabled={false}
                        style={{
                          backgroundColor: "#FFE99B",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </>
    </div>
  );
}

export default FinalBudget;
