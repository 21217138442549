import { MonthlyPrices } from "./constant";

export const countDaysFromSubscribeDate = (subscribeDate) => {
  const currentDate = new Date(); // Current date and time
  const subscriptionDate = new Date(subscribeDate);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds =
    currentDate.getTime() - subscriptionDate.getTime();

  // Convert milliseconds to days
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  return differenceInDays;
};

export const checkExpiry = (user) => {
  const currentDate = new Date();
  let subscriptionEndDate = new Date(user.SubscribeDate);

  if (user.Plan === "Monthly") {
    subscriptionEndDate.setMonth(subscriptionEndDate.getMonth() + 1);
  } else if (user.Plan === "Yearly") {
    subscriptionEndDate.setFullYear(subscriptionEndDate.getFullYear() + 1);
  }

  // Check if the subscription has expired
  if (subscriptionEndDate < currentDate) {
    // Update the user's expire field to true
    return true;
  }

  return false;
};

export const countNewPrice = (user, pack) => {
  let newAmount = MonthlyPrices[pack];
  if (user.Package === "Standard" && pack === "Standard") return newAmount;

  if (user.Package === "Premium" && pack === "Premium") return newAmount;
  if (
    user &&
    user.Payment &&
    !user.isExpired &&
    user.SubscribeDate &&
    user.Package !== null
  ) {
    if (user.Plan === "Monthly") {
      console.log("Comming ------");
      const usedDays = countDaysFromSubscribeDate(user.SubscribeDate);
      console.log("Days : ", usedDays);
      newAmount =
        MonthlyPrices[pack] -
        (Number(MonthlyPrices[user.Package]) * (30 - usedDays)) / 30;
    }
  }

  return newAmount.toFixed(2);
};
