import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
  Reorder,
  Resize,
  PdfExport,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import axios from "../../../config/axios";
import "../../shared/ListView.css";
import useIncomeDataFetch from "../../shared/useIncomeDataFetch"; // Import the custom hook
import useFrequencyDataFetch from "../../shared/useFrequencyDataFetch";
import useIncomeNameDataFetch from "../../shared/useIncomeNameDataFetch";
import IncomeDialog from "./IncomeDialog";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { dropdownTemplate } from "../../shared/HeaderTempate";
import { CheckIncomeFields } from "../../../utils/requiredFields";

function Income() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const userPackage = user?.Package;
  const { incomeData, isIncomeLoaded } = useIncomeDataFetch();
  const { frequencyData, isFrequencyLoaded } = useFrequencyDataFetch();
  const { incomeNameData, isIncomeNameLoaded } = useIncomeNameDataFetch();
  const [incomeNames, setIncomeNames] = useState();
  const [incomeFrequencies, setIncomeFrequencies] = useState();
  const [payDays, setPayDays] = useState();
  const [dataFilter, setDataFilter] = useState("Household");
  const [incomeNet, setIncomeNet] = useState(0);
  const [incomeGross, setIncomeGross] = useState(0);
  const [incomeNetYearly, setIncomeNetYearly] = useState(0);
  const [incomeGrossYearly, setIncomeGrossYearly] = useState(0);
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isAddDialogVisible, setIsAddDialogVisible] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(true);
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
  }
  const newCurrentDate = formatDate(new Date());
  const usedCurrency = user?.Currency + " #,###.00";
  //const gridData = filteredProjectData
  const [gridData, setGridData] = useState([]);
  const [updatedIncomeData, setUpdatedIncomeData] = useState([]);

  const handleAccountOwnerChange = async (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setDataFilter(e.value);
      const ownerFilter = e.value;
      let finalIncome;
      if (user?.Type !== "Root") {
        finalIncome = updatedIncomeData?.filter(
          (item) => item.UserID === user?.id
        );
      } else {
        finalIncome = updatedIncomeData;
      }
      finalIncome.sort((a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn));

      let filteredIncome;
      if (
        ownerFilter === "Self" ||
        ownerFilter === "Partner" ||
        ownerFilter === "Joint"
      ) {
        filteredIncome = finalIncome.filter(
          (item) => item.AccountOwner === ownerFilter
        );
      } else {
        filteredIncome = finalIncome;
      }
      setGridData(filteredIncome);
      setIsDataChanged(true);
    }
  };

  useEffect(() => {
    if (isIncomeNameLoaded) {
      const uniqueIncomeNames = [
        ...new Set(
          incomeNameData.map((item) =>
            item.description ? item.description : ""
          )
        ),
      ];
      uniqueIncomeNames.sort((a, b) => a.localeCompare(b));
      setIncomeNames(uniqueIncomeNames);
    }
  }, [incomeNameData, isIncomeNameLoaded]);

  useEffect(() => {
    if (isFrequencyLoaded) {
      const uniqueFrequencies = [
        ...new Set(
          frequencyData.map((item) => (item.Frequency ? item.Frequency : ""))
        ),
      ];
      uniqueFrequencies.sort((a, b) => a.localeCompare(b));
      setIncomeFrequencies(uniqueFrequencies);
      const uniquePayDays = [
        ...new Set(
          frequencyData.map((item) => (item.PayDay ? item.PayDay : ""))
        ),
      ];
      setPayDays(uniquePayDays);
    }
  }, [frequencyData, isFrequencyLoaded]);

  useEffect(() => {
    if (isIncomeLoaded) {
      setUpdatedIncomeData(incomeData);
      incomeData.sort((a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn));
      setGridData(incomeData);
      setIsDataChanged(true);
      setIsDataLoaded(true);
    }
  }, [incomeData, isIncomeLoaded]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded || !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  useEffect(() => {
    const calculateYearlyIncome = (incomeType) => {
      return gridData.reduce((accumulator, record) => {
        const income = record[incomeType] || 0;
        const payFrequency = record.PayFrequency || "";

        let yearlyIncome = 0;

        switch (payFrequency) {
          case "Yearly":
            yearlyIncome = income;
            break;
          case "Monthly":
            yearlyIncome = income * 12;
            break;
          case "Semi-Monthly":
            yearlyIncome = income * 24;
            break;
          case "Weekly":
            yearlyIncome = income * 52;
            break;
          case "Bi-Weekly":
            yearlyIncome = income * 26;
            break;
          default:
            yearlyIncome = 0;
        }

        return accumulator + Number(yearlyIncome);
      }, 0);
    };

    const calculateMonthlyIncome = (incomeType) => {
      return gridData.reduce((accumulator, record) => {
        const income = record[incomeType] || 0;
        const payFrequency = record.PayFrequency || "";

        let monthlyIncome = 0;

        switch (payFrequency) {
          case "Yearly":
            monthlyIncome = income / 12;
            break;
          case "Monthly":
            monthlyIncome = income;
            break;
          case "Semi-Monthly":
            monthlyIncome = income * 2;
            break;
          case "Weekly":
            monthlyIncome = income * 4;
            break;
          case "Bi-Weekly":
            monthlyIncome = income * 2;
            break;
          default:
            monthlyIncome = 0;
        }

        return accumulator + Number(monthlyIncome);
      }, 0);
    };

    const netYearlyIncome = calculateYearlyIncome("NetPay");
    const grossYearlyIncome = calculateYearlyIncome("GrossPay");
    const netMonthlyIncome = calculateMonthlyIncome("NetPay");
    const grossMonthlyIncome = calculateMonthlyIncome("GrossPay");

    setIncomeNetYearly(netYearlyIncome);
    setIncomeGrossYearly(grossYearlyIncome);
    setIncomeGross(grossMonthlyIncome);
    setIncomeNet(netMonthlyIncome);
  }, [gridData]);

  const [selectedID, setSelectedID] = useState("");
  const [selectedRootID, setSelectedRootID] = useState("");
  const [selectedUserID, setSelectedUserID] = useState("");
  const [selectedAccountOwner, setSelectedAccountOwner] = useState("");
  const [selectedIncomeName, setSelectedIncomeName] = useState("");
  const [selectedIncomeDescription, setSelectedIncomeDescription] =
    useState("");
  const [selectedPayFrequency, setSelectedPayFrequency] = useState("");
  const [selectedPayDay, setSelectedPayDay] = useState("");
  const [selectedGrossPay, setSelectedGrossPay] = useState("");
  const [selectedNetPay, setSelectedNetPay] = useState("");

  const [newRootID, setNewRootID] = useState("");
  const [newUserID, setNewUserID] = useState("");
  const [newAccountOwner, setNewAccountOwner] = useState("");
  const [newIncomeName, setNewIncomeName] = useState("");
  const [newIncomeDescription, setNewIncomeDescription] = useState("");
  const [newPayFrequency, setNewPayFrequency] = useState("");
  const [newPayDay, setNewPayDay] = useState("");
  const [newGrossPay, setNewGrossPay] = useState("");
  const [newNetPay, setNewNetPay] = useState("");
  const [isRequired, setIsRequired] = useState(false);

  const incomeNewData = {
    RootID: newRootID,
    UserID: newUserID,
    AccountOwner: newAccountOwner,
    IncomeName: newIncomeName,
    IncomeDescription: newIncomeDescription,
    PayFrequency: newPayFrequency,
    PayDay: newPayDay,
    GrossPay: newGrossPay,
    NetPay: newNetPay,
    UpdatedOn: newCurrentDate,
  };

  const handleRowSelect = async (args) => {
    setSelectedID(args.data.id);
    setSelectedRootID(args.data.RootID);
    setSelectedUserID(args.data.UserID);
    setSelectedAccountOwner(args.data.AccountOwner);
    setSelectedIncomeName(args.data.IncomeName);
    setSelectedIncomeDescription(args.data.IncomeDescription);
    setSelectedPayFrequency(args.data.PayFrequency);
    setSelectedPayDay(args.data.PayDay);
    setSelectedGrossPay(args.data.GrossPay);
    setSelectedNetPay(args.data.NetPay);
  };

  const handleExcelButtonClick = () => {};

  const handlePdfButtonClick = () => {};

  const handleEditDialogClose = () => {
    setIsEditDialogVisible(false);
  };

  const handleAddDialogClose = () => {
    setIsAddDialogVisible(false);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    setIsEditDialogVisible(!isEditDialogVisible);
    setNewRootID(selectedRootID);
    setNewUserID(selectedUserID);
    setNewAccountOwner(selectedAccountOwner);
    setNewIncomeName(selectedIncomeName);
    setNewIncomeDescription(selectedIncomeDescription);
    setNewPayFrequency(selectedPayFrequency);
    setNewPayDay(selectedPayDay);
    setNewGrossPay(selectedGrossPay);
    setNewNetPay(selectedNetPay);
    setIsRequired(false);
  };

  const handleAddButtonClick = () => {
    setIsAddDialogVisible(!isAddDialogVisible);
    setSelectedID("");
    setSelectedRootID("");
    setSelectedUserID("");
    setSelectedAccountOwner("");
    setSelectedIncomeName("");
    setSelectedIncomeDescription("");
    setSelectedPayFrequency("");
    setSelectedPayDay("");
    setSelectedGrossPay("");
    setSelectedNetPay("");
    if (GridRef.current) {
      GridRef.current.clearSelection();
    }
    setNewRootID(user?.RootID);
    setNewUserID(user?.id);
    setNewAccountOwner("");
    setNewIncomeName("");
    setNewIncomeDescription("");
    setNewPayFrequency("");
    setNewPayDay("");
    setNewGrossPay("");
    setNewNetPay("");
    setIsRequired(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeleteDialogVisible(!isDeleteDialogVisible);
  };

  const handleAddRecordButton = async () => {
    // Make a POST request to add the Record record
    setIsDataChanged(false);
    try {
      const RecordResponse = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/income",
        incomeNewData
      );
      console.log("Record response:", RecordResponse.data);
      if (RecordResponse.data.status === "success") {
        toast.success("Added successfully.");
        try {
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/income/rootuserid/${user?.RootID}`
          );
          const Records = updatedResponse.data.items;
          setUpdatedIncomeData(Records);
          let finalIncome;
          if (user?.Type !== "Root") {
            finalIncome = Records?.filter((item) => item.UserID === user?.id);
          } else {
            finalIncome = Records;
          }
          finalIncome.sort(
            (a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn)
          );

          let filteredIncome;
          if (
            dataFilter === "Self" ||
            dataFilter === "Partner" ||
            dataFilter === "Joint"
          ) {
            filteredIncome = finalIncome.filter(
              (item) => item.AccountOwner === dataFilter
            );
          } else {
            filteredIncome = finalIncome;
          }
          setGridData(filteredIncome);
        } catch (fetchError) {
          setUpdatedIncomeData([]);
          setGridData([]);
        }
      } else {
        console.error(
          "Error adding the record record:",
          RecordResponse.data.message
        );
        toast.error("Error adding the record");
      }
    } catch (error) {
      console.error("Error adding the record:", error);
      toast.error("Error adding the record");
    }
    setIsDataChanged(true);
  };

  const handleUpdateRecordButton = async (selectedID) => {
    setIsDataChanged(false);
    const numericSelectedID = parseInt(selectedID);
    try {
      const RecordResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/income/${numericSelectedID}`,
        {
          RootID: newRootID,
          UserID: newUserID,
          AccountOwner: newAccountOwner,
          IncomeName: newIncomeName,
          IncomeDescription: newIncomeDescription,
          PayFrequency: newPayFrequency,
          PayDay: newPayDay,
          GrossPay: newGrossPay,
          NetPay: newNetPay,
          UpdatedOn: newCurrentDate,
        }
      );
      console.log("Record response:", RecordResponse.data);
      if (RecordResponse.data.status === "success") {
        toast.success("the record updated successfully.");
        try {
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/income/rootuserid/${user?.RootID}`
          );
          const Records = updatedResponse.data.items;
          setUpdatedIncomeData(Records);
          let finalIncome;
          if (user?.Type !== "Root") {
            finalIncome = Records?.filter((item) => item.UserID === user?.id);
          } else {
            finalIncome = Records;
          }
          finalIncome.sort(
            (a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn)
          );

          let filteredIncome;
          if (
            dataFilter === "Self" ||
            dataFilter === "Partner" ||
            dataFilter === "Joint"
          ) {
            filteredIncome = finalIncome.filter(
              (item) => item.AccountOwner === dataFilter
            );
          } else {
            filteredIncome = finalIncome;
          }
          setGridData(filteredIncome);
        } catch (fetchError) {
          setUpdatedIncomeData([]);
          setGridData([]);
        }
      } else {
        console.error(
          "Error updating the record record:",
          RecordResponse.data.message
        );
        toast.error("Error updating the record");
      }
    } catch (error) {
      console.error("Error updating the record:", error);
      toast.error("Error updating the record");
    }
    setIsDataChanged(true);
  };

  const handleDeleteRecordButton = async (selectedID) => {
    setIsDataChanged(false);
    const numericSelectedID = parseInt(selectedID);
    try {
      const deleteResponse = await axios.delete(
        process.env.REACT_APP_SERVER_URL + `/api/income/${numericSelectedID}`
      );
      console.log("Delete response:", deleteResponse.data);
      if (deleteResponse.data.status === "success") {
        toast.success("The record was deleted successfully.");
        try {
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/income/rootuserid/${user?.RootID}`
          );
          const Records = updatedResponse.data.items;
          setUpdatedIncomeData(Records);
          let finalIncome;
          if (user?.Type !== "Root") {
            finalIncome = Records?.filter((item) => item.UserID === user?.id);
          } else {
            finalIncome = Records;
          }
          finalIncome.sort(
            (a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn)
          );

          let filteredIncome;
          if (
            dataFilter === "Self" ||
            dataFilter === "Partner" ||
            dataFilter === "Joint"
          ) {
            filteredIncome = finalIncome.filter(
              (item) => item.AccountOwner === dataFilter
            );
          } else {
            filteredIncome = finalIncome;
          }
          setGridData(filteredIncome);
        } catch (fetchError) {
          setUpdatedIncomeData([]);
          setGridData([]);
        }
      } else {
        console.error(
          "Error deleting the record:",
          deleteResponse.data.message
        );
        toast.error("Error updating item");
      }
    } catch (error) {
      console.error("Error deleting the record:", error);
      toast.error("Error deleting the record");
    }
    setIsDataChanged(true);
  };

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = ["ColumnChooser"];
  const loadingIndicator = { indicatorType: "Spinner" };

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          <button className="add-button" onClick={handleAddButtonClick}>
            <AddIcon className="send-icon" /> Add New{" "}
          </button>
          <button
            className="edit-button"
            onClick={handleEditButtonClick}
            disabled={selectedID === ""}
          >
            <EditIcon className="send-icon" /> Edit{" "}
          </button>
          <button
            className="delete-button"
            onClick={handleDeleteButtonClick}
            disabled={selectedID === ""}
          >
            <DeleteIcon className="but-icon-whereabouts" /> Delete{" "}
          </button>
        </div>
        {/* <div className="button-container-subA2">
          <button className="excel-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          <button className="pdf-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button>
        </div> */}
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  let addButtons = [
    {
      buttonModel: {
        content: "Save New",
        cssClass: "e-success update-btn",
        isPrimary: true,
      },
      click: () => {
        if (
          CheckIncomeFields(
            newAccountOwner,
            newIncomeName,
            newPayFrequency,
            newPayDay,
            newGrossPay,
            newNetPay
          )
        ) {
          setIsRequired(true);
        } else {
          handleAddRecordButton(selectedID);
          setIsAddDialogVisible(false);
        }
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsAddDialogVisible(false);
      },
    },
  ];

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-success update-btn",
        isPrimary: true,
      },
      click: () => {
        if (
          CheckIncomeFields(
            newAccountOwner,
            newIncomeName,
            newPayFrequency,
            newPayDay,
            newGrossPay,
            newNetPay
          )
        ) {
          setIsRequired(true);
        } else {
          handleUpdateRecordButton(selectedID);
          setIsEditDialogVisible(false);
        }
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsEditDialogVisible(false);
      },
    },
  ];

  let deleteButtons = [
    {
      buttonModel: {
        content: "Delete",
        cssClass: "e-danger update-btn",
        isPrimary: true,
      },
      click: () => {
        handleDeleteRecordButton(selectedID);
        setIsDeleteDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsDeleteDialogVisible(false);
      },
    },
  ];

  const budgetedWeeklyGrossPayTemplate = (props) => {
    const grossPay = props.GrossPay;
    const frequency = props.PayFrequency;
    let weekPay;
    if (frequency === "Weekly") {
      weekPay = grossPay;
    } else {
      weekPay = 0;
    }
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(weekPay);

    return <div>{formattedAmount}</div>;
  };

  const budgetedWeeklyNetPayTemplate = (props) => {
    const netPay = props.NetPay;
    const frequency = props.PayFrequency;
    let weekPay;
    if (frequency === "Weekly") {
      weekPay = netPay;
    } else {
      weekPay = 0;
    }
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(weekPay);

    return <div>{formattedAmount}</div>;
  };

  const budgetedBiWeeklyGrossPayTemplate = (props) => {
    const grossPay = props.GrossPay;
    const frequency = props.PayFrequency;
    let weekPay;
    if (frequency === "Bi-Weekly") {
      weekPay = grossPay;
    } else {
      weekPay = 0;
    }
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(weekPay);

    return <div>{formattedAmount}</div>;
  };

  const budgetedBiWeeklyNetPayTemplate = (props) => {
    const netPay = props.NetPay;
    const frequency = props.PayFrequency;
    let weekPay;
    if (frequency === "Bi-Weekly") {
      weekPay = netPay;
    } else {
      weekPay = 0;
    }
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(weekPay);

    return <div>{formattedAmount}</div>;
  };

  const budgetedSemiMonthlyGrossPayTemplate = (props) => {
    const grossPay = props.GrossPay;
    const frequency = props.PayFrequency;
    let weekPay;
    if (frequency === "Semi-Monthly") {
      weekPay = grossPay;
    } else {
      weekPay = 0;
    }
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(weekPay);

    return <div>{formattedAmount}</div>;
  };

  const budgetedSemiMonthlyNetPayTemplate = (props) => {
    const netPay = props.NetPay;
    const frequency = props.PayFrequency;
    let weekPay;
    if (frequency === "Semi-Monthly") {
      weekPay = netPay;
    } else {
      weekPay = 0;
    }
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(weekPay);

    return <div>{formattedAmount}</div>;
  };

  const budgetedMonthlyGrossPayTemplate = (props) => {
    const grossPay = props.GrossPay;
    const frequency = props.PayFrequency;
    let grossIncome = 0;
    switch (frequency) {
      case "Yearly":
        grossIncome = grossPay / 12;
        break;
      case "Monthly":
        grossIncome = grossPay;
        break;
      case "Semi-Monthly":
        grossIncome = grossPay * 2;
        break;
      case "Weekly":
        grossIncome = grossPay * 4;
        break;
      case "Bi-Weekly":
        grossIncome = grossPay * 2;
        break;
      default:
        grossIncome = 0;
    }
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(grossIncome);

    return <div>{formattedAmount}</div>;
  };

  const budgetedMonthlyNetPayTemplate = (props) => {
    const netPay = props.NetPay;
    const frequency = props.PayFrequency;
    let netIncome = 0;
    switch (frequency) {
      case "Yearly":
        netIncome = netPay / 12;
        break;
      case "Monthly":
        netIncome = netPay;
        break;
      case "Semi-Monthly":
        netIncome = netPay * 2;
        break;
      case "Weekly":
        netIncome = netPay * 4;
        break;
      case "Bi-Weekly":
        netIncome = netPay * 2;
        break;
      default:
        netIncome = 0;
    }
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(netIncome);

    return <div>{formattedAmount}</div>;
  };

  const budgetedYearlyGrossPayTemplate = (props) => {
    const grossPay = props.GrossPay;
    const frequency = props.PayFrequency;
    let grossIncome = 0;
    switch (frequency) {
      case "Yearly":
        grossIncome = grossPay * 1;
        break;
      case "Monthly":
        grossIncome = grossPay * 12;
        break;
      case "Semi-Monthly":
        grossIncome = grossPay * 24;
        break;
      case "Weekly":
        grossIncome = grossPay * 52;
        break;
      case "Bi-Weekly":
        grossIncome = grossPay * 26;
        break;
      default:
        grossIncome = 0;
    }
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(grossIncome);

    return <div>{formattedAmount}</div>;
  };

  const budgetedYearlyNetPayTemplate = (props) => {
    const netPay = props.NetPay;
    const frequency = props.PayFrequency;
    let netIncome = 0;
    switch (frequency) {
      case "Yearly":
        netIncome = netPay * 1;
        break;
      case "Monthly":
        netIncome = netPay * 12;
        break;
      case "Semi-Monthly":
        netIncome = netPay * 24;
        break;
      case "Weekly":
        netIncome = netPay * 52;
        break;
      case "Bi-Weekly":
        netIncome = netPay * 26;
        break;
      default:
        netIncome = 0;
    }
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(netIncome);

    return <div>{formattedAmount}</div>;
  };

  const handleUpgradeButtonClick = () => {
    navigate("/packages");
  };

  return (
    <div className="all-outer-project-containers">
      <div className="title-container">
        <div className="mainTitle_dropdown">
          <h3>Income for {user?.FullName}</h3>
          <div className="_account_owner1">
            <ComboBoxComponent
              id="comboelement"
              value="Select Owner"
              floatLabelType="Always"
              headerTemplate={dropdownTemplate}
              dataSource={["Self", "Partner", "Joint", "Household"]}
              change={handleAccountOwnerChange}
              style={{ color: "#FFE99B", backgroundColor: "black" }}
            />
          </div>
        </div>
      </div>
      {/* summary and buttons */}
      <div className="summay-btns">
        <div className="summary-container-income">
          {/* firstTwobtns */}
          <div className="firstBTNS">
            <div className="income_net_pay1">
              <p style={{ fontSize: "12px", textWrap: "nowrap" }}>
                Budgeted Gross Monthly Pay
              </p>
              <div className="value">
                <strong style={{ fontWeight: "bold" }}>
                  {user?.Currency}
                  {incomeGross.toLocaleString(user?.Separator, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </strong>
              </div>
            </div>
            <div className="income_net_pay1">
              <p style={{ fontSize: "12px" }}>Budgeted Net Monthly Pay</p>
              <div className="value">
                <strong style={{ fontWeight: "bold" }}>
                  {user?.Currency}
                  {incomeNet.toLocaleString(user?.Separator, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </strong>
              </div>
            </div>
          </div>

          <div className="secondBTNS">
            <div className="income_net_pay1">
              <p style={{ fontSize: "12px" }}>Gross Yearly Pay</p>
              <div className="value">
                <strong style={{ fontWeight: "bold" }}>
                  {user?.Currency}
                  {incomeGrossYearly.toLocaleString(user?.Separator, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </strong>
              </div>
            </div>
            <div className="income_net_pay1">
              <p style={{ fontSize: "12px" }}>Net Yearly Pay</p>
              <div className="value">
                <strong style={{ fontWeight: "bold" }}>
                  {user?.Currency}
                  {incomeNetYearly.toLocaleString(user?.Separator, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </strong>
              </div>
            </div>
          </div>
        </div>

        <p>
          * Note 1: Budgeted net monthly income for someone paid weekly is based
          on 4 pay periods per month (48 pay periods per year). The 4 Extra pays
          per year are directed towards savings.{" "}
        </p>
        <p>
          * Note 2: Budgeted net monthly income for someone paid bi-weekly is
          based on 2 pay periods per month (24 pay periods per year). The 2
          Extra pays per year are directed towards savings.{" "}
        </p>
      </div>

      <br />
      <>
        {userPackage !== "Basic" &&
          userPackage !== "Standard" &&
          userPackage !== "Premium" && (
            <div className="title-container1">
              <h2>This Feature is Only Available to Paid Users Only</h2>
              <div className="button-container">
                <div className="button-container-subA1">
                  <button
                    className="add-button"
                    onClick={handleUpgradeButtonClick}
                  >
                    <UpgradeIcon className="send-icon" />
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          )}
        {(userPackage === "Basic" ||
          userPackage === "Standard" ||
          userPackage === "Premium") && (
          <>
            <div className="all-inner-project-containers">
              {isDataLoaded && (
                <GridComponent
                  dataSource={gridData}
                  childMapping="items"
                  ref={GridRef}
                  classClass="custom-treegrid-class"
                  height="420"
                  width="100%"
                  color="red"
                  allowReordering={true}
                  allowResizing={true}
                  allowExcelExport={true}
                  allowPdfExport={true}
                  allowPaging={true}
                  allowSorting={true}
                  allowFiltering={true}
                  allowTextWrap={true}
                  showColumnChooser={true}
                  filterSettings={FilterOptions}
                  pageSettings={pageSettings}
                  editSettings={editOptions}
                  toolbar={updatedToolbarOptions}
                  loadingIndicator={loadingIndicator}
                  allowSelection={true}
                  rowSelected={handleRowSelect}
                  className="unknown"
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="AccountOwner"
                      headerText="Account Owner"
                      width="120"
                      textAlign="Left"
                    />
                    <ColumnDirective
                      field="IncomeName"
                      headerText="Income"
                      width="120"
                      textAlign="Left"
                    />
                    <ColumnDirective
                      field="IncomeDescription"
                      headerText="Description"
                      width="200"
                      textAlign="Left"
                    />
                    <ColumnDirective
                      field="PayFrequency"
                      headerText="Pay Frequency"
                      width="120"
                      textAlign="Left"
                    />
                    <ColumnDirective
                      field="PayDay"
                      headerText="Due Date"
                      width="120"
                      textAlign="Left"
                    />
                    <ColumnDirective
                      headerText="Weekly Gross Pay"
                      width="100"
                      textAlign="Left"
                      template={budgetedWeeklyGrossPayTemplate}
                    />
                    <ColumnDirective
                      headerText="Weekly Net Pay"
                      width="100"
                      textAlign="Left"
                      template={budgetedWeeklyNetPayTemplate}
                    />
                    <ColumnDirective
                      headerText="Bi-Weekly Gross Pay"
                      width="100"
                      textAlign="Left"
                      template={budgetedBiWeeklyGrossPayTemplate}
                    />
                    <ColumnDirective
                      headerText="Bi-Weekly Net Pay"
                      width="100"
                      textAlign="Left"
                      template={budgetedBiWeeklyNetPayTemplate}
                    />
                    <ColumnDirective
                      headerText="Semi-Monthly Gross Pay"
                      width="100"
                      textAlign="Left"
                      template={budgetedSemiMonthlyGrossPayTemplate}
                    />
                    <ColumnDirective
                      headerText="Semi-Monthly Net Pay"
                      width="100"
                      textAlign="Left"
                      template={budgetedSemiMonthlyNetPayTemplate}
                    />
                    <ColumnDirective
                      headerText="Budgeted Monthly Gross Pay"
                      width="100"
                      textAlign="Left"
                      template={budgetedMonthlyGrossPayTemplate}
                    />
                    <ColumnDirective
                      headerText="Budgeted Monthly Net Pay"
                      width="100"
                      textAlign="Left"
                      template={budgetedMonthlyNetPayTemplate}
                    />
                    <ColumnDirective
                      headerText="Yearly Gross Pay"
                      width="100"
                      textAlign="Left"
                      template={budgetedYearlyGrossPayTemplate}
                    />
                    <ColumnDirective
                      headerText="Yearly Net Pay"
                      width="150"
                      textAlign="Left"
                      template={budgetedYearlyNetPayTemplate}
                    />
                  </ColumnsDirective>
                  <Inject
                    services={[
                      Page,
                      Sort,
                      Filter,
                      Edit,
                      Toolbar,
                      ColumnChooser,
                      Reorder,
                      Resize,
                      PdfExport,
                      ExcelExport,
                    ]}
                  />
                </GridComponent>
              )}
            </div>
          </>
        )}
      </>
      <div>
        {isEditDialogVisible && (
          <DialogComponent
            visible={isEditDialogVisible}
            width="50%"
            height="480px"
            header="Edit Income"
            allowDragging={true}
            showCloseIcon={true}
            close={handleEditDialogClose}
            buttons={editButtons}
          >
            <IncomeDialog
              selectedAccountOwner={selectedAccountOwner}
              selectedIncomeName={selectedIncomeName}
              selectedIncomeDescription={selectedIncomeDescription}
              selectedPayFrequency={selectedPayFrequency}
              selectedPayDay={selectedPayDay}
              selectedGrossPay={selectedGrossPay}
              selectedNetPay={selectedNetPay}
              incomeNames={incomeNames}
              incomeFrequencies={incomeFrequencies}
              payDays={payDays}
              frequencyData={frequencyData}
              usedCurrency={usedCurrency}
              setNewAccountOwner={setNewAccountOwner}
              setNewIncomeName={setNewIncomeName}
              setNewIncomeDescription={setNewIncomeDescription}
              setNewPayFrequency={setNewPayFrequency}
              setNewPayDay={setNewPayDay}
              setNewGrossPay={setNewGrossPay}
              setNewNetPay={setNewNetPay}
              isRequired={isRequired}
            />
          </DialogComponent>
        )}
      </div>
      <div>
        {isAddDialogVisible && (
          <DialogComponent
            visible={isAddDialogVisible}
            width="50%"
            height="480px"
            header="Add Income"
            allowDragging={true}
            showCloseIcon={true}
            close={handleAddDialogClose}
            buttons={addButtons}
          >
            <IncomeDialog
              selectedAccountOwner={selectedAccountOwner}
              selectedIncomeName={selectedIncomeName}
              selectedIncomeDescription={selectedIncomeDescription}
              selectedPayFrequency={selectedPayFrequency}
              selectedPayDay={selectedPayDay}
              selectedGrossPay={selectedGrossPay}
              selectedNetPay={selectedNetPay}
              incomeNames={incomeNames}
              incomeFrequencies={incomeFrequencies}
              payDays={payDays}
              frequencyData={frequencyData}
              usedCurrency={usedCurrency}
              setNewAccountOwner={setNewAccountOwner}
              setNewIncomeName={setNewIncomeName}
              setNewIncomeDescription={setNewIncomeDescription}
              setNewPayFrequency={setNewPayFrequency}
              setNewPayDay={setNewPayDay}
              setNewGrossPay={setNewGrossPay}
              setNewNetPay={setNewNetPay}
              isRequired={isRequired}
            />
          </DialogComponent>
        )}
      </div>
      <div>
        {isDeleteDialogVisible && (
          <DialogComponent
            visible={isDeleteDialogVisible}
            width="25%"
            height="200px"
            header="Delete Income"
            content={`Do you want to delete the selected ${selectedIncomeName}?`}
            allowDragging={true}
            showCloseIcon={true}
            close={handleDeleteDialogClose}
            buttons={deleteButtons}
          ></DialogComponent>
        )}
      </div>
    </div>
  );
}
export default Income;
