import "./Header.css";
import logo from "./logo.jpg";
import HeaderOption from "./HeaderOption";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import SavingsIcon from "@mui/icons-material/Savings";
import ElderlyIcon from "@mui/icons-material/Elderly";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ScoreIcon from "@mui/icons-material/Score";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import WorkIcon from "@mui/icons-material/Work";
import { NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import useUserStore from "../../app/user";

function Header({ isDisplaySidebar, setisDisplaySidebar }) {
  const user = useUserStore((state) => state.user);
  const setJwt = useUserStore((state) => state.setJwt);
  const setUser = useUserStore((state) => state.setUser);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const linkRef1 = useRef(null);
  const linkRef2 = useRef(null);
  const linkRef3 = useRef(null);
  const linkRef4 = useRef(null);
  const linkRef5 = useRef(null);
  const location = useLocation();

  const navigate = useNavigate();
  //const profile = process.env.REACT_APP_SERVER_URL + "/public/uploads/" + user?.UserName + ".jpg";

  const handleLogout = () => {
    // Perform logout actions
    // Redirect to the login page
    setUser(null);
    setJwt(null);
    navigate("/login");
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false); // Close the dialog
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleSubMenuToggle = (title, isClose) => {
    setOpenSubMenu((prev) => (prev === title ? null : title));
    if (window.innerWidth <= 770 && isClose) {
      setisDisplaySidebar((prev) => !prev);
    }
    linkRef1.current.href = "#";
  };

  const handleMenuTabClick = (title) => {
    setOpenSubMenu(null); // Close the submenu when a menu tab is clicked
    if (window.innerWidth <= 770) {
      setisDisplaySidebar((prev) => !prev);
    }
  };

  const getClasseName = (title) => {
    const isActive = location.pathname.includes(title) || (title === "dashboard" && location.pathname === "/");
    return isActive ? "active-link" : "";
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 770) setisDisplaySidebar(true);
      else setisDisplaySidebar(false);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (linkRef1.current) linkRef1.current.href = "#";
    if (linkRef2.current) linkRef2.current.href = "#";
    if (linkRef3.current) linkRef3.current.href = "#";
    if (linkRef4.current) linkRef4.current.href = "#";
    if (linkRef5.current) linkRef5.current.href = "#";
  }, [linkRef1.current?.href, linkRef2.current?.href, linkRef3.current?.href, linkRef4.current?.href, linkRef5.current?.href]);

  return (
    <div className="header">
      <div className="header_left">
        <img src={logo} alt="LOGO" />
        <div className="header_name">
          <h1>ELITE</h1>
          <h2>Cash Flow Consulting</h2>
        </div>
      </div>
      <div className="header_scroll">
        <div className="header_right">
          <NavLink to="/" onClick={() => handleMenuTabClick("Dashboard")} className={getClasseName("dashboard")}>
            {
              <HeaderOption
                Icon={SpaceDashboardIcon}
                title="Dashboard"
                // eslint-disable-next-line no-undef
                openSubMenu={openSubMenu}
                onSubMenuToggle={handleSubMenuToggle}
                setisDisplaySidebar={setisDisplaySidebar}
              />
            }
          </NavLink>
          <NavLink to="#" ref={linkRef1} onClick={() => handleSubMenuToggle("Income")} className={getClasseName("income")}>
            {
              <HeaderOption
                Icon={MonetizationOnIcon}
                title="Income"
                openSubMenu={openSubMenu}
                onSubMenuToggle={handleSubMenuToggle}
                setisDisplaySidebar={setisDisplaySidebar}
                subMenu={[
                  { LinkTo: "/income/incomedetails", title: "Income Records" },
                  { LinkTo: "/income/extraweeks", title: "Extra Pay Dates" },
                ]}
              />
            }
          </NavLink>
          <NavLink to="#" ref={linkRef2} onClick={() => handleSubMenuToggle("Expenses")} className={getClasseName("expenses")}>
            {
              <HeaderOption
                Icon={ShoppingCartIcon}
                title="Expenses"
                openSubMenu={openSubMenu}
                onSubMenuToggle={handleSubMenuToggle}
                setisDisplaySidebar={setisDisplaySidebar}
                subMenu={[
                  {
                    LinkTo: "/expenses/expensedetails",
                    title: "Expense Records",
                  },
                  {
                    LinkTo: "/expenses/jointcontribution",
                    title: "Joint Contribuation",
                  },
                ]}
              />
            }
          </NavLink>
          <NavLink to="/debts" onClick={() => handleMenuTabClick("Debts")} className={getClasseName("debts")}>
            {<HeaderOption Icon={CreditCardIcon} title="Debts" />}
          </NavLink>
          <NavLink to="/retirement" onClick={() => handleMenuTabClick("Retirement")} className={getClasseName("retirement")}>
            {<HeaderOption Icon={ElderlyIcon} title="Retirement" />}
          </NavLink>
          <NavLink to="/savings" onClick={() => handleMenuTabClick("Savings")} className={getClasseName("savings")}>
            {<HeaderOption Icon={SavingsIcon} title="Savings" />}
          </NavLink>
          <NavLink to="#" ref={linkRef3} onClick={() => handleSubMenuToggle("Bank Accounts")} className={getClasseName("bankaccounts")}>
            {
              <HeaderOption
                Icon={PointOfSaleIcon}
                title="Bank Accounts"
                openSubMenu={openSubMenu}
                setisDisplaySidebar={setisDisplaySidebar}
                onSubMenuToggle={handleSubMenuToggle}
                subMenu={[
                  {
                    LinkTo: "/bankaccounts/transactions",
                    title: "All transactions",
                  },
                  {
                    LinkTo: "/bankaccounts/unclearedtransactions",
                    title: "Uncleared Transactions",
                  },
                  {
                    LinkTo: "/bankaccounts/bankrecon",
                    title: "Bank Reconciliation",
                  },
                ]}
              />
            }
          </NavLink>
          <NavLink to="#" ref={linkRef4} onClick={() => handleSubMenuToggle("Final Budget")} className={getClasseName("finalbudget")}>
            {
              <HeaderOption
                Icon={ScoreIcon}
                title="Final Budget"
                openSubMenu={openSubMenu}
                onSubMenuToggle={handleSubMenuToggle}
                setisDisplaySidebar={setisDisplaySidebar}
                subMenu={[
                  {
                    LinkTo: "/finalbudget/budgetoverview",
                    title: "Budget Summary",
                  },
                  {
                    LinkTo: "/finalbudget/finalbudget",
                    title: "Budget Detail",
                  },
                  {
                    LinkTo: "/finalbudget/checklist",
                    title: "Checklist",
                  },
                  {
                    LinkTo: "/finalbudget/fundstracker",
                    title: "Extra Funds Tracker",
                  },
                ]}
              />
            }
          </NavLink>
          <NavLink to="/assets" onClick={() => handleMenuTabClick("Assets")} className={getClasseName("assets")}>
            {<HeaderOption Icon={HomeWorkIcon} title="Assets" />}
          </NavLink>
          <NavLink to="/networth" onClick={() => handleMenuTabClick("Net Worth")} className={getClasseName("networth")}>
            {<HeaderOption Icon={WorkIcon} title="Net Worth" />}
          </NavLink>
          <NavLink to="#" ref={linkRef5} onClick={() => handleSubMenuToggle("Admin")} className={getClasseName("admin")}>
            {
              <HeaderOption
                Icon={AdminPanelSettingsIcon}
                title="Admin"
                openSubMenu={openSubMenu}
                onSubMenuToggle={handleSubMenuToggle}
                setisDisplaySidebar={setisDisplaySidebar}
                subMenu={[
                  {
                    LinkTo: "/admin/userprofile",
                    title: "User Profile",
                  },
                  {
                    LinkTo: "/admin/budgetcategories",
                    title: "Budget Categories",
                  },
                  {
                    LinkTo: "/admin/subcategories",
                    title: "Budget Subcategories",
                  },
                  {
                    LinkTo: "/admin/expensegoals",
                    title: "Expense Goals",
                  },
                  {
                    LinkTo: "/admin/debtgoals",
                    title: "Debt Goals",
                  },
                  {
                    LinkTo: "/admin/bankaccountnames",
                    title: "Bank Account Names",
                  },
                ]}
              />
            }
          </NavLink>
        </div>
      </div>

      <div className="header_user" onClick={() => setIsDialogOpen(true)}>
        <img src={user?.Profile} alt="User Pic" />
        <div className="userInfo">
          <h1>{user?.FullName}</h1>
          <p>{user?.Package} Package</p>
          <button onClick={handleLogout}>Logout</button>
        </div>
        <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
          <div className="logout_user" onClick={stopPropagation}>
            <img src={user?.Profile} alt="User Pic" />
            <div className="logoutInfo">
              <h1>{user?.FullName}</h1>
              <p>{user?.Package} Package</p>
              <button onClick={handleLogout}>Logout</button>
              <button onClick={handleCloseDialog}>Cancel</button>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
}

export default Header;
