import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import "./FinalBudget.css";
import "../../shared/ListView.css";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";
import useRetirementDataFetch from "../../shared/useRetirementDataFetch";
import useSavingDataFetch from "../../shared/useSavingDataFetch";
import useDebtDataFetch from "../../shared/useDebtDataFetch";
import useExpenseDataFetch from "../../shared/useExpenseDataFetch";
import useAccountDataFetch from "../../shared/useAccountDataFetch";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { dropdownTemplate } from "../../shared/HeaderTempate";
import { Checkbox, FormControl, IconButton, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Print } from "@mui/icons-material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { usePDF } from "react-to-pdf";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

function CheckList() {
  const user = useUserStore((state) => state.user);
  const { toPDF, targetRef } = usePDF({ filename: "checklist.pdf" });
  const navigate = useNavigate();
  const { retirementData, isRetirementLoaded } = useRetirementDataFetch();
  const { savingData, isSavingLoaded } = useSavingDataFetch();
  const { debtData, isDebtLoaded } = useDebtDataFetch();
  const { expenseData, isExpenseLoaded } = useExpenseDataFetch();
  const { accountData, isAccountLoaded } = useAccountDataFetch();
  //UPDATED DATA
  const [updatedDebtData, setUpdatedDebtData] = useState([]);
  const [updatedExpenseData, setUpdatedExpenseData] = useState([]);
  const [updatedRetirementData, setUpdatedRetirementData] = useState([]);
  const [updatedSavingsData, setUpdatedSavingsData] = useState([]);

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [dataFilter, setDataFilter] = useState("Household");
  const [bankFilter, setBankFilter] = useState("");
  const [bankAccounts, setBankAccounts] = useState();
  const [monthsName, setMonthsName] = useState(["Filter Months"]);
  const userPackage = user?.Package;

  const handleAccountOwnerChange = async (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setDataFilter(e.value);
      const accountOwner = e.value;
      let finalRetirement;
      let finalSaving;
      let finaldebt;
      let finalExpenses;
      if (user?.Type !== "Root") {
        finalRetirement = retirementData.filter((item) => item.UserID === user?.id);
        finalSaving = savingData.filter((item) => item.UserID === user?.id);
        finaldebt = debtData.filter((item) => item.UserID === user?.id);
        finalExpenses = expenseData.filter((item) => item.UserID === user?.id);
      } else {
        finalRetirement = retirementData;
        finalSaving = savingData;
        finaldebt = debtData;
        finalExpenses = expenseData;
      }

      let filteredBankRetirement;
      let filteredBankSavings;
      let filteredBankDebts;
      let filteredBankExpenses;
      if (bankFilter !== "" && bankFilter !== "All") {
        filteredBankRetirement = finalRetirement.filter((item) => item.BankAccount === bankFilter);
        filteredBankSavings = finalSaving.filter((item) => item.BankAccount === bankFilter);
        filteredBankDebts = finaldebt.filter((item) => item.BankAccount === bankFilter);
        filteredBankExpenses = finalExpenses.filter((item) => item.BankAccount === bankFilter);
      } else {
        filteredBankRetirement = finalRetirement;
        filteredBankSavings = finalSaving;
        filteredBankDebts = finaldebt;
        filteredBankExpenses = finalExpenses;
      }

      let filteredRetirement;
      let filteredSavings;
      let filteredDebts;
      let filteredExpenses;
      if (accountOwner === "Self" || accountOwner === "Partner" || accountOwner === "Joint") {
        filteredRetirement = filteredBankRetirement.filter((item) => item.AccountOwner === accountOwner);
        filteredSavings = filteredBankSavings.filter((item) => item.AccountOwner === accountOwner);
        filteredDebts = filteredBankDebts.filter((item) => item.AccountOwner === accountOwner);
        filteredExpenses = filteredBankExpenses.filter((item) => item.AccountOwner === accountOwner);
      } else {
        filteredRetirement = filteredBankRetirement;
        filteredSavings = filteredBankSavings;
        filteredDebts = filteredBankDebts;
        filteredExpenses = filteredBankExpenses.filter((item) => item.BudgetItem !== "Joint Contribution");
      }
      setUpdatedDebtData(filteredDebts);
      setUpdatedExpenseData(filteredExpenses);
      setUpdatedRetirementData(filteredRetirement);
      setUpdatedSavingsData(filteredSavings);

      setIsDataChanged(true);
    }
  };

  const handleBankAccountChange = async (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      const accountName = e.value;
      const targetAccountData = accountData.find((item) => item.Account === accountName);
      const selAccountID = targetAccountData ? targetAccountData.id : "";
      setBankFilter(selAccountID);
      const bankAccount = selAccountID;
      let finalRetirement;
      let finalSaving;
      let finaldebt;
      let finalExpenses;
      if (user?.Type !== "Root") {
        finalRetirement = retirementData.filter((item) => item.UserID === user?.id);
        finalSaving = savingData.filter((item) => item.UserID === user?.id);
        finaldebt = debtData.filter((item) => item.UserID === user?.id);
        finalExpenses = expenseData.filter((item) => item.UserID === user?.id);
      } else {
        finalRetirement = retirementData;
        finalSaving = savingData;
        finaldebt = debtData;
        finalExpenses = expenseData;
      }

      let filteredBankRetirement;
      let filteredBankSavings;
      let filteredBankDebts;
      let filteredBankExpenses;
      if (bankAccount !== "" && bankAccount !== "All") {
        filteredBankRetirement = finalRetirement.filter((item) => item.BankAccount === bankAccount);
        filteredBankSavings = finalSaving.filter((item) => item.BankAccount === bankAccount);
        filteredBankDebts = finaldebt.filter((item) => item.BankAccount === bankAccount);
        filteredBankExpenses = finalExpenses.filter((item) => item.BankAccount === bankAccount);
      } else {
        filteredBankRetirement = finalRetirement;
        filteredBankSavings = finalSaving;
        filteredBankDebts = finaldebt;
        filteredBankExpenses = finalExpenses.filter((item) => item.BudgetItem !== "Joint Contribution");
      }

      let filteredRetirement;
      let filteredSavings;
      let filteredDebts;
      let filteredExpenses;
      if (dataFilter === "Self" || dataFilter === "Partner" || dataFilter === "Joint") {
        filteredRetirement = filteredBankRetirement.filter((item) => item.AccountOwner === dataFilter);
        filteredSavings = filteredBankSavings.filter((item) => item.AccountOwner === dataFilter);
        filteredDebts = filteredBankDebts.filter((item) => item.AccountOwner === dataFilter);
        filteredExpenses = filteredBankExpenses.filter((item) => item.AccountOwner === dataFilter);
      } else {
        filteredRetirement = filteredBankRetirement;
        filteredSavings = filteredBankSavings;
        filteredDebts = filteredBankDebts;
        filteredExpenses = filteredBankExpenses;
      }
      setUpdatedDebtData(filteredDebts);
      setUpdatedExpenseData(filteredExpenses);
      setUpdatedRetirementData(filteredRetirement);
      setUpdatedSavingsData(filteredSavings);

      setIsDataChanged(true);
    }
  };

  useEffect(() => {
    if (isExpenseLoaded && isRetirementLoaded && isSavingLoaded && isDebtLoaded) {
      let finalRetirement;
      let finalSaving;
      let finaldebt;
      let finalExpenses;
      if (user?.Type !== "Root") {
        finalRetirement = retirementData.filter((item) => item.UserID === user?.id);
        finalSaving = savingData.filter((item) => item.UserID === user?.id);
        finaldebt = debtData.filter((item) => item.UserID === user?.id);
        finalExpenses = expenseData.filter((item) => item.UserID === user?.id);
      } else {
        finalRetirement = retirementData;
        finalSaving = savingData;
        finaldebt = debtData;
        finalExpenses = expenseData;
      }

      setUpdatedDebtData(finaldebt);
      setUpdatedExpenseData(finalExpenses);
      setUpdatedRetirementData(finalRetirement);
      setUpdatedSavingsData(finalSaving);

      setIsDataChanged(true);
      setIsDataLoaded(true);
    }
  }, [expenseData, retirementData, isRetirementLoaded, savingData, isExpenseLoaded, isSavingLoaded, debtData, isDebtLoaded, user]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded || !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  useEffect(() => {
    if (isAccountLoaded) {
      const uniqueBankAccounts = [...new Set(accountData.map((item) => (item.Account ? item.Account : "")))];
      const formattedBankAccounts = ["All", ...uniqueBankAccounts];
      setBankAccounts(formattedBankAccounts);
    }
  }, [accountData, isAccountLoaded]);

  const combineData = (finalDebtData, finalExpenseData, finalRetirementData, finalSavingsData) => {
    const combinedArray = [];
    // Function to add data from a specific category
    const addData = (data, category) => {
      data.forEach((item) => {
        combinedArray.push({
          Category: category,
          BudgetItem: item.BudgetItem || "",
          Description: item.Description || "",
          DayDue: item.DayDue || "",
          MonthlyPayment: item.MonthlyPayment || 0,
          Adjustments: item.Adjustments || 0,
          PaymentMethod: item.PaymentMethod || "",
        });
      });
    };
    // Add data from each category
    addData(finalDebtData, "DEBT PAYMENTS");
    addData(finalExpenseData, "EXPENSE PAYMENTS");
    addData(finalRetirementData, "RETIREMENT SAVINGS PAYMENTS");
    addData(finalSavingsData, "SAVING & INVESTING PAYMENTS");

    return combinedArray;
  };

  const combinedData = combineData(updatedDebtData, updatedExpenseData, updatedRetirementData, updatedSavingsData);

  combinedData.sort((a, b) => {
    // Compare by Category
    if (a.Category !== b.Category) {
      return a.Category.localeCompare(b.Category);
    }
    // If Category is the same, compare by BudgetItem
    if (a.BudgetItem !== b.BudgetItem) {
      return a.BudgetItem.localeCompare(b.BudgetItem);
    }
    // If BudgetItem is also the same, compare by Description
    return a.Description.localeCompare(b.Description);
  });

  const getUniqueCategories = (combinedData) => {
    const uniqueCategories = new Set();
    combinedData.forEach((item) => {
      uniqueCategories.add(item.Category);
    });
    return Array.from(uniqueCategories);
  };
  const uniqueCategories = getUniqueCategories(combinedData);

  const getUniqueBudgetItemsByCategory = (combinedData) => {
    const uniqueBudgetItemsByCategory = {};
    combinedData.forEach((item) => {
      const category = item.Category;
      const budgetItem = item.BudgetItem;
      if (!uniqueBudgetItemsByCategory[category]) {
        uniqueBudgetItemsByCategory[category] = new Set();
      }
      uniqueBudgetItemsByCategory[category].add(budgetItem);
    });
    // Convert sets to arrays
    for (const category in uniqueBudgetItemsByCategory) {
      uniqueBudgetItemsByCategory[category] = Array.from(uniqueBudgetItemsByCategory[category]);
    }
    return uniqueBudgetItemsByCategory;
  };
  const uniqueBudgetItemsByCategory = getUniqueBudgetItemsByCategory(combinedData);

  const getUniqueDescriptionsByCategory = (combinedData) => {
    const uniqueDescriptionsByCategory = {};
    combinedData.forEach((item) => {
      const category = item.Category;
      const budgetItem = item.BudgetItem;
      const description = item.Description;
      const dayDue = item.DayDue;
      const paymentMethod = item.PaymentMethod;
      const monthlyPayment = item.MonthlyPayment;
      const adjustments = item.Adjustments;

      if (!uniqueDescriptionsByCategory[category]) {
        uniqueDescriptionsByCategory[category] = {};
      }

      if (!uniqueDescriptionsByCategory[category][budgetItem]) {
        uniqueDescriptionsByCategory[category][budgetItem] = {};
      }
      if (!uniqueDescriptionsByCategory[category][budgetItem][description]) {
        uniqueDescriptionsByCategory[category][budgetItem][description] = {
          DayDue: dayDue,
          PaymentMethod: paymentMethod,
          MonthlyPayment: monthlyPayment,
          Adjustments: adjustments,
        };
      }
    });

    return uniqueDescriptionsByCategory;
  };
  const uniqueDescriptionsByCategory = getUniqueDescriptionsByCategory(combinedData);

  const getCurrentYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return currentYear?.toString().slice(-2);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMonthsName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  const generateMonthHeaders = () => {
    const lastTwoDigitsOfYear = getCurrentYear();
    let newMonths = months;
    if (monthsName.length > 1)
      newMonths = monthsName.filter((item) => item !== "Filter Months").sort((a, b) => months.indexOf(a) - months.indexOf(b));
    const monthHeaders = newMonths?.map((month) => `${month}-${lastTwoDigitsOfYear}`);

    return monthHeaders;
  };
  const monthHeaders = useMemo(() => generateMonthHeaders(), [monthsName, generateMonthHeaders]);

  const tableCategoryStyle = {
    padding: "8px",
    textAlign: "left",
    backgroundColor: "black",
    color: "#ffe99b",
    fontWeight: "bold",
  };

  const tableBudgetItemStyle = {
    padding: "8px",
    textAlign: "left",
    backgroundColor: "lightgray",
    paddingLeft: "20px",
    fontWeight: "bold",
    whiteSpace: "pre-wrap",
  };

  const tableDescriptionStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
  };

  const tableFirstDescriptionStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    paddingLeft: "40px",
    textAlign: "left",
  };

  const tableCurrencyStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    paddingLeft: "20px",
    textAlign: "left",
    whiteSpace: "pre-wrap",
  };

  const handleUpgradeButtonClick = () => {
    navigate("/packages");
  };

  return (
    <div ref={targetRef} className="outer-container-fb">
      <div className="title-container-fb">
        <div className="mainTitle_dropdown">
          <h3>Final Budget CheckList for {user?.FullName}</h3>
          <div className="_account_owner1">
            <ComboBoxComponent
              id="comboelement"
              value="Select Owner"
              // placeholder="* Account Owner"
              floatLabelType="Always"
              headerTemplate={dropdownTemplate}
              dataSource={["Self", "Partner", "Joint", "Household"]}
              change={handleAccountOwnerChange}
              style={{ backgroundColor: "black", color: "#FFE99B" }}
            />
          </div>
          <div className="_account_owner1">
            <ComboBoxComponent
              id="comboelement"
              value="Select Bank Account"
              // placeholder="* Bank Account"
              headerTemplate={dropdownTemplate}
              floatLabelType="Always"
              dataSource={bankAccounts}
              change={handleBankAccountChange}
              style={{ backgroundColor: "black", color: "#FFE99B" }}
            />
          </div>
        </div>
      </div>
      <>
        {userPackage !== "Basic" && userPackage !== "Standard" && userPackage !== "Premium" && (
          <div className="title-container1">
            <h2>This Feature is Only Available to Paid Users Only</h2>
            <div className="button-container">
              <div className="button-container-subA1">
                <button className="add-button" onClick={handleUpgradeButtonClick}>
                  {" "}
                  <UpgradeIcon className="send-icon" />
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="print-container">
          <div className="print-container-inner">
            <IconButton onClick={toPDF} style={{ width: "min-content" }}>
              <Print /> Print{" "}
            </IconButton>
            <FormControl sx={{ m: 1, width: 150 }}>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                defaultValue={"Filter Months"}
                className="filter-month-section"
                multiple
                value={monthsName}
                onChange={handleChange}
                input={<OutlinedInput label="" />}
                renderValue={() => ["Filter Months"]}
                MenuProps={MenuProps}
              >
                {months.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox size="small" checked={monthsName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {(userPackage === "Basic" || userPackage === "Standard" || userPackage === "Premium") && (
          <div className="summary-container-cl">
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                borderSpacing: "0",
              }}
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: "#FFE99B",
                    color: "black",
                  }}
                >
                  <th
                    style={{
                      border: "1px solid #ddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    SAVINGS & SPENDING
                  </th>
                  <th style={{ border: "1px solid #ddd", padding: "5px" }}>DAY DUE</th>
                  <th
                    style={{
                      whiteSpace: "nowrap",
                      border: "1px solid #ddd",
                      padding: "5px",
                    }}
                  >
                    MONTHLY BUDGET
                  </th>
                  <th style={{ border: "1px solid #ddd", padding: "5px" }}>PAYMENT METHOD</th>
                  {monthHeaders.map((header, index) => (
                    <th
                      key={index}
                      style={{
                        whiteSpace: "nowrap",
                        border: "1px solid #ddd",
                        padding: "5px",
                      }}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {uniqueCategories &&
                  uniqueCategories.map((category, categoryIndex) => (
                    <React.Fragment key={categoryIndex}>
                      <tr>
                        <td style={tableCategoryStyle}>{category}</td> {/* SAVINGS & SPENDING */}
                        <td style={tableCategoryStyle}>{/* DAY DUE COLUMN */}</td>
                        <td style={tableCategoryStyle}>{/* Monthly Budget column */}</td>
                        <td style={tableCategoryStyle}>{/* PAYMENT METHOD column */}</td>
                        {monthHeaders.map((header, index) => (
                          <td key={index} style={tableCategoryStyle}>
                            {/* Your content for each cell in the row */}
                          </td>
                        ))}
                      </tr>
                      {uniqueBudgetItemsByCategory[category] &&
                        uniqueBudgetItemsByCategory[category].map((budgetItem, budgetItemIndex) => (
                          <React.Fragment key={`${category}_${budgetItemIndex}`}>
                            <tr>
                              <td style={tableBudgetItemStyle}>{budgetItem}</td> {/* Each budget item under the category */}
                              <td style={tableBudgetItemStyle}>{/* DAY DUE COLUMN */}</td>
                              <td style={tableBudgetItemStyle}>{/* Monthly Budget column */}</td>
                              <td style={tableBudgetItemStyle}>{/* PAYMENT METHOD column */}</td>
                              {monthHeaders.map((header, index) => (
                                <td key={index} style={tableBudgetItemStyle}>
                                  {/* Your content for each cell in the row */}
                                </td>
                              ))}
                            </tr>
                            {uniqueDescriptionsByCategory[category] &&
                              uniqueDescriptionsByCategory[category][budgetItem] &&
                              Object.entries(uniqueDescriptionsByCategory[category][budgetItem]).map(
                                ([description, details], descriptionIndex) => (
                                  <tr key={`${category}_${budgetItem}_${descriptionIndex}`}>
                                    <td style={tableFirstDescriptionStyle}>{description}</td> {/* Description */}
                                    <td style={tableDescriptionStyle}>{details.DayDue}</td> {/* DAY DUE COLUMN */}
                                    <td style={tableCurrencyStyle}>
                                      {user?.Currency +
                                        " " +
                                        (Number(details.MonthlyPayment) + Number(details.Adjustments)).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                    </td>{" "}
                                    {/* Monthly Budget column */}
                                    <td style={tableDescriptionStyle}>{details.PaymentMethod}</td> {/* PAYMENT METHOD column */}
                                    {monthHeaders.map((header, index) => (
                                      <td key={index} style={tableDescriptionStyle}>
                                        {/* Your content for each cell in the row */}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              )}
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    </div>
  );
}
export default CheckList;
