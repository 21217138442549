import React, { useState, useEffect } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { Country, State, City } from "country-state-city";
import useDateFormatDataFetch from "../../shared/useDateFormatDataFetch";
import "./UserDialog.css";
import ImageCrop from "../../ImageCrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "250px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function UserDialog({
  handleUpdateRecordButton,
  handleEditDialogClose,
  selectedFullName,
  selectedSelfDOB,
  selectedPartnerDOB,
  selectedEmail,
  selectedSeparator,
  selectedCity,
  selectedState,
  selectedCountry,
  selectedPassword,
  selectedCurrency,
  selectedDateFormat,
  selectedProfile,
  setNewFullName,
  setNewSelfDOB,
  setNewPartnerDOB,
  setNewEmail,
  setNewSeparator,
  setNewCity,
  setNewState,
  setNewCountry,
  setNewPassword,
  setNewCurrency,
  setNewDateFormat,
  setNewProfile,
}) {
  const { dateFormatData, isDateFormatLoaded } = useDateFormatDataFetch();
  const CountryData = Country.getAllCountries();
  const [stateData, setStateData] = useState();
  const [formatData, setFormatData] = useState("");
  const [countryCode, setCountryCode] = useState();
  const [countryNames, setCountryNames] = useState();
  const [stateNames, setStateNames] = useState();
  const [cityNames, setCityNames] = useState();
  const [cropOpen, setCropOpen] = useState(false);

  useEffect(() => {
    const uniqueCountryNames = [
      ...new Set(CountryData.map((item) => (item?.name ? item?.name : ""))),
    ];
    setCountryNames(uniqueCountryNames);
    const targetCountry = CountryData.find(
      (item) => item?.name === selectedCountry
    );
    const selCountryCode = targetCountry ? targetCountry.isoCode : "";
    setCountryCode(selCountryCode);
    const CountryStateData = State.getStatesOfCountry(selCountryCode);
    setStateData(CountryStateData);
    const uniqueStateNames = [
      ...new Set(CountryStateData.map((item) => (item.name ? item.name : ""))),
    ];
    setStateNames(uniqueStateNames);

    const targetState = CountryStateData.find(
      (item) => item?.name === selectedState
    );
    const selStateCode = targetState ? targetState.isoCode : "";
    const cityData = City.getCitiesOfState(selCountryCode, selStateCode);
    const uniqueCityNames = [
      ...new Set(cityData.map((item) => (item.name ? item.name : ""))),
    ];
    setCityNames(uniqueCityNames);
  }, [CountryData, selectedCountry, selectedState]);

  useEffect(() => {
    if (isDateFormatLoaded) {
      const uniqueDateFormats = [
        ...new Set(
          dateFormatData.map((item) =>
            item.description ? item.description : ""
          )
        ),
      ];
      setFormatData(uniqueDateFormats);
    }
  }, [dateFormatData, isDateFormatLoaded]);

  const handleFullNameChange = (e) => {
    if (e && e.value) {
      setNewFullName(e.value);
    }
  };

  const handleSelfDOBChange = (e) => {
    if (e && e.value) {
      setNewSelfDOB(e.value);
    }
  };

  const handlePartnerDOBChange = (e) => {
    if (e && e.value) {
      setNewPartnerDOB(e.value);
    }
  };

  const handleEmailChange = (e) => {
    if (e && e.value) {
      setNewEmail(e.value);
    }
  };

  // const handleHomeAddressChange = (e) => {
  //   if (e && e.value) {
  //     setNewHomeAddress(e.value);
  //   }
  // };

  const handleCityChange = (e) => {
    if (e && e.value) {
      setNewCity(e.value);
    }
  };

  const handleStateChange = (e) => {
    if (e && e.value) {
      setNewState(e.value);
      const selState = e.value;
      const targetState = stateData.find((item) => item.name === selState);
      const selStateCode = targetState ? targetState.isoCode : "";
      const cityData = City.getCitiesOfState(countryCode, selStateCode);
      const uniqueCityNames = [
        ...new Set(cityData.map((item) => (item.name ? item.name : ""))),
      ];
      setCityNames(uniqueCityNames);
    }
  };

  const handleCountryChange = (e) => {
    if (e && e.value) {
      setNewCountry(e.value);
      const selCountry = e.value;
      const targetCountry = CountryData.find(
        (item) => item?.name === selCountry
      );
      const selCountryCode = targetCountry ? targetCountry.isoCode : "";
      setCountryCode(selCountryCode);

      setNewSeparator("en-" + selCountryCode);

      const CountryStateData = State.getStatesOfCountry(selCountryCode);
      setStateData(CountryStateData);
      const uniqueStateNames = [
        ...new Set(
          CountryStateData.map((item) => (item.name ? item.name : ""))
        ),
      ];
      setStateNames(uniqueStateNames);
    }
  };

  const handlePasswordChange = (e) => {
    if (e && e.value) {
      setNewPassword(e.value);
    }
  };

  const handleCurrencyChange = (e) => {
    if (e && e.value) {
      setNewCurrency(e.value);
    }
  };

  const handleDateFormatChange = (e) => {
    if (e && e.value) {
      setNewDateFormat(e.value);
    }
  };

  return (
    <div className="userDialoug-wrapper">
      <div className="main-user-container-details1">
        <div className="user-field-container1">
          <div className="my-cap-user-row1-dialog2">
            <div className="profile-pic-container">
              <img
                src={selectedProfile}
                alt="profile"
                className="profile-pic"
              />
              {/* <ImageCrop /> */}
              <ButtonComponent onClick={() => setCropOpen(true)}>
                Upload Profile Picture
              </ButtonComponent>
            </div>
            <div className="Profile-pic-right_dia">
              <div className="user_full_name_dia">
                <TextBoxComponent
                  placeholder="* Full Name"
                  value={selectedFullName}
                  change={handleFullNameChange}
                  floatLabelType="Always"
                  style={{ backgroundColor: "black", color: "#FFE99B" }}
                />
              </div>
              <div className="user_email_dia">
                <TextBoxComponent
                  placeholder="* Email"
                  value={selectedEmail}
                  change={handleEmailChange}
                  floatLabelType="Always"
                  style={{ backgroundColor: "black", color: "#FFE99B" }}
                />
              </div>
              {/* <div className="user_email_dia">
                <TextBoxComponent
                  placeholder="* Password"
                  value={selectedPassword}
                  change={handlePasswordChange}
                  floatLabelType="Always"
                  style={{ backgroundColor: "black", color: "#FFE99B" }}
                />
              </div> */}
            </div>
          </div>

          <div className="my-cap-user-row2_dia">
            <div className="user_self_dob_dia">
              <DatePickerComponent
                id="datepicker"
                value={selectedSelfDOB}
                placeholder="* Your D.O.B."
                floatLabelType="Always"
                format="MMM-yyyy"
                change={handleSelfDOBChange}
                style={{ backgroundColor: "black", color: "#FFE99B" }}
              />
            </div>
            <div className="user_partner_dob_dia">
              <DatePickerComponent
                id="datepicker"
                value={selectedPartnerDOB}
                placeholder="* Your Partner D.O.B."
                floatLabelType="Always"
                format="MMM-yyyy"
                change={handlePartnerDOBChange}
              />
            </div>
            <div className="user_currency_dia">
              <TextBoxComponent
                value={selectedCurrency}
                placeholder="Currency Symbol"
                floatLabelType="Always"
                change={handleCurrencyChange}
              />
            </div>
            <div className="user_format_dia">
              <ComboBoxComponent
                id="comboelement"
                placeholder="Date Format"
                value={selectedDateFormat}
                dataSource={formatData}
                change={handleDateFormatChange}
                floatLabelType="Always"
              />
            </div>
          </div>
          <div className="my-cap-user-row4_dia">
            <div className="user_country_dia">
              <ComboBoxComponent
                id="comboelement"
                placeholder="* Country"
                value={selectedCountry}
                dataSource={countryNames}
                change={handleCountryChange}
                floatLabelType="Always"
              />
            </div>
            <div className="user_state_dia">
              <ComboBoxComponent
                id="comboelement"
                placeholder="State"
                value={selectedState}
                dataSource={stateNames}
                change={handleStateChange}
                floatLabelType="Always"
              />
            </div>
          </div>
        </div>

        <div className="update-btns-div">
          <ButtonComponent
            cssClass="e-warning update-btn"
            onClick={handleEditDialogClose}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            cssClass="e-success update-btn"
            onClick={handleUpdateRecordButton}
          >
            Update
          </ButtonComponent>
        </div>

        <Modal
          open={cropOpen}
          onClose={() => setCropOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ImageCrop
              setCropOpen={setCropOpen}
              setNewProfile={setNewProfile}
            />
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default UserDialog;
