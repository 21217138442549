import React, { useState } from "react";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import "./JointContribution.css";

function UserSplitDialog({ selectedSelfAmount, setNewSelfAmount }) {
  const [partnerAmount, setPartnerAmount] = useState(
    100 - Number(selectedSelfAmount)
  );

  const handleGoalChange = (e) => {
    if (e.value !== "") {
      setNewSelfAmount(e.value);
      setPartnerAmount(100 - Number(e.value));
    }
  };

  return (
    <div className="main-user-container-details1">
      <div className="user-field-container1">
        <div className="my-cap-user-row1_joi">
          <div className="category_goal_joi">
            <NumericTextBoxComponent
              placeholder="* Self %"
              format="n1"
              value={selectedSelfAmount}
              change={handleGoalChange}
              floatLabelType="Always"
              min={0}
              max={100}
              showSpinButton={false}
            />
          </div>
          <div className="category_goal_joi">
            <NumericTextBoxComponent
              placeholder="* Partner %"
              format="n1"
              value={partnerAmount}
              floatLabelType="Always"
              showSpinButton={false}
              enabled={true}
              style={{ pointerEvents: "none" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserSplitDialog;
