import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
  Reorder,
  Resize,
  PdfExport,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";

import { DialogComponent } from "@syncfusion/ej2-react-popups";
import axios from "../../../config/axios";
import "../../shared/ListView.css";
import useExpenseCatDataFetch from "../../shared/useExpenseCatDataFetch";
import useExpenseDataFetch from "../../shared/useExpenseDataFetch";
import useIncomeDataFetch from "../../shared/useIncomeDataFetch";
import BudgetCategoryDialog from "./BudgetCategoryDialog";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";
import UpgradeIcon from "@mui/icons-material/Upgrade";

function ExpenseGoal() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const userPackage = user?.Package;
  const { expenseCatData, isExpenseCatLoaded } = useExpenseCatDataFetch();
  const { expenseData, isExpenseLoaded } = useExpenseDataFetch();
  const { incomeData, isIncomeLoaded } = useIncomeDataFetch();
  const GridRef = useRef(null);
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [charityActual, setCharityActual] = useState(0);
  const [childrenActual, setChildrenActual] = useState(0);
  const [duesSubscriptionsActual, setDuesSubscriptionsActual] = useState(0);
  const [entertainmentActual, setEntertainmentActual] = useState(0);
  const [foodActual, setFoodActual] = useState(0);
  const [healthMedicalActual, setHealthMedicalActual] = useState(0);
  const [personalCareActual, setPersonalCareActual] = useState(0);
  const [housingActual, setHousingActual] = useState(0);
  const [renterActual, setRenterActual] = useState(0);
  const [otherExpenseActual, setOtherExpenseActual] = useState(0);
  const [otherInsuranceActual, setOtherInsuranceActual] = useState(0);
  const [parentCareActual, setparentCareActual] = useState(0);
  const [petsAnimalsActual, setPetsAnimalsActual] = useState(0);
  const [recreationActual, setRecreationActual] = useState(0);
  const [rentalPropertyActual, setRentalPropertyActual] = useState(0);
  const [transportationActual, setTransportationActual] = useState(0);
  const [vacationsActual, setVacationsActual] = useState(0);

  useEffect(() => {
    if (isExpenseCatLoaded && isIncomeLoaded && isExpenseLoaded) {
      let finalExpense;
      let finalIncome;
      if (user?.Type !== "Root") {
        finalIncome = incomeData.filter((item) => item.UserID === user?.id);
        finalExpense = expenseData.filter((item) => item.UserID === user?.id);
      } else {
        finalExpense = expenseData;
        finalIncome = incomeData;
      }
      const charityData = finalExpense?.filter(
        (item) => item.BudgetItem === "Charity"
      );
      const childrenData = finalExpense?.filter(
        (item) => item.BudgetItem === "Children"
      );
      const duesSubscriptionsData = finalExpense?.filter(
        (item) => item.BudgetItem === "Dues/subscriptions"
      );
      const entertainmentData = finalExpense?.filter(
        (item) => item.BudgetItem === "Entertainment"
      );
      const foodData = finalExpense?.filter(
        (item) => item.BudgetItem === "Food"
      );
      const healthMedicalData = finalExpense?.filter(
        (item) => item.BudgetItem === "Health / Medical"
      );
      const personalCareData = finalExpense?.filter(
        (item) => item.BudgetItem === "Household, Personal Care & Gifts"
      );
      const housingData = finalExpense?.filter(
        (item) => item.BudgetItem === "Housing-Homeowner (Excl Mortgage)"
      );
      const renterData = finalExpense?.filter(
        (item) => item.BudgetItem === "Housing-Renter"
      );
      const otherExpenseData = finalExpense?.filter(
        (item) => item.BudgetItem === "Other Expense"
      );

      const otherInsuranceData = finalExpense?.filter(
        (item) => item.BudgetItem === "Other Insurance"
      );
      const parentCareData = finalExpense?.filter(
        (item) => item.BudgetItem === "Parents / Elder Care"
      );
      const petsAnimalsData = finalExpense?.filter(
        (item) => item.BudgetItem === "Pets / Animals"
      );
      const recreationData = finalExpense?.filter(
        (item) => item.BudgetItem === "Recreation"
      );
      const rentalPropertyData = finalExpense?.filter(
        (item) => item.BudgetItem === "Rental Property"
      );
      const transportationData = finalExpense?.filter(
        (item) => item.BudgetItem === "Transportation"
      );
      const vacationsData = finalExpense?.filter(
        (item) => item.BudgetItem === "Vacations"
      );

      const charityMonthlyDebt = charityData.reduce((accumulator, record) => {
        const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
        const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
        // Add the MonthlyPayment and Adjustments to the accumulator
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);

      const childrenMonthlyDebt = childrenData.reduce((accumulator, record) => {
        const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
        const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
        // Add the MonthlyPayment and Adjustments to the accumulator
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);

      const duesSubscriptionsMonthlyDebt = duesSubscriptionsData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const entertainmentMonthlyDebt = entertainmentData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const foodMonthlyDebt = foodData.reduce((accumulator, record) => {
        const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
        const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
        // Add the MonthlyPayment and Adjustments to the accumulator
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);

      const healthMedicalMonthlyDebt = healthMedicalData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const personalCareMonthlyDebt = personalCareData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const housingMonthlyDebt = housingData.reduce((accumulator, record) => {
        const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
        const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
        // Add the MonthlyPayment and Adjustments to the accumulator
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);

      const renterMonthlyDebt = renterData.reduce((accumulator, record) => {
        const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
        const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
        // Add the MonthlyPayment and Adjustments to the accumulator
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);

      const otherExpenseMonthlyDebt = otherExpenseData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const otherInsuranceMonthlyDebt = otherInsuranceData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const parentCareMonthlyDebt = parentCareData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const petsAnimalsMonthlyDebt = petsAnimalsData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const recreationMonthlyDebt = recreationData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const rentalPropertyMonthlyDebt = rentalPropertyData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const transportationMonthlyDebt = transportationData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const vacationsMonthlyDebt = vacationsData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const totalMonthlyIncome = finalIncome.reduce((accumulator, record) => {
        const netPay = record.NetPay || 0; // Handle the case where NetPay is undefined
        const payFrequency = record.PayFrequency || ""; // Handle the case where PayFrequency is undefined
        let monthlyIncome = 0;
        // Determine monthlyIncome based on PayFrequency
        switch (payFrequency) {
          case "Yearly":
            monthlyIncome = netPay / 12;
            break;
          case "Monthly":
            monthlyIncome = netPay;
            break;
          case "Semi-Monthly":
            monthlyIncome = netPay * 2;
            break;
          case "Weekly":
            monthlyIncome = netPay * 4;
            break;
          case "Bi-Weekly":
            monthlyIncome = netPay * 2;
            break;
          default:
            monthlyIncome = 0;
        }
        // Add monthlyIncome to the accumulator
        return accumulator + Number(monthlyIncome);
      }, 0); // Initialize accumulator with 0

      setCharityActual(
        100 * (Number(charityMonthlyDebt) / Number(totalMonthlyIncome))
      );

      setChildrenActual(
        100 * (Number(childrenMonthlyDebt) / Number(totalMonthlyIncome))
      );
      setDuesSubscriptionsActual(
        100 *
          (Number(duesSubscriptionsMonthlyDebt) / Number(totalMonthlyIncome))
      );
      setEntertainmentActual(
        100 * (Number(entertainmentMonthlyDebt) / Number(totalMonthlyIncome))
      );
      setFoodActual(
        100 * (Number(foodMonthlyDebt) / Number(totalMonthlyIncome))
      );
      setHealthMedicalActual(
        100 * (Number(healthMedicalMonthlyDebt) / Number(totalMonthlyIncome))
      );
      setPersonalCareActual(
        100 * (Number(personalCareMonthlyDebt) / Number(totalMonthlyIncome))
      );
      setHousingActual(
        100 * (Number(housingMonthlyDebt) / Number(totalMonthlyIncome))
      );
      setRenterActual(
        100 * (Number(renterMonthlyDebt) / Number(totalMonthlyIncome))
      );
      setOtherExpenseActual(
        100 * (Number(otherExpenseMonthlyDebt) / Number(totalMonthlyIncome))
      );
      setOtherInsuranceActual(
        100 * (Number(otherInsuranceMonthlyDebt) / Number(totalMonthlyIncome))
      );
      setparentCareActual(
        100 * (Number(parentCareMonthlyDebt) / Number(totalMonthlyIncome))
      );
      setPetsAnimalsActual(
        100 * (Number(petsAnimalsMonthlyDebt) / Number(totalMonthlyIncome))
      );
      setRecreationActual(
        100 * (Number(recreationMonthlyDebt) / Number(totalMonthlyIncome))
      );
      setRentalPropertyActual(
        100 * (Number(rentalPropertyMonthlyDebt) / Number(totalMonthlyIncome))
      );
      setTransportationActual(
        100 * (Number(transportationMonthlyDebt) / Number(totalMonthlyIncome))
      );
      setVacationsActual(
        100 * (Number(vacationsMonthlyDebt) / Number(totalMonthlyIncome))
      );

      setGridData(expenseCatData);
      setIsDataChanged(true);
      setIsDataLoaded(true);
    }
  }, [
    expenseCatData,
    expenseData,
    incomeData,
    isExpenseCatLoaded,
    isIncomeLoaded,
    isExpenseLoaded,
  ]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded || !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  const [selectedID, setSelectedID] = useState("");
  const [selectedRootID, setSelectedRootID] = useState("");
  const [selectedUserID, setSelectedUserID] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedGoal, setSelectedGoal] = useState("");

  const [newRootID, setNewRootID] = useState("");
  const [newUserID, setNewUserID] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [newGoal, setNewGoal] = useState("");

  const handleRowSelect = async (args) => {
    // Set the selected ID when a row is selected in the Grid
    setSelectedID(args.data.id);
    setSelectedRootID(args.data.RootUserID);
    setSelectedUserID(args.data.RegUserID);
    setSelectedCategory(args.data.Category);
    setSelectedGoal(args.data.Goal);
  };

  const handleExcelButtonClick = () => {};

  const handlePdfButtonClick = () => {};

  const handleEditDialogClose = () => {
    setIsEditDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    setIsEditDialogVisible(!isEditDialogVisible);
    setNewRootID(selectedRootID);
    setNewUserID(selectedUserID);
    setNewCategory(selectedCategory);
    setNewGoal(selectedGoal);
  };

  const handleUpdateRecordButton = async (selectedID) => {
    setIsDataChanged(false);
    const numericSelectedID = parseInt(selectedID);
    try {
      const RecordResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/debtcat/${numericSelectedID}`,
        {
          RootUserID: newRootID,
          RegUserID: newUserID,
          Category: newCategory,
          Goal: newGoal,
        }
      );
      console.log("Record response:", RecordResponse.data);
      if (RecordResponse.data.status === "success") {
        toast.success("the record updated successfully.");
        // Get the UPDATED DATA by making a GET request
        const RecordResponseR = await axios.get(
          process.env.REACT_APP_SERVER_URL +
            `/api/debtcat/reguserid/${user?.id}`
        );
        const Records = RecordResponseR.data.items;
        Records.sort((a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn));
        setGridData(Records);
      } else {
        console.error(
          "Error updating the record record:",
          RecordResponse.data.message
        );
        toast.error("Error updating the record");
      }
    } catch (error) {
      console.error("Error updating the record:", error);
      toast.error("Error updating the record");
    }
    setIsDataChanged(true);
  };

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = ["ColumnChooser"];
  const loadingIndicator = { indicatorType: "Spinner" };

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          <button
            className="edit-button"
            onClick={handleEditButtonClick}
            disabled={selectedID === ""}
          >
            <EditIcon className="send-icon" /> Edit Goal{" "}
          </button>
        </div>
        {/* <div className="button-container-subA2">
          <button className="excel-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          <button className="pdf-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button>
        </div> */}
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-success update-btn",
        isPrimary: true,
      },
      click: () => {
        handleUpdateRecordButton(selectedID);
        setIsEditDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsEditDialogVisible(false);
      },
    },
  ];

  const actualGoalTemplate = (props) => {
    const category = props.Category;
    let actualValue;
    switch (category) {
      case "Charity":
        actualValue = charityActual;
        break;
      case "Children":
        actualValue = childrenActual;
        break;
      case "Dues/subscriptions":
        actualValue = duesSubscriptionsActual;
        break;
      case "Entertainment":
        actualValue = entertainmentActual;
        break;
      case "Food":
        actualValue = foodActual;
        break;
      case "Health / Medical":
        actualValue = healthMedicalActual;
        break;
      case "Household, Personal Care & Gifts":
        actualValue = personalCareActual;
        break;
      case "Housing-Homeowner (Excl Mortgage)":
        actualValue = housingActual;
        break;
      case "Housing-Renter":
        actualValue = renterActual;
        break;
      case "Other Expense":
        actualValue = otherExpenseActual;
        break;
      case "Other Insurance":
        actualValue = otherInsuranceActual;
        break;
      case "Parents / Elder Care":
        actualValue = parentCareActual;
        break;
      case "Pets / Animals":
        actualValue = petsAnimalsActual;
        break;
      case "Recreation":
        actualValue = recreationActual;
        break;
      case "Rental Property":
        actualValue = rentalPropertyActual;
        break;
      case "Transportation":
        actualValue = transportationActual;
        break;
      case "Vacations":
        actualValue = vacationsActual;
        break;
      default:
        actualValue = null;
    }
    //const formattedActualValue = actualValue !== null ? `${Math.round(actualValue)}%` : 'N/A';
    //const formattedActualValue = actualValue !== null ? `${actualValue.toFixed(2)}%` : 'N/A';
    let formattedActualValue;
    if (actualValue > 0 || actualValue === 0) {
      formattedActualValue = `${actualValue.toFixed(2)}%`;
    } else {
      formattedActualValue = "0%";
    }

    return <div>{formattedActualValue}</div>;
  };

  const flagTemplate = (props) => {
    const category = props.Category;
    const goalValue = props.Goal;
    let actualValue;
    let flag;
    switch (category) {
      case "Charity":
        actualValue = charityActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "Children":
        actualValue = childrenActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "Dues/subscriptions":
        actualValue = duesSubscriptionsActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "Entertainment":
        actualValue = entertainmentActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "Food":
        actualValue = foodActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "Health / Medical":
        actualValue = healthMedicalActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "Household, Personal Care & Gifts":
        actualValue = personalCareActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "Housing-Homeowner (Excl Mortgage)":
        actualValue = housingActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "Housing-Renter":
        actualValue = renterActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "Other Expense":
        actualValue = otherExpenseActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "Other Insurance":
        actualValue = otherInsuranceActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "Parents / Elder Care":
        actualValue = parentCareActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "Pets / Animals":
        actualValue = petsAnimalsActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "Recreation":
        actualValue = recreationActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "Rental Property":
        actualValue = rentalPropertyActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "Transportation":
        actualValue = transportationActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "Vacations":
        actualValue = vacationsActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      default:
        actualValue = null;
        flag = "N/A";
    }

    return <div>{flag}</div>;
  };

  const goalTemplate = (props) => {
    const formattedGoal = `${props.Goal}%`;
    return <div>{formattedGoal}</div>;
  };

  const handleUpgradeButtonClick = () => {
    navigate("/packages");
  };

  return (
    <div className="all-outer-project-containers">
      <div className="title-container">
        <div className="mainTitle_dropdown">
          <h3>Expense Budget Items & Goals for {user?.FullName}</h3>
        </div>
      </div>
      <br />
      <>
        {userPackage !== "Basic" &&
          userPackage !== "Standard" &&
          userPackage !== "Premium" && (
            <div className="title-container1">
              <h2>This Feature is Only Available to Paid Users Only</h2>
              <div className="button-container">
                <div className="button-container-subA1">
                  <button
                    className="add-button"
                    onClick={handleUpgradeButtonClick}
                  >
                    {" "}
                    <UpgradeIcon className="send-icon" />
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          )}
        {(userPackage === "Basic" ||
          userPackage === "Standard" ||
          userPackage === "Premium") && (
          <div className="all-inner-project-containers">
            {isDataLoaded && (
              <GridComponent
                dataSource={gridData}
                childMapping="items"
                ref={GridRef}
                classClass="custom-treegrid-class"
                height="480"
                width="100%"
                allowReordering={true}
                allowResizing={true}
                allowExcelExport={true}
                allowPdfExport={true}
                allowPaging={true}
                allowSorting={true}
                allowFiltering={true}
                allowTextWrap={true}
                showColumnChooser={true}
                filterSettings={FilterOptions}
                pageSettings={pageSettings}
                editSettings={editOptions}
                toolbar={updatedToolbarOptions}
                loadingIndicator={loadingIndicator}
                allowSelection={true}
                rowSelected={handleRowSelect}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="Category"
                    headerText="Budget Category"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Goal"
                    headerText="Budget Goal"
                    width="120"
                    textAlign="Left"
                    template={goalTemplate}
                  />
                  <ColumnDirective
                    headerText="Actual Goal"
                    width="150"
                    textAlign="Left"
                    template={actualGoalTemplate}
                  />
                  <ColumnDirective
                    headerText="Flag"
                    width="120"
                    textAlign="Left"
                    template={flagTemplate}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Page,
                    Sort,
                    Filter,
                    Edit,
                    Toolbar,
                    ColumnChooser,
                    Reorder,
                    Resize,
                    PdfExport,
                    ExcelExport,
                  ]}
                />
              </GridComponent>
            )}
          </div>
        )}
      </>
      <div>
        {isEditDialogVisible && (
          <DialogComponent
            visible={isEditDialogVisible}
            width="30%"
            height="300px"
            header="Edit Goal"
            allowDragging={true}
            showCloseIcon={true}
            close={handleEditDialogClose}
            buttons={editButtons}
          >
            <BudgetCategoryDialog
              selectedCategory={selectedCategory}
              selectedGoal={selectedGoal}
              setNewGoal={setNewGoal}
            />
          </DialogComponent>
        )}
      </div>
    </div>
  );
}
export default ExpenseGoal;
