import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  NumericTextBoxComponent,
  TextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import "./NetWorth.css";
import "../../shared/ListView.css";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";
import useAssetDataFetch from "../../shared/useAssetDataFetch";
import useRetirementDataFetch from "../../shared/useRetirementDataFetch";
import useSavingDataFetch from "../../shared/useSavingDataFetch";
import useDebtDataFetch from "../../shared/useDebtDataFetch";
import useIncomeDataFetch from "../../shared/useIncomeDataFetch";
import useBankAccountDataFetch from "../../shared/useBankAccountDataFetch";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { dropdownTemplate } from "../../shared/HeaderTempate";
import { IconButton } from "@mui/material";
import { Print } from "@mui/icons-material";
import { usePDF } from "react-to-pdf";

function NetWorth() {
  const user = useUserStore((state) => state.user);
  const { toPDF, targetRef } = usePDF({ filename: "networth.pdf" });
  const navigate = useNavigate();
  const { assetData, isAssetLoaded } = useAssetDataFetch();
  const { retirementData, isRetirementLoaded } = useRetirementDataFetch();
  const { savingData, isSavingLoaded } = useSavingDataFetch();
  const { debtData, isDebtLoaded } = useDebtDataFetch();
  const { incomeData, isIncomeLoaded } = useIncomeDataFetch();
  const { bankAccountData, isBankAccountLoaded } = useBankAccountDataFetch();
  const [retirementTotal, setRetirementTotal] = useState(0);
  const [closingBalance, setClosingBalance] = useState("");
  const [savingsTotal, setSavingsTotal] = useState(0);
  const [estateTotal, setEstateTotal] = useState(0);
  const [vehicleTotal, setVehicleTotal] = useState(0);
  const [applianceTotal, setApplianceTotal] = useState(0);
  const [otherTotal, setOtherTotal] = useState(0);
  const [allAssetTotal, setAllAssetTotal] = useState(0);
  const [dataFilter, setDataFilter] = useState("Household");
  const [allLiabilitiesTotal, setAllLiabilitiesTotal] = useState(0);
  const [realEstateLoanTotal, setRealEstateLoanTotal] = useState(0);
  const [creditCardTotal, setCreditCardTotal] = useState(0);
  const [medicalDebt, setMedicalDebt] = useState(0);
  const [vehicleLoan, setVehicleLoan] = useState(0);
  const [loansTotal, setLoansTotal] = useState(0);
  const [otherLoansTotal, setOtherLoansTotal] = useState(0);
  const [actualNetWorth, setActualNetWorth] = useState(0);
  const [grossIncomeTotal, setGrossIncomeTotal] = useState(0);

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const usedCurrency = user?.Currency + " #,###.00";
  const userPackage = user?.Package;

  function calculateAge(dob) {
    const birthDate = new Date(dob);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    // Check if the birthday has occurred this year
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  }

  let userAge = 0;
  if (dataFilter === "Self") {
    userAge = calculateAge(user?.SelfDOB);
  } else if (dataFilter === "Partner") {
    userAge = calculateAge(user?.PartnerDOB);
  } else {
    // Use Math.max to get the maximum age between Self and Partner
    userAge = Math.max(
      calculateAge(user?.SelfDOB),
      calculateAge(user?.PartnerDOB)
    );
  }

  const handleAccountOwnerChange = async (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setDataFilter(e.value);
      const accountOwner = e.value;
      let finalAsset;
      let finalRetirement;
      let finalSaving;
      let finaldebt;
      let finalIncome;
      let finalBankAccount;
      if (user?.Type !== "Root") {
        finalAsset = assetData.filter((item) => item.UserID === user?.id);
        finalRetirement = retirementData.filter(
          (item) => item.UserID === user?.id
        );
        finalSaving = savingData.filter((item) => item.UserID === user?.id);
        finaldebt = debtData.filter((item) => item.UserID === user?.id);
        finalIncome = incomeData.filter((item) => item.UserID === user?.id);
        finalBankAccount = bankAccountData?.filter(
          (item) => item.UserID === user?.id
        );
      } else {
        finalAsset = assetData;
        finalRetirement = retirementData;
        finalSaving = savingData;
        finaldebt = debtData;
        finalIncome = incomeData;
        finalBankAccount = bankAccountData;
      }

      let filteredData;
      let filteredRetirement;
      let filteredSavings;
      let filteredDebts;
      let filteredIncome;
      let filteredBankAccount;
      if (
        accountOwner === "Self" ||
        accountOwner === "Partner" ||
        accountOwner === "Joint"
      ) {
        filteredData = finalAsset.filter(
          (item) => item.AccountOwner === accountOwner
        );
        filteredRetirement = finalRetirement.filter(
          (item) => item.AccountOwner === accountOwner
        );
        filteredSavings = finalSaving.filter(
          (item) => item.AccountOwner === accountOwner
        );
        filteredDebts = finaldebt.filter(
          (item) => item.AccountOwner === accountOwner
        );
        filteredIncome = finalIncome.filter(
          (item) => item.AccountOwner === accountOwner
        );
        filteredBankAccount = finalBankAccount.filter(
          (item) => item.AccountOwner === accountOwner
        );
      } else {
        filteredData = finalAsset;
        filteredRetirement = finalRetirement;
        filteredSavings = finalSaving;
        filteredDebts = finaldebt;
        filteredIncome = finalIncome;
        filteredBankAccount = finalBankAccount;
      }

      //Get retirement market value
      const totalMarketValue = filteredRetirement?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );
      setRetirementTotal(totalMarketValue);

      const totalBalance = filteredSavings?.reduce((accumulator, record) => {
        const MarketValue = record?.MarketValue || 0;
        return accumulator + Number(MarketValue);
      }, 0);
      setSavingsTotal(totalBalance);

      //Get total real estate value
      const filteredEstateData = filteredData?.filter(
        (item) => item.AssetCategory === "Real Estate"
      );
      const totalEstate = filteredEstateData?.reduce((accumulator, record) => {
        const MarketValue = record?.MarketValue || 0;
        return accumulator + Number(MarketValue);
      }, 0);
      setEstateTotal(totalEstate);

      //Get total real vehicle value
      const filteredVehicleData = filteredData?.filter(
        (item) => item.AssetCategory === "Vehicle"
      );
      const totalVehicle = filteredVehicleData?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );
      setVehicleTotal(totalVehicle);

      //Get total real Furnishings/Appliances value
      const filteredApplianceData = filteredData?.filter(
        (item) => item.AssetCategory === "Furnishing / Appliances"
      );
      const totalAppliance = filteredApplianceData?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );
      setApplianceTotal(totalAppliance);

      //Get total real Other value
      const filteredOtherData = filteredData?.filter(
        (item) => item.AssetCategory === "Other"
      );
      const totalOther = filteredOtherData?.reduce((accumulator, record) => {
        const MarketValue = record?.MarketValue || 0;
        return accumulator + Number(MarketValue);
      }, 0);
      setOtherTotal(totalOther);

      //Get totalLiabilities value
      const totalLiabilities = filteredDebts?.reduce((accumulator, record) => {
        const loanBalance = record?.LoanBalance || 0;
        return accumulator + Number(loanBalance);
      }, 0);
      setAllLiabilitiesTotal(totalLiabilities);

      // Get totalRealEstateLoan value
      const totalRealEstateLoan = filteredDebts?.reduce(
        (accumulator, record) => {
          if (record?.BudgetItem === "Real Estate Loan") {
            const loanBalance = record?.LoanBalance || 0;
            return accumulator + Number(loanBalance);
          }
          return accumulator;
        },
        0
      );
      setRealEstateLoanTotal(totalRealEstateLoan);

      // Get totalCreditCard value
      const totalCreditCard = filteredDebts?.reduce((accumulator, record) => {
        if (record?.BudgetItem === "Credit Card") {
          const loanBalance = record?.LoanBalance || 0;
          return accumulator + Number(loanBalance);
        }
        return accumulator;
      }, 0);
      setCreditCardTotal(totalCreditCard);

      // Get totalMedicalDebt value
      const totalMedicalDebt = filteredDebts?.reduce((accumulator, record) => {
        if (record?.BudgetItem === "Medical Debt") {
          const loanBalance = record?.LoanBalance || 0;
          return accumulator + Number(loanBalance);
        }
        return accumulator;
      }, 0);
      setMedicalDebt(totalMedicalDebt);

      // Get totalVehicleLoan value
      const totalVehicleLoan = filteredDebts?.reduce((accumulator, record) => {
        if (record?.BudgetItem === "Vehicle Loan") {
          const loanBalance = record?.LoanBalance || 0;
          return accumulator + Number(loanBalance);
        }
        return accumulator;
      }, 0);
      setVehicleLoan(totalVehicleLoan);

      // Get totalLoans value
      const totalLoans = filteredDebts?.reduce((accumulator, record) => {
        if (
          record?.BudgetItem === "Family/Friend Loan" ||
          record?.BudgetItem === "Personal Loan" ||
          record?.BudgetItem === "Student Loan"
        ) {
          const loanBalance = record?.LoanBalance || 0;
          return accumulator + Number(loanBalance);
        }
        return accumulator;
      }, 0);
      setLoansTotal(totalLoans);

      const totalAnnualIncome = filteredIncome.reduce((accumulator, record) => {
        const grossPay = record.GrossPay || 0;
        const payFrequency = record.PayFrequency || "";
        let annualIncome = 0;
        // Determine monthlyIncome based on PayFrequency
        switch (payFrequency) {
          case "Yearly":
            annualIncome = grossPay;
            break;
          case "Monthly":
            annualIncome = grossPay * 12;
            break;
          case "Semi-Monthly":
            annualIncome = grossPay * 24;
            break;
          case "Weekly":
            annualIncome = grossPay * 52;
            break;
          case "Bi-Weekly":
            annualIncome = grossPay * 26;
            break;
          default:
            annualIncome = 0;
        }
        // Add monthlyIncome to the accumulator
        return accumulator + Number(annualIncome);
      }, 0); // Initialize accumulator with 0
      setGrossIncomeTotal(totalAnnualIncome);

      // Calculate closing balance
      const balanceAfter = filteredBankAccount.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);
      setClosingBalance(balanceAfter);
      const totalAssetsValue =
        Number(totalMarketValue) +
        Number(totalBalance) +
        Number(balanceAfter) +
        Number(totalEstate) +
        Number(totalVehicle) +
        Number(totalAppliance) +
        Number(totalOther);
      setAllAssetTotal(totalAssetsValue);
      const totalOtherLoanValue =
        Number(totalLiabilities) -
        (Number(totalRealEstateLoan) +
          Number(totalCreditCard) +
          Number(totalMedicalDebt) +
          Number(totalVehicleLoan) +
          Number(totalLoans));
      setOtherLoansTotal(totalOtherLoanValue);
      setActualNetWorth(Number(totalAssetsValue) - Number(totalLiabilities));
      setIsDataChanged(true);
    }
  };

  useEffect(() => {
    if (
      isAssetLoaded &&
      isRetirementLoaded &&
      isSavingLoaded & isDebtLoaded & isIncomeLoaded & isBankAccountLoaded
    ) {
      let finalAsset;
      let finalRetirement;
      let finalSaving;
      let finaldebt;
      let finalIncome;
      let finalBankAccount;
      if (user?.Type !== "Root") {
        finalAsset = assetData.filter((item) => item.UserID === user?.id);
        finalRetirement = retirementData.filter(
          (item) => item.UserID === user?.id
        );
        finalSaving = savingData.filter((item) => item.UserID === user?.id);
        finaldebt = debtData.filter((item) => item.UserID === user?.id);
        finalIncome = incomeData.filter((item) => item.UserID === user?.id);
        finalBankAccount = bankAccountData?.filter(
          (item) => item.UserID === user?.id
        );
      } else {
        finalAsset = assetData;
        finalRetirement = retirementData;
        finalSaving = savingData;
        finaldebt = debtData;
        finalIncome = incomeData;
        finalBankAccount = bankAccountData;
      }

      let filteredData;
      let filteredRetirement;
      let filteredSavings;
      let filteredDebts;
      let filteredIncome;
      let filteredBankAccount;
      if (
        dataFilter === "Self" ||
        dataFilter === "Partner" ||
        dataFilter === "Joint"
      ) {
        filteredData = finalAsset.filter(
          (item) => item.AccountOwner === dataFilter
        );
        filteredRetirement = finalRetirement.filter(
          (item) => item.AccountOwner === dataFilter
        );
        filteredSavings = finalSaving.filter(
          (item) => item.AccountOwner === dataFilter
        );
        filteredDebts = finaldebt.filter(
          (item) => item.AccountOwner === dataFilter
        );
        filteredIncome = finalIncome.filter(
          (item) => item.AccountOwner === dataFilter
        );
        filteredBankAccount = finalBankAccount.filter(
          (item) => item.AccountOwner === dataFilter
        );
      } else {
        filteredData = finalAsset;
        filteredRetirement = finalRetirement;
        filteredSavings = finalSaving;
        filteredDebts = finaldebt;
        filteredIncome = finalIncome;
        filteredBankAccount = finalBankAccount;
      }

      //Get retirement market value
      const totalMarketValue = filteredRetirement?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );
      setRetirementTotal(totalMarketValue);

      const totalBalance = filteredSavings?.reduce((accumulator, record) => {
        const MarketValue = record?.MarketValue || 0;
        return accumulator + Number(MarketValue);
      }, 0);
      setSavingsTotal(totalBalance);

      //Get total real estate value
      const filteredEstateData = filteredData?.filter(
        (item) => item.AssetCategory === "Real Estate"
      );
      const totalEstate = filteredEstateData?.reduce((accumulator, record) => {
        const MarketValue = record?.MarketValue || 0;
        return accumulator + Number(MarketValue);
      }, 0);
      setEstateTotal(totalEstate);

      //Get total real vehicle value
      const filteredVehicleData = filteredData?.filter(
        (item) => item.AssetCategory === "Vehicle"
      );
      const totalVehicle = filteredVehicleData?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );
      setVehicleTotal(totalVehicle);

      //Get total real Furnishings/Appliances value
      const filteredApplianceData = filteredData?.filter(
        (item) => item.AssetCategory === "Furnishing / Appliances"
      );
      const totalAppliance = filteredApplianceData?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );
      setApplianceTotal(totalAppliance);

      //Get total real Other value
      const filteredOtherData = filteredData?.filter(
        (item) => item.AssetCategory === "Other"
      );
      const totalOther = filteredOtherData?.reduce((accumulator, record) => {
        const MarketValue = record?.MarketValue || 0;
        return accumulator + Number(MarketValue);
      }, 0);
      setOtherTotal(totalOther);

      //Get totalLiabilities value
      const totalLiabilities = filteredDebts?.reduce((accumulator, record) => {
        const loanBalance = record?.LoanBalance || 0;
        return accumulator + Number(loanBalance);
      }, 0);
      setAllLiabilitiesTotal(totalLiabilities);

      // Get totalRealEstateLoan value
      const totalRealEstateLoan = filteredDebts?.reduce(
        (accumulator, record) => {
          if (record?.BudgetItem === "Real Estate Loan") {
            const loanBalance = record?.LoanBalance || 0;
            return accumulator + Number(loanBalance);
          }
          return accumulator;
        },
        0
      );
      setRealEstateLoanTotal(totalRealEstateLoan);

      // Get totalCreditCard value
      const totalCreditCard = filteredDebts?.reduce((accumulator, record) => {
        if (record?.BudgetItem === "Credit Card") {
          const loanBalance = record?.LoanBalance || 0;
          return accumulator + Number(loanBalance);
        }
        return accumulator;
      }, 0);
      setCreditCardTotal(totalCreditCard);

      // Get totalMedicalDebt value
      const totalMedicalDebt = filteredDebts?.reduce((accumulator, record) => {
        if (record?.BudgetItem === "Medical Debt") {
          const loanBalance = record?.LoanBalance || 0;
          return accumulator + Number(loanBalance);
        }
        return accumulator;
      }, 0);
      setMedicalDebt(totalMedicalDebt);

      // Get totalVehicleLoan value
      const totalVehicleLoan = filteredDebts?.reduce((accumulator, record) => {
        if (record?.BudgetItem === "Vehicle Loan") {
          const loanBalance = record?.LoanBalance || 0;
          return accumulator + Number(loanBalance);
        }
        return accumulator;
      }, 0);
      setVehicleLoan(totalVehicleLoan);

      // Get totalLoans value
      const totalLoans = filteredDebts?.reduce((accumulator, record) => {
        if (
          record?.BudgetItem === "Family/Friend Loan" ||
          record?.BudgetItem === "Personal Loan" ||
          record?.BudgetItem === "Student Loan"
        ) {
          const loanBalance = record?.LoanBalance || 0;
          return accumulator + Number(loanBalance);
        }
        return accumulator;
      }, 0);
      setLoansTotal(totalLoans);

      const totalAnnualIncome = filteredIncome.reduce((accumulator, record) => {
        const grossPay = record.GrossPay || 0;
        const payFrequency = record.PayFrequency || "";
        let annualIncome = 0;
        // Determine monthlyIncome based on PayFrequency
        switch (payFrequency) {
          case "Yearly":
            annualIncome = grossPay;
            break;
          case "Monthly":
            annualIncome = grossPay * 12;
            break;
          case "Semi-Monthly":
            annualIncome = grossPay * 24;
            break;
          case "Weekly":
            annualIncome = grossPay * 52;
            break;
          case "Bi-Weekly":
            annualIncome = grossPay * 26;
            break;
          default:
            annualIncome = 0;
        }
        // Add monthlyIncome to the accumulator
        return accumulator + Number(annualIncome);
      }, 0); // Initialize accumulator with 0
      setGrossIncomeTotal(totalAnnualIncome);

      // Calculate closing balance
      const balanceAfter = filteredBankAccount.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);
      setClosingBalance(balanceAfter);
      const totalAssetsValue =
        Number(totalMarketValue) +
        Number(totalBalance) +
        Number(balanceAfter) +
        Number(totalEstate) +
        Number(totalVehicle) +
        Number(totalAppliance) +
        Number(totalOther);
      setAllAssetTotal(totalAssetsValue);
      const totalOtherLoanValue =
        Number(totalLiabilities) -
        (Number(totalRealEstateLoan) +
          Number(totalCreditCard) +
          Number(totalMedicalDebt) +
          Number(totalVehicleLoan) +
          Number(totalLoans));
      setOtherLoansTotal(totalOtherLoanValue);
      setActualNetWorth(Number(totalAssetsValue) - Number(totalLiabilities));

      setIsDataChanged(true);
      setIsDataLoaded(true);
    }
  }, [
    assetData,
    isAssetLoaded,
    retirementData,
    isRetirementLoaded,
    savingData,
    isSavingLoaded,
    debtData,
    isDebtLoaded,
    incomeData,
    isIncomeLoaded,
    bankAccountData,
    isBankAccountLoaded,
    user,
  ]);

  // Show toast when isIncomeLoaded becomes false
  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded || !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  const handleUpgradeButtonClick = () => {
    navigate("/packages");
  };

  return (
    <div className="projects_container">
      <div className="projects_main">
        <div ref={targetRef} className="all-outer-project-containers">
          <div className="title-container">
            <div className="mainTitle_dropdown">
              <h3>Net Worth for {user?.FullName}</h3>
              <div className="_account_owner1">
                <ComboBoxComponent
                  id="comboelement"
                  value="Select Owner"
                  headerTemplate={dropdownTemplate}
                  dataSource={["Self", "Partner", "Joint", "Household"]}
                  change={handleAccountOwnerChange}
                  style={{ backgroundColor: "black", color: "#FFE99B" }}
                />
              </div>
            </div>
          </div>
          <>
            {userPackage !== "Basic" &&
              userPackage !== "Standard" &&
              userPackage !== "Premium" && (
                <div className="title-container1">
                  <h2>This Feature is Only Available to Paid Users Only</h2>
                  <div className="button-container">
                    <div className="button-container-subA1">
                      <button
                        className="add-button"
                        onClick={handleUpgradeButtonClick}
                      >
                        {" "}
                        <UpgradeIcon className="send-icon" />
                        Pay Now
                      </button>
                    </div>
                  </div>
                </div>
              )}
            {(userPackage === "Basic" ||
              userPackage === "Standard" ||
              userPackage === "Premium") && (
              <>
                {userPackage !== "Premium" && (
                  <div className="title-container1">
                    <h2>This Feature is Only Available on Premium Package</h2>
                    <div className="button-container">
                      <div className="button-container-subA1">
                        <button
                          className="add-button"
                          onClick={handleUpgradeButtonClick}
                        >
                          <UpgradeIcon className="send-icon" />
                          Upgrade Now
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="print-container">
                  <div className="print-container-inner">
                    <IconButton onClick={toPDF}>
                      <Print /> Print{" "}
                    </IconButton>
                  </div>
                </div>
                {userPackage === "Premium" && (
                  <div className="inner-container-top">
                    <div className="asset_container">
                      <h1>ASSETS (Market Value of What You Own)</h1>
                      <div className="asset-content">
                        <div className="firstCol">
                          <div className="asset-input-group">
                            <h2>Checking, Savings & Investment Accounts</h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={
                                  Number(savingsTotal) + Number(closingBalance)
                                }
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                          <div className="asset-input-group">
                            <h2>Household Furnishings & Appliances</h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={applianceTotal}
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                          <div className="asset-input-group">
                            <h2>Other Assets</h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={otherTotal}
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="SecondCol">
                          <div className="asset-input-group">
                            <h2>Real Estate</h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={estateTotal}
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                          <div className="asset-input-group">
                            <h2>Retirement Accounts</h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={retirementTotal}
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                          <div className="asset-input-group">
                            <h2>Vehicles</h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={vehicleTotal}
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                          <div className="asset-input-group">
                            <h2>Total Assets</h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={allAssetTotal}
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="asset_container">
                      <h1>LIABILITIES (How Much You Owe)</h1>
                      <div className="asset-content">
                        <div className="firstCol">
                          <div className="asset-input-group">
                            <h2>Credit Cards</h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={creditCardTotal}
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                          <div className="asset-input-group">
                            <h2>Medical Debt</h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={medicalDebt}
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                          <div className="asset-input-group">
                            <h2>Other Debt</h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={otherLoansTotal}
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="SecondCol">
                          <div className="asset-input-group">
                            <h2>Other Loans</h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={loansTotal}
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                          <div className="asset-input-group">
                            <h2>Real Estate Loans</h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={realEstateLoanTotal}
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                          <div className="asset-input-group">
                            <h2>Vehicle Loans</h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={vehicleLoan}
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>

                          <div className="asset-input-group">
                            <h2>Total Liabilities</h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={allLiabilitiesTotal}
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="asset_container">
                      <h1>NET WORTH (Assets - Liabilities)</h1>
                      <div className="asset-content">
                        <div className="firstCol"></div>
                        <div className="SecondCol">
                          <div className="asset-input-group">
                            <h2>Net Worth</h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={actualNetWorth}
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="asset_container">
                      <h1>EXPECTED NET WORTH</h1>
                      <div className="asset-content">
                        <div className="firstCol">
                          <div className="asset-input-group">
                            <h2>Net Worth</h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={actualNetWorth}
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                          <div className="asset-input-group">
                            <h2>
                              Expected Net Worth for Age & Annual household
                              Income
                            </h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={
                                  (Number(userAge) / 10) *
                                  Number(grossIncomeTotal)
                                }
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="SecondCol">
                          <div className="asset-input-group">
                            <h2>
                              Difference between Expected Net Worth & Actual Net
                              Worth{" "}
                            </h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={
                                  Number(actualNetWorth) -
                                  (Number(userAge) / 10) *
                                    Number(grossIncomeTotal)
                                }
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                          <div className="asset-input-group">
                            <h2>
                              Age (or oldest person's age for Household or
                              Joint)
                            </h2>
                            <div className="_input">
                              <TextBoxComponent
                                value={userAge}
                                format="n2"
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                          <div className="asset-input-group">
                            <h2>
                              Gross Annual Household Income from all sources
                            </h2>
                            <div className="_input">
                              <NumericTextBoxComponent
                                value={grossIncomeTotal}
                                format={usedCurrency}
                                showSpinButton={false}
                                disabled={false}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
}

export default NetWorth;
