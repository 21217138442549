import React from "react";
import { TextBoxComponent, NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import "./UserDialog.css";

function BudgetCategoryDialog({ selectedCategory, selectedGoal, setNewGoal }) {
  const handleGoalChange = (e) => {
    if (e.value !== "") {
      setNewGoal(e.value);
    }
  };

  return (
    <div className="main-user-container-details1">
      <div className="user-field-container1">
        <div className="my-cap-user-row1-budg">
          <div className="budget_Category_budg">
            <TextBoxComponent
              placeholder="Item Description"
              value={selectedCategory}
              floatLabelType="Always"
              readOnly
              style={{
                pointerEvents: "none",
              }}
            />
          </div>
          <div className="category_goal_budg">
            <NumericTextBoxComponent
              placeholder="* Goal %"
              format="n1"
              value={selectedGoal}
              change={handleGoalChange}
              floatLabelType="Always"
              min={0}
              max={100}
              showSpinButton={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BudgetCategoryDialog;
