import React, { useState, useEffect } from "react";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";

import "./AssetDialog.css";
import {
  customTemplate,
  dropdownTemplate,
  requiredNote,
} from "../../shared/HeaderTempate";

function AssetDialog({
  selectedAccountOwner,
  selectedAssetCategory,
  selectedDescription,
  selectedMarketValue,
  assetCategory,
  latestData,
  assetNameData,
  usedCurrency,
  setNewAccountOwner,
  setNewAssetCategory,
  setNewDescription,
  setNewMarketValue,
  isRequired,
}) {
  const [itemDescription, setItemDescription] = useState();

  useEffect(() => {
    let assets = assetNameData;
    let usedAssets = latestData;
    if (selectedAssetCategory) {
      assets = assetNameData.filter(
        (item) => item.Category === selectedAssetCategory
      );
      usedAssets = latestData.filter(
        (item) => item.AssetCategory === selectedAssetCategory
      );
    }
    const uniqueDescriptions = [
      ...new Set(
        assets.map((item) => (item.Description ? item.Description : ""))
      ),
    ];
    const uniqueUsedDescriptions = [
      ...new Set(
        usedAssets.map((item) => (item.Description ? item.Description : ""))
      ),
    ];
    // Combine arrays and filter out duplicates from uniqueDescriptions
    const combinedDescriptions = [
      ...uniqueUsedDescriptions,
      ...uniqueDescriptions.filter(
        (description) => !uniqueUsedDescriptions.includes(description)
      ),
    ];
    combinedDescriptions.sort((a, b) => a.localeCompare(b));
    setItemDescription(combinedDescriptions);
  }, [assetNameData, latestData]);

  const handleDescriptionChange = (e) => {
    if (e && e.value) {
      setNewDescription(e.value);
    } else setNewDescription("");
  };

  const handleMarketValueChange = (e) => {
    if (e && e.value) {
      setNewMarketValue(e.value);
    } else setNewMarketValue("");
  };

  const handleAssetCategoryChange = (e) => {
    const selAssetCategory = e.value;
    if (e && e.value) {
      setNewAssetCategory(e.value);
      const assets = assetNameData.filter(
        (item) => item.Category === selAssetCategory
      );
      const usedAssets = latestData.filter(
        (item) => item.AssetCategory === selAssetCategory
      );
      const uniqueDescriptions = [
        ...new Set(
          assets.map((item) => (item.Description ? item.Description : ""))
        ),
      ];
      const uniqueUsedDescriptions = [
        ...new Set(
          usedAssets.map((item) => (item.Description ? item.Description : ""))
        ),
      ];
      // Combine arrays and filter out duplicates from uniqueDescriptions
      const combinedDescriptions = [
        ...uniqueUsedDescriptions,
        ...uniqueDescriptions.filter(
          (description) => !uniqueUsedDescriptions.includes(description)
        ),
      ];
      combinedDescriptions.sort((a, b) => a.localeCompare(b));
      setItemDescription(combinedDescriptions);
    } else setNewAssetCategory("");
  };

  const handleAccountOwnerChange = (e) => {
    if (e && e.value) {
      setNewAccountOwner(e.value);
    } else setNewAccountOwner("");
  };

  return (
    <div className="main-asset-container-details1">
      <div className="fund-field-container1">
        <div className="my-asset-row1">
          <div className="asset_category">
            <ComboBoxComponent
              id="comboelement"
              placeholder="* Asset Category"
              autofill={true}
              headerTemplate={dropdownTemplate}
              value={selectedAssetCategory}
              dataSource={assetCategory}
              change={handleAssetCategoryChange}
              floatLabelType="Always"
            />
          </div>
          <div className="asset_description">
            <ComboBoxComponent
              id="comboelement"
              placeholder="* Assets Description"
              autofill={true}
              headerTemplate={customTemplate}
              value={selectedDescription}
              dataSource={itemDescription}
              change={handleDescriptionChange}
              floatLabelType="Always"
            />
          </div>
        </div>
        <div className="my-asset-row2">
          <div className="asset_account_owner">
            <ComboBoxComponent
              id="comboelement"
              value={selectedAccountOwner}
              placeholder="* Account Owner"
              autofill={true}
              headerTemplate={dropdownTemplate}
              floatLabelType="Always"
              dataSource={["Self", "Partner", "Joint", "Household"]}
              change={handleAccountOwnerChange}
            />
          </div>
          <div className="asset_market_Value">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={selectedMarketValue}
              placeholder="* Market Value"
              floatLabelType="Always"
              showSpinButton={false}
              change={handleMarketValueChange}
            />
          </div>
        </div>
        {isRequired && <div className="noteContainer">{requiredNote()}</div>}
      </div>
    </div>
  );
}

export default AssetDialog;
