import React, { useState, useEffect } from "react";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";

import "./DebtDialog.css";
import {
  customTemplate,
  dropdownTemplate,
  requiredNote,
} from "../../shared/HeaderTempate";

function DebtDialog({
  selectedAccountOwner,
  selectedBudgetItem,
  selectedDescription,
  selectedBudgetType,
  selectedDayDue,
  selectedPaymentMethod,
  selectedBankAccount,
  selectedSubCategory,
  selectedMonthlyPayment,
  selectedAdjustments,
  selectedLoanBalance,
  bankAccounts,
  subCategories,
  paymentMethods,
  budgetItems,
  accountData,
  latestData,
  debtNameData,
  usedCurrency,
  setNewAccountOwner,
  setNewBudgetItem,
  setNewDescription,
  setNewBudgetType,
  setNewDayDue,
  setNewPaymentMethod,
  setNewBankAccount,
  setNewSubCategory,
  setNewMonthlyPayment,
  setNewAdjustments,
  setNewLoanBalance,
  isRequired,
}) {
  const [monthlyPay, setMonthlyPay] = useState(selectedMonthlyPayment);
  const [adjustment, setAdjustment] = useState(selectedAdjustments);

  const [adjustedMonthlyPay, setAdjustedMonthlyPay] = useState(
    Number(selectedMonthlyPayment) + Number(selectedAdjustments)
  );
  const [originalAnnualPay, setOriginalAnnualPay] = useState(
    Number(selectedMonthlyPayment) * 12
  );
  const [adjustedAnnualPay, setAdjustedAnnualPay] = useState(
    (Number(selectedMonthlyPayment) + Number(selectedAdjustments)) * 12
  );
  const [itemDescription, setItemDescription] = useState();

  useEffect(() => {
    let debts = debtNameData;
    let usedDebts = latestData;
    if (selectedBudgetItem) {
      debts = debtNameData.filter(
        (item) => item.BudgetItem === selectedBudgetItem
      );
      usedDebts = latestData.filter(
        (item) => item.BudgetItem === selectedBudgetItem
      );
    }
    const uniqueDescriptions = [
      ...new Set(
        debts.map((item) => (item.Description ? item.Description : ""))
      ),
    ];
    const uniqueUsedDescriptions = [
      ...new Set(
        usedDebts.map((item) => (item.Description ? item.Description : ""))
      ),
    ];
    // Combine arrays and filter out duplicates from uniqueDescriptions
    const combinedDescriptions = [
      ...uniqueUsedDescriptions,
      ...uniqueDescriptions.filter(
        (description) => !uniqueUsedDescriptions.includes(description)
      ),
    ];
    combinedDescriptions.sort((a, b) => a.localeCompare(b));
    setItemDescription(combinedDescriptions);
  }, [debtNameData, latestData]);

  const handleDescriptionChange = (e) => {
    if (e && e.value) {
      setNewDescription(e.value);
    } else setNewDescription("");
  };

  const handleSubCategoriesChange = (e) => {
    if (e && e.value) {
      setNewSubCategory(e.value);
    } else setNewSubCategory("");
  };

  const handleAccountOwnerChange = (e) => {
    if (e && e.value) {
      setNewAccountOwner(e.value);
    } else setNewAccountOwner("");
  };

  const handleLoanBalanceChange = (e) => {
    if (e && e.value) {
      setNewLoanBalance(e.value);
    } else setNewLoanBalance("");
  };

  const handleBudgetItemChange = (e) => {
    const selBudgetItem = e.value;
    if (e && e.value) {
      setNewBudgetItem(e.value);
      const debts = debtNameData.filter(
        (item) => item.BudgetItem === selBudgetItem
      );
      const usedDebts = latestData.filter(
        (item) => item.BudgetItem === selBudgetItem
      );
      const uniqueDescriptions = [
        ...new Set(
          debts.map((item) => (item.Description ? item.Description : ""))
        ),
      ];
      const uniqueUsedDescriptions = [
        ...new Set(
          usedDebts.map((item) => (item.Description ? item.Description : ""))
        ),
      ];
      // Combine arrays and filter out duplicates from uniqueDescriptions
      const combinedDescriptions = [
        ...uniqueUsedDescriptions,
        ...uniqueDescriptions.filter(
          (description) => !uniqueUsedDescriptions.includes(description)
        ),
      ];
      combinedDescriptions.sort((a, b) => a.localeCompare(b));
      setItemDescription(combinedDescriptions);
    } else setNewBudgetItem("");
  };

  const handleBudgetTypeChange = (e) => {
    if (e && e.value) {
      setNewBudgetType(e.value);
    } else setNewBudgetType("");
  };

  const handleBankAccountsChange = (e) => {
    if (e && e.value) {
      const accountName = e.value;
      const targetAccountData = accountData.find(
        (item) => item.Account === accountName
      );
      const selAccountID = targetAccountData ? targetAccountData.id : "";
      setNewBankAccount(selAccountID);
    } else setNewBankAccount("");
  };

  const handlePaymentMethodsChange = (e) => {
    if (e && e.value) {
      setNewPaymentMethod(e.value);
    } else setNewPaymentMethod("");
  };

  const handlePayDueChange = (e) => {
    if (e && e.value) {
      setNewDayDue(e.value);
    } else setNewDayDue("");
  };

  const handleOriginalMonthlyChange = (e) => {
    if (e && e.value) {
      setNewMonthlyPayment(e.value);
      setMonthlyPay(e.value);
      setAdjustedMonthlyPay(e.value + Number(adjustment));
      setOriginalAnnualPay(e.value * 12);
      setAdjustedAnnualPay((e.value + Number(adjustment)) * 12);
    } else setNewMonthlyPayment("");
  };

  const handleAdjustmentsChange = (e) => {
    if (e && e.value) {
      setNewAdjustments(e.value);
      setAdjustment(e.value);
      setAdjustedMonthlyPay(Number(monthlyPay) + e.value);
      setOriginalAnnualPay(Number(monthlyPay) * 12);
      setAdjustedAnnualPay((Number(monthlyPay) + e.value) * 12);
    } else setNewAdjustments(0);
  };

  return (
    <div className="main-ben-container-details1">
      <div className="beneficiary-field-container1">
        <div className="my-cap-ben-row1_debt">
          <div className="debt_budget_item">
            <ComboBoxComponent
              id="comboelement"
              placeholder="* Budget Item"
              headerTemplate={dropdownTemplate}
              autofill={true}
              value={selectedBudgetItem}
              dataSource={budgetItems}
              change={handleBudgetItemChange}
              floatLabelType="Always"
            />
          </div>
          <div className="debt_description">
            <ComboBoxComponent
              id="comboelement"
              placeholder="* Debt Description"
              headerTemplate={customTemplate}
              autofill={true}
              value={selectedDescription}
              dataSource={itemDescription}
              change={handleDescriptionChange}
              floatLabelType="Always"
            />
          </div>
        </div>
        <div className="my-cap-ben-row2_debt">
          <div className="debt_account_owner">
            <ComboBoxComponent
              id="comboelement"
              value={selectedAccountOwner}
              placeholder="* Account Owner"
              headerTemplate={dropdownTemplate}
              autofill={true}
              floatLabelType="Always"
              dataSource={["Self", "Partner", "Joint", "Household"]}
              change={handleAccountOwnerChange}
            />
          </div>
          <div className="debt_sub_cat">
            <ComboBoxComponent
              id="comboelement"
              value={selectedSubCategory}
              placeholder="* Subcategory"
              headerTemplate={dropdownTemplate}
              autofill={true}
              floatLabelType="Always"
              dataSource={subCategories}
              change={handleSubCategoriesChange}
            />
          </div>
          <div className="debt_budget_type">
            <ComboBoxComponent
              id="comboelement"
              value={selectedBudgetType}
              placeholder="* Budget Type"
              headerTemplate={dropdownTemplate}
              autofill={true}
              floatLabelType="Always"
              dataSource={["Committed", "Extra", "'Funds'"]}
              change={handleBudgetTypeChange}
            />
          </div>
          <div className="debt_bank_account">
            <ComboBoxComponent
              id="comboelement"
              placeholder="* Bank Account Name"
              headerTemplate={dropdownTemplate}
              autofill={true}
              value={selectedBankAccount}
              dataSource={bankAccounts}
              change={handleBankAccountsChange}
              floatLabelType="Always"
            />
          </div>
        </div>
        <div className="my-cap-ben-row4_debt">
          <div className="debt_payment_method">
            <ComboBoxComponent
              id="comboelement"
              placeholder="Payment Method"
              headerTemplate={dropdownTemplate}
              autofill={true}
              value={selectedPaymentMethod}
              dataSource={paymentMethods}
              change={handlePaymentMethodsChange}
              floatLabelType="Always"
            />
          </div>
          <div className="debt_payment_due">
            <ComboBoxComponent
              id="comboelement"
              value={selectedDayDue}
              headerTemplate={dropdownTemplate}
              autofill={true}
              placeholder="* Payment Due"
              floatLabelType="Always"
              dataSource={[
                "1st",
                "2nd",
                "3rd",
                "4th",
                "5th",
                "6th",
                "7th",
                "8th",
                "9th",
                "10th",
                "11th",
                "12th",
                "13th",
                "14th",
                "15th",
                "16th",
                "17th",
                "18th",
                "19th",
                "20th",
                "21st",
                "22nd",
                "23rd",
                "24th",
                "25th",
                "26th",
                "27th",
                "28th",
                "29th",
                "30th",
                "31st",
                "N/A",
              ]}
              change={handlePayDueChange}
            />
          </div>
          <div className="debt_loan_balance">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={selectedLoanBalance}
              placeholder="* Loan Balance"
              floatLabelType="Always"
              showSpinButton={false}
              change={handleLoanBalanceChange}
            />
          </div>
          <div className="debt_monthly_pay">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={selectedMonthlyPayment}
              placeholder="* Monthly Payment"
              floatLabelType="Always"
              showSpinButton={false}
              change={handleOriginalMonthlyChange}
            />
          </div>
        </div>
        <div className="my-cap-ben-row3_debt">
          <div className="debt_adjustment">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={selectedAdjustments}
              placeholder=" Adjustment"
              floatLabelType="Always"
              change={handleAdjustmentsChange}
            />
          </div>
          <div className="debt_adjusted_pay">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={adjustedMonthlyPay}
              placeholder="Adjusted Monthly Payment"
              floatLabelType="Always"
              showSpinButton={false}
              disabled={false}
              style={{
                pointerEvents: "none",
              }}
            />
          </div>
          <div className="debt_original_debt">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={originalAnnualPay}
              placeholder="Original Annual Payment"
              floatLabelType="Always"
              showSpinButton={false}
              disabled={false}
              style={{
                pointerEvents: "none",
              }}
            />
          </div>
          <div className="debt_adjusted_debt">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={adjustedAnnualPay}
              placeholder="Adjusted Annual Payment"
              floatLabelType="Always"
              showSpinButton={false}
              disabled={false}
              style={{
                pointerEvents: "none",
              }}
            />
          </div>
        </div>
        {isRequired && <div className="noteContainer">{requiredNote()}</div>}
      </div>
    </div>
  );
}

export default DebtDialog;
