import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useAccountDataFetch = () => {
  const user = useUserStore((state) => state.user);
  const [accountData, setAccountData] = useState([]);
  const [isAccountLoaded, setIsAccountLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchAccountData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + `/api/account/reguserid/${user?.id}`
      );
      setIsAccountLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching Account data:", error);
      setIsAccountLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    if (user?.Payment)
      fetchAccountData().then((AccountInitialData) => {
        setAccountData(AccountInitialData);
      });
    else setIsAccountLoaded(true);
  }, [user]);

  // Return an object containing all the fetched data
  return {
    accountData,
    isAccountLoaded,
  };
};

export default useAccountDataFetch;
