import React from 'react'
import '../Sidebar.css'
import BankOption from './BankOption';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { NavLink } from 'react-router-dom';

function Banks() {
  return (
    <div className='sidebar'>
        <NavLink to="transactions">{<BankOption Icon = {ReceiptIcon} title="All Transactions"/>}</NavLink>
        <NavLink to="unclearedtransactions">{<BankOption Icon = {ReceiptIcon} title="Uncleared Transactions"/>}</NavLink>
        <NavLink to="bankrecon">{<BankOption Icon = {AccountBalanceWalletIcon} title= "Bank Recon"/>}</NavLink>                
    </div>
  )
}
export default Banks