import React from "react";
import {
  NumericTextBoxComponent,
  TextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import "./FundTrackerDialog.css";
import { dropdownTemplate, requiredNote } from "../../shared/HeaderTempate";

function FundTrackerDialog({
  selectedAccountOwner,
  selectedRecDate,
  selectedDescription,
  selectedAmount,
  usedCurrency,
  setNewAccountOwner,
  setNewRecDate,
  setNewDescription,
  setNewAmount,
  isRequired,
}) {
  const handleDescriptionChange = (e) => {
    if (e && e.value) {
      setNewDescription(e.value);
    } else setNewDescription("");
  };

  const handleAmountChange = (e) => {
    if (e && e.value) {
      setNewAmount(e.value);
    } else setNewAmount("");
  };

  const handleRecDateChange = (e) => {
    if (e && e.value) {
      setNewRecDate(e.value);
    } else setNewRecDate("");
  };

  const handleAccountOwnerChange = (e) => {
    if (e && e.value) {
      setNewAccountOwner(e.value);
    } else setNewAccountOwner("");
  };

  return (
    <div className="main-asset-container-details1">
      <div className="fund-field-container1">
        <div className="my-fund-row1">
          <div className="fund_category">
            <ComboBoxComponent
              id="comboelement"
              value={selectedAccountOwner}
              placeholder="* Account Owner"
              autofill={true}
              headerTemplate={dropdownTemplate}
              floatLabelType="Always"
              dataSource={["Self", "Partner", "Household"]}
              change={handleAccountOwnerChange}
            />
          </div>
          <div className="fund_description">
            <TextBoxComponent
              placeholder="* Description"
              value={selectedDescription}
              change={handleDescriptionChange}
              floatLabelType="Always"
            />
          </div>
        </div>
        <div className="my-fund-row2">
          <div className="fund_account_owner">
            <DatePickerComponent
              id="datepicker"
              placeholder="* Date"
              value={selectedRecDate}
              change={handleRecDateChange}
              floatLabelType="Always"
              format="MMM dd, yyyy"
            />
          </div>
          <div className="fund_market_Value">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={selectedAmount}
              placeholder="* Amount"
              floatLabelType="Always"
              showSpinButton={false}
              change={handleAmountChange}
            />
          </div>
        </div>
        {isRequired && <div className="noteContainer">{requiredNote()}</div>}
      </div>
    </div>
  );
}

export default FundTrackerDialog;
