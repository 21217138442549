import { create } from "zustand";
import { persist } from "zustand/middleware";

const useUserStore = create()(
  persist(
    (set) => ({
      user: null,
      jwt: null,
      setUser: (user) => set(() => ({ user: user })),
      updateUserPackage: (data) =>
        set((state) => ({
          user: { ...state.user, Package: data, Payment: true },
        })),
      setUserExpired: () =>
        set((state) => ({
          user: { ...state.user, isExpired: true, Payment: false },
        })),
      updateUserData: (data) =>
        set((state) => ({ user: { ...state.user, ...data } })),
      setJwt: (token) => set(() => ({ jwt: token })),
      clearUser: () => set(() => ({ user: null, jwt: null })),
    }),
    {
      name: "elite",
    }
  )
);

export default useUserStore;
