import React from 'react'
import { Outlet } from 'react-router-dom';
import Income from './Income';
import '../MainBody.css'

function IncomeLayout() {
  return (
    <div className='projects_container'>
      <div className='projects_sidebar'>
        <Income />
      </div>
      <div className='projects_main'>
        <Outlet />
      </div>
    </div>
  )
}

export default IncomeLayout