import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import useUserStore from "../../app/user";
import { IoMdMenu } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import "./ResponsiveHeader.css"

function ResponsiveHeader({ isDisplaySidebar , setisDisplaySidebar }) {
    const user = useUserStore((state) => state.user);
    const setJwt = useUserStore((state) => state.setJwt);
    const setUser = useUserStore((state) => state.setUser);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const navigate = useNavigate();
    //const profile = process.env.REACT_APP_SERVER_URL + "/public/uploads/" + user?.UserName + ".jpg";

    const handleLogout = () => {
        // Perform logout actions
        // Redirect to the login page
        setUser(null);
        setJwt(null);
        navigate("/login");
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false); // Close the dialog
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const ToggleSideBar = () => {
        setisDisplaySidebar(!isDisplaySidebar);
    }

    return (
        <div className="navbar_container">
            {/*LEFTSIDE menuIcon */}
            <div className="menuIcon" onClick={ToggleSideBar}>
                {isDisplaySidebar ? <AiOutlineClose /> : <IoMdMenu /> } 
            </div>

            {/* RIGHT SIDE */}
            <div className="right_container">
            <div className="responsive_header_right" onClick={() => setIsDialogOpen(true)}>
                <img src={user?.Profile} alt="User Pic" className="profile_img" />
                <div className="userInfo">
                    <p>{user?.FullName}</p>
                    <small>{user?.Package} Package</small>
                </div>
                <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
                    <div className="logout_user" onClick={stopPropagation}>
                        <img src={user?.Profile}  alt="User Pic"  />
                        <div className="logoutInfo">
                            <h1>{user?.FullName}</h1>
                            <p>{user?.Package} Package</p>
                            <button onClick={handleLogout}>Logout</button>
                            <button onClick={handleCloseDialog}>Cancel</button>
                        </div>
                    </div>
                </Dialog>
            </div>
            </div>

        </div>
    );
}

export default ResponsiveHeader;
