import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useExpenseCatDataFetch = () => {
  const user = useUserStore((state) => state.user);
  const [expenseCatData, setExpenseCatData] = useState([]);
  const [isExpenseCatLoaded, setIsExpenseCatLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchExpenseCatData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          `/api/expensecat/reguserid/${user?.id}`
      );
      setIsExpenseCatLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching ExpenseCat data:", error);
      setIsExpenseCatLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchExpenseCatData().then((ExpenseCatInitialData) => {
        setExpenseCatData(ExpenseCatInitialData);
      });
    else setIsExpenseCatLoaded(true);
  }, [user]);

  // Return an object containing all the fetched data
  return {
    expenseCatData,
    isExpenseCatLoaded,
  };
};

export default useExpenseCatDataFetch;
