export function CheckIncomeFields(
  newAccountOwner,
  newIncomeName,
  newPayFrequency,
  newPayDay,
  newGrossPay,
  newNetPay
) {
  let isRequired = false;
  if (
    newAccountOwner === "" ||
    newIncomeName === "" ||
    newPayFrequency === "" ||
    newPayDay === "" ||
    newGrossPay === "" ||
    newNetPay === ""
  ) {
    isRequired = true;
  }
  return isRequired;
}
export function CheckExpenseFields(
  newAccountOwner,
  newBudgetItem,
  newDescription,
  newSubCategory,
  newBudgetType,
  newDayDue,
  newMonthlyPayment,
  newBankAccount
) {
  let isRequired = false;
  if (
    newAccountOwner === "" ||
    newBudgetItem === "" ||
    newDescription === "" ||
    newSubCategory === "" ||
    newBudgetType === "" ||
    newDayDue === "" ||
    newMonthlyPayment === "" ||
    newBankAccount === ""
  ) {
    isRequired = true;
  }
  return isRequired;
}
export function CheckDebtFields(
  newAccountOwner,
  newBudgetItem,
  newDescription,
  newSubCategory,
  newBudgetType,
  newDayDue,
  newMonthlyPayment,
  newLoanBalance,
  newBankAccount
) {
  let isRequired = false;
  if (
    newAccountOwner === "" ||
    newBudgetItem === "" ||
    newDescription === "" ||
    newSubCategory === "" ||
    newBudgetType === "" ||
    newDayDue === "" ||
    newMonthlyPayment === "" ||
    newLoanBalance === "" ||
    newBankAccount === ""
  ) {
    isRequired = true;
  }
  return isRequired;
}
export function CheckRetirementFields(
  newAccountOwner,
  newBudgetItem,
  newDescription,
  newSubCategory,
  newBudgetType,
  newDayDue,
  newMonthlyPayment,
  newMarketValue,
  newContribution,
  newBankAccount
) {
  let isRequired = false;
  if (
    newAccountOwner === "" ||
    newBudgetItem === "" ||
    newDescription === "" ||
    newSubCategory === "" ||
    newBudgetType === "" ||
    newDayDue === "" ||
    newMonthlyPayment === "" ||
    newMarketValue === "" ||
    newContribution === "" ||
    newBankAccount === ""
  ) {
    isRequired = true;
  }
  return isRequired;
}
export function CheckSavingsFields(
  newAccountOwner,
  newBudgetItem,
  newDescription,
  newSubCategory,
  newBudgetType,
  newDayDue,
  newMonthlyPayment,
  newMarketValue,
  newBankAccount
) {
  let isRequired = false;
  if (
    newAccountOwner === "" ||
    newBudgetItem === "" ||
    newDescription === "" ||
    newSubCategory === "" ||
    newBudgetType === "" ||
    newDayDue === "" ||
    newMonthlyPayment === "" ||
    newMarketValue === "" ||
    newBankAccount === ""
  ) {
    isRequired = true;
  }
  return isRequired;
}
export function CheckFundsTrackerFields(
  newAccountOwner,
  newRecDate,
  newDescription,
  newAmount
) {
  let isRequired = false;
  if (
    newAccountOwner === "" ||
    newRecDate === "" ||
    newDescription === "" ||
    newAmount === ""
  ) {
    isRequired = true;
  }
  return isRequired;
}
export function CheckAssetFields(
  newAccountOwner,
  newAssetCategory,
  newDescription,
  newMarketValue
) {
  let isRequired = false;
  if (
    newAccountOwner === "" ||
    newAssetCategory === "" ||
    newDescription === "" ||
    newMarketValue === ""
  ) {
    isRequired = true;
  }
  return isRequired;
}
export function CheckAmountFields(
  newTransType,
  newBudgetItem,
  newWithdrawal,
  newDeposit
) {
  let isRequired = false;
  if (
    newTransType === "Withdrawal" ||
    (newTransType === "Transfer" && newBudgetItem === "To Another Bank Account")
  ) {
    if (newWithdrawal === "") {
      isRequired = true;
    }
  } else {
    if (newDeposit === "") {
      isRequired = true;
    }
  }

  return isRequired;
}
export function CheckBankFields(
  newAccountOwner,
  newBudgetItem,
  newTransType,
  newBudgetCategory,
  newTransDate,
  newWithdrawal,
  newDeposit,
  newClearedBank,
  newBankAccount
) {
  let isRequired = false;
  if (
    newAccountOwner === "" ||
    newBudgetItem === "" ||
    newTransType === "" ||
    newBudgetCategory === "" ||
    newTransDate === "" ||
    newClearedBank === "" ||
    newBankAccount === "" ||
    CheckAmountFields(newTransType, newBudgetItem, newWithdrawal, newDeposit)
  ) {
    console.log(isRequired, "isRequired");
    isRequired = true;
  }
  return isRequired;
}
