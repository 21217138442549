import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
  Reorder,
  Resize,
  PdfExport,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";

import { DialogComponent } from "@syncfusion/ej2-react-popups";
import axios from "../../../config/axios";
import "../../shared/ListView.css";
import useSubCatDataFetch from "../../shared/useSubCatDataFetch";
import useExpenseDataFetch from "../../shared/useExpenseDataFetch";
import useDebtDataFetch from "../../shared/useDebtDataFetch";
import useRetirementDataFetch from "../../shared/useRetirementDataFetch";
import useSavingDataFetch from "../../shared/useSavingDataFetch";
import useIncomeDataFetch from "../../shared/useIncomeDataFetch";
import BudgetCategoryDialog from "./BudgetCategoryDialog";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";
import UpgradeIcon from "@mui/icons-material/Upgrade";

function SubCategory() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const userPackage = user?.Package;
  const { subCatData, isSubCatLoaded } = useSubCatDataFetch();
  const { expenseData, isExpenseLoaded } = useExpenseDataFetch();
  const { debtData, isDebtLoaded } = useDebtDataFetch();
  const { retirementData, isRetirementLoaded } = useRetirementDataFetch();
  const { savingData, isSavingLoaded } = useSavingDataFetch();
  const { incomeData, isIncomeLoaded } = useIncomeDataFetch();
  const GridRef = useRef(null);
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [needsActual, setNeedsActual] = useState(0);
  const [debtActual, setDebtActual] = useState(0);
  const [savingsActual, setSavingsActual] = useState(0);

  useEffect(() => {
    if (
      isSavingLoaded &&
      isRetirementLoaded &&
      isSubCatLoaded &&
      isExpenseLoaded &&
      isIncomeLoaded &&
      isDebtLoaded
    ) {
      let finalExpense;
      let finaldebt;
      let finalIncome;
      let finalRetirement;
      let finalSaving;
      if (user?.Type !== "Root") {
        finalExpense = expenseData.filter((item) => item.UserID === user?.id);
        finalIncome = incomeData.filter((item) => item.UserID === user?.id);
        finaldebt = debtData.filter((item) => item.UserID === user?.id);
        finalRetirement = retirementData.filter(
          (item) => item.UserID === user?.id
        );
        finalSaving = savingData.filter((item) => item.UserID === user?.id);
      } else {
        finalExpense = expenseData;
        finaldebt = debtData;
        finalRetirement = retirementData;
        finalIncome = incomeData;
        finalSaving = savingData;
      }

      const needsExpenseData = finalExpense?.filter(
        (item) => item.SubCategory === "(N) Needs/Necessities"
      );
      const needsDebtData = finaldebt?.filter(
        (item) => item.SubCategory === "(N) Needs/Necessities"
      );
      const needsRetirementData = finalRetirement?.filter(
        (item) => item.SubCategory === "(N) Needs/Necessities"
      );
      const needsSavingsData = finalSaving?.filter(
        (item) => item.SubCategory === "(N) Needs/Necessities"
      );
      const debtExpenseData = finalExpense?.filter(
        (item) => item.SubCategory === "(W) Debt/Wants/Nonessentials"
      );
      const debtDebtData = finaldebt?.filter(
        (item) => item.SubCategory === "(W) Debt/Wants/Nonessentials"
      );
      const debtRetirementData = finalRetirement?.filter(
        (item) => item.SubCategory === "(W) Debt/Wants/Nonessentials"
      );
      const debtSavingsData = finalSaving?.filter(
        (item) => item.SubCategory === "(W) Debt/Wants/Nonessentials"
      );
      const savingsExpenseData = finalExpense?.filter(
        (item) => item.SubCategory === "(S) Savings"
      );
      const savingsDebtData = finaldebt?.filter(
        (item) => item.SubCategory === "(S) Savings"
      );
      const savingsRetirementData = finalRetirement?.filter(
        (item) => item.SubCategory === "(S) Savings"
      );
      const savingsSavingData = finalSaving?.filter(
        (item) => item.SubCategory === "(S) Savings"
      );

      const needsExpenseMonthly = needsExpenseData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      ); // Initialize accumulator with 0

      const debtExpenseMonthly = debtExpenseData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const savingsExpenseMonthly = savingsExpenseData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const needsDebtMonthly = needsDebtData.reduce((accumulator, record) => {
        const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
        const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0); // Initialize accumulator with 0

      const debtDebtMonthly = debtDebtData.reduce((accumulator, record) => {
        const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
        const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
        // Add the MonthlyPayment and Adjustments to the accumulator
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);

      const savingsDebtMonthly = savingsDebtData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const needsSavingMonthly = needsSavingsData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      ); // Initialize accumulator with 0

      const debtSavingMonthly = debtSavingsData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const savingsSavingMonthly = savingsSavingData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const needsRetirementMonthly = needsRetirementData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      ); // Initialize accumulator with 0

      const debtRetirementMonthly = debtRetirementData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const savingsRetirementMonthly = savingsRetirementData.reduce(
        (accumulator, record) => {
          const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
          const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
          // Add the MonthlyPayment and Adjustments to the accumulator
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      const totalMonthlyIncome = finalIncome.reduce((accumulator, record) => {
        const netPay = record.NetPay || 0; // Handle the case where NetPay is undefined
        const payFrequency = record.PayFrequency || ""; // Handle the case where PayFrequency is undefined
        let monthlyIncome = 0;
        // Determine monthlyIncome based on PayFrequency
        switch (payFrequency) {
          case "Yearly":
            monthlyIncome = netPay / 12;
            break;
          case "Monthly":
            monthlyIncome = netPay;
            break;
          case "Semi-Monthly":
            monthlyIncome = netPay * 2;
            break;
          case "Weekly":
            monthlyIncome = netPay * 4;
            break;
          case "Bi-Weekly":
            monthlyIncome = netPay * 2;
            break;
          default:
            monthlyIncome = 0;
        }
        // Add monthlyIncome to the accumulator
        return accumulator + Number(monthlyIncome);
      }, 0); // Initialize accumulator with 0

      const totalGrossMonthlyIncome = finalIncome.reduce(
        (accumulator, record) => {
          const grossPay = record.GrossPay || 0; // Handle the case where NetPay is undefined
          const payFrequency = record.PayFrequency || ""; // Handle the case where PayFrequency is undefined
          let monthlyIncome = 0;
          // Determine monthlyIncome based on PayFrequency
          switch (payFrequency) {
            case "Yearly":
              monthlyIncome = grossPay / 12;
              break;
            case "Monthly":
              monthlyIncome = grossPay;
              break;
            case "Semi-Monthly":
              monthlyIncome = grossPay * 2;
              break;
            case "Weekly":
              monthlyIncome = grossPay * 4;
              break;
            case "Bi-Weekly":
              monthlyIncome = grossPay * 2;
              break;
            default:
              monthlyIncome = 0;
          }
          // Add monthlyIncome to the accumulator
          return accumulator + Number(monthlyIncome);
        },
        0
      ); // Initialize accumulator with 0

      const needsTotal =
        Number(needsExpenseMonthly) / Number(totalMonthlyIncome) +
        Number(needsDebtMonthly) / Number(totalMonthlyIncome) +
        Number(needsSavingMonthly) / Number(totalMonthlyIncome) +
        Number(needsRetirementMonthly) / Number(totalGrossMonthlyIncome);
      const debtTotal =
        Number(debtExpenseMonthly) / Number(totalMonthlyIncome) +
        Number(debtDebtMonthly) / Number(totalMonthlyIncome) +
        Number(debtSavingMonthly) / Number(totalMonthlyIncome) +
        Number(debtRetirementMonthly) / Number(totalGrossMonthlyIncome);
      const savingsTotal =
        Number(savingsExpenseMonthly) / Number(totalMonthlyIncome) +
        Number(savingsDebtMonthly) / Number(totalMonthlyIncome) +
        Number(savingsSavingMonthly) / Number(totalMonthlyIncome) +
        Number(savingsRetirementMonthly) / Number(totalGrossMonthlyIncome);

      setNeedsActual(100 * needsTotal);
      setDebtActual(100 * debtTotal);
      setSavingsActual(100 * savingsTotal);
      setGridData(subCatData);
      setIsDataChanged(true);
      setIsDataLoaded(true);
    }
  }, [
    subCatData,
    savingData,
    expenseData,
    debtData,
    retirementData,
    incomeData,
    isSubCatLoaded,
    isExpenseLoaded,
    isIncomeLoaded,
    isDebtLoaded,
    isRetirementLoaded,
    isSavingLoaded,
  ]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded || !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  const [selectedID, setSelectedID] = useState("");
  const [selectedRootID, setSelectedRootID] = useState("");
  const [selectedUserID, setSelectedUserID] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedGoal, setSelectedGoal] = useState("");

  const [newRootID, setNewRootID] = useState("");
  const [newUserID, setNewUserID] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [newGoal, setNewGoal] = useState("");

  const handleRowSelect = async (args) => {
    // Set the selected ID when a row is selected in the Grid
    setSelectedID(args.data.id);
    setSelectedRootID(args.data.RootUserID);
    setSelectedUserID(args.data.RegUserID);
    setSelectedCategory(args.data.Category);
    setSelectedGoal(args.data.Goal);
  };

  const handleExcelButtonClick = () => {};

  const handlePdfButtonClick = () => {};

  const handleEditDialogClose = () => {
    setIsEditDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    setIsEditDialogVisible(!isEditDialogVisible);
    setNewRootID(selectedRootID);
    setNewUserID(selectedUserID);
    setNewCategory(selectedCategory);
    setNewGoal(selectedGoal);
  };

  const handleUpdateRecordButton = async (selectedID) => {
    setIsDataChanged(false);
    const numericSelectedID = parseInt(selectedID);
    try {
      const RecordResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/subcat/${numericSelectedID}`,
        {
          RootUserID: newRootID,
          RegUserID: newUserID,
          Category: newCategory,
          Goal: newGoal,
        }
      );
      console.log("Record response:", RecordResponse.data);
      if (RecordResponse.data.status === "success") {
        toast.success("the record updated successfully.");
        // Get the UPDATED DATA by making a GET request
        const RecordResponseR = await axios.get(
          process.env.REACT_APP_SERVER_URL + `/api/subcat/reguserid/${user?.id}`
        );
        const Records = RecordResponseR.data.items;
        Records.sort((a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn));
        setGridData(Records);
      } else {
        console.error(
          "Error updating the record record:",
          RecordResponse.data.message
        );
        toast.error("Error updating the record");
      }
    } catch (error) {
      console.error("Error updating the record:", error);
      toast.error("Error updating the record");
    }
    setIsDataChanged(true);
  };

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = ["ColumnChooser"];
  const loadingIndicator = { indicatorType: "Spinner" };

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          <button
            className="edit-button"
            onClick={handleEditButtonClick}
            disabled={selectedID === ""}
          >
            <EditIcon className="send-icon" /> Edit Goal{" "}
          </button>
        </div>
        {/* <div className="button-container-subA2">
          <button className="excel-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          <button className="pdf-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button>
        </div> */}
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-success update-btn",
        isPrimary: true,
      },
      click: () => {
        handleUpdateRecordButton(selectedID);
        setIsEditDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsEditDialogVisible(false);
      },
    },
  ];

  const actualGoalTemplate = (props) => {
    const category = props.Category;
    let actualValue;
    switch (category) {
      case "(N) Needs/Necessities":
        actualValue = needsActual;
        break;
      case "(W) Debt/Wants/Nonessentials":
        actualValue = debtActual;
        break;
      case "(S) Savings":
        actualValue = savingsActual;
        break;
      default:
        actualValue = null;
    }
    //const formattedActualValue = actualValue !== null ? `${Math.round(actualValue)}%` : 'N/A';
    //const formattedActualValue = actualValue !== null ? `${actualValue.toFixed(2)}%` : 'N/A';
    let formattedActualValue;
    if (actualValue > 0 || actualValue === 0) {
      formattedActualValue = `${Math.round(actualValue)}%`;
    } else {
      formattedActualValue = "0%";
    }
    return <div>{formattedActualValue}</div>;
  };

  const flagTemplate = (props) => {
    const category = props.Category;
    const goalValue = props.Goal;
    let actualValue;
    let flag;
    switch (category) {
      case "(N) Needs/Necessities":
        actualValue = needsActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "(W) Debt/Wants/Nonessentials":
        actualValue = debtActual;
        if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      case "(S) Savings":
        actualValue = savingsActual;
        if (Number(actualValue) < Number(goalValue)) {
          flag = (
            <ThumbDownIcon
              style={{ color: "red", fontSize: "30px", padding: "0px" }}
            />
          );
        } else if (Number(actualValue) > Number(goalValue)) {
          flag = (
            <ThumbUpIcon
              style={{ color: "green", fontSize: "30px", padding: "0px" }}
            />
          );
        } else {
          flag = (
            <ThumbUpIcon
              style={{ color: "orange", fontSize: "30px", padding: "0px" }}
            />
          );
        }
        break;
      default:
        actualValue = null;
        flag = "N/A";
    }
    return <div>{flag}</div>;
  };

  const goalTemplate = (props) => {
    const formattedGoal = `${props.Goal}%`;
    return <div>{formattedGoal}</div>;
  };

  const handleUpgradeButtonClick = () => {
    navigate("/packages");
  };

  return (
    <div className="all-outer-project-containers">
      <div className="title-container">
        <div className="mainTitle_dropdown">
          <h3>Budget Subcategories & Goals for {user?.FullName}</h3>
        </div>
      </div>
      <br />
      <>
        {userPackage !== "Basic" &&
          userPackage !== "Standard" &&
          userPackage !== "Premium" && (
            <div className="title-container1">
              <h2>This Feature is Only Available to Paid Users Only</h2>
              <div className="button-container">
                <div className="button-container-subA1">
                  <button
                    className="add-button"
                    onClick={handleUpgradeButtonClick}
                  >
                    {" "}
                    <UpgradeIcon className="send-icon" />
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          )}
        {(userPackage === "Basic" ||
          userPackage === "Standard" ||
          userPackage === "Premium") && (
          <div className="all-inner-project-containers">
            {isDataLoaded && (
              <GridComponent
                dataSource={gridData}
                childMapping="items"
                ref={GridRef}
                classClass="custom-treegrid-class"
                height="480"
                width="100%"
                allowReordering={true}
                allowResizing={true}
                allowExcelExport={true}
                allowPdfExport={true}
                allowPaging={true}
                allowSorting={true}
                allowFiltering={true}
                allowTextWrap={true}
                showColumnChooser={true}
                filterSettings={FilterOptions}
                pageSettings={pageSettings}
                editSettings={editOptions}
                toolbar={updatedToolbarOptions}
                loadingIndicator={loadingIndicator}
                allowSelection={true}
                rowSelected={handleRowSelect}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="Category"
                    headerText="Budget Category"
                    width="150"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Goal"
                    headerText="Budget Goal"
                    width="120"
                    textAlign="Left"
                    template={goalTemplate}
                  />
                  <ColumnDirective
                    headerText="Actual Goal"
                    width="150"
                    textAlign="Left"
                    template={actualGoalTemplate}
                  />
                  <ColumnDirective
                    headerText="Flag"
                    width="120"
                    textAlign="Left"
                    template={flagTemplate}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Page,
                    Sort,
                    Filter,
                    Edit,
                    Toolbar,
                    ColumnChooser,
                    Reorder,
                    Resize,
                    PdfExport,
                    ExcelExport,
                  ]}
                />
              </GridComponent>
            )}
          </div>
        )}
      </>
      <div>
        {isEditDialogVisible && (
          <DialogComponent
            visible={isEditDialogVisible}
            width="30%"
            height="300px"
            header="Edit Goal"
            allowDragging={true}
            showCloseIcon={true}
            close={handleEditDialogClose}
            buttons={editButtons}
          >
            <BudgetCategoryDialog
              selectedCategory={selectedCategory}
              selectedGoal={selectedGoal}
              setNewGoal={setNewGoal}
            />
          </DialogComponent>
        )}
      </div>
    </div>
  );
}
export default SubCategory;
