import React, { useState, useEffect } from "react";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import "./Package.css";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "@mui/material/Button";
import PaidIcon from "@mui/icons-material/Paid";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "../../../config/axios";
import CheckoutForm from "./CheckoutForm";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import useUserStore from "../../../app/user";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Package() {
  const user = useUserStore((state) => state.user);
  const [clientSecret, setClientSecret] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [monthlyAmount, setMonthlyAmount] = useState(0);
  const [yearlyAmount, setYearlyAmount] = useState(0);
  const [selectedMount, setSelectedAmount] = useState(0);
  const [creditAmount, setCreditAmount] = useState(0);
  const [isPackSelected, setIsPackSelected] = useState(false);
  const [isOptionDisabled, setIsOptionDisabled] = useState(true);
  const [isDefaultOption, setIsDefaultOption] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");

  useEffect(() => {
    const daysByMonths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const subscribeDate = new Date(user.SubscribeDate);
    const plan = user.Plan;
    const pack = user.Package;
    const isActive = !user.isExpired;
    const today = new Date();
    const millisecondsPerDay = 1000 * 60 * 60 * 24; // Number of milliseconds in a day
    const millisecondsDifference = today - subscribeDate;
    const usedDays = Math.floor(millisecondsDifference / millisecondsPerDay); // Convert milliseconds to days
    let totalDays = 0;
    if (isActive) {
      if (plan === "Monthly") {
        setIsOptionDisabled(false);
        totalDays = daysByMonths[subscribeDate.getMonth()];
        const unUsedDays = totalDays - usedDays;
        if (pack === "Basic") {
          setCreditAmount((12.99 * Number(unUsedDays)) / Number(totalDays));
        } else if (pack === "Standard") {
          setCreditAmount((13.99 * Number(unUsedDays)) / Number(totalDays));
        } else if (pack === "Premium") {
          setCreditAmount((14.99 * Number(unUsedDays)) / Number(totalDays));
        } else {
          setCreditAmount(0);
        }
      } else if (plan === "Yearly") {
        setIsOptionDisabled(true);
        totalDays = 365;
        const unUsedDays = totalDays - usedDays;
        if (pack === "Basic") {
          setCreditAmount((12 * 7.99 * Number(unUsedDays)) / Number(totalDays));
        } else if (pack === "Standard") {
          setCreditAmount((12 * 8.99 * Number(unUsedDays)) / Number(totalDays));
        } else if (pack === "Premium") {
          setCreditAmount((12 * 9.99 * Number(unUsedDays)) / Number(totalDays));
        } else {
          setCreditAmount(0);
        }
      } else {
        setCreditAmount(0);
        setIsOptionDisabled(false);
      }
    }
  }, [user]);

  const options = {
    clientSecret: clientSecret,
    appearance: {
      theme: "stripe",
    },
  };

  const availableIcon = (
    <CheckCircleIcon
      style={{ color: "green", fontSize: "25px", paddingTop: "0px" }}
    />
  );
  const unAvailableIcon = (
    <CancelIcon style={{ color: "red", fontSize: "25px", paddingTop: "0px" }} />
  );

  const handleSubscribe = (data) => {
    setSelectedPackage(data.Package);
    setMonthlyAmount(data.MonthlyAmount);
    setYearlyAmount(data.YearlyAmount);
    setSelectedAmount(data.YearlyAmount);
    setSelectedPlan("Yearly");
    setIsDefaultOption(true);
    // Set isPackSelected to true after a delay of 100 milliseconds
    setTimeout(() => {
      setIsPackSelected(true);
      // Scroll to the end of the page after a delay of 100 milliseconds
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 100);
    }, 100);
  };

  const handlePlanSelect = (data) => {
    setSelectedAmount(data.amount);
    setSelectedPlan(data.plan);
    setIsDefaultOption(false);
  };

  console.log(selectedPlan, "Plan");

  const handleUpgrade = (data) => {
    setSelectedPlan(() => ({
      amount: data.amount,
      pack: data.pack,
      plan: data.plan,
    }));
    axios
      .post(
        process.env.REACT_APP_SERVER_URL +
          "/api/paymentmethod/create-payment-intent",
        data
      )
      .then(({ data }) => {
        console.log(data);
        setClientSecret(data.secret);
        setShowDialog(() => true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setShowDialog((prev) => !prev);
  };

  return (
    <div className="summary-container-package">
      <Modal
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          {clientSecret && (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm selectedPlan={selectedPlan} />
            </Elements>
          )}
        </Box>
      </Modal>
      <div className="title-container">
        <h3 style={{ backgroundColor: "#FFE99B" }}>
          ELITE Cashflow Consulting App Packages
        </h3>
      </div>
      <div className="inner-summary-container-package">
        <div className="inner-ind-summary-container-package0">
          <div
            className="title-input-group"
            style={{ width: "100%", textAlign: "center" }}>
            <h2 style={{ textAlign: "center" }}> FEATURES</h2>
          </div>
          <div className="fb-input-group-package1">
            <h2>Dashboard</h2>
            <div className="fb-input-group-package">
              <h2>View/Filter/Print Dashboard</h2>
              <div className="fb_input-package"></div>
            </div>
          </div>
          <div className="fb-input-group-package1">
            <h2>Income</h2>
            <div className="fb-input-group-package">
              <h2>Add/Update/Delete/Filter/Sort/Print Income Records</h2>
              <div className="fb_input-package"></div>
            </div>
            <div className="fb-input-group-package">
              <h2>View/Print Extra Pay Dates for Weekly and Bi-Weekly Pay</h2>
              <div className="fb_input-package"></div>
            </div>
          </div>
          <div className="fb-input-group-package1">
            <h2>Expenses</h2>
            <div className="fb-input-group-package">
              <h2>Add/Update/Delete/Filter/Sort/Print Expenses</h2>
              <div className="fb_input-package"></div>
            </div>
            <div className="fb-input-group-package">
              <h2>Set/Update Joint Expense Contribution</h2>
              <div className="fb_input-package"></div>
            </div>
          </div>
          <div className="fb-input-group-package1">
            <h2>Debts</h2>
            <div className="fb-input-group-package">
              <h2>Add/Update/Delete/Filter/Sort/Print Debts</h2>
              <div className="fb_input-package"></div>
            </div>
          </div>
          <div className="fb-input-group-package1">
            <h2>Retirement</h2>
            <div className="fb-input-group-package">
              <h2>Add/Update/Delete/Filter/Sort/Print Retirement Savings</h2>
              <div className="fb_input-package"></div>
            </div>
          </div>
          <div className="fb-input-group-package1">
            <h2>Savings</h2>
            <div className="fb-input-group-package">
              <h2>Add/Update/Delete/Filter/Sort/Print Savings</h2>
              <div className="fb_input-package"></div>
            </div>
          </div>
          <div className="fb-input-group-package1">
            <h2>Banking</h2>
            <div className="fb-input-group-package">
              <h2>Add/Update/Delete/Filter/Sort/Print Bank transactions</h2>
              <div className="fb_input-package"></div>
            </div>
            <div className="fb-input-group-package">
              <h2>Reconcile Individual Bank Accounts</h2>
              <div className="fb_input-package"></div>
            </div>
          </div>
          <div className="fb-input-group-package1">
            <h2>Final Budget</h2>
            <div className="fb-input-group-package">
              <h2>View/Filter/Print Budget Summary</h2>
              <div className="fb_input-package"></div>
            </div>
            <div className="fb-input-group-package">
              <h2>View/Filter/Print Budget Details</h2>
              <div className="fb_input-package"></div>
            </div>
            <div className="fb-input-group-package">
              <h2>View/Filter/Print Budget checklist</h2>
              <div className="fb_input-package"></div>
            </div>
            <div className="fb-input-group-package">
              <h2>View/Filter/Print Extra FundsTracker</h2>
              <div className="fb_input-package"></div>
            </div>
          </div>
          <div className="fb-input-group-package1">
            <h2>Assets</h2>
            <div className="fb-input-group-package">
              <h2>Add/Update/Delete/Filter/Sort/Print Assets</h2>
              <div className="fb_input-package"></div>
            </div>
          </div>
          <div className="fb-input-group-package1">
            <h2>Net Worth</h2>
            <div className="fb-input-group-package">
              <h2>View/Filter/Print Net Worth</h2>
              <div className="fb_input-package"></div>
            </div>
          </div>
          <div className="fb-input-group-package1">
            <h2>Admin</h2>
            <div className="fb-input-group-package">
              <h2>Update User Profile</h2>
              <div className="fb_input-package"></div>
            </div>
            <div className="fb-input-group-package">
              <h2>Set/Update Main Budget Category Goals</h2>
              <div className="fb_input-package"></div>
            </div>
            <div className="fb-input-group-package">
              <h2>Set/Update Budget Subcategory Goals</h2>
              <div className="fb_input-package"></div>
            </div>
            <div className="fb-input-group-package">
              <h2>Set/Update Expense Category Goals</h2>
              <div className="fb_input-package"></div>
            </div>
            <div className="fb-input-group-package">
              <h2>Set/Update Debt Category Goals</h2>
              <div className="fb_input-package"></div>
            </div>
            <div className="fb-input-group-package">
              <h2>Add/Update Bank Accounts</h2>
              <div className="fb_input-package"></div>
            </div>
            <div className="pay-button"></div>
          </div>
        </div>

        <div className="inner-ind-summary-container-package1">
          <div className="title-input-group">
            <h2>BASIC PACKAGE</h2>
          </div>
          <div className="fb-input-group-package3">
            <h2>Dashboard</h2>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Income</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Expenses</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>S</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Debt</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Retirement</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Savings</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Banking</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{unAvailableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>R</h2>
              <div className="fb_input-package">{unAvailableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>FinalBudget</h2>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Assets</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Net Worth</h2>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{unAvailableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Admin</h2>
            <div className="fb-input-group-package2">
              <h2>UP</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>MC</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>SC</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>EC</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>DC</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>BA</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="pay-button">
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  backgroundColor: "#FFC7CE",
                  color: "#000",
                  fontSize: 14,
                }}
                disabled={
                  user.Package === "Standard" ||
                  user.Package === "Premium" ||
                  user.Package === "Basic"
                }
                startIcon={<PaidIcon />}
                onClick={() =>
                  handleSubscribe({
                    MonthlyAmount: 12.99,
                    YearlyAmount: 95.88,
                    Package: "Basic",
                  })
                }>
                From $7.99pm Subscribe
              </Button>
            </div>
          </div>
        </div>
        <div className="inner-ind-summary-container-package2">
          <div className="title-input-group">
            <h2>STANDARD PACKAGE</h2>
          </div>
          <div className="fb-input-group-package3">
            <h2>Dashboard</h2>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Income</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Expenses</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>S</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Debt</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Retirement</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Savings</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Banking</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>R</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>FinalBudget</h2>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Assets</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Net Worth</h2>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{unAvailableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Admin</h2>
            <div className="fb-input-group-package2">
              <h2>UP</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>MC</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>SC</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>EC</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>DC</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>BA</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="pay-button">
              <Button
                variant="contained"
                color="warning"
                sx={{
                  backgroundColor: "#FFEB9C",
                  color: "#000",
                  fontSize: 14,
                }}
                disabled={
                  user.Package === "Premium" || user.Package === "Standard"
                }
                onClick={() =>
                  handleSubscribe({
                    MonthlyAmount: 13.99,
                    YearlyAmount: 107.88,
                    Package: "Standard",
                  })
                }
                startIcon={<PaidIcon />}>
                From $8.99PM Subscribe
              </Button>
            </div>
          </div>
        </div>
        <div className="inner-ind-summary-container-package3">
          <div className="title-input-group">
            <h2>PREMIUM PACKAGE</h2>
          </div>
          <div className="fb-input-group-package3">
            <h2>Dashboard</h2>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Income</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Expenses</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>S</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Debt</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Retirement</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Savings</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Banking</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>R</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>FinalBudget</h2>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Assets</h2>
            <div className="fb-input-group-package2">
              <h2>A</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Net Worth</h2>
            <div className="fb-input-group-package2">
              <h2>V</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
          </div>
          <div className="fb-input-group-package3">
            <h2>Admin</h2>
            <div className="fb-input-group-package2">
              <h2>UP</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>MC</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>SC</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>EC</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>DC</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="fb-input-group-package2">
              <h2>BA</h2>
              <div className="fb_input-package">{availableIcon}</div>
            </div>
            <div className="pay-button">
              <Button
                variant="contained"
                color="success"
                sx={{
                  backgroundColor: "#C6EFCE",
                  color: "#000",
                  fontSize: 14,
                }}
                startIcon={<PaidIcon />}
                disabled={user.Package === "Premium"}
                onClick={() =>
                  handleSubscribe({
                    MonthlyAmount: 14.99,
                    YearlyAmount: 119.88,
                    Package: "Premium",
                  })
                }>
                From $9.99pm Subscribe
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="inner-summary-container-package">
        <div className="inner-ind-summary-container-package0"></div>
        {isPackSelected && (
          <div className="inner-ind-summary-container-package0">
            <div className="bottom-summary-container-package">
              <div className="package-input-group">
                <h2>Selected Package</h2>
                <h2>{selectedPackage}</h2>
              </div>
              <div className="package-input-group">
                <h2>
                  <RadioButtonComponent
                    label="Month-to-Month Plan"
                    disabled={isOptionDisabled}
                    name="default"
                    cssClass="e-warning"
                    onChange={() =>
                      handlePlanSelect({
                        amount: `${monthlyAmount}`,
                        plan: "Monthly",
                      })
                    }
                  />
                </h2>
                <h2>${monthlyAmount} Monthly</h2>
              </div>
              <div className="package-input-group">
                <h2>
                  <RadioButtonComponent
                    label="Year-to-Year Plan"
                    checked={isDefaultOption}
                    name="default"
                    cssClass="e-warning"
                    onChange={() =>
                      handlePlanSelect({
                        amount: `${yearlyAmount}`,
                        plan: "Yearly",
                      })
                    }
                  />
                </h2>
                <h2>
                  ${yearlyAmount} Yearly ( ~ $
                  {(Number(yearlyAmount) / 12).toFixed(2)}pm)
                </h2>
              </div>
            </div>
            <div className="bottom-summary-container-package">
              <div className="package-input-group">
                <h2>Selected Package Amount</h2>
                <div className="package_input">
                  <NumericTextBoxComponent
                    value={Number(selectedMount)}
                    format="c2"
                    showSpinButton={false}
                    disabled={false}
                    style={{
                      pointerEvents: "none",
                    }}
                  />
                </div>
              </div>
              <div className="package-input-group">
                <h2>Credit for Amount not Used in the Current Package</h2>
                <div className="package_input">
                  <NumericTextBoxComponent
                    value={Number(creditAmount)}
                    format="c2"
                    showSpinButton={false}
                    disabled={false}
                    style={{
                      pointerEvents: "none",
                    }}
                  />
                </div>
              </div>
              <div className="package-input-group">
                <h2>
                  Total Amount To Pay (Selected Package Amount - Credit Amount)
                </h2>
                <div className="package_input">
                  <NumericTextBoxComponent
                    value={Number(selectedMount) - Number(creditAmount)}
                    format="c2"
                    showSpinButton={false}
                    disabled={false}
                    style={{
                      pointerEvents: "none",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="bottom-summary-container-package">
              <div className="pay-button">
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    backgroundColor: "lightblue",
                    color: "#000",
                    fontSize: 16,
                  }}
                  startIcon={<PaidIcon />}
                  onClick={() =>
                    handleUpgrade({
                      amount: `${Number(selectedMount) - Number(creditAmount)}`,
                      pack: `${selectedPackage}`,
                      plan: `${selectedPlan}`,
                    })
                  }>
                  UPGRADE NOW ($
                  {(Number(selectedMount) - Number(creditAmount)).toFixed(2)})
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Package;
