import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useSavingDataFetch = () => {
  const user = useUserStore((state) => state.user);
  const [savingData, setSavingData] = useState([]);
  const [isSavingLoaded, setIsSavingLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchSavingData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          `/api/saving/rootuserid/${user?.RootID}`
      );
      setIsSavingLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching Saving data:", error);
      setIsSavingLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchSavingData().then((SavingInitialData) => {
        setSavingData(SavingInitialData);
      });
    else setIsSavingLoaded(true);
  }, [user]);

  // Return an object containing all the fetched data
  return {
    savingData,
    isSavingLoaded,
  };
};

export default useSavingDataFetch;
