import React, { useState, useEffect } from "react";
import "./Login.css";
import logo from "../../header/logo.jpg";
import { useNavigate } from "react-router-dom";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockResetIcon from "@mui/icons-material/LockReset";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";
import axios from "../../../config/axios";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import UserDialog from "../admin/UserDialog";
// import { setUser } from "../../../app/slices/user";
import { GoogleLogin } from "@react-oauth/google";

function Login() {
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [changePassword, setChangePassword] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [isAddDialogVisible, setIsAddDialogVisible] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const setUser = useUserStore((state) => state.setUser);
  const setJwt = useUserStore((state) => state.setJwt);

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
  }
  const newCurrentDate = formatDate(new Date());
  const [selectedID, setSelectedID] = useState("");
  const [selectedFullName, setSelectedFullName] = useState("");
  const [selectedSelfDOB, setSelectedSelfDOB] = useState("");
  const [selectedPartnerDOB, setSelectedPartnerDOB] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedHomeAddress, setSelectedHomeAddress] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedPassword, setSelectedPassword] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedDateFormat, setSelectedDateFormat] = useState("");

  const [newRootID, setNewRootID] = useState("");
  const [newFullName, setNewFullName] = useState("");
  const [newSelfDOB, setNewSelfDOB] = useState("");
  const [newPartnerDOB, setNewPartnerDOB] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newHomeAddress, setNewHomeAddress] = useState("");
  const [newCity, setNewCity] = useState("");
  const [newState, setNewState] = useState("");
  const [newCountry, setNewCountry] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [newType, setNewType] = useState("");
  const [newCurrency, setNewCurrency] = useState("");
  const [newDateFormat, setNewDateFormat] = useState("");

  useEffect(() => {
    let loadingToastId;
    if (!isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataChanged]);

  const userNewData = {
    RootID: newRootID,
    FullName: newFullName,
    SelfDOB: newSelfDOB,
    PartnerDOB: newPartnerDOB,
    Email: newEmail,
    HomeAddress: newHomeAddress,
    City: newCity,
    State: newState,
    Country: newCountry,
    Password: newPassword,
    Status: newStatus,
    Type: newType,
    Currency: newCurrency,
    DateFormat: newDateFormat,
    UpdatedOn: newCurrentDate,
  };

  const handleLogin = async () => {
    try {
      setError(""); // Clear any previous error message
      // Perform API call to check if the username and password match
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/auth/login",
        { email, password }
      );

      if (response.status === 200) {
        const { data } = response;

        // Set user details in Redux store
        setUser(data.items);
        setJwt(data.jwt);
        // If user credentials match, navigate to home page
        navigate("/");
        toast.success("Welcome " + data.items?.FullName);
        setLoginSuccess(true);
      } else {
        setError("Invalid Email or Password"); // Set an error message
        setLoginSuccess(false); // Set login success to false
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("An error occurred while fetching user data."); // Set an error message
      setLoginSuccess(false); // Set login success to false
    }
  };

  const handleGoogleLogin = async (credentials) => {
    try {
      setError(""); // Clear any previous error message
      // Perform API call to check if the username and password match
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/auth/login-google",
        { token: credentials.credential }
      );

      if (response.status === 200) {
        const { data } = response;

        // Set user details in Redux store
        setUser(data.items);
        setJwt(data.jwt);
        // If user credentials match, navigate to home page
        navigate("/");
        toast.success("Welcome " + data.items?.FullName);
        setLoginSuccess(true);
      } else {
        setError("Invalid Email or Password"); // Set an error message
        setLoginSuccess(false); // Set login success to false
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("An error occurred while fetching user data."); // Set an error message
      setLoginSuccess(false); // Set login success to false
    }
  };

  const handleForgotPassword = async () => {
    try {
      // Perform API call to check if the username and email match
      setError(""); // Clear any previous error message
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/rootuser"
      );
      if (response.ok) {
        const responseData = await response.json();
        const users = responseData.items;
        const user = users.find((item) => item.Email === email);
        if (user) {
          // Simulate sending an email with the password (replace with actual email logic)
          setForgotPasswordSuccess(true); // Set forgot password success to true
          alert(`Password: ${user.Password} has been sent to ${user?.Email}`);
        } else {
          setError("Invalid email."); // Set an error message
          setForgotPasswordSuccess(false); // Set forgot password success to false
        }
      } else {
        setError("Failed to verify the user?."); // Set an error message
        setForgotPasswordSuccess(false); // Set forgot password success to false
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("An error occurred while fetching user data."); // Set an error message
      setForgotPasswordSuccess(false); // Set forgot password success to false
    }
  };

  const handleChangePassword = async () => {
    try {
      // Check if newPassword matches confirmNewPassword
      if (newPassword !== confirmNewPassword) {
        setError("New password and confirm password do not match.");
        setChangePasswordSuccess(false);
        return;
      }

      // Clear any previous error message
      setError("");

      // Perform API call to fetch user data
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/user"
      );
      if (response.ok) {
        const responseData = await response.json();
        const users = responseData.items;
        const user = users.find(
          (item) => item.Email === email && item.Password === password
        );
        if (user) {
          // Update the user's password to newPassword
          user.Password = newPassword;

          // Perform API call to update user data with the new password
          const updateUserResponse = await axios.put(
            process.env.REACT_APP_SERVER_URL + `/api/user/${user?.id}`,
            user
          );

          if (updateUserResponse.ok) {
            setChangePasswordSuccess(true); // Set change password success to true
          } else {
            setError("Failed to update password."); // Set an error message
            setChangePasswordSuccess(false); // Set change password success to false
          }
        } else {
          setError("Invalid email or password."); // Set an error message
          setChangePasswordSuccess(false); // Set change password success to false
        }
      } else {
        setError("Failed to fetch user data."); // Set an error message
        setChangePasswordSuccess(false); // Set change password success to false
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("An error occurred while fetching user data."); // Set an error message
      setChangePasswordSuccess(false); // Set change password success to false
    }
  };

  const handleAddDialogClose = () => {
    setIsAddDialogVisible(false);
  };

  const handleAddButtonClick = () => {
    setIsAddDialogVisible(!isAddDialogVisible);
    setSelectedID("");
    setSelectedFullName("");
    setSelectedSelfDOB("");
    setSelectedPartnerDOB("");
    setSelectedEmail("");
    setSelectedHomeAddress("");
    setSelectedCity("New York City");
    setSelectedState("New York");
    setSelectedCountry("United States");
    setSelectedPassword("");
    setSelectedCurrency("$");
    setSelectedDateFormat("MMM dd, yyyy");

    setNewRootID(null);
    setNewFullName("");
    setNewSelfDOB(null);
    setNewPartnerDOB(null);
    setNewEmail("");
    setNewHomeAddress("");
    setNewCity("New York City");
    setNewState("New York");
    setNewCountry("United States");
    setNewPassword("");
    setNewStatus("Active");
    setNewType("Root");
    setNewCurrency("$");
    setNewDateFormat("MMM dd, yyyy");
  };

  const handleAddRecordButton = async () => {
    // Make a POST request to add the Record record
    setIsDataChanged(false);
    try {
      const RecordResponse = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/rootuser",
        userNewData
      );
      console.log("Record response:", RecordResponse.data);
      if (RecordResponse.data.status === "success") {
        toast.success("Added successfully.");
        // Get the UPDATED DATA by making a GET request
        const RecordResponseR = await axios.get(
          process.env.REACT_APP_SERVER_URL + `/api/rootuser/email/${newEmail}`
        );
        const Records = RecordResponseR.data.items;
        Records.sort((a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn));
      } else {
        console.error(
          "Error adding the record record:",
          RecordResponse.data.message
        );
        toast.error("Error adding the record");
      }
    } catch (error) {
      console.error("Error adding the record:", error);
      toast.error("Error adding the record");
    }
    setIsDataChanged(true);
  };

  let addButtons = [
    {
      buttonModel: {
        content: "Create",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        handleAddRecordButton(selectedID);
        setIsAddDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsAddDialogVisible(false);
      },
    },
  ];

  return (
    <div className="login-container">
      {!forgotPassword && !changePassword && (
        <div className="login-form">
          <img src={logo} alt="LOGO" />
          <h2>Login</h2>
          {loginSuccess && <p className="success-message">Login successful!</p>}
          {error && <p className="error-message">{error}</p>}
          <div className="input-group">
            <input
              className="username"
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoFocus // Set autoFocus attribute here
            />
          </div>
          <div className="input-group">
            <input
              className="password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button className="login-button" onClick={handleLogin}>
            <LockOpenIcon style={{ marginRight: "8px" }} /> Login
          </button>
          <button
            className="change-button2"
            //onClick={() => setChangePassword(true)}
            >
            Forgot Password?
          </button>
          <button className="create-button2" 
          //onClick={handleAddButtonClick}
          >
            Don't have an Account? Click Here!
          </button>
          <GoogleLogin
            //onSuccess={handleGoogleLogin}
            onError={() => {
              console.log("Login Failed");
            }}
          />
        </div>
      )}

      {forgotPassword && (
        <div className="forgot-password">
          <img src={logo} alt="LOGO" />
          <h2>Forgot Password</h2>
          {forgotPasswordSuccess && (
            <p className="success-message">
              Password reset requested. Check your email!
            </p>
          )}
          {error && <p className="error-message">{error}</p>}
          <div className="input-group">
            <input
              className="username"
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoFocus // Set autoFocus attribute here
            />
          </div>
          <div className="input-group">
            <input
              className="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button className="button" onClick={handleForgotPassword}>
            Request Password
          </button>
          <button className="button" onClick={() => setForgotPassword(false)}>
            Back to Login
          </button>
        </div>
      )}
      {changePassword && (
        <div className="change-password">
          <img src={logo} alt="LOGO" />
          <h2>Change Password</h2>
          {changePasswordSuccess && (
            <p className="success-message">Password changed successfully!</p>
          )}
          {error && <p className="error-message">{error}</p>}
          <div className="input-group">
            <input
              className="username"
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoFocus // Set autoFocus attribute here
            />
          </div>
          <div className="input-group">
            <input
              className="password"
              type="password"
              placeholder="Current Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <input
              className="password"
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <input
              className="password"
              type="password"
              placeholder="Confirm New Password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              required
            />
          </div>
          <button className="change-button" onClick={handleChangePassword}>
            <LockResetIcon style={{ marginRight: "5px" }} />
            Change Password
          </button>
          <button
            className="login-button2"
            onClick={() => setChangePassword(false)}>
            Back to Login
          </button>
        </div>
      )}

      <div>
        {isAddDialogVisible && (
          <DialogComponent
            visible={isAddDialogVisible}
            width="60%"
            height="780px"
            header="Create an Account"
            allowDragging={true}
            showCloseIcon={true}
            close={handleAddDialogClose}
            buttons={addButtons}>
            <UserDialog
              selectedFullName={selectedFullName}
              selectedSelfDOB={selectedSelfDOB}
              selectedPartnerDOB={selectedPartnerDOB}
              selectedEmail={selectedEmail}
              selectedHomeAddress={selectedHomeAddress}
              selectedCity={selectedCity}
              selectedState={selectedState}
              selectedCountry={selectedCountry}
              selectedPassword={selectedPassword}
              selectedCurrency={selectedCurrency}
              selectedDateFormat={selectedDateFormat}
              setNewFullName={setNewFullName}
              setNewSelfDOB={setNewSelfDOB}
              setNewPartnerDOB={setNewPartnerDOB}
              setNewEmail={setNewEmail}
              setNewHomeAddress={setNewHomeAddress}
              setNewCity={setNewCity}
              setNewState={setNewState}
              setNewCountry={setNewCountry}
              setNewPassword={setNewPassword}
              setNewCurrency={setNewCurrency}
              setNewDateFormat={setNewDateFormat}
            />
          </DialogComponent>
        )}
      </div>
    </div>
  );
}
export default Login;
