import React from 'react'
import '../Sidebar.css'
import BudgetOption from './BudgetOption';
import ScoreIcon from '@mui/icons-material/Score';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FlutterDashRoundedIcon from '@mui/icons-material/FlutterDashRounded';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import { NavLink } from 'react-router-dom';

function Budget() {
  return (
    <div className='sidebar'>
        <NavLink to="budgetoverview">{<BudgetOption Icon = {FlutterDashRoundedIcon} title="Budget Summary"/>}</NavLink>
        <NavLink to="finalbudget">{<BudgetOption Icon = {ScoreIcon} title="Budget Detail"/>}</NavLink>
        <NavLink to="checklist">{<BudgetOption Icon = {FactCheckIcon} title= "Checklist"/>}</NavLink>
        <NavLink to="fundstracker">{<BudgetOption Icon = {MonitorHeartIcon} title= "Extra Funds Tracker"/>}</NavLink>               
    </div>
  )
}
export default Budget