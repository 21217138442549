import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
  Reorder,
  Resize,
  PdfExport,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import { getValue } from "@syncfusion/ej2-base";
import "../../../../node_modules/@syncfusion/ej2/bootstrap4.css";
import "../../../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-react-popups/styles/material.css";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import axios from "../../../config/axios";
import "./FinalBudget.css";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";
import useRetirementDataFetch from "../../shared/useRetirementDataFetch";
import useSavingDataFetch from "../../shared/useSavingDataFetch";
import useDebtDataFetch from "../../shared/useDebtDataFetch";
import useExpenseDataFetch from "../../shared/useExpenseDataFetch";
import useFundTrackerDataFetch from "../../shared/useFundTrackerDataFetch";
import useIncomeDataFetch from "../../shared/useIncomeDataFetch";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import FundTrackerDialog from "./FundTrackerDialog";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { dropdownTemplate } from "../../shared/HeaderTempate";
import { CheckFundsTrackerFields } from "../../../utils/requiredFields";

function FundsTracker() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const userPackage = user?.Package;
  const { retirementData, isRetirementLoaded } = useRetirementDataFetch();
  const { savingData, isSavingLoaded } = useSavingDataFetch();
  const { debtData, isDebtLoaded } = useDebtDataFetch();
  const { expenseData, isExpenseLoaded } = useExpenseDataFetch();
  const { fundTrackerData, isFundTrackerLoaded } = useFundTrackerDataFetch();
  const { incomeData, isIncomeLoaded } = useIncomeDataFetch();

  const [excessTotal, setExcessTotal] = useState(0);
  const [fundTrackerTotal, setFundTrackerTotal] = useState(0);

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [updatedFundTrackerData, setUpdatedFundTrackerData] = useState([]);
  const usedCurrency = user?.Currency + " #,###.00";
  const [ownerFilter, setOwnerFilter] = useState("Household");
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonthNum = today.getMonth() + 1;
  const currentMonthName = today.toLocaleString("default", { month: "long" });
  const [yearFilter, setYearFilter] = useState(currentYear);
  const [monthFilter, setMonthFilter] = useState(currentMonthNum);

  const GridRef = useRef(null); // Create the treeGridRef reference
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isAddDialogVisible, setIsAddDialogVisible] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
  }
  const newCurrentDate = formatDate(new Date());

  const [yearData, setYearData] = useState("");
  const monthData = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleAccountOwnerChange = async (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setOwnerFilter(e.value);
      const accountOwner = e.value;
      let finalRetirement;
      let finalSaving;
      let finaldebt;
      let finalExpenses;
      let finalFundTracker;
      let finalIncome;
      if (user?.Type !== "Root") {
        finalRetirement = retirementData.filter(
          (item) => item.UserID === user?.id
        );
        finalSaving = savingData.filter((item) => item.UserID === user?.id);
        finaldebt = debtData.filter((item) => item.UserID === user?.id);
        finalExpenses = expenseData.filter((item) => item.UserID === user?.id);
        finalFundTracker = updatedFundTrackerData.filter(
          (item) => item.UserID === user?.id
        );
        finalIncome = incomeData.filter((item) => item.UserID === user?.id);
      } else {
        finalRetirement = retirementData;
        finalSaving = savingData;
        finaldebt = debtData;
        finalExpenses = expenseData;
        finalFundTracker = updatedFundTrackerData;
        finalIncome = incomeData;
      }

      let filteredRetirement;
      let filteredSavings;
      let filteredDebts;
      let filteredExpenses;
      let filteredFundTracker;
      let filteredIncome;
      if (
        accountOwner === "Self" ||
        accountOwner === "Partner" ||
        accountOwner === "Joint"
      ) {
        filteredRetirement = finalRetirement.filter(
          (item) => item.AccountOwner === accountOwner
        );
        filteredSavings = finalSaving.filter(
          (item) => item.AccountOwner === accountOwner
        );
        filteredDebts = finaldebt.filter(
          (item) => item.AccountOwner === accountOwner
        );
        filteredExpenses = finalExpenses.filter(
          (item) => item.AccountOwner === accountOwner
        );
        filteredFundTracker = finalFundTracker.filter(
          (item) => item.AccountOwner === accountOwner
        );
        filteredIncome = finalIncome.filter(
          (item) => item.AccountOwner === accountOwner
        );
      } else {
        filteredRetirement = finalRetirement;
        filteredSavings = finalSaving;
        filteredDebts = finaldebt;
        filteredExpenses = finalExpenses.filter(
          (item) => item.BudgetItem !== "Joint Contribution"
        );
        filteredFundTracker = finalFundTracker;
        filteredIncome = finalIncome;
      }

      let filteredYearData;
      if (yearFilter !== "" && yearFilter !== "All") {
        filteredYearData = filteredFundTracker.filter((item) => {
          const transDate = new Date(item.RecDate);
          return transDate.getFullYear() === parseInt(yearFilter);
        });
      } else {
        filteredYearData = filteredFundTracker;
      }

      let filteredMonthData;
      if (monthFilter !== "" && monthFilter !== "All") {
        filteredMonthData = filteredYearData.filter((item) => {
          const transDate = new Date(item.RecDate);
          return transDate.getMonth() + 1 === parseInt(monthFilter);
        });
      } else {
        filteredMonthData = filteredYearData;
      }

      //GET EXPENSE DATA
      const totalExpenseSum = filteredExpenses?.reduce(
        (accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      //GET DEBT DATA
      const totalDebtSum = filteredDebts?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);

      const totalRetirementNet = filteredRetirement?.reduce(
        (accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          const contribution = record?.Contribution || "";
          // Only add the amount if contribution is "Yes"
          if (contribution === "Yes") {
            return accumulator + Number(monthlyPayment) + Number(adjustments);
          }
          return accumulator;
        },
        0
      );

      //GET SAVING DATA
      const totalSavingSum = filteredSavings?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);

      //GET FUND TRACLER DATA
      const totalFundTrackerSum = filteredMonthData?.reduce(
        (accumulator, record) => {
          const amount = record?.Amount || 0;
          return accumulator + Number(amount);
        },
        0
      );
      setFundTrackerTotal(totalFundTrackerSum);

      const totalMonthlyNetIncome = filteredIncome.reduce(
        (accumulator, record) => {
          const netPay = record.NetPay || 0; // Handle the case where NetPay is undefined
          const payFrequency = record.PayFrequency || ""; // Handle the case where PayFrequency is undefined
          let monthlyNetIncome = 0;
          // Determine monthlyIncome based on PayFrequency
          switch (payFrequency) {
            case "Yearly":
              monthlyNetIncome = netPay / 12;
              break;
            case "Monthly":
              monthlyNetIncome = netPay;
              break;
            case "Semi-Monthly":
              monthlyNetIncome = netPay * 2;
              break;
            case "Weekly":
              monthlyNetIncome = netPay * 4;
              break;
            case "Bi-Weekly":
              monthlyNetIncome = netPay * 2;
              break;
            default:
              monthlyNetIncome = 0;
          }
          // Add monthlyIncome to the accumulator
          return accumulator + Number(monthlyNetIncome);
        },
        0
      ); // Initialize accumulator with 0

      setExcessTotal(
        Number(totalMonthlyNetIncome) -
          (Number(totalDebtSum) +
            Number(totalExpenseSum) +
            Number(totalSavingSum) +
            Number(totalRetirementNet))
      );

      setGridData(filteredMonthData);
      setIsDataChanged(true);
    }
  };

  const handleYearChange = async (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      const yearName = e.value;
      setYearFilter(e.value);
      let finalRetirement;
      let finalSaving;
      let finaldebt;
      let finalExpenses;
      let finalFundTracker;
      let finalIncome;
      if (user?.Type !== "Root") {
        finalRetirement = retirementData.filter(
          (item) => item.UserID === user?.id
        );
        finalSaving = savingData.filter((item) => item.UserID === user?.id);
        finaldebt = debtData.filter((item) => item.UserID === user?.id);
        finalExpenses = expenseData.filter((item) => item.UserID === user?.id);
        finalFundTracker = updatedFundTrackerData.filter(
          (item) => item.UserID === user?.id
        );
        finalIncome = incomeData.filter((item) => item.UserID === user?.id);
      } else {
        finalRetirement = retirementData;
        finalSaving = savingData;
        finaldebt = debtData;
        finalExpenses = expenseData;
        finalFundTracker = updatedFundTrackerData;
        finalIncome = incomeData;
      }

      let filteredRetirement;
      let filteredSavings;
      let filteredDebts;
      let filteredExpenses;
      let filteredFundTracker;
      let filteredIncome;
      if (
        ownerFilter === "Self" ||
        ownerFilter === "Partner" ||
        ownerFilter === "Joint"
      ) {
        filteredRetirement = finalRetirement.filter(
          (item) => item.AccountOwner === ownerFilter
        );
        filteredSavings = finalSaving.filter(
          (item) => item.AccountOwner === ownerFilter
        );
        filteredDebts = finaldebt.filter(
          (item) => item.AccountOwner === ownerFilter
        );
        filteredExpenses = finalExpenses.filter(
          (item) => item.AccountOwner === ownerFilter
        );
        filteredFundTracker = finalFundTracker.filter(
          (item) => item.AccountOwner === ownerFilter
        );
        filteredIncome = finalIncome.filter(
          (item) => item.AccountOwner === ownerFilter
        );
      } else {
        filteredRetirement = finalRetirement;
        filteredSavings = finalSaving;
        filteredDebts = finaldebt;
        filteredExpenses = finalExpenses.filter(
          (item) => item.BudgetItem !== "Joint Contribution"
        );
        filteredFundTracker = finalFundTracker;
        filteredIncome = finalIncome;
      }

      let filteredYearData;
      if (yearName !== "") {
        filteredYearData = filteredFundTracker.filter((item) => {
          const transDate = new Date(item.RecDate);
          return transDate.getFullYear() === parseInt(yearName);
        });
      } else {
        filteredYearData = filteredFundTracker;
      }

      let filteredMonthData;
      if (monthFilter !== "" && monthFilter !== "All") {
        filteredMonthData = filteredYearData.filter((item) => {
          const transDate = new Date(item.RecDate);
          return transDate.getMonth() + 1 === parseInt(monthFilter);
        });
      } else {
        filteredMonthData = filteredYearData;
      }

      //GET EXPENSE DATA
      const totalExpenseSum = filteredExpenses?.reduce(
        (accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      //GET DEBT DATA
      const totalDebtSum = filteredDebts?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);

      const totalRetirementNet = filteredRetirement?.reduce(
        (accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          const contribution = record?.Contribution || "";
          // Only add the amount if contribution is "Yes"
          if (contribution === "Yes") {
            return accumulator + Number(monthlyPayment) + Number(adjustments);
          }
          return accumulator;
        },
        0
      );

      //GET SAVING DATA
      const totalSavingSum = filteredSavings?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);

      const totalMonthlyNetIncome = filteredIncome.reduce(
        (accumulator, record) => {
          const netPay = record.NetPay || 0; // Handle the case where NetPay is undefined
          const payFrequency = record.PayFrequency || ""; // Handle the case where PayFrequency is undefined
          let monthlyNetIncome = 0;
          // Determine monthlyIncome based on PayFrequency
          switch (payFrequency) {
            case "Yearly":
              monthlyNetIncome = netPay / 12;
              break;
            case "Monthly":
              monthlyNetIncome = netPay;
              break;
            case "Semi-Monthly":
              monthlyNetIncome = netPay * 2;
              break;
            case "Weekly":
              monthlyNetIncome = netPay * 4;
              break;
            case "Bi-Weekly":
              monthlyNetIncome = netPay * 2;
              break;
            default:
              monthlyNetIncome = 0;
          }
          // Add monthlyIncome to the accumulator
          return accumulator + Number(monthlyNetIncome);
        },
        0
      ); // Initialize accumulator with 0
      setExcessTotal(
        Number(totalMonthlyNetIncome) -
          (Number(totalDebtSum) +
            Number(totalExpenseSum) +
            Number(totalSavingSum) +
            Number(totalRetirementNet))
      );

      //GET FUND TRACLER DATA
      const totalFundTrackerSum = filteredMonthData?.reduce(
        (accumulator, record) => {
          const amount = record?.Amount || 0;
          return accumulator + Number(amount);
        },
        0
      );
      setFundTrackerTotal(totalFundTrackerSum);
      setGridData(filteredMonthData);
      setIsDataChanged(true);
    }
  };

  function getMonthNumber(monthName) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const normalizedMonthName = monthName.trim().toLowerCase();
    const monthIndex = months.findIndex(
      (month) => month.toLowerCase() === normalizedMonthName
    );
    // Adding 1 to convert from 0-based index to 1-based month number
    return monthIndex !== -1 ? monthIndex + 1 : null;
  }

  const handleMonthChange = async (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      const monthName = e.value;
      let selMonthNumber = "";

      const validMonths = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (validMonths.includes(monthName)) {
        const monthNumber = getMonthNumber(monthName);
        setMonthFilter(monthNumber);
        selMonthNumber = monthNumber;
      } else {
        setMonthFilter("");
      }

      let finalRetirement;
      let finalSaving;
      let finaldebt;
      let finalExpenses;
      let finalFundTracker;
      let finalIncome;
      if (user?.Type !== "Root") {
        finalRetirement = retirementData.filter(
          (item) => item.UserID === user?.id
        );
        finalSaving = savingData.filter((item) => item.UserID === user?.id);
        finaldebt = debtData.filter((item) => item.UserID === user?.id);
        finalExpenses = expenseData.filter((item) => item.UserID === user?.id);
        finalFundTracker = updatedFundTrackerData.filter(
          (item) => item.UserID === user?.id
        );
        finalIncome = incomeData.filter((item) => item.UserID === user?.id);
      } else {
        finalRetirement = retirementData;
        finalSaving = savingData;
        finaldebt = debtData;
        finalExpenses = expenseData;
        finalFundTracker = updatedFundTrackerData;
        finalIncome = incomeData;
      }

      let filteredRetirement;
      let filteredSavings;
      let filteredDebts;
      let filteredExpenses;
      let filteredFundTracker;
      let filteredIncome;
      if (
        ownerFilter === "Self" ||
        ownerFilter === "Partner" ||
        ownerFilter === "Joint"
      ) {
        filteredRetirement = finalRetirement.filter(
          (item) => item.AccountOwner === ownerFilter
        );
        filteredSavings = finalSaving.filter(
          (item) => item.AccountOwner === ownerFilter
        );
        filteredDebts = finaldebt.filter(
          (item) => item.AccountOwner === ownerFilter
        );
        filteredExpenses = finalExpenses.filter(
          (item) => item.AccountOwner === ownerFilter
        );
        filteredFundTracker = finalFundTracker.filter(
          (item) => item.AccountOwner === ownerFilter
        );
        filteredIncome = finalIncome.filter(
          (item) => item.AccountOwner === ownerFilter
        );
      } else {
        filteredRetirement = finalRetirement;
        filteredSavings = finalSaving;
        filteredDebts = finaldebt;
        filteredExpenses = finalExpenses.filter(
          (item) => item.BudgetItem !== "Joint Contribution"
        );
        filteredFundTracker = finalFundTracker;
        filteredIncome = finalIncome;
      }

      let filteredYearData;
      if (yearFilter !== "" && yearFilter !== "All") {
        filteredYearData = filteredFundTracker.filter((item) => {
          const transDate = new Date(item.RecDate);
          return transDate.getFullYear() === parseInt(yearFilter);
        });
      } else {
        filteredYearData = filteredFundTracker;
      }

      let filteredMonthData;
      if (selMonthNumber !== "") {
        filteredMonthData = filteredYearData.filter((item) => {
          const transDate = new Date(item.RecDate);
          return transDate.getMonth() + 1 === parseInt(selMonthNumber);
        });
      } else {
        filteredMonthData = filteredYearData;
      }

      //GET EXPENSE DATA
      const totalExpenseSum = filteredExpenses?.reduce(
        (accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      //GET DEBT DATA
      const totalDebtSum = filteredDebts?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);

      const totalRetirementNet = filteredRetirement?.reduce(
        (accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          const contribution = record?.Contribution || "";
          // Only add the amount if contribution is "Yes"
          if (contribution === "Yes") {
            return accumulator + Number(monthlyPayment) + Number(adjustments);
          }
          return accumulator;
        },
        0
      );

      //GET SAVING DATA
      const totalSavingSum = filteredSavings?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);

      const totalMonthlyNetIncome = filteredIncome.reduce(
        (accumulator, record) => {
          const netPay = record.NetPay || 0; // Handle the case where NetPay is undefined
          const payFrequency = record.PayFrequency || ""; // Handle the case where PayFrequency is undefined
          let monthlyNetIncome = 0;
          // Determine monthlyIncome based on PayFrequency
          switch (payFrequency) {
            case "Yearly":
              monthlyNetIncome = netPay / 12;
              break;
            case "Monthly":
              monthlyNetIncome = netPay;
              break;
            case "Semi-Monthly":
              monthlyNetIncome = netPay * 2;
              break;
            case "Weekly":
              monthlyNetIncome = netPay * 4;
              break;
            case "Bi-Weekly":
              monthlyNetIncome = netPay * 2;
              break;
            default:
              monthlyNetIncome = 0;
          }
          // Add monthlyIncome to the accumulator
          return accumulator + Number(monthlyNetIncome);
        },
        0
      ); // Initialize accumulator with 0
      setExcessTotal(
        Number(totalMonthlyNetIncome) -
          (Number(totalDebtSum) +
            Number(totalExpenseSum) +
            Number(totalSavingSum) +
            Number(totalRetirementNet))
      );

      //GET FUND TRACLER DATA
      const totalFundTrackerSum = filteredMonthData?.reduce(
        (accumulator, record) => {
          const amount = record?.Amount || 0;
          return accumulator + Number(amount);
        },
        0
      );
      setFundTrackerTotal(totalFundTrackerSum);
      setGridData(filteredMonthData);
      setIsDataChanged(true);
    }
  };

  useEffect(() => {
    if (
      isExpenseLoaded &&
      isRetirementLoaded &&
      isSavingLoaded &&
      isDebtLoaded &&
      isFundTrackerLoaded &&
      isIncomeLoaded
    ) {
      let finalRetirement;
      let finalSaving;
      let finaldebt;
      let finalExpenses;
      let finalFundTracker;
      let finalIncome;
      if (user?.Type !== "Root") {
        finalRetirement = retirementData.filter(
          (item) => item.UserID === user?.id
        );
        finalSaving = savingData.filter((item) => item.UserID === user?.id);
        finaldebt = debtData.filter((item) => item.UserID === user?.id);
        finalExpenses = expenseData.filter((item) => item.UserID === user?.id);
        finalFundTracker = fundTrackerData.filter(
          (item) => item.UserID === user?.id
        );
        finalIncome = incomeData.filter((item) => item.UserID === user?.id);
      } else {
        finalRetirement = retirementData;
        finalSaving = savingData;
        finaldebt = debtData;
        finalExpenses = expenseData;
        finalFundTracker = fundTrackerData;
        finalIncome = incomeData;
      }

      let filteredRetirement;
      let filteredSavings;
      let filteredDebts;
      let filteredExpenses;
      let filteredFundTracker;
      let filteredIncome;
      if (
        ownerFilter === "Self" ||
        ownerFilter === "Partner" ||
        ownerFilter === "Joint"
      ) {
        filteredRetirement = finalRetirement.filter(
          (item) => item.AccountOwner === ownerFilter
        );
        filteredSavings = finalSaving.filter(
          (item) => item.AccountOwner === ownerFilter
        );
        filteredDebts = finaldebt.filter(
          (item) => item.AccountOwner === ownerFilter
        );
        filteredExpenses = finalExpenses.filter(
          (item) => item.AccountOwner === ownerFilter
        );
        filteredFundTracker = finalFundTracker.filter(
          (item) => item.AccountOwner === ownerFilter
        );
        filteredIncome = finalIncome.filter(
          (item) => item.AccountOwner === ownerFilter
        );
      } else {
        filteredRetirement = finalRetirement;
        filteredSavings = finalSaving;
        filteredDebts = finaldebt;
        filteredExpenses = finalExpenses.filter(
          (item) => item.BudgetItem !== "Joint Contribution"
        );
        filteredFundTracker = finalFundTracker;
        filteredIncome = finalIncome;
      }

      let filteredYearData;
      if (yearFilter !== "" && yearFilter !== "All") {
        filteredYearData = filteredFundTracker.filter((item) => {
          const transDate = new Date(item.RecDate);
          return transDate.getFullYear() === parseInt(yearFilter);
        });
      } else {
        filteredYearData = filteredFundTracker;
      }

      let filteredMonthData;
      if (monthFilter !== "" && monthFilter !== "All") {
        filteredMonthData = filteredYearData.filter((item) => {
          const transDate = new Date(item.RecDate);
          return transDate.getMonth() + 1 === parseInt(monthFilter);
        });
      } else {
        filteredMonthData = filteredYearData;
      }

      //GET EXPENSE DATA
      const totalExpenseSum = filteredExpenses?.reduce(
        (accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        },
        0
      );

      //GET DEBT DATA
      const totalDebtSum = filteredDebts?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);

      const totalRetirementNet = filteredRetirement?.reduce(
        (accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          const contribution = record?.Contribution || "";
          // Only add the amount if contribution is "Yes"
          if (contribution === "Yes") {
            return accumulator + Number(monthlyPayment) + Number(adjustments);
          }
          return accumulator;
        },
        0
      );

      //GET SAVING DATA
      const totalSavingSum = filteredSavings?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);

      //GET FUND TRACLER DATA
      const totalFundTrackerSum = filteredMonthData?.reduce(
        (accumulator, record) => {
          const amount = record?.Amount || 0;
          return accumulator + Number(amount);
        },
        0
      );
      setFundTrackerTotal(totalFundTrackerSum);

      const totalMonthlyNetIncome = filteredIncome.reduce(
        (accumulator, record) => {
          const netPay = record.NetPay || 0; // Handle the case where NetPay is undefined
          const payFrequency = record.PayFrequency || ""; // Handle the case where PayFrequency is undefined
          let monthlyNetIncome = 0;
          // Determine monthlyIncome based on PayFrequency
          switch (payFrequency) {
            case "Yearly":
              monthlyNetIncome = netPay / 12;
              break;
            case "Monthly":
              monthlyNetIncome = netPay;
              break;
            case "Semi-Monthly":
              monthlyNetIncome = netPay * 2;
              break;
            case "Weekly":
              monthlyNetIncome = netPay * 4;
              break;
            case "Bi-Weekly":
              monthlyNetIncome = netPay * 2;
              break;
            default:
              monthlyNetIncome = 0;
          }
          // Add monthlyIncome to the accumulator
          return accumulator + Number(monthlyNetIncome);
        },
        0
      ); // Initialize accumulator with 0
      setExcessTotal(
        Number(totalMonthlyNetIncome) -
          (Number(totalDebtSum) +
            Number(totalExpenseSum) +
            Number(totalSavingSum) +
            Number(totalRetirementNet))
      );

      // Get unique years
      const uniqueYears = [
        ...new Set(
          finalFundTracker.map((item) => {
            const date = new Date(item.RecDate);
            return isNaN(date) ? null : date.getFullYear()?.toString(); // Convert to string
          })
        ),
      ].filter((year) => year !== null);

      setYearData(uniqueYears);
      setGridData(filteredMonthData);
      setUpdatedFundTrackerData(fundTrackerData);
      setIsDataChanged(true);
      setIsDataLoaded(true);
    }
  }, [
    expenseData,
    retirementData,
    isRetirementLoaded,
    savingData,
    isExpenseLoaded,
    isSavingLoaded,
    debtData,
    isDebtLoaded,
    fundTrackerData,
    isFundTrackerLoaded,
    incomeData,
    isIncomeLoaded,
    user,
  ]);

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded || !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  const [selectedID, setSelectedID] = useState("");
  const [selectedRootID, setSelectedRootID] = useState("");
  const [selectedUserID, setSelectedUserID] = useState("");
  const [selectedAccountOwner, setSelectedAccountOwner] = useState("");
  const [selectedRecDate, setSelectedRecDate] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedAmount, setSelectedAmount] = useState("");

  const [newRootID, setNewRootID] = useState("");
  const [newUserID, setNewUserID] = useState("");
  const [newAccountOwner, setNewAccountOwner] = useState("");
  const [newRecDate, setNewRecDate] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newAmount, setNewAmount] = useState(0);
  const [isRequired, setIsRequired] = useState(false);

  const assetNewData = {
    RootID: newRootID,
    UserID: newUserID,
    AccountOwner: newAccountOwner,
    RecDate: newRecDate,
    Description: newDescription,
    Amount: -Math.abs(newAmount),
    UpdatedOn: newCurrentDate,
  };

  const handleRowSelect = async (args) => {
    // Set the selected ID when a row is selected in the Grid
    setSelectedID(args.data.id);
    setSelectedRootID(args.data.RootID);
    setSelectedUserID(args.data.UserID);
    setSelectedAccountOwner(args.data.AccountOwner);
    setSelectedRecDate(args.data.RecDate);
    setSelectedDescription(args.data.Description);
    setSelectedAmount(args.data.Amount);
  };

  const handleExcelButtonClick = () => {};

  const handlePdfButtonClick = () => {};

  const handleEditDialogClose = () => {
    setIsEditDialogVisible(false);
  };

  const handleAddDialogClose = () => {
    setIsAddDialogVisible(false);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    setIsEditDialogVisible(!isEditDialogVisible);
    setNewRootID(selectedRootID);
    setNewUserID(selectedUserID);
    setNewAccountOwner(selectedAccountOwner);
    setNewRecDate(selectedRecDate);
    setNewDescription(selectedDescription);
    setNewAmount(selectedAmount);
    setIsRequired(false);
  };

  const handleAddButtonClick = () => {
    setIsAddDialogVisible(!isAddDialogVisible);
    setSelectedID("");
    setSelectedRootID("");
    setSelectedUserID("");
    setSelectedAccountOwner("");
    setSelectedRecDate("");
    setSelectedDescription("");
    setSelectedAmount("");
    if (GridRef.current) {
      GridRef.current.clearSelection();
    }
    setNewRootID(user?.RootID);
    setNewUserID(user?.id);
    setNewAccountOwner("");
    setNewRecDate("");
    setNewDescription("");
    setSelectedAmount("");
    setIsRequired(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeleteDialogVisible(!isDeleteDialogVisible);
  };

  const handleAddRecordButton = async () => {
    // Make a POST request to add the Record record
    setIsDataChanged(false);
    try {
      const RecordResponse = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/fundtracker",
        assetNewData
      );
      console.log("Record response:", RecordResponse.data);
      if (RecordResponse.data.status === "success") {
        toast.success("Added successfully.");
        try {
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/fundtracker/rootuserid/${user?.RootID}`
          );
          const Records = updatedResponse.data.items;
          setUpdatedFundTrackerData(Records);
          let finalFundTracker;
          if (user?.Type !== "Root") {
            finalFundTracker = Records?.filter(
              (item) => item.UserID === user?.id
            );
          } else {
            finalFundTracker = Records;
          }
          finalFundTracker?.sort(
            (a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn)
          );
          let filteredFundTracker;
          if (
            ownerFilter === "Self" ||
            ownerFilter === "Partner" ||
            ownerFilter === "Joint"
          ) {
            filteredFundTracker = finalFundTracker?.filter(
              (item) => item.AccountOwner === ownerFilter
            );
          } else {
            filteredFundTracker = finalFundTracker;
          }

          let filteredYearData;
          if (yearFilter !== "" && yearFilter !== "All") {
            filteredYearData = filteredFundTracker.filter((item) => {
              const transDate = new Date(item.RecDate);
              return transDate.getFullYear() === parseInt(yearFilter);
            });
          } else {
            filteredYearData = filteredFundTracker;
          }

          let filteredMonthData;
          if (monthFilter !== "") {
            filteredMonthData = filteredYearData.filter((item) => {
              const transDate = new Date(item.RecDate);
              return transDate.getMonth() + 1 === parseInt(monthFilter);
            });
          } else {
            filteredMonthData = filteredYearData;
          }

          //GET FUND TRACLER DATA
          const totalFundTrackerSum = filteredFundTracker?.reduce(
            (accumulator, record) => {
              const amount = record?.Amount || 0;
              return accumulator + Number(amount);
            },
            0
          );
          // Get unique years
          const uniqueYears = [
            ...new Set(
              finalFundTracker.map((item) => {
                const date = new Date(item.RecDate);
                return isNaN(date) ? null : date.getFullYear()?.toString(); // Convert to string
              })
            ),
          ].filter((year) => year !== null);

          setYearData(uniqueYears);
          setFundTrackerTotal(totalFundTrackerSum);
          setGridData(filteredMonthData);
        } catch (fetchError) {
          setFundTrackerTotal(0);
          setYearData("");
          setGridData([]);
          setUpdatedFundTrackerData([]);
        }
      } else {
        console.error(
          "Error adding the record record:",
          RecordResponse.data.message
        );
        toast.error("Error adding the record");
      }
    } catch (error) {
      console.error("Error adding the record:", error);
      toast.error("Error adding the record");
    }
    setIsDataChanged(true);
  };

  const handleUpdateRecordButton = async (selectedID) => {
    setIsDataChanged(false);
    const numericSelectedID = parseInt(selectedID);
    try {
      const RecordResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL +
          `/api/fundtracker/${numericSelectedID}`,
        {
          RootID: newRootID,
          UserID: newUserID,
          AccountOwner: newAccountOwner,
          RecDate: newRecDate,
          Description: newDescription,
          Amount: -Math.abs(newAmount),
          UpdatedOn: newCurrentDate,
        }
      );
      console.log("Record response:", RecordResponse.data);
      if (RecordResponse.data.status === "success") {
        toast.success("the record updated successfully.");
        try {
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/fundtracker/rootuserid/${user?.RootID}`
          );
          const Records = updatedResponse.data.items;
          setUpdatedFundTrackerData(Records);
          let finalFundTracker;
          if (user?.Type !== "Root") {
            finalFundTracker = Records?.filter(
              (item) => item.UserID === user?.id
            );
          } else {
            finalFundTracker = Records;
          }
          finalFundTracker?.sort(
            (a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn)
          );
          let filteredFundTracker;
          if (
            ownerFilter === "Self" ||
            ownerFilter === "Partner" ||
            ownerFilter === "Joint"
          ) {
            filteredFundTracker = finalFundTracker?.filter(
              (item) => item.AccountOwner === ownerFilter
            );
          } else {
            filteredFundTracker = finalFundTracker;
          }

          let filteredYearData;
          if (yearFilter !== "" && yearFilter !== "All") {
            filteredYearData = filteredFundTracker.filter((item) => {
              const transDate = new Date(item.RecDate);
              return transDate.getFullYear() === parseInt(yearFilter);
            });
          } else {
            filteredYearData = filteredFundTracker;
          }

          let filteredMonthData;
          if (monthFilter !== "") {
            filteredMonthData = filteredYearData.filter((item) => {
              const transDate = new Date(item.RecDate);
              return transDate.getMonth() + 1 === parseInt(monthFilter);
            });
          } else {
            filteredMonthData = filteredYearData;
          }

          //GET FUND TRACLER DATA
          const totalFundTrackerSum = filteredFundTracker?.reduce(
            (accumulator, record) => {
              const amount = record?.Amount || 0;
              return accumulator + Number(amount);
            },
            0
          );
          // Get unique years
          const uniqueYears = [
            ...new Set(
              finalFundTracker.map((item) => {
                const date = new Date(item.RecDate);
                return isNaN(date) ? null : date.getFullYear()?.toString(); // Convert to string
              })
            ),
          ].filter((year) => year !== null);

          setYearData(uniqueYears);
          setFundTrackerTotal(totalFundTrackerSum);
          setGridData(filteredMonthData);
        } catch (fetchError) {
          setFundTrackerTotal(0);
          setYearData("");
          setGridData([]);
          setUpdatedFundTrackerData([]);
        }
      } else {
        console.error(
          "Error updating the record record:",
          RecordResponse.data.message
        );
        toast.error("Error updating the record");
      }
    } catch (error) {
      console.error("Error updating the record:", error);
      toast.error("Error updating the record");
    }
    setIsDataChanged(true);
  };

  const handleDeleteRecordButton = async (selectedID) => {
    setIsDataChanged(false);
    const numericSelectedID = parseInt(selectedID);
    try {
      const deleteResponse = await axios.delete(
        process.env.REACT_APP_SERVER_URL +
          `/api/fundtracker/${numericSelectedID}`
      );

      console.log("Delete response:", deleteResponse.data);

      if (deleteResponse.data.status === "success") {
        toast.success("The record was deleted successfully.");
        try {
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/fundtracker/rootuserid/${user?.RootID}`
          );
          const Records = updatedResponse.data.items;
          setUpdatedFundTrackerData(Records);
          let finalFundTracker;
          if (user?.Type !== "Root") {
            finalFundTracker = Records?.filter(
              (item) => item.UserID === user?.id
            );
          } else {
            finalFundTracker = Records;
          }
          finalFundTracker?.sort(
            (a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn)
          );
          let filteredFundTracker;
          if (
            ownerFilter === "Self" ||
            ownerFilter === "Partner" ||
            ownerFilter === "Joint"
          ) {
            filteredFundTracker = finalFundTracker?.filter(
              (item) => item.AccountOwner === ownerFilter
            );
          } else {
            filteredFundTracker = finalFundTracker;
          }

          let filteredYearData;
          if (yearFilter !== "" && yearFilter !== "All") {
            filteredYearData = filteredFundTracker.filter((item) => {
              const transDate = new Date(item.RecDate);
              return transDate.getFullYear() === parseInt(yearFilter);
            });
          } else {
            filteredYearData = filteredFundTracker;
          }

          let filteredMonthData;
          if (monthFilter !== "") {
            filteredMonthData = filteredYearData.filter((item) => {
              const transDate = new Date(item.RecDate);
              return transDate.getMonth() + 1 === parseInt(monthFilter);
            });
          } else {
            filteredMonthData = filteredYearData;
          }

          //GET FUND TRACLER DATA
          const totalFundTrackerSum = filteredFundTracker?.reduce(
            (accumulator, record) => {
              const amount = record?.Amount || 0;
              return accumulator + Number(amount);
            },
            0
          );
          // Get unique years
          const uniqueYears = [
            ...new Set(
              finalFundTracker.map((item) => {
                const date = new Date(item.RecDate);
                return isNaN(date) ? null : date.getFullYear()?.toString(); // Convert to string
              })
            ),
          ].filter((year) => year !== null);

          setYearData(uniqueYears);
          setFundTrackerTotal(totalFundTrackerSum);
          setGridData(filteredMonthData);
        } catch (fetchError) {
          setFundTrackerTotal(0);
          setYearData("");
          setGridData([]);
          setUpdatedFundTrackerData([]);
        }
      } else {
        console.error(
          "Error deleting the record:",
          deleteResponse.data.message
        );
        toast.error("Error deleting item");
      }
    } catch (error) {
      console.error("Error deleting the record:", error);
      toast.error("Error deleting the record");
    }
    setIsDataChanged(true);
  };

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = ["ColumnChooser"];
  const loadingIndicator = { indicatorType: "Spinner" };

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          <button className="add-button" onClick={handleAddButtonClick}>
            <AddIcon className="send-icon" /> Add New{" "}
          </button>
          <button
            className="edit-button"
            onClick={handleEditButtonClick}
            disabled={selectedID === ""}
          >
            <EditIcon className="send-icon" /> Edit{" "}
          </button>
          <button
            className="delete-button"
            onClick={handleDeleteButtonClick}
            disabled={selectedID === ""}
          >
            <DeleteIcon className="but-icon-whereabouts" /> Delete{" "}
          </button>
        </div>
        {/* <div className="button-container-subA2">
          <button className="excel-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          <button className="pdf-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button>
        </div> */}
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  let addButtons = [
    {
      buttonModel: {
        content: "Save New",
        cssClass: "e-success update-btn",
        isPrimary: true,
      },
      click: () => {
        if (
          CheckFundsTrackerFields(
            newAccountOwner,
            newRecDate,
            newDescription,
            newAmount
          )
        ) {
          setIsRequired(true);
        } else {
          handleAddRecordButton(selectedID);
          setIsAddDialogVisible(false);
        }
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsAddDialogVisible(false);
      },
    },
  ];

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-success update-btn",
        isPrimary: true,
      },
      click: () => {
        if (
          CheckFundsTrackerFields(
            newAccountOwner,
            newRecDate,
            newDescription,
            newAmount
          )
        ) {
          setIsRequired(true);
        } else {
          handleUpdateRecordButton(selectedID);
          setIsEditDialogVisible(false);
        }
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsEditDialogVisible(false);
      },
    },
  ];

  let deleteButtons = [
    {
      buttonModel: {
        content: "Delete",
        cssClass: "e-danger update-btn",
        isPrimary: true,
      },
      click: () => {
        handleDeleteRecordButton(selectedID);
        setIsDeleteDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsDeleteDialogVisible(false);
      },
    },
  ];

  const currencyFormatter3 = (field, data, column) => {
    const Amount = getValue("Amount", data); // Assuming the field name is "MonthlyPayment"
    // Format the amount with the desired currency symbol, space separator, and decimal places
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Amount);
    return formattedAmount;
  };

  const dateFormatter = (field, data, column) => {
    const dateValue = getValue("RecDate", data); // Replace "DateFieldName" with your actual date field name
    // Format the date with the desired options
    const formattedDate = new Intl.DateTimeFormat(user?.Separator, {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(new Date(dateValue));
    return formattedDate;
  };

  const handleUpgradeButtonClick = () => {
    navigate("/packages");
  };

  return (
    <div className="all-outer-project-containers">
      <div className="title-container">
        <div className="mainTitle_dropdown">
          <h3>Extra Funds Tracker for {user?.FullName}</h3>
          <div className="_account_owner1">
            <ComboBoxComponent
              id="comboelement"
              value="Select Owner"
              autofill={true}
              headerTemplate={dropdownTemplate}
              // placeholder="* Account Owner"
              floatLabelType="Always"
              dataSource={["Self", "Partner", "Household"]}
              change={handleAccountOwnerChange}
              style={{ backgroundColor: "black", color: "#FFE99B" }}
            />
          </div>
        </div>
      </div>

      <div className="summay-debit-btns">
        <div className="summary-container-income">
          <div className="expense-firstBTNS">
            <div className="_years">
              <ComboBoxComponent
                id="comboelement"
                value={currentYear}
                placeholder="Year"
                floatLabelType="Always"
                autofill={true}
                headerTemplate={dropdownTemplate}
                dataSource={yearData}
                allowCustom={true}
                change={handleYearChange}
                style={{ backgroundColor: "black", color: "#FFE99B" }}
              />
            </div>
            <div className="_months">
              <ComboBoxComponent
                id="comboelement"
                value={currentMonthName}
                placeholder="Month"
                autofill={true}
                headerTemplate={dropdownTemplate}
                floatLabelType="Always"
                dataSource={monthData}
                change={handleMonthChange}
                style={{ backgroundColor: "black", color: "#FFE99B" }}
              />
            </div>
          </div>

          <div className="expense-secondBTNS">
            <div className="income_net_pay1">
              <NumericTextBoxComponent
                placeholder="Excess"
                floatLabelType="Always"
                value={excessTotal}
                format={usedCurrency}
                showSpinButton={false}
                style={{
                  pointerEvents: "none",
                }}
              />
            </div>
            <div className="income_net_pay1">
              <NumericTextBoxComponent
                placeholder="Remaining Balance"
                floatLabelType="Always"
                value={Number(fundTrackerTotal) + Number(excessTotal)}
                format={usedCurrency}
                showSpinButton={false}
                style={{
                  pointerEvents: "none",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <>
        {userPackage !== "Basic" &&
          userPackage !== "Standard" &&
          userPackage !== "Premium" && (
            <div className="title-container1">
              <h2>This Feature is Only Available to Paid Users Only</h2>
              <div className="button-container">
                <div className="button-container-subA1">
                  <button
                    className="add-button"
                    onClick={handleUpgradeButtonClick}
                  >
                    {" "}
                    <UpgradeIcon className="send-icon" />
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          )}
        {(userPackage === "Basic" ||
          userPackage === "Standard" ||
          userPackage === "Premium") && (
          <div className="all-inner-asset-containers">
            {isDataLoaded && (
              <GridComponent
                dataSource={gridData}
                childMapping="items"
                ref={GridRef}
                classClass="custom-treegrid-class"
                height="480"
                width="100%"
                allowReordering={true}
                allowResizing={true}
                allowExcelExport={true}
                allowPdfExport={true}
                allowPaging={true}
                allowSorting={true}
                allowFiltering={true}
                allowTextWrap={true}
                showColumnChooser={true}
                filterSettings={FilterOptions}
                pageSettings={pageSettings}
                editSettings={editOptions}
                toolbar={updatedToolbarOptions}
                loadingIndicator={loadingIndicator}
                allowSelection={true}
                rowSelected={handleRowSelect}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="AccountOwner"
                    headerText="Account Owner"
                    width="120"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="RecDate"
                    headerText="Date"
                    width="180"
                    textAlign="Left"
                    allowFiltering={false}
                    valueAccessor={dateFormatter}
                  />
                  <ColumnDirective
                    field="Description"
                    headerText="Description"
                    width="300"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="Amount"
                    headerText="Amount"
                    width="140"
                    textAlign="Left"
                    valueAccessor={currencyFormatter3}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Page,
                    Sort,
                    Filter,
                    Edit,
                    Toolbar,
                    ColumnChooser,
                    Reorder,
                    Resize,
                    PdfExport,
                    ExcelExport,
                  ]}
                />
              </GridComponent>
            )}
          </div>
        )}
      </>
      <div>
        {isEditDialogVisible && (
          <DialogComponent
            visible={isEditDialogVisible}
            width="40%"
            height="380px"
            header="Edit Extra Funds Record"
            allowDragging={true}
            showCloseIcon={true}
            close={handleEditDialogClose}
            buttons={editButtons}
          >
            <FundTrackerDialog
              selectedAccountOwner={selectedAccountOwner}
              selectedRecDate={selectedRecDate}
              selectedDescription={selectedDescription}
              selectedAmount={selectedAmount}
              usedCurrency={usedCurrency}
              setNewAccountOwner={setNewAccountOwner}
              setNewRecDate={setNewRecDate}
              setNewDescription={setNewDescription}
              setNewAmount={setNewAmount}
              isRequired={isRequired}
            />
          </DialogComponent>
        )}
      </div>
      <div>
        {isAddDialogVisible && (
          <DialogComponent
            visible={isAddDialogVisible}
            width="40%"
            height="380px"
            header="Add Extra Funds Record"
            allowDragging={true}
            showCloseIcon={true}
            close={handleAddDialogClose}
            buttons={addButtons}
          >
            <FundTrackerDialog
              selectedAccountOwner={selectedAccountOwner}
              selectedRecDate={selectedRecDate}
              selectedDescription={selectedDescription}
              selectedAmount={selectedAmount}
              usedCurrency={usedCurrency}
              setNewAccountOwner={setNewAccountOwner}
              setNewRecDate={setNewRecDate}
              setNewDescription={setNewDescription}
              setNewAmount={setNewAmount}
              isRequired={isRequired}
            />
          </DialogComponent>
        )}
      </div>
      <div>
        {isDeleteDialogVisible && (
          <DialogComponent
            visible={isDeleteDialogVisible}
            width="25%"
            height="200px"
            header="Delete Extra Funds Record"
            content={`Do you want to delete the selected Record (${selectedDescription})?`}
            allowDragging={true}
            showCloseIcon={true}
            close={handleDeleteDialogClose}
            buttons={deleteButtons}
          ></DialogComponent>
        )}
      </div>
    </div>
  );
}
export default FundsTracker;
