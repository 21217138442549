import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  NumericTextBoxComponent,
  TextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
  Reorder,
  Resize,
  PdfExport,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import { getValue } from "@syncfusion/ej2-base";

import { DialogComponent } from "@syncfusion/ej2-react-popups";
import axios from "../../../config/axios";
import "../../shared/ListView.css";
import useBankAccountDataFetch from "../../shared/useBankAccountDataFetch";
import useAccountDataFetch from "../../shared/useAccountDataFetch";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { dropdownTemplate } from "../../shared/HeaderTempate";

function UnclearedTransaction() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const { bankAccountData, isBankAccountLoaded } = useBankAccountDataFetch();
  const { accountData, isAccountLoaded } = useAccountDataFetch();
  const [ownerFilter, setOwnerFilter] = useState("Household");
  const [bankFilter, setBankFilter] = useState("");
  const [yearFilter, setYearFilter] = useState("");
  const [monthFilter, setMonthFilter] = useState("");
  const [openingBalance, setOpeningBalance] = useState(0);
  const [closingBalance, setClosingBalance] = useState(0);
  const [yearData, setYearData] = useState("");
  const [monthData, setMonthData] = useState("");
  const [filterBankAccounts, setFilterBankAccounts] = useState("");
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
  }
  const newCurrentDate = formatDate(new Date());
  const [gridData, setGridData] = useState([]);
  const [updatedBankAccountData, setUpdatedBankAccountData] = useState([]);
  const usedCurrency = user?.Currency + " #,###.00";
  const userPackage = user?.Package;

  const handleAccountOwnerChange = async (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setOwnerFilter(e.value);
      const accountOwner = e.value;
      const unClearedData = updatedBankAccountData.filter(
        (item) => item.ClearedBank === "Not Cleared"
      );
      let finalBankAccount;
      if (user?.Type !== "Root") {
        finalBankAccount = unClearedData.filter(
          (item) => item.UserID === user?.id
        );
      } else {
        finalBankAccount = unClearedData;
      }
      finalBankAccount?.sort(
        (a, b) => new Date(b.TransDate) - new Date(a.TransDate)
      );

      let filteredData;
      if (
        accountOwner === "Self" ||
        accountOwner === "Partner" ||
        accountOwner === "Joint"
      ) {
        filteredData = finalBankAccount?.filter(
          (item) => item.AccountOwner === accountOwner
        );
      } else {
        filteredData = finalBankAccount;
      }

      let filteredBankData;
      if (bankFilter !== "" && bankFilter !== "All") {
        filteredBankData = filteredData.filter(
          (item) => item.BankAccount === bankFilter
        );
      } else {
        filteredBankData = filteredData;
      }

      let filteredYearData;
      if (yearFilter !== "" && yearFilter !== "All") {
        filteredYearData = filteredBankData.filter((item) => {
          const transDate = new Date(item.TransDate);
          return transDate.getFullYear() === parseInt(yearFilter);
        });
      } else {
        filteredYearData = filteredBankData;
      }

      let filteredMonthData;
      if (monthFilter !== "" && monthFilter !== "All") {
        filteredMonthData = filteredYearData.filter((item) => {
          const transDate = new Date(item.TransDate);
          return transDate.getMonth() + 1 === parseInt(monthFilter);
        });
      } else {
        filteredMonthData = filteredYearData;
      }

      // Get items in filteredBankData but not in filteredMonthData
      //const itemsNotInMonthData = filteredBankData.filter((item) => !filteredMonthData.includes(item));
      // Assuming TransDate is a Date object, adjust as needed
      const itemsNotInMonthData = filteredBankData.filter((item) =>
        filteredMonthData?.every(
          (monthItem) => item.TransDate < monthItem.TransDate
        )
      );

      // Calculate opening balance
      const balanceBefore = itemsNotInMonthData.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      // Calculate closing balance
      const balanceAfter = filteredMonthData.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      const totalBalance = Number(balanceBefore) + Number(balanceAfter);

      setGridData(filteredMonthData);
      if (filteredMonthData.length > 0) {
        setOpeningBalance(balanceBefore);
        setClosingBalance(totalBalance);
      } else {
        setOpeningBalance(0);
        setClosingBalance(0);
      }
      setIsDataChanged(true);
    }
  };

  const handleBankAccountChange = async (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      const accountName = e.value;
      let bankAccount = "";
      if (accountName !== "" && accountName !== "All") {
        const targetAccountData = accountData.find(
          (item) => item.Account === accountName
        );
        const selAccountID = targetAccountData ? targetAccountData.id : "";
        setBankFilter(selAccountID);
        bankAccount = selAccountID;
      } else {
        setBankFilter("");
      }
      const unClearedData = updatedBankAccountData.filter(
        (item) => item.ClearedBank === "Not Cleared"
      );
      let finalBankAccount;
      if (user?.Type !== "Root") {
        finalBankAccount = unClearedData.filter(
          (item) => item.UserID === user?.id
        );
      } else {
        finalBankAccount = unClearedData;
      }
      finalBankAccount?.sort(
        (a, b) => new Date(b.TransDate) - new Date(a.TransDate)
      );

      let filteredData;
      if (
        ownerFilter === "Self" ||
        ownerFilter === "Partner" ||
        ownerFilter === "Joint"
      ) {
        filteredData = finalBankAccount?.filter(
          (item) => item.AccountOwner === ownerFilter
        );
      } else {
        filteredData = finalBankAccount;
      }

      let filteredBankData;
      if (bankAccount !== "" && bankAccount !== "All") {
        filteredBankData = filteredData.filter(
          (item) => item.BankAccount === bankAccount
        );
      } else {
        filteredBankData = filteredData;
      }

      let filteredYearData;
      if (yearFilter !== "" && yearFilter !== "All") {
        filteredYearData = filteredBankData.filter((item) => {
          const transDate = new Date(item.TransDate);
          return transDate.getFullYear() === parseInt(yearFilter);
        });
      } else {
        filteredYearData = filteredBankData;
      }

      let filteredMonthData;
      if (monthFilter !== "" && monthFilter !== "All") {
        filteredMonthData = filteredYearData.filter((item) => {
          const transDate = new Date(item.TransDate);
          return transDate.getMonth() + 1 === parseInt(monthFilter);
        });
      } else {
        filteredMonthData = filteredYearData;
      }

      // Get items in filteredBankData but not in filteredMonthData
      //const itemsNotInMonthData = filteredBankData.filter((item) => !filteredMonthData.includes(item));
      // Assuming TransDate is a Date object, adjust as needed
      const itemsNotInMonthData = filteredBankData.filter((item) =>
        filteredMonthData?.every(
          (monthItem) => item.TransDate < monthItem.TransDate
        )
      );

      // Calculate opening balance
      const balanceBefore = itemsNotInMonthData.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      // Calculate closing balance
      const balanceAfter = filteredMonthData.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      const totalBalance = Number(balanceBefore) + Number(balanceAfter);

      setGridData(filteredMonthData);
      if (filteredMonthData.length > 0) {
        setOpeningBalance(balanceBefore);
        setClosingBalance(totalBalance);
      } else {
        setOpeningBalance(0);
        setClosingBalance(0);
      }
      setIsDataChanged(true);
    }
  };

  const handleYearChange = async (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      const yearName = e.value;
      setYearFilter(e.value);
      const unClearedData = updatedBankAccountData.filter(
        (item) => item.ClearedBank === "Not Cleared"
      );
      let finalBankAccount;
      if (user?.Type !== "Root") {
        finalBankAccount = unClearedData.filter(
          (item) => item.UserID === user?.id
        );
      } else {
        finalBankAccount = unClearedData;
      }
      finalBankAccount?.sort(
        (a, b) => new Date(b.TransDate) - new Date(a.TransDate)
      );

      let filteredData;
      if (
        ownerFilter === "Self" ||
        ownerFilter === "Partner" ||
        ownerFilter === "Joint"
      ) {
        filteredData = finalBankAccount?.filter(
          (item) => item.AccountOwner === ownerFilter
        );
      } else {
        filteredData = finalBankAccount;
      }

      let filteredBankData;
      if (bankFilter !== "" && bankFilter !== "All") {
        filteredBankData = filteredData.filter(
          (item) => item.BankAccount === bankFilter
        );
      } else {
        filteredBankData = filteredData;
      }

      let filteredYearData;
      if (yearName !== "" && yearName !== "All") {
        filteredYearData = filteredBankData.filter((item) => {
          const transDate = new Date(item.TransDate);
          return transDate.getFullYear() === parseInt(yearName);
        });
      } else {
        filteredYearData = filteredBankData;
      }

      let filteredMonthData;
      if (monthFilter !== "" && monthFilter !== "All") {
        filteredMonthData = filteredYearData.filter((item) => {
          const transDate = new Date(item.TransDate);
          return transDate.getMonth() + 1 === parseInt(monthFilter);
        });
      } else {
        filteredMonthData = filteredYearData;
      }

      // Get items in filteredBankData but not in filteredMonthData
      //const itemsNotInMonthData = filteredBankData.filter((item) => !filteredMonthData.includes(item));
      // Assuming TransDate is a Date object, adjust as needed
      const itemsNotInMonthData = filteredBankData.filter((item) =>
        filteredMonthData?.every(
          (monthItem) => item.TransDate < monthItem.TransDate
        )
      );

      // Calculate opening balance
      const balanceBefore = itemsNotInMonthData.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      // Calculate closing balance
      const balanceAfter = filteredMonthData.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      const totalBalance = Number(balanceBefore) + Number(balanceAfter);

      setGridData(filteredMonthData);
      if (filteredMonthData.length > 0) {
        setOpeningBalance(balanceBefore);
        setClosingBalance(totalBalance);
      } else {
        setOpeningBalance(0);
        setClosingBalance(0);
      }
      setIsDataChanged(true);
    }
  };

  function getMonthNumber(monthName) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const normalizedMonthName = monthName.trim().toLowerCase();
    const monthIndex = months.findIndex(
      (month) => month.toLowerCase() === normalizedMonthName
    );
    // Adding 1 to convert from 0-based index to 1-based month number
    return monthIndex !== -1 ? monthIndex + 1 : null;
  }

  const handleMonthChange = async (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      const monthName = e.value;
      let selMonthNumber = "";

      const validMonths = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (validMonths.includes(monthName)) {
        const monthNumber = getMonthNumber(monthName);
        setMonthFilter(monthNumber);
        selMonthNumber = monthNumber;
      } else {
        setMonthFilter("");
      }
      const unClearedData = updatedBankAccountData.filter(
        (item) => item.ClearedBank === "Not Cleared"
      );
      let finalBankAccount;
      if (user?.Type !== "Root") {
        finalBankAccount = unClearedData.filter(
          (item) => item.UserID === user?.id
        );
      } else {
        finalBankAccount = unClearedData;
      }
      finalBankAccount?.sort(
        (a, b) => new Date(b.TransDate) - new Date(a.TransDate)
      );

      let filteredData;
      if (
        ownerFilter === "Self" ||
        ownerFilter === "Partner" ||
        ownerFilter === "Joint"
      ) {
        filteredData = finalBankAccount?.filter(
          (item) => item.AccountOwner === ownerFilter
        );
      } else {
        filteredData = finalBankAccount;
      }

      let filteredBankData;
      if (bankFilter !== "" && bankFilter !== "All") {
        filteredBankData = filteredData.filter(
          (item) => item.BankAccount === bankFilter
        );
      } else {
        filteredBankData = filteredData;
      }

      let filteredYearData;
      if (yearFilter !== "" && yearFilter !== "All") {
        filteredYearData = filteredBankData.filter((item) => {
          const transDate = new Date(item.TransDate);
          return transDate.getFullYear() === parseInt(yearFilter);
        });
      } else {
        filteredYearData = filteredBankData;
      }

      let filteredMonthData;
      if (selMonthNumber !== "" && selMonthNumber !== "All") {
        filteredMonthData = filteredYearData.filter((item) => {
          const transDate = new Date(item.TransDate);
          return transDate.getMonth() + 1 === parseInt(selMonthNumber);
        });
      } else {
        filteredMonthData = filteredYearData;
      }

      // Get items in filteredBankData but not in filteredMonthData
      //const itemsNotInMonthData = filteredBankData.filter((item) => !filteredMonthData.includes(item));
      // Assuming TransDate is a Date object, adjust as needed
      const itemsNotInMonthData = filteredBankData.filter((item) =>
        filteredMonthData?.every(
          (monthItem) => item.TransDate < monthItem.TransDate
        )
      );

      // Calculate opening balance
      const balanceBefore = itemsNotInMonthData.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      // Calculate closing balance
      const balanceAfter = filteredMonthData.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      const totalBalance = Number(balanceBefore) + Number(balanceAfter);

      setGridData(filteredMonthData);
      if (filteredMonthData.length > 0) {
        setOpeningBalance(balanceBefore);
        setClosingBalance(totalBalance);
      } else {
        setOpeningBalance(0);
        setClosingBalance(0);
      }
      setIsDataChanged(true);
    }
  };

  useEffect(() => {
    if (isBankAccountLoaded && isAccountLoaded) {
      setUpdatedBankAccountData(bankAccountData);
      const unClearedData = bankAccountData.filter(
        (item) => item.ClearedBank === "Not Cleared"
      );
      let finalBankAccount;
      if (user?.Type !== "Root") {
        finalBankAccount = unClearedData.filter(
          (item) => item.UserID === user?.id
        );
      } else {
        finalBankAccount = unClearedData;
      }
      finalBankAccount.sort(
        (a, b) => new Date(b.TransDate) - new Date(a.TransDate)
      );

      // Calculate closing balance
      const balanceAfter = finalBankAccount.reduce((accumulator, item) => {
        if (item.ClearedBank === "Cleared") {
          const deposit = item.Deposit || 0;
          const withdrawal = item.Withdrawal || 0;
          return accumulator + (Number(deposit) + Number(withdrawal));
        }
        return accumulator;
      }, 0);

      // Get unique years
      const uniqueYears = [
        ...new Set(
          finalBankAccount.map((item) => {
            const date = new Date(item.TransDate);
            return isNaN(date) ? null : date.getFullYear()?.toString(); // Convert to string
          })
        ),
      ].filter((year) => year !== null);
      uniqueYears.sort((a, b) => a.localeCompare(b));
      const formattedYearAccounts = ["All", ...uniqueYears];

      // Get unique months with full names
      const uniqueMonths = [
        ...new Set(
          finalBankAccount.map((item) => {
            const date = new Date(item.TransDate);
            return isNaN(date)
              ? null
              : new Intl.DateTimeFormat(user?.Separator, {
                  month: "long",
                }).format(date);
          })
        ),
      ].filter((month) => month !== null);
      const formattedMonthAccounts = ["All", ...uniqueMonths];

      const uniqueBankAccounts = [
        ...new Set(
          accountData.map((item) => (item.Account ? item.Account : ""))
        ),
      ];
      uniqueBankAccounts.sort((a, b) => a.localeCompare(b));
      const formattedBankAccounts = ["All", ...uniqueBankAccounts];
      setFilterBankAccounts(formattedBankAccounts);

      setYearData(formattedYearAccounts);
      setMonthData(formattedMonthAccounts);
      setGridData(finalBankAccount);
      setOpeningBalance(0);
      setClosingBalance(balanceAfter);
      setIsDataChanged(true);
      setIsDataLoaded(true);
    }
  }, [
    bankAccountData,
    accountData,
    isBankAccountLoaded,
    isAccountLoaded,
    user,
  ]);

  // Show toast when isIncomeLoaded becomes false
  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded || !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  const [selectedID, setSelectedID] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");

  const handleRowSelect = async (args) => {
    // Set the selected ID when a row is selected in the Grid
    setSelectedID(args.data.id);
    setSelectedDescription(args.data.Description);
  };

  const handleExcelButtonClick = () => {};

  const handlePdfButtonClick = () => {};

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogVisible(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeleteDialogVisible(!isDeleteDialogVisible);
  };

  const handleUpdateRecordButton = async (selectedID) => {
    setIsDataChanged(false);
    const numericSelectedID = parseInt(selectedID);
    try {
      const RecordResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL +
          `/api/bankaccount/${numericSelectedID}`,
        {
          ClearedBank: "Cleared",
          UpdatedOn: newCurrentDate,
        }
      );
      console.log("Record response:", RecordResponse.data);
      if (RecordResponse.data.status === "success") {
        toast.success("the record updated successfully.");
        // Get the UPDATED DATA by making a GET request
        try {
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/bankaccount/rootuserid/${user?.RootID}`
          );
          const Records = updatedResponse.data.items;
          setUpdatedBankAccountData(Records);
          const unClearedData = Records.filter(
            (item) => item.ClearedBank === "Not Cleared"
          );
          let finalBankAccount;
          if (user?.Type !== "Root") {
            finalBankAccount = unClearedData.filter(
              (item) => item.UserID === user?.id
            );
          } else {
            finalBankAccount = unClearedData;
          }
          finalBankAccount?.sort(
            (a, b) => new Date(b.TransDate) - new Date(a.TransDate)
          );

          let filteredData;
          if (
            ownerFilter === "Self" ||
            ownerFilter === "Partner" ||
            ownerFilter === "Joint"
          ) {
            filteredData = finalBankAccount?.filter(
              (item) => item.AccountOwner === ownerFilter
            );
          } else {
            filteredData = finalBankAccount;
          }

          let filteredBankData;
          if (bankFilter !== "" && bankFilter !== "All") {
            filteredBankData = filteredData.filter(
              (item) => item.BankAccount === bankFilter
            );
          } else {
            filteredBankData = filteredData;
          }

          let filteredYearData;
          if (yearFilter !== "" && yearFilter !== "All") {
            filteredYearData = filteredBankData.filter((item) => {
              const transDate = new Date(item.TransDate);
              return transDate.getFullYear() === parseInt(yearFilter);
            });
          } else {
            filteredYearData = filteredBankData;
          }

          let filteredMonthData;
          if (monthFilter !== "" && monthFilter !== "All") {
            filteredMonthData = filteredYearData.filter((item) => {
              const transDate = new Date(item.TransDate);
              return transDate.getMonth() + 1 === parseInt(monthFilter);
            });
          } else {
            filteredMonthData = filteredYearData;
          }

          // Get items in filteredBankData but not in filteredMonthData
          //const itemsNotInMonthData = filteredBankData.filter((item) => !filteredMonthData.includes(item));
          // Assuming TransDate is a Date object, adjust as needed
          const itemsNotInMonthData = filteredBankData.filter((item) =>
            filteredMonthData.every(
              (monthItem) => item.TransDate < monthItem.TransDate
            )
          );

          // Calculate opening balance
          const balanceBefore = itemsNotInMonthData.reduce(
            (accumulator, item) => {
              if (item.ClearedBank === "Cleared") {
                const deposit = item.Deposit || 0;
                const withdrawal = item.Withdrawal || 0;
                return accumulator + (Number(deposit) + Number(withdrawal));
              }
              return accumulator;
            },
            0
          );

          // Calculate closing balance
          const balanceAfter = filteredMonthData.reduce((accumulator, item) => {
            if (item.ClearedBank === "Cleared") {
              const deposit = item.Deposit || 0;
              const withdrawal = item.Withdrawal || 0;
              return accumulator + (Number(deposit) + Number(withdrawal));
            }
            return accumulator;
          }, 0);

          const totalBalance = Number(balanceBefore) + Number(balanceAfter);

          // Get unique years
          const uniqueYears = [
            ...new Set(
              finalBankAccount.map((item) => {
                const date = new Date(item.TransDate);
                return isNaN(date) ? null : date.getFullYear()?.toString(); // Convert to string
              })
            ),
          ].filter((year) => year !== null);
          const formattedYearAccounts = ["All", ...uniqueYears];

          // Get unique months with full names
          const uniqueMonths = [
            ...new Set(
              finalBankAccount.map((item) => {
                const date = new Date(item.TransDate);
                return isNaN(date)
                  ? null
                  : new Intl.DateTimeFormat(user?.Separator, {
                      month: "long",
                    }).format(date);
              })
            ),
          ].filter((month) => month !== null);
          const formattedMonthAccounts = ["All", ...uniqueMonths];

          setYearData(formattedYearAccounts);
          setMonthData(formattedMonthAccounts);
          setUpdatedBankAccountData(Records);
          setGridData(filteredMonthData);
          if (filteredMonthData.length > 0) {
            setOpeningBalance(balanceBefore);
            setClosingBalance(totalBalance);
          } else {
            setOpeningBalance(0);
            setClosingBalance(0);
          }
          setIsDataChanged(true);
        } catch (fetchError) {
          setGridData([]);
          setYearData("");
          setMonthData("");
          setUpdatedBankAccountData([]);
          setOpeningBalance(0);
          setClosingBalance(0);
          setIsDataChanged(true);
        }
      } else {
        console.error(
          "Error updating the record record:",
          RecordResponse.data.message
        );
        toast.error("Error updating the record");
      }
    } catch (error) {
      console.error("Error updating the record:", error);
      toast.error("Error updating the record");
    }
    setIsDataChanged(true);
  };

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = ["ColumnChooser"];
  const loadingIndicator = { indicatorType: "Spinner" };

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          <button
            className="add-button"
            onClick={handleDeleteButtonClick}
            disabled={selectedID === ""}
          >
            <CheckCircleOutlineIcon className="but-icon-whereabouts" /> Clear
            Transaction{" "}
          </button>
        </div>
        {/* <div className="button-container-subA2">
          <button className="excel-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          <button className="pdf-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button>
        </div> */}
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  let editButtons = [
    {
      buttonModel: {
        content: "Clear",
        cssClass: "e-success update-btn",
        isPrimary: true,
      },
      click: () => {
        handleUpdateRecordButton(selectedID);
        setIsDeleteDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsDeleteDialogVisible(false);
      },
    },
  ];

  const bankAccountTamplate = (props) => {
    const targetAccountData = accountData.find(
      (item) => item.id === props.BankAccount
    );
    const selAccountName = targetAccountData ? targetAccountData.Account : "";
    return <div>{selAccountName}</div>;
  };

  const bankClearedTemplate = (props) => {
    const clearedTrans = props.ClearedBank;
    let formattedValue = "";
    if (clearedTrans === "Not Cleared") {
      formattedValue = (
        <RadioButtonUncheckedIcon style={{ color: "red", fontSize: "30px" }} />
      );
    } else if (clearedTrans === "Cleared") {
      formattedValue = (
        <CheckCircleOutlineIcon style={{ color: "green", fontSize: "30px" }} />
      );
    } else {
      formattedValue = (
        <RadioButtonUncheckedIcon
          style={{ color: "orange", fontSize: "30px" }}
        />
      );
    }
    return <div>{formattedValue}</div>;
  };

  const bankBalanceTemplate = (props) => {
    const deposit = props.Deposit;
    const withdrawal = props.Withdrawal;
    const cleared = props.ClearedBank;
    let netDeposit = 0;
    if (cleared === "Cleared") {
      netDeposit = Number(deposit) + Number(withdrawal);
    } else {
      netDeposit = 0;
    }
    const fontColor = netDeposit < 0 ? "red" : "green";
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(netDeposit);
    return (
      <div style={{ color: fontColor, margin: "0px", padding: "0px" }}>
        {formattedAmount}
      </div>
    );
  };

  const currencyFormatter1 = (field, data, column) => {
    const withdrawal = getValue("Withdrawal", data); // Assuming the field name is "Withdrawal"
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(withdrawal);
    return formattedAmount;
  };

  const currencyFormatter2 = (field, data, column) => {
    const deposit = getValue("Deposit", data); // Assuming the field name is "MonthlyPayment"
    // Format the amount with the desired currency symbol, space separator, and decimal places
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(deposit);
    return formattedAmount;
  };

  const dateFormatter = (field, data, column) => {
    const dateValue = getValue("TransDate", data); // Replace "DateFieldName" with your actual date field name
    // Format the date with the desired options
    const formattedDate = new Intl.DateTimeFormat(user?.Separator, {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(new Date(dateValue));
    return formattedDate;
  };

  const handleUpgradeButtonClick = () => {
    navigate("/packages");
  };

  gridData.sort((a, b) => b.BankAccount - a.BankAccount);

  return (
    <div className="all-outer-project-containers">
      <div className="title-container">
        <div className="mainTitle_dropdown">
          <h3>Uncleared Banking Transactions for {user?.FullName}</h3>
          <div className="_account_owner1">
            <ComboBoxComponent
              id="comboelement"
              value="Select Owner"
              // placeholder="* Account Owner"
              headerTemplate={dropdownTemplate}
              floatLabelType="Always"
              dataSource={["Self", "Partner", "Joint", "Household"]}
              change={handleAccountOwnerChange}
              style={{ color: "#FFE99B", backgroundColor: "black" }}
            />
          </div>
        </div>

        <div className="summay-debit-btns">
          <div className="summary-container-income">
            <div className="bank-firstBTNS">
              <div className="_bank_account">
                <ComboBoxComponent
                  id="comboelement"
                  value="Select Bank"
                  placeholder="Bank Account"
                  headerTemplate={dropdownTemplate}
                  floatLabelType="Always"
                  dataSource={filterBankAccounts}
                  change={handleBankAccountChange}
                  style={{ color: "#FFE99B", backgroundColor: "black" }}
                />
              </div>
              <div className="_years">
                <ComboBoxComponent
                  id="comboelement"
                  value="Year"
                  placeholder="Year"
                  floatLabelType="Always"
                  headerTemplate={dropdownTemplate}
                  dataSource={yearData}
                  allowCustom={true}
                  change={handleYearChange}
                  style={{ color: "#FFE99B", backgroundColor: "black" }}
                />
              </div>
              <div className="_months">
                <ComboBoxComponent
                  id="comboelement"
                  value="Select Month"
                  placeholder="Month"
                  headerTemplate={dropdownTemplate}
                  floatLabelType="Always"
                  dataSource={monthData}
                  change={handleMonthChange}
                  style={{ color: "#FFE99B", backgroundColor: "black" }}
                />
              </div>
            </div>
            {userPackage !== "Basic" && (
              <>
                <div className="bank-secondBTNS">
                  <div className="income_net_pay1">
                    <NumericTextBoxComponent
                      placeholder="Opening Balance"
                      floatLabelType="Always"
                      value={openingBalance}
                      format={usedCurrency}
                      showSpinButton={false}
                      style={{
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  <div className="income_net_pay1">
                    <NumericTextBoxComponent
                      placeholder="Closing Balance"
                      floatLabelType="Always"
                      value={closingBalance}
                      format={usedCurrency}
                      showSpinButton={false}
                      style={{
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            {userPackage === "Basic" && (
              <>
                <div className="bank-secondBTNS">
                  <div className="income_net_pay1">
                    <TextBoxComponent
                      value="Not Available"
                      placeholder="Opening Balance"
                      floatLabelType="Always"
                      disabled={false}
                      style={{
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  <div className="total_">
                    <TextBoxComponent
                      value="Not Available"
                      placeholder="Closing Balance"
                      floatLabelType="Always"
                      disabled={false}
                      style={{
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <p></p>
        </div>
        <>
          {userPackage !== "Basic" &&
            userPackage !== "Standard" &&
            userPackage !== "Premium" && (
              <div className="title-container1">
                <h2>This Feature is Only Available to Paid Users Only</h2>
                <div className="button-container">
                  <div className="button-container-subA1">
                    <button
                      className="add-button"
                      onClick={handleUpgradeButtonClick}
                    >
                      {" "}
                      <UpgradeIcon className="send-icon" />
                      Pay Now
                    </button>
                  </div>
                </div>
              </div>
            )}
          {(userPackage === "Basic" ||
            userPackage === "Standard" ||
            userPackage === "Premium") && (
            <>
              {userPackage === "Basic" && (
                <div className="title-container1">
                  <h2>This Feature is Not Available on Basic Package</h2>
                  <div className="button-container">
                    <div className="button-container-subA1">
                      <button
                        className="add-button"
                        onClick={handleUpgradeButtonClick}
                      >
                        {" "}
                        <UpgradeIcon className="send-icon" />
                        Upgrade Now
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {userPackage !== "Basic" && (
                <>
                  <br />
                  <div className="all-inner-project-containers">
                    {isDataLoaded && (
                      <GridComponent
                        dataSource={gridData}
                        childMapping="items"
                        ref={GridRef}
                        classClass="custom-treegrid-class"
                        height="480"
                        width="100%"
                        allowReordering={true}
                        allowResizing={true}
                        allowExcelExport={true}
                        allowPdfExport={true}
                        allowPaging={true}
                        allowSorting={true}
                        allowFiltering={true}
                        allowTextWrap={true}
                        showColumnChooser={true}
                        filterSettings={FilterOptions}
                        pageSettings={pageSettings}
                        editSettings={editOptions}
                        toolbar={updatedToolbarOptions}
                        loadingIndicator={loadingIndicator}
                        allowSelection={true}
                        rowSelected={handleRowSelect}
                      >
                        <ColumnsDirective>
                          <ColumnDirective
                            template={bankAccountTamplate}
                            headerText="Bank Account"
                            width="120"
                            textAlign="Left"
                          />
                          <ColumnDirective
                            field="AccountOwner"
                            headerText="Account Owner"
                            width="120"
                            textAlign="Left"
                          />
                          <ColumnDirective
                            field="CkNum"
                            headerText="Ck #"
                            width="80"
                            textAlign="Left"
                          />
                          <ColumnDirective
                            field="TransDate"
                            headerText="Trans. Date"
                            width="120"
                            textAlign="Left"
                            allowFiltering={false}
                            valueAccessor={dateFormatter}
                          />
                          <ColumnDirective
                            field="Description"
                            headerText="Transaction Description"
                            width="300"
                            textAlign="Left"
                          />
                          <ColumnDirective
                            field="BudgetCategory"
                            headerText="What is it?"
                            width="130"
                            textAlign="Left"
                          />
                          <ColumnDirective
                            field="BudgetItem"
                            headerText="Budget Item"
                            width="120"
                            textAlign="Left"
                          />
                          <ColumnDirective
                            field="Withdrawal"
                            headerText="Payment, Fee, Withdrawal (-)"
                            width="120"
                            textAlign="Left"
                            valueAccessor={currencyFormatter1}
                          />
                          <ColumnDirective
                            field="Deposit"
                            headerText="Deposit (+)"
                            width="120"
                            textAlign="Left"
                            valueAccessor={currencyFormatter2}
                          />
                          <ColumnDirective
                            field="ClearedBank"
                            headerText="Cleared?"
                            width="85"
                            textAlign="Left"
                            template={bankClearedTemplate}
                          />
                          <ColumnDirective
                            headerText="Cleared Amount"
                            width="120"
                            textAlign="Left"
                            template={bankBalanceTemplate}
                          />
                        </ColumnsDirective>
                        <Inject
                          services={[
                            Page,
                            Sort,
                            Filter,
                            Edit,
                            Toolbar,
                            ColumnChooser,
                            Reorder,
                            Resize,
                            PdfExport,
                            ExcelExport,
                          ]}
                        />
                      </GridComponent>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </>
        <div>
          {isDeleteDialogVisible && (
            <DialogComponent
              visible={isDeleteDialogVisible}
              width="25%"
              height="200px"
              header="Clear Transaction"
              content={`Do you want to clear the selected Bank Account Transaction (${selectedDescription})?`}
              allowDragging={true}
              showCloseIcon={true}
              close={handleDeleteDialogClose}
              buttons={editButtons}
            ></DialogComponent>
          )}
        </div>
      </div>
    </div>
  );
}
export default UnclearedTransaction;
