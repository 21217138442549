import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./components/header/Header";
import ResponsiveHeader from "./components/header/responsiveNavbar";
import { Outlet } from "react-router-dom";
import "./components/header/ResponsiveHeader.css";
import { useState } from "react";
import "./App.css";
function MainLayout() {
  const location = useLocation();
  const [isDisplaySidebar, setisDisplaySidebar] = useState(true);
  //const navigate = useNavigate();

  // Check if the current location is the login page
  const isLoginPage = location.pathname === "/login";
  const RemoveSidebar = () => {
    if (window.innerWidth < 500) setisDisplaySidebar(false);
  };

  useEffect(() => {
    if (window?.innerWidth > 500) setisDisplaySidebar(true);
    else setisDisplaySidebar(false);
  }, [window.innerWidth]);

  return (
    <div>
      {!isLoginPage && (
        <>
          <div className="mobile_sidebar">
            <ResponsiveHeader
              isDisplaySidebar={isDisplaySidebar}
              setisDisplaySidebar={setisDisplaySidebar}
            />
          </div>

          <div
            className="header_wrapper"
            style={{ display: isDisplaySidebar ? "block" : "none" }}
            onClick={RemoveSidebar}>
            <Header
              isDisplaySidebar={isDisplaySidebar}
              setisDisplaySidebar={setisDisplaySidebar}
            />
          </div>
        </>
      )}
      <div className="mainLayout">
        <Outlet />
      </div>
    </div>
  );
}

export default MainLayout;
