import React, { useState, useRef } from "react";

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

import "react-image-crop/dist/ReactCrop.css";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import Compressor from "compressorjs";
import { toast } from "react-toastify";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function ImageCrop({ setCropOpen, setNewProfile }) {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const inputRef = useRef();
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const aspect = 10 / 10;

  function onSelectFile(e) {
    // const reader = new FileReader();
    // reader.readAsDataURL(event.target.files[0]);
    // reader.onloadend = () => {
    //   if (reader.result) {
    //     setImgSrc(reader.result?.toString());
    //   }
    // };

    const file = e.target.files[0];

    if (!file) return;

    if (file?.size > 2000000) {
      toast.warning("Image must be less then 2mb");
      return;
    }

    new Compressor(file, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        const reader = new FileReader();
        reader.readAsDataURL(compressedResult);
        reader.onloadend = () => {
          if (reader.result) {
            setImgSrc(reader.result?.toString());
          }
        };
      },
    });
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  async function onDownloadCropClick() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: "image/png",
    });

    const base64 = await blobToBase64(blob);
    setNewProfile(base64);
    setCropOpen(false);
    // console.log("BLob : ", base64);
    // if (blobUrlRef.current) {
    //   URL.revokeObjectURL(blobUrlRef.current);
    // }
    // blobUrlRef.current = URL.createObjectURL(blob);
    // if (hiddenAnchorRef.current) {
    //   hiddenAnchorRef.current.href = blobUrlRef.current;
    //   hiddenAnchorRef.current.click();
    // }
  }

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        // Convert blob to base64 without the prefix
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0
        );
      }
    },
    100,
    completedCrop
  );

  return (
    <div className="">
      <div className="Crop-Controls">
        <ButtonComponent
          cssClass="crop-btn e-primary"
          onClick={() => inputRef.current.click()}
        >
          Upload
        </ButtonComponent>
        <div className="crop-btns">
          <ButtonComponent
            cssClass="crop-btn e-success"
            onClick={onDownloadCropClick}
          >
            Done
          </ButtonComponent>
          <ButtonComponent
            cssClass="crop-btn e-warning"
            onClick={() => setCropOpen(false)}
          >
            Cancel
          </ButtonComponent>
        </div>
        <input
          ref={inputRef}
          className="e-input"
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          placeholder="Enter Name"
        />
      </div>
      <div>
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => {
              setCrop(percentCrop);
            }}
            onComplete={(c) => setCompletedCrop(c)}
            // aspect={aspect}
            // minWidth={400}
            minHeight={100}
            // circularCrop
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{
                maxHeight: "300px",
                width: "auto",
              }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
        {!!completedCrop && (
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

// import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
// import { useRef, useState } from "react";
// import ReactCrop, {
//   centerCrop,
//   convertToPixelCrop,
//   makeAspectCrop,
// } from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";

// function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
//   return centerCrop(
//     makeAspectCrop(
//       {
//         unit: "%",
//         width: 90,
//       },
//       aspect,
//       mediaWidth,
//       mediaHeight
//     ),
//     mediaWidth,
//     mediaHeight
//   );
// }

// function ImageCrop() {
//   const [crop, setCrop] = useState({
//     unit: "%",
//     width: 50,
//     height: 50,
//     x: 25,
//     y: 25,
//   });
//   const [src, setSrc] = useState();
//   const [completeCrop, setCompleteCrop] = useState();
//   const [aspect, setAspect] = useState(16 / 9);
//   const imgRef = useRef();

//   const handleCrop = (c, percentCrop) => {
//     setCrop(percentCrop);
//     console.log("Crop : ", c);
//     console.log("Per ", percentCrop);
//   };

//   const handleComplete = (e) => {
//     console.log(e);
//     setCompleteCrop(e);
//   };

//   function onImageLoad(e) {
//     if (aspect) {
//       const { width, height } = e.currentTarget;
//       setCrop(centerAspectCrop(width, height, aspect));
//     }
//   }

//   return (
//     <>
// <TextBoxComponent
//   type="file"
//   change={({ event }) => {
//     console.log(event.target.files[0]);
//     const reader = new FileReader();
//     reader.readAsDataURL(event.target.files[0]);
//     reader.onloadend = () => {
//       if (reader.result) {
//         setSrc(reader.result?.toString());
//       }
//     };
//   }}
// />

//       {src && (
//         <ReactCrop
//           crop={crop}
//           onChange={handleCrop}
//           onComplete={handleComplete}
//           aspect={16 / 9}
//           minHeight={100}>
//           <img ref={imgRef} src={src} onLoad={onImageLoad} alt="crop" />
//         </ReactCrop>
//       )}
//     </>
//   );
// }

// export default ImageCrop;
