import React from "react";
import "../Sidebar.css";
import AdminOption from "./AdminOption";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MessageIcon from "@mui/icons-material/Message";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";

import { NavLink } from "react-router-dom";

function Admin() {
  return (
    <div className="sidebar">
      <NavLink to="userprofile">
        {<AdminOption Icon={ManageAccountsIcon} title="User Profile" />}
      </NavLink>
      <NavLink to="budgetcategories">
        {<AdminOption Icon={MessageIcon} title="Budget Categories" />}
      </NavLink>
      <NavLink to="subcategories">
        {<AdminOption Icon={SpeakerNotesIcon} title="Budget Subcategories" />}
      </NavLink>
      <NavLink to="expensegoals">
        {<AdminOption Icon={ShoppingCartIcon} title="Expense Goals" />}
      </NavLink>
      <NavLink to="debtgoals">
        {<AdminOption Icon={CreditCardIcon} title="Debt Goals" />}
      </NavLink>
      <NavLink to="bankaccountnames">
        {
          <AdminOption
            Icon={AccountBalanceWalletIcon}
            title="Bank Account Names"
          />
        }
      </NavLink>
    </div>
  );
}
export default Admin;
