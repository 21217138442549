import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
  Reorder,
  Resize,
  PdfExport,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import { getValue } from "@syncfusion/ej2-base";

import { DialogComponent } from "@syncfusion/ej2-react-popups";
import axios from "../../../config/axios";
import "../../shared/ListView.css";
import useExpenseDataFetch from "../../shared/useExpenseDataFetch"; // Import the custom hook
import useIncomeDataFetch from "../../shared/useIncomeDataFetch";
import useExpenseNameDataFetch from "../../shared/useExpenseNameDataFetch";
import usePaymentMethodsDataFetch from "../../shared/usePaymentMethodsDataFetch";
import useMainCatDataFetch from "../../shared/useMainCatDataFetch";
import useSubCatDataFetch from "../../shared/useSubCatDataFetch";
import useAccountDataFetch from "../../shared/useAccountDataFetch";
import ExpenseDialog from "./ExpenseDialog";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { dropdownTemplate } from "../../shared/HeaderTempate";
import { CheckExpenseFields } from "../../../utils/requiredFields";

function Expenses() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const userPackage = user?.Package;
  const { expenseData, isExpenseLoaded } = useExpenseDataFetch();
  const { incomeData, isIncomeLoaded } = useIncomeDataFetch();
  const { ExpenseNameData, isExpenseNameLoaded } = useExpenseNameDataFetch();
  const { paymentMethodsData, isPaymentMethodsLoaded } =
    usePaymentMethodsDataFetch();
  const { mainCatData, isMainCatLoaded } = useMainCatDataFetch();
  const { subCatData, isSubCatLoaded } = useSubCatDataFetch();
  const { accountData, isAccountLoaded } = useAccountDataFetch();
  const [paymentMethods, setPaymentMethods] = useState();
  const [budgetItems, setBudgetItems] = useState();
  const [expenseGoal, setExpenseGoal] = useState(0);
  const [expenseActual, setExpenseActual] = useState(0);
  const [expenseDifference, setExpenseDifference] = useState(0);
  const [monthTotal, setMonthTotal] = useState(0);
  const [incomeTotal, setIncomeTotal] = useState(0);
  const [diffLabel, setDiffLabel] = useState("Over/Under");
  const [diffIcon, setDiffIcon] = useState("");
  const [subCategories, setSubCategories] = useState();
  const [bankAccounts, setBankAccounts] = useState();
  const [dataFilter, setDataFilter] = useState("Household");
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isAddDialogVisible, setIsAddDialogVisible] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
  }
  const newCurrentDate = formatDate(new Date());
  const [gridData, setGridData] = useState([]);
  const [updatedExpenseData, setUpdatedExpenseData] = useState([]);
  const [latestData, setLatestData] = useState([]);
  const usedCurrency = user?.Currency + " #,###.00";

  const handleAccountOwnerChange = async (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setDataFilter(e.value);
      const accountOwner = e.value;
      let finalExpense;
      let finalIncome;
      if (user?.Type !== "Root") {
        finalExpense = updatedExpenseData?.filter(
          (item) => item.UserID === user?.id
        );
        finalIncome = incomeData.filter((item) => item.UserID === user?.id);
      } else {
        finalExpense = updatedExpenseData;
        finalIncome = incomeData;
      }
      finalExpense?.sort(
        (a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn)
      );
      finalIncome.sort((a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn));
      let filteredData;
      let filteredIncome;
      if (
        accountOwner === "Self" ||
        accountOwner === "Partner" ||
        accountOwner === "Joint"
      ) {
        filteredData = finalExpense?.filter(
          (item) => item.AccountOwner === accountOwner
        );
        filteredIncome = finalIncome.filter(
          (item) => item.AccountOwner === accountOwner
        );
      } else {
        filteredData = finalExpense.filter(
          (item) => item.BudgetItem !== "Joint Contribution"
        );
        filteredIncome = finalIncome;
      }
      setGridData(filteredData);
      const totalSum = filteredData?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0);
      setMonthTotal(totalSum);
      const totalMonthlyIncome = filteredIncome.reduce(
        (accumulator, record) => {
          const netPay = record.NetPay || 0; // Handle the case where NetPay is undefined
          const payFrequency = record.PayFrequency || ""; // Handle the case where PayFrequency is undefined
          let monthlyIncome = 0;
          // Determine monthlyIncome based on PayFrequency
          switch (payFrequency) {
            case "Yearly":
              monthlyIncome = netPay / 12;
              break;
            case "Monthly":
              monthlyIncome = netPay;
              break;
            case "Semi-Monthly":
              monthlyIncome = netPay * 2;
              break;
            case "Weekly":
              monthlyIncome = netPay * 4;
              break;
            case "Bi-Weekly":
              monthlyIncome = netPay * 2;
              break;
            default:
              monthlyIncome = 0;
          }
          // Add monthlyIncome to the accumulator
          return accumulator + Number(monthlyIncome);
        },
        0
      ); // Initialize accumulator with 0
      setIncomeTotal(totalMonthlyIncome);
      setIsDataChanged(true);
    }
  };

  useEffect(() => {
    const expensePerc = Number(monthTotal) / Number(incomeTotal);
    const difference =
      (Number(expenseGoal) - Number(expensePerc)) * Number(incomeTotal);
    if (Number(difference) < 0) {
      setDiffLabel("Over by");
      setDiffIcon(
        <ThumbDownIcon
          style={{ color: "red", fontSize: "40px", paddingTop: "35px" }}
        />
      );
    } else if (Number(difference) > 0) {
      setDiffLabel("Under by");
      setDiffIcon(
        <ThumbUpIcon
          style={{ color: "green", fontSize: "40px", paddingTop: "35px" }}
        />
      );
    } else {
      setDiffLabel("Over/Under");
      setDiffIcon(
        <ThumbUpIcon
          style={{ color: "orange", fontSize: "40px", paddingTop: "35px" }}
        />
      );
    }
    setExpenseActual(expensePerc);
    setExpenseDifference(difference);
  }, [monthTotal, incomeTotal, expenseGoal]);

  useEffect(() => {
    if (isPaymentMethodsLoaded) {
      const uniquePayMethods = [
        ...new Set(
          paymentMethodsData.map((item) =>
            item.description ? item.description : ""
          )
        ),
      ];
      uniquePayMethods.sort((a, b) => a.localeCompare(b));
      setPaymentMethods(uniquePayMethods);
    }
  }, [paymentMethodsData, isPaymentMethodsLoaded]);

  useEffect(() => {
    if (isSubCatLoaded) {
      const uniqueSubCats = [
        ...new Set(
          subCatData.map((item) => (item.Category ? item.Category : ""))
        ),
      ];
      setSubCategories(uniqueSubCats);
    }
  }, [subCatData, isSubCatLoaded]);

  useEffect(() => {
    if (isMainCatLoaded) {
      const filteredData = mainCatData.find(
        (item) => item.Category === "Expenses"
      );
      if (filteredData) {
        const uniqueGoal = filteredData.Goal;
        setExpenseGoal(Number(uniqueGoal) / 100);
      } else {
        setExpenseGoal(0);
      }
    }
  }, [mainCatData, isMainCatLoaded]);

  useEffect(() => {
    if (isExpenseNameLoaded) {
      const uniqueBudgetItems = [
        ...new Set(
          ExpenseNameData.map((item) =>
            item.BudgetItem ? item.BudgetItem : ""
          )
        ),
      ];
      uniqueBudgetItems.sort((a, b) => a.localeCompare(b));
      setBudgetItems(uniqueBudgetItems);
    }
  }, [ExpenseNameData, isExpenseNameLoaded]);

  useEffect(() => {
    if (isExpenseLoaded && isIncomeLoaded && isAccountLoaded) {
      setUpdatedExpenseData(expenseData);
      let finalExpense;
      let finalIncome;
      if (user?.Type !== "Root") {
        finalExpense = expenseData.filter((item) => item.UserID === user?.id);
        finalIncome = incomeData.filter((item) => item.UserID === user?.id);
      } else {
        finalExpense = expenseData;
        finalIncome = incomeData;
      }
      finalExpense.sort(
        (a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn)
      );
      finalIncome.sort((a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn));

      let filteredData;
      let filteredIncome;
      if (
        dataFilter === "Self" ||
        dataFilter === "Partner" ||
        dataFilter === "Joint"
      ) {
        filteredData = finalExpense.filter(
          (item) => item.AccountOwner === dataFilter
        );
        filteredIncome = finalIncome.filter(
          (item) => item.AccountOwner === dataFilter
        );
      } else {
        filteredData = finalExpense.filter(
          (item) => item.BudgetItem !== "Joint Contribution"
        );
        filteredIncome = finalIncome;
      }
      // filteredData is an array of objects with properties MonthlyPayment and Adjustments
      const totalSum = filteredData.reduce((accumulator, record) => {
        const monthlyPayment = record.MonthlyPayment || 0; // Handle the case where MonthlyPayment is undefined
        const adjustments = record.Adjustments || 0; // Handle the case where Adjustments is undefined
        // Add the MonthlyPayment and Adjustments to the accumulator
        return accumulator + Number(monthlyPayment) + Number(adjustments);
      }, 0); // Initialize accumulator with 0
      setMonthTotal(totalSum);

      // Assuming incomeData is an array of objects with properties NetPay and PayFrequency
      const totalMonthlyIncome = filteredIncome.reduce(
        (accumulator, record) => {
          const netPay = record.NetPay || 0; // Handle the case where NetPay is undefined
          const payFrequency = record.PayFrequency || ""; // Handle the case where PayFrequency is undefined
          let monthlyIncome = 0;
          // Determine monthlyIncome based on PayFrequency
          switch (payFrequency) {
            case "Yearly":
              monthlyIncome = netPay / 12;
              break;
            case "Monthly":
              monthlyIncome = netPay;
              break;
            case "Semi-Monthly":
              monthlyIncome = netPay * 2;
              break;
            case "Weekly":
              monthlyIncome = netPay * 4;
              break;
            case "Bi-Weekly":
              monthlyIncome = netPay * 2;
              break;
            default:
              monthlyIncome = 0;
          }
          // Add monthlyIncome to the accumulator
          return accumulator + Number(monthlyIncome);
        },
        0
      ); // Initialize accumulator with 0
      setIncomeTotal(totalMonthlyIncome);
      setGridData(filteredData);
      setLatestData(finalExpense);
      const uniqueBankAccounts = [
        ...new Set(
          accountData.map((item) => (item.Account ? item.Account : ""))
        ),
      ];
      uniqueBankAccounts.sort((a, b) => a.localeCompare(b));
      setBankAccounts(uniqueBankAccounts);
      setIsDataChanged(true);
      setIsDataLoaded(true);
    }
  }, [
    expenseData,
    incomeData,
    accountData,
    isExpenseLoaded,
    isIncomeLoaded,
    isAccountLoaded,
    user,
  ]);

  // Show toast when isIncomeLoaded becomes false
  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded || !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  const [selectedID, setSelectedID] = useState("");
  const [selectedRootID, setSelectedRootID] = useState("");
  const [selectedUserID, setSelectedUserID] = useState("");
  const [selectedAccountOwner, setSelectedAccountOwner] = useState("");
  const [selectedBudgetItem, setSelectedBudgetItem] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedBudgetType, setSelectedBudgetType] = useState("");
  const [selectedDayDue, setSelectedDayDue] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [selectedBankAccount, setSelectedBankAccount] = useState("");
  const [selectedAccountID, setSelectedAccountID] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedMonthlyPayment, setSelectedMonthlyPayment] = useState("");
  const [selectedAdjustments, setSelectedAdjustments] = useState("");

  const [newRootID, setNewRootID] = useState("");
  const [newUserID, setNewUserID] = useState("");
  const [newAccountOwner, setNewAccountOwner] = useState("");
  const [newBudgetItem, setNewBudgetItem] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newBudgetType, setNewBudgetType] = useState("");
  const [newDayDue, setNewDayDue] = useState("");
  const [newPaymentMethod, setNewPaymentMethod] = useState("");
  const [newBankAccount, setNewBankAccount] = useState("");
  const [newSubCategory, setNewSubCategory] = useState("");
  const [newMonthlyPayment, setNewMonthlyPayment] = useState(0);
  const [newAdjustments, setNewAdjustments] = useState(0);
  const [isRequired, setIsRequired] = useState(false);

  const expenseNewData = {
    RootUserID: newRootID,
    RegUserID: newUserID,
    AccountOwner: newAccountOwner,
    BudgetItem: newBudgetItem,
    Description: newDescription,
    BudgetType: newBudgetType,
    DayDue: newDayDue,
    PaymentMethod: newPaymentMethod,
    BankAccount: newBankAccount,
    SubCategory: newSubCategory,
    MonthlyPayment: newMonthlyPayment,
    Adjustments: newAdjustments,
    UpdatedOn: newCurrentDate,
  };

  const handleRowSelect = async (args) => {
    // Set the selected ID when a row is selected in the Grid
    setSelectedID(args.data.id);
    setSelectedRootID(args.data.RootID);
    setSelectedUserID(args.data.UserID);
    setSelectedAccountOwner(args.data.AccountOwner);
    setSelectedBudgetItem(args.data.BudgetItem);
    setSelectedDescription(args.data.Description);
    setSelectedBudgetType(args.data.BudgetType);
    setSelectedDayDue(args.data.DayDue);
    setSelectedPaymentMethod(args.data.PaymentMethod);
    setSelectedAccountID(args.data.BankAccount);
    const targetAccountData = accountData.find(
      (item) => item.id === args.data.BankAccount
    );
    const selAccountName = targetAccountData ? targetAccountData.Account : "";
    setSelectedBankAccount(selAccountName);
    setSelectedSubCategory(args.data.SubCategory);
    setSelectedMonthlyPayment(args.data.MonthlyPayment);
    setSelectedAdjustments(args.data.Adjustments);
  };

  const handleExcelButtonClick = () => {};

  const handlePdfButtonClick = () => {};

  const handleEditDialogClose = () => {
    setIsEditDialogVisible(false);
  };

  const handleAddDialogClose = () => {
    setIsAddDialogVisible(false);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    setIsEditDialogVisible(!isEditDialogVisible);
    setNewRootID(selectedRootID);
    setNewUserID(selectedUserID);
    setNewAccountOwner(selectedAccountOwner);
    setNewBudgetItem(selectedBudgetItem);
    setNewDescription(selectedDescription);
    setNewBudgetType(selectedBudgetType);
    setNewDayDue(selectedDayDue);
    setNewPaymentMethod(selectedPaymentMethod);
    setNewBankAccount(parseInt(selectedAccountID));
    setNewSubCategory(selectedSubCategory);
    setNewMonthlyPayment(selectedMonthlyPayment);
    setNewAdjustments(selectedAdjustments);
    setIsRequired(false);
  };

  const handleAddButtonClick = () => {
    setIsAddDialogVisible(!isAddDialogVisible);
    setSelectedID("");
    setSelectedRootID("");
    setSelectedUserID("");
    setSelectedAccountOwner("");
    setSelectedBudgetItem("");
    setSelectedDescription("");
    setSelectedBudgetType("");
    setSelectedDayDue("");
    setSelectedPaymentMethod("");
    setSelectedBankAccount("");
    setSelectedSubCategory("");
    setSelectedMonthlyPayment("");
    setSelectedAdjustments("");
    if (GridRef.current) {
      GridRef.current.clearSelection();
    }
    setNewRootID(user?.RootID);
    setNewUserID(user?.id);
    setNewAccountOwner("");
    setNewBudgetItem("");
    setNewDescription("");
    setNewBudgetType("");
    setNewDayDue("");
    setNewPaymentMethod("");
    setNewBankAccount("");
    setNewSubCategory("");
    setNewMonthlyPayment("");
    setNewAdjustments(0);
    setIsRequired(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeleteDialogVisible(!isDeleteDialogVisible);
  };

  const handleAddRecordButton = async () => {
    // Make a POST request to add the Record record
    setIsDataChanged(false);
    try {
      const RecordResponse = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/expense",
        expenseNewData
      );
      console.log("Record response:", RecordResponse.data);
      if (RecordResponse.data.status === "success") {
        toast.success("Added successfully.");
        try {
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/expense/rootuserid/${user?.RootID}`
          );
          const Records = updatedResponse.data.items;
          setUpdatedExpenseData(Records);
          let finalExpense;
          if (user?.Type !== "Root") {
            finalExpense = Records?.filter((item) => item.UserID === user?.id);
          } else {
            finalExpense = Records;
          }
          finalExpense?.sort(
            (a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn)
          );
          let filteredData;
          if (
            dataFilter === "Self" ||
            dataFilter === "Partner" ||
            dataFilter === "Joint"
          ) {
            filteredData = finalExpense?.filter(
              (item) => item.AccountOwner === dataFilter
            );
          } else {
            filteredData = finalExpense;
          }
          setGridData(filteredData);
          setLatestData(finalExpense);
          const totalSum = filteredData?.reduce((accumulator, record) => {
            const monthlyPayment = record?.MonthlyPayment || 0;
            const adjustments = record?.Adjustments || 0;
            return accumulator + Number(monthlyPayment) + Number(adjustments);
          }, 0);
          setMonthTotal(totalSum);
        } catch (fetchError) {
          setMonthTotal(0);
          setGridData([]);
          setUpdatedExpenseData([]);
          setBankAccounts("");
        }
      } else {
        console.error(
          "Error adding the record record:",
          RecordResponse.data.message
        );
        toast.error("Error adding the record");
      }
    } catch (error) {
      console.error("Error adding the record:", error);
      toast.error("Error adding the record");
    }
    setIsDataChanged(true);
  };

  const handleUpdateRecordButton = async (selectedID) => {
    setIsDataChanged(false);
    const numericSelectedID = parseInt(selectedID);
    try {
      const RecordResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/expense/${numericSelectedID}`,
        {
          RootUserID: newRootID,
          RegUserID: newUserID,
          AccountOwner: newAccountOwner,
          BudgetItem: newBudgetItem,
          Description: newDescription,
          BudgetType: newBudgetType,
          DayDue: newDayDue,
          PaymentMethod: newPaymentMethod,
          BankAccount: newBankAccount,
          SubCategory: newSubCategory,
          MonthlyPayment: newMonthlyPayment,
          Adjustments: newAdjustments,
          UpdatedOn: newCurrentDate,
        }
      );
      console.log("Record response:", RecordResponse.data);
      if (RecordResponse.data.status === "success") {
        toast.success("the record updated successfully.");
        try {
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/expense/rootuserid/${user?.RootID}`
          );
          const Records = updatedResponse.data.items;
          setUpdatedExpenseData(Records);
          let finalExpense;
          if (user?.Type !== "Root") {
            finalExpense = Records?.filter((item) => item.UserID === user?.id);
          } else {
            finalExpense = Records;
          }
          finalExpense?.sort(
            (a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn)
          );
          let filteredData;
          if (
            dataFilter === "Self" ||
            dataFilter === "Partner" ||
            dataFilter === "Joint"
          ) {
            filteredData = finalExpense?.filter(
              (item) => item.AccountOwner === dataFilter
            );
          } else {
            filteredData = finalExpense;
          }
          setGridData(filteredData);
          setLatestData(finalExpense);
          const totalSum = filteredData?.reduce((accumulator, record) => {
            const monthlyPayment = record?.MonthlyPayment || 0;
            const adjustments = record?.Adjustments || 0;
            return accumulator + Number(monthlyPayment) + Number(adjustments);
          }, 0);
          setMonthTotal(totalSum);
        } catch (fetchError) {
          setMonthTotal(0);
          setGridData([]);
          setUpdatedExpenseData([]);
          setBankAccounts("");
        }
      } else {
        console.error(
          "Error updating the record record:",
          RecordResponse.data.message
        );
        toast.error("Error updating the record");
      }
    } catch (error) {
      console.error("Error updating the record:", error);
      toast.error("Error updating the record");
    }
    setIsDataChanged(true);
  };

  const handleDeleteRecordButton = async (selectedID) => {
    setIsDataChanged(false);
    const numericSelectedID = parseInt(selectedID);

    try {
      const deleteResponse = await axios.delete(
        process.env.REACT_APP_SERVER_URL + `/api/expense/${numericSelectedID}`
      );
      console.log("Delete response:", deleteResponse.data);
      if (deleteResponse.data.status === "success") {
        toast.success("The record was deleted successfully.");
        try {
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/expense/rootuserid/${user?.RootID}`
          );
          const Records = updatedResponse.data.items;
          setUpdatedExpenseData(Records);
          let finalExpense;
          if (user?.Type !== "Root") {
            finalExpense = Records?.filter((item) => item.UserID === user?.id);
          } else {
            finalExpense = Records;
          }
          finalExpense?.sort(
            (a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn)
          );
          let filteredData;
          if (
            dataFilter === "Self" ||
            dataFilter === "Partner" ||
            dataFilter === "Joint"
          ) {
            filteredData = finalExpense?.filter(
              (item) => item.AccountOwner === dataFilter
            );
          } else {
            filteredData = finalExpense;
          }
          setGridData(filteredData);
          setLatestData(finalExpense);
          const totalSum = filteredData?.reduce((accumulator, record) => {
            const monthlyPayment = record?.MonthlyPayment || 0;
            const adjustments = record?.Adjustments || 0;
            return accumulator + Number(monthlyPayment) + Number(adjustments);
          }, 0);
          setMonthTotal(totalSum);
        } catch (fetchError) {
          setMonthTotal(0);
          setGridData([]);
          setUpdatedExpenseData([]);
          setBankAccounts("");
        }
      } else {
        console.error(
          "Error deleting the record:",
          deleteResponse.data.message
        );
        toast.error("Error deleting item");
      }
    } catch (error) {
      console.error("Error deleting the record:", error);
      toast.error("Error deleting the record");
    }
    setIsDataChanged(true);
  };

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = ["ColumnChooser"];
  const loadingIndicator = { indicatorType: "Spinner" };

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          <button className="add-button" onClick={handleAddButtonClick}>
            <AddIcon className="send-icon" /> Add New{" "}
          </button>
          <button
            className="edit-button"
            onClick={handleEditButtonClick}
            disabled={selectedID === ""}
          >
            <EditIcon className="send-icon" /> Edit{" "}
          </button>
          <button
            className="delete-button"
            onClick={handleDeleteButtonClick}
            disabled={selectedID === ""}
          >
            <DeleteIcon className="but-icon-whereabouts" /> Delete{" "}
          </button>
        </div>
        {/* <div className="button-container-subA2">
          <button className="excel-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          <button className="pdf-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button>
        </div> */}
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  let addButtons = [
    {
      buttonModel: {
        content: "Save New",
        cssClass: "e-success update-btn",
        isPrimary: true,
      },
      click: () => {
        if (
          CheckExpenseFields(
            newAccountOwner,
            newBudgetItem,
            newDescription,
            newSubCategory,
            newBudgetType,
            newDayDue,
            newMonthlyPayment,
            newBankAccount
          )
        ) {
          setIsRequired(true);
        } else {
          handleAddRecordButton(selectedID);
          setIsAddDialogVisible(false);
        }
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsAddDialogVisible(false);
      },
    },
  ];

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-success update-btn",
        isPrimary: true,
      },
      click: () => {
        if (
          CheckExpenseFields(
            newAccountOwner,
            newBudgetItem,
            newDescription,
            newSubCategory,
            newBudgetType,
            newDayDue,
            newMonthlyPayment,
            newBankAccount
          )
        ) {
          setIsRequired(true);
        } else {
          handleUpdateRecordButton(selectedID);
          setIsEditDialogVisible(false);
        }
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsEditDialogVisible(false);
      },
    },
  ];

  let deleteButtons = [
    {
      buttonModel: {
        content: "Delete",
        cssClass: "e-danger update-btn",
        isPrimary: true,
      },
      click: () => {
        handleDeleteRecordButton(selectedID);
        setIsDeleteDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsDeleteDialogVisible(false);
      },
    },
  ];

  const accountNameTemplate = (props) => {
    const accountID = props.BankAccount;
    const targetAccountData = accountData.find((item) => item.id === accountID);
    if (targetAccountData) {
      const accountName = targetAccountData.Account;
      return <div>{accountName}</div>;
    } else {
      return <div>" "</div>;
    }
  };

  const adjustedMonthlyPayTemplate = (props) => {
    const originalPay = props.MonthlyPayment;
    const adjustment = props.Adjustments;
    const adjustedMonthlyPay = Number(originalPay) + Number(adjustment);
    const fontColor =
      adjustedMonthlyPay > Number(originalPay) ? "inherit" : "inherit";
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(adjustedMonthlyPay);

    return <div style={{ color: fontColor }}>{formattedAmount}</div>;
  };

  const adjustedAnnualPayTemplate = (props) => {
    const originalPay = props.MonthlyPayment;
    const adjustment = props.Adjustments;
    const adjustedAnnualPay = 12 * (Number(originalPay) + Number(adjustment));
    const fontColor =
      Number(originalPay) + Number(adjustment) > Number(originalPay)
        ? "inherit"
        : "inherit";
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(adjustedAnnualPay);
    return <div style={{ color: fontColor }}>{formattedAmount}</div>;
  };

  const originalAnnualPayTemplate = (props) => {
    const originalPay = props.MonthlyPayment;
    const originalAnnualPay = 12 * Number(originalPay);
    const fontColor = originalAnnualPay < 0 ? "inherit" : "inherit";
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(originalAnnualPay);
    return <div style={{ color: fontColor }}>{formattedAmount}</div>;
  };

  const currencyFormatter1 = (field, data, column) => {
    const monthlyPayment = getValue("MonthlyPayment", data); // Assuming the field name is "MonthlyPayment"
    // Format the amount with the desired currency symbol, space separator, and decimal places
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(monthlyPayment);
    return formattedAmount;
  };

  const currencyFormatter2 = (field, data, column) => {
    const adjustments = getValue("Adjustments", data); // Assuming the field name is "MonthlyPayment"
    // Format the amount with the desired currency symbol, space separator, and decimal places
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(adjustments);
    return formattedAmount;
  };

  const handleUpgradeButtonClick = () => {
    navigate("/packages");
  };

  return (
    <div className="all-outer-project-containers">
      <div className="title-container">
        <div className="mainTitle_dropdown">
          <h3>Expenses for {user?.FullName}</h3>
          <div className="_account_owner1">
            <ComboBoxComponent
              id="comboelement"
              value="Select Owner"
              headerTemplate={dropdownTemplate}
              floatLabelType="Always"
              dataSource={["Self", "Partner", "Joint", "Household"]}
              change={handleAccountOwnerChange}
              style={{ color: "#FFE99B", backgroundColor: "black" }}
            />
          </div>
        </div>

        <div
          className="summay-btns-exp"
          style={{
            width: "100%",
            backgroundColor: "rgba(252, 221, 178, 20%)",
          }}
        >
          <div className="summary-container-income">
            <div className="firstBTNS">
              <div className="income_net_pay1">
                <p
                  style={{
                    fontSize: "12.5px",
                    fontWeight: "bold",
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Monthly Net Income
                </p>
                <div className="value">
                  <strong style={{ fontWeight: "bold" }}>
                    {user?.Currency}
                    {incomeTotal.toLocaleString(user?.Separator, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </strong>
                </div>
              </div>

              <div className="income_net_pay1">
                <p
                  style={{
                    fontSize: "12.5px",
                    fontWeight: "bold",
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Monthly Expenses
                </p>
                <div className="value">
                  <strong style={{ fontWeight: "bold" }}>
                    {user?.Currency}
                    {monthTotal.toLocaleString(user?.Separator, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </strong>
                </div>
              </div>
            </div>

            <div className="secondBTNS">
              <div className="income_net_pay1">
                <p
                  style={{
                    fontSize: "12.5px",
                    fontWeight: "bold",
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Actual %
                </p>
                <div className="value">
                  <strong style={{ fontWeight: "bold" }}>
                    {(expenseActual * 100).toFixed(2) + " %"}
                  </strong>
                </div>
              </div>
              <div className="income_net_pay1">
                <p
                  style={{
                    fontSize: "12.5px",
                    fontWeight: "bold",
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Goal %
                </p>
                <div className="value">
                  <strong style={{ fontWeight: "bold" }}>
                    {(expenseGoal * 100).toFixed(2) + " %"}
                  </strong>
                </div>
              </div>
            </div>

            <div className="thirdBTNS">
              <div className="income_net_pay1">
                <p
                  style={{
                    fontSize: "12.5px",
                    fontWeight: "bold",
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {diffLabel}
                </p>
                <div className="value">
                  <strong style={{ fontWeight: "bold" }}>
                    {user?.Currency}
                    {expenseDifference.toLocaleString(user?.Separator, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </strong>
                </div>
              </div>
              <div className="_over_under_icon">
                <h1>{diffIcon}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <>
        {userPackage !== "Basic" &&
          userPackage !== "Standard" &&
          userPackage !== "Premium" && (
            <div className="title-container1">
              <h2>This Feature is Only Available to Paid Users Only</h2>
              <div className="button-container">
                <div className="button-container-subA1">
                  <button
                    className="add-button"
                    onClick={handleUpgradeButtonClick}
                  >
                    {" "}
                    <UpgradeIcon className="send-icon" />
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          )}
        {(userPackage === "Basic" ||
          userPackage === "Standard" ||
          userPackage === "Premium") && (
          <>
            <div className="all-inner-project-containers">
              {isDataLoaded && (
                <GridComponent
                  dataSource={gridData}
                  childMapping="items"
                  ref={GridRef}
                  classClass="custom-treegrid-class"
                  height="480"
                  width="100%"
                  allowReordering={true}
                  allowResizing={true}
                  allowExcelExport={true}
                  allowPdfExport={true}
                  allowPaging={true}
                  allowSorting={true}
                  allowFiltering={true}
                  allowTextWrap={true}
                  showColumnChooser={true}
                  filterSettings={FilterOptions}
                  pageSettings={pageSettings}
                  editSettings={editOptions}
                  toolbar={updatedToolbarOptions}
                  loadingIndicator={loadingIndicator}
                  allowSelection={true}
                  rowSelected={handleRowSelect}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="AccountOwner"
                      headerText="Account Owner"
                      width="120"
                      textAlign="Left"
                    />
                    <ColumnDirective
                      field="BudgetItem"
                      headerText="Budget Item"
                      width="180"
                      textAlign="Left"
                    />
                    <ColumnDirective
                      field="Description"
                      headerText="Expense Description"
                      width="300"
                      textAlign="Left"
                    />
                    <ColumnDirective
                      field="SubCategory"
                      headerText="Subcategory"
                      width="230"
                      textAlign="Left"
                    />
                    <ColumnDirective
                      field="BudgetType"
                      headerText="Budget Type"
                      width="120"
                      textAlign="Left"
                    />
                    <ColumnDirective
                      headerText="Bank Account"
                      width="200"
                      textAlign="Left"
                      template={accountNameTemplate}
                    />
                    <ColumnDirective
                      field="PaymentMethod"
                      headerText="Payment Method"
                      width="150"
                      textAlign="Left"
                    />
                    <ColumnDirective
                      field="DayDue"
                      headerText="Due Date"
                      width="90"
                      textAlign="Left"
                    />
                    <ColumnDirective
                      field="MonthlyPayment"
                      headerText="Original Monthly Expenses"
                      width="160"
                      textAlign="Left"
                      valueAccessor={currencyFormatter1}
                    />
                    <ColumnDirective
                      field="Adjustments"
                      headerText="Adjustment"
                      width="140"
                      textAlign="Left"
                      valueAccessor={currencyFormatter2}
                    />
                    <ColumnDirective
                      headerText="Adjusted Monthly Expenses"
                      width="160"
                      textAlign="Left"
                      template={adjustedMonthlyPayTemplate}
                    />
                    <ColumnDirective
                      headerText="Original Annual Expenses"
                      width="160"
                      textAlign="Left"
                      template={originalAnnualPayTemplate}
                    />
                    <ColumnDirective
                      headerText="Adjusted Annual Expenses"
                      width="160"
                      textAlign="Left"
                      template={adjustedAnnualPayTemplate}
                    />
                  </ColumnsDirective>
                  <Inject
                    services={[
                      Page,
                      Sort,
                      Filter,
                      Edit,
                      Toolbar,
                      ColumnChooser,
                      Reorder,
                      Resize,
                      PdfExport,
                      ExcelExport,
                    ]}
                  />
                </GridComponent>
              )}
            </div>
          </>
        )}
      </>
      <div>
        {isEditDialogVisible && (
          <DialogComponent
            visible={isEditDialogVisible}
            width="60%"
            height="580px"
            header="Edit Expense"
            allowDragging={true}
            showCloseIcon={true}
            close={handleEditDialogClose}
            buttons={editButtons}
          >
            <ExpenseDialog
              selectedAccountOwner={selectedAccountOwner}
              selectedBudgetItem={selectedBudgetItem}
              selectedDescription={selectedDescription}
              selectedBudgetType={selectedBudgetType}
              selectedDayDue={selectedDayDue}
              selectedPaymentMethod={selectedPaymentMethod}
              selectedBankAccount={selectedBankAccount}
              selectedSubCategory={selectedSubCategory}
              selectedMonthlyPayment={selectedMonthlyPayment}
              selectedAdjustments={selectedAdjustments}
              bankAccounts={bankAccounts}
              subCategories={subCategories}
              paymentMethods={paymentMethods}
              budgetItems={budgetItems}
              ExpenseNameData={ExpenseNameData}
              accountData={accountData}
              latestData={latestData}
              usedCurrency={usedCurrency}
              setNewAccountOwner={setNewAccountOwner}
              setNewBudgetItem={setNewBudgetItem}
              setNewDescription={setNewDescription}
              setNewBudgetType={setNewBudgetType}
              setNewDayDue={setNewDayDue}
              setNewPaymentMethod={setNewPaymentMethod}
              setNewBankAccount={setNewBankAccount}
              setNewSubCategory={setNewSubCategory}
              setNewMonthlyPayment={setNewMonthlyPayment}
              setNewAdjustments={setNewAdjustments}
              isRequired={isRequired}
            />
          </DialogComponent>
        )}
      </div>
      <div>
        {isAddDialogVisible && (
          <DialogComponent
            visible={isAddDialogVisible}
            width="60%"
            height="580px"
            header="Add Expense"
            allowDragging={true}
            showCloseIcon={true}
            close={handleAddDialogClose}
            buttons={addButtons}
          >
            <ExpenseDialog
              selectedAccountOwner={selectedAccountOwner}
              selectedBudgetItem={selectedBudgetItem}
              selectedDescription={selectedDescription}
              selectedBudgetType={selectedBudgetType}
              selectedDayDue={selectedDayDue}
              selectedPaymentMethod={selectedPaymentMethod}
              selectedBankAccount={selectedBankAccount}
              selectedSubCategory={selectedSubCategory}
              selectedMonthlyPayment={selectedMonthlyPayment}
              selectedAdjustments={selectedAdjustments}
              bankAccounts={bankAccounts}
              subCategories={subCategories}
              paymentMethods={paymentMethods}
              budgetItems={budgetItems}
              ExpenseNameData={ExpenseNameData}
              accountData={accountData}
              latestData={latestData}
              usedCurrency={usedCurrency}
              setNewAccountOwner={setNewAccountOwner}
              setNewBudgetItem={setNewBudgetItem}
              setNewDescription={setNewDescription}
              setNewBudgetType={setNewBudgetType}
              setNewDayDue={setNewDayDue}
              setNewPaymentMethod={setNewPaymentMethod}
              setNewBankAccount={setNewBankAccount}
              setNewSubCategory={setNewSubCategory}
              setNewMonthlyPayment={setNewMonthlyPayment}
              setNewAdjustments={setNewAdjustments}
              isRequired={isRequired}
            />
          </DialogComponent>
        )}
      </div>
      <div>
        {isDeleteDialogVisible && (
          <DialogComponent
            visible={isDeleteDialogVisible}
            width="25%"
            height="200px"
            header="Delete Expense"
            content={`Do you want to delete the selected expense (${selectedDescription})?`}
            allowDragging={true}
            showCloseIcon={true}
            close={handleDeleteDialogClose}
            buttons={deleteButtons}
          ></DialogComponent>
        )}
      </div>
    </div>
  );
}
export default Expenses;
