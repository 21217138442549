import React, { useState, useEffect } from "react";
import {
  TextBoxComponent,
  NumericTextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import useDebtNameDataFetch from "../../shared/useDebtNameDataFetch";
import useExpenseNameDataFetch from "../../shared/useExpenseNameDataFetch";

import "./BankAccountDialog.css";
import { dropdownTemplate, requiredNote } from "../../shared/HeaderTempate";

function BankAccountDialog({
  selectedAccountOwner,
  selectedBudgetItem,
  selectedDescription,
  selectedBudgetCategory,
  selectedCkNum,
  selectedClearedBank,
  selectedBankAccount,
  selectedOtherAccount,
  selectedTransDate,
  selectedWithdrawal,
  selectedDeposit,
  selectedTransType,
  bankAccounts,
  accountData,
  usedCurrency,
  setNewAccountOwner,
  setNewBudgetItem,
  setNewDescription,
  setNewBudgetCategory,
  setNewCkNum,
  setNewClearedBank,
  setNewBankAccount,
  setNewOtherAccount,
  setNewTransDate,
  setNewWithdrawal,
  setNewDeposit,
  setNewTransType,
  isRequired,
}) {
  const { debtNameData, isDebtNameLoaded } = useDebtNameDataFetch();
  const { ExpenseNameData, isExpenseNameLoaded } = useExpenseNameDataFetch();
  const [budgetCategoryTitle, setBudgetCategoryTitle] = useState("");
  const [budgetCategoryData, setBudgetCategoryData] = useState("");
  const [budgetItemTitle, setBudgetItemTitle] = useState();
  const [budgetItemData, setBudgetItemData] = useState();
  const [debtItem, setDebtItem] = useState();
  const [expenseItem, setExpenseItem] = useState();
  const [isOtherAccountEnabled, setIsOtherAccountEnabled] = useState(false);
  const [isDeposit, setIsDeposit] = useState(false);
  const [isWithdrawal, setIsWithdrawal] = useState(false);

  const creditCardCat = ["Credit Card Payment"];
  const depositCat = ["Bank Deposit"];
  const transferCat = ["Transaction Transfer"];
  const withdrawalCat = ["Debt", "Expense", "Retirement", "Savings"];

  const creditCardItem = ["Total Charges Previously Entered in the Register"];
  const depositItem = ["Direct Deposit"];
  const transferItem = ["From Another Bank Account", "To Another Bank Account"];
  const retirementItem = ["Retirement"];
  const savingsItem = ["Savings"];

  useEffect(() => {
    if (isDebtNameLoaded) {
      const uniqueBudgetItems = [
        ...new Set(
          debtNameData.map((item) => (item.BudgetItem ? item.BudgetItem : ""))
        ),
      ];
      setDebtItem(uniqueBudgetItems);
    }
  }, [debtNameData, isDebtNameLoaded]);

  useEffect(() => {
    if (isExpenseNameLoaded) {
      const uniqueBudgetItems = [
        ...new Set(
          ExpenseNameData.map((item) =>
            item.BudgetItem ? item.BudgetItem : ""
          )
        ),
      ];
      setExpenseItem(uniqueBudgetItems);
    }
  }, [ExpenseNameData, isExpenseNameLoaded]);

  useEffect(() => {
    if (selectedBudgetCategory === "Expense") {
      setBudgetItemData(expenseItem);
    } else if (selectedBudgetCategory === "Debt") {
      setBudgetItemData(debtItem);
    } else if (selectedBudgetCategory === "Retirement") {
      setBudgetItemData(retirementItem);
    } else if (selectedBudgetCategory === "Savings") {
      setBudgetItemData(savingsItem);
    } else if (selectedBudgetCategory === "Credit Card Payment") {
      setBudgetItemData(creditCardItem);
    } else if (selectedBudgetCategory === "Bank Deposit") {
      setBudgetItemData(depositItem);
    } else if (selectedBudgetCategory === "Transaction Transfer") {
      setBudgetItemData(transferItem);
    } else {
      setBudgetItemData([]);
    }
  }, [selectedBudgetCategory]);

  useEffect(() => {
    if (selectedTransType === "Withdrawal") {
      setBudgetCategoryData(withdrawalCat);
      setIsOtherAccountEnabled(false);
      setBudgetItemTitle("* Budget Item");
      setIsWithdrawal(true);
      setIsDeposit(false);
    } else if (selectedTransType === "Deposit") {
      setBudgetCategoryData(depositCat);
      setBudgetItemTitle("* Deposit Type");
      setIsOtherAccountEnabled(false);
      setIsDeposit(true);
      setIsWithdrawal(false);
    } else if (selectedTransType === "Transfer") {
      setBudgetCategoryData(transferCat);
      setBudgetItemTitle("* Transfer Type");
      setIsOtherAccountEnabled(true);
      setIsDeposit(false);
      setIsWithdrawal(false);
    } else if (selectedTransType === "Credit Card Payment") {
      setBudgetCategoryData(creditCardCat);
      setBudgetItemTitle("* Deposit Type");
      setIsOtherAccountEnabled(false);
      setIsDeposit(true);
      setIsWithdrawal(false);
    } else {
      setBudgetCategoryData([]);
      setBudgetItemTitle("* Other Type");
      setIsOtherAccountEnabled(false);
      setIsDeposit(false);
      setIsWithdrawal(false);
    }

    if (selectedTransType === "Withdrawal") {
      setBudgetCategoryTitle("* Budget Category");
    } else {
      setBudgetCategoryTitle("* What is it?");
    }
  }, [selectedTransType]);

  useEffect(() => {
    if (selectedBudgetItem === "From Another Bank Account") {
      setIsDeposit(true);
      setIsWithdrawal(false);
    }
    if (selectedBudgetItem === "To Another Bank Account") {
      setIsDeposit(false);
      setIsWithdrawal(true);
    }
  }, [selectedBudgetItem]);

  const handleTransDateChange = (e) => {
    if (e && e.value) {
      setNewTransDate(e.value);
    } else setNewTransDate("");
  };

  const handleTransTypeChange = (e) => {
    const selTransType = e.value;
    if (e && e.value) {
      setNewTransType(e.value);
    } else setNewTransType("");

    if (selTransType === "Withdrawal") {
      setBudgetCategoryTitle("* Budget Category");
    } else {
      setBudgetCategoryTitle("* What is it?");
    }

    if (selTransType === "Withdrawal") {
      setBudgetCategoryData(withdrawalCat);
      setBudgetItemTitle("* Budget Item");
      setIsOtherAccountEnabled(false);
      setIsDeposit(false);
      setIsWithdrawal(true);
      setNewDeposit(0);
      setNewWithdrawal(0);
    } else if (selTransType === "Deposit") {
      setBudgetCategoryData(depositCat);
      setBudgetItemTitle("* Deposit Type");
      setIsOtherAccountEnabled(false);
      setIsDeposit(true);
      setIsWithdrawal(false);
      setNewDeposit(0);
      setNewWithdrawal(0);
    } else if (selTransType === "Transfer") {
      setBudgetCategoryData(transferCat);
      setBudgetItemTitle("* Transfer Type");
      setIsOtherAccountEnabled(true);
      setIsDeposit(false);
      setIsWithdrawal(false);
      setNewDeposit(0);
      setNewWithdrawal(0);
    } else if (selTransType === "Credit Card Payment") {
      setBudgetCategoryData(creditCardCat);
      setBudgetItemTitle("* Deposit Type");
      setIsOtherAccountEnabled(false);
      setIsDeposit(true);
      setIsWithdrawal(false);
      setNewDeposit(0);
      setNewWithdrawal(0);
    } else {
      setBudgetCategoryData([]);
      setBudgetItemTitle("* Other Type");
      setIsOtherAccountEnabled(false);
      setIsDeposit(false);
      setIsWithdrawal(false);
      setNewDeposit(0);
      setNewWithdrawal(0);
    }
  };

  const handleAccountOwnerChange = (e) => {
    if (e && e.value) {
      setNewAccountOwner(e.value);
    } else setNewAccountOwner("");
  };

  const handleClearedBankChange = (e) => {
    if (e && e.value) {
      setNewClearedBank(e.value);
    } else setNewClearedBank("");
  };

  const handleBudgetItemChange = (e) => {
    if (e && e.value) {
      setNewBudgetItem(e.value);
      if (e.value === "From Another Bank Account") {
        setIsDeposit(true);
        setIsWithdrawal(false);
        setNewDeposit(0);
        setNewWithdrawal(0);
      }
      if (e.value === "To Another Bank Account") {
        setIsDeposit(false);
        setIsWithdrawal(true);
        setNewDeposit(0);
        setNewWithdrawal(0);
      }
    } else setNewBudgetItem("");
  };

  const handleBudgetCategoryChange = (e) => {
    const selBudgetCat = e.value;
    if (e && e.value) {
      setNewBudgetCategory(e.value);
    } else setNewBudgetCategory("");
    if (selBudgetCat === "Expense") {
      setBudgetItemData(expenseItem);
    } else if (selBudgetCat === "Debt") {
      setBudgetItemData(debtItem);
    } else if (selBudgetCat === "Retirement") {
      setBudgetItemData(retirementItem);
    } else if (selBudgetCat === "Savings") {
      setBudgetItemData(savingsItem);
    } else if (selBudgetCat === "Credit Card Payment") {
      setBudgetItemData(creditCardItem);
    } else if (selBudgetCat === "Bank Deposit") {
      setBudgetItemData(depositItem);
    } else if (selBudgetCat === "Transaction Transfer") {
      setBudgetItemData(transferItem);
    } else {
      setBudgetItemData([]);
    }
  };

  const handleCkNumChange = (e) => {
    if (e && e.value) {
      setNewCkNum(e.value);
    } else setNewCkNum("");
  };

  const handleBankAccountChange = (e) => {
    if (e && e.value) {
      const accountName = e.value;
      const targetAccountData = accountData.find(
        (item) => item.Account === accountName
      );
      const selAccountID = targetAccountData ? targetAccountData.id : "";
      setNewBankAccount(selAccountID);
    } else setNewBankAccount("");
  };

  const handleOtherBankAccountChange = (e) => {
    if (e && e.value) {
      const accountName = e.value;
      const targetAccountData = accountData.find(
        (item) => item.Account === accountName
      );
      const selAccountID = targetAccountData ? targetAccountData.id : "";
      setNewOtherAccount(selAccountID);
    }
  };

  const handleDescriptionChange = (e) => {
    if (e && e.value) {
      setNewDescription(e.value);
    }
  };

  const handleWithdrawalChange = (e) => {
    if (e && e.value) {
      setNewWithdrawal(e.value);
    }
  };

  const handleDepositChange = (e) => {
    if (e && e.value) {
      setNewDeposit(e.value);
    }
  };

  return (
    <div className="main-trans-details1">
      <div className="trans-outer-container">
        <p style={{ fontSize: "14px", padding: "10px", paddingBottom: "0px" }}>
          Note: Select "Credit Card Payment" to properly account for the total
          charges you previously entered in the register.
        </p>
        <div className="transaction-row2">
          <div className="Trans_Type">
            <ComboBoxComponent
              id="comboelement"
              value={selectedTransType}
              placeholder="* Transaction Type"
              autofill={true}
              headerTemplate={dropdownTemplate}
              floatLabelType="Always"
              dataSource={[
                "Credit Card Payment",
                "Deposit",
                "Transfer",
                "Withdrawal",
              ]}
              change={handleTransTypeChange}
            />
          </div>
          <div className="trans_budget_category">
            <ComboBoxComponent
              id="comboelement"
              placeholder={budgetCategoryTitle}
              autofill={true}
              headerTemplate={dropdownTemplate}
              value={selectedBudgetCategory}
              dataSource={budgetCategoryData}
              change={handleBudgetCategoryChange}
              floatLabelType="Always"
            />
          </div>
          <div className="trans_budget_item">
            <ComboBoxComponent
              id="comboelement"
              placeholder={budgetItemTitle}
              autofill={true}
              headerTemplate={dropdownTemplate}
              value={selectedBudgetItem}
              dataSource={budgetItemData}
              change={handleBudgetItemChange}
              floatLabelType="Always"
            />
          </div>
        </div>
        <div className="transaction-row1">
          <div className="trans_account_owner">
            <ComboBoxComponent
              id="comboelement"
              value={selectedAccountOwner}
              placeholder="* Account Owner"
              autofill={true}
              headerTemplate={dropdownTemplate}
              floatLabelType="Always"
              dataSource={["Self", "Partner", "Joint", "Household"]}
              change={handleAccountOwnerChange}
            />
          </div>
          <div className="trans_bank_account">
            <ComboBoxComponent
              id="comboelement"
              value={selectedBankAccount}
              placeholder="* Bank Account"
              autofill={true}
              headerTemplate={dropdownTemplate}
              floatLabelType="Always"
              dataSource={bankAccounts}
              change={handleBankAccountChange}
            />
          </div>
          {isOtherAccountEnabled && (
            <div className="trans_other_account">
              <ComboBoxComponent
                id="comboelement"
                value={selectedOtherAccount}
                placeholder="Other Bank Account"
                autofill={true}
                headerTemplate={dropdownTemplate}
                floatLabelType="Always"
                dataSource={isOtherAccountEnabled && bankAccounts}
                change={handleOtherBankAccountChange}
                // enabled={isOtherAccountEnabled}
                style={!isOtherAccountEnabled ? { pointerEvents: "none" } : {}}
              />
            </div>
          )}
        </div>

        <div className="transaction-row3">
          <div className="trans_ck_num">
            <TextBoxComponent
              id="comboelement"
              placeholder="CK #"
              value={selectedCkNum}
              change={handleCkNumChange}
              floatLabelType="Always"
            />
          </div>
          <div className="trans_description">
            <TextBoxComponent
              id="comboelement"
              placeholder="Transaction Description"
              value={selectedDescription}
              change={handleDescriptionChange}
              floatLabelType="Always"
            />
          </div>
        </div>

        <div className="transaction-row3">
          <div className="trans_date">
            <DatePickerComponent
              id="datepicker"
              placeholder="* Transaction Date"
              value={selectedTransDate}
              change={handleTransDateChange}
              floatLabelType="Always"
              format="MMM dd, yyyy"
            />
          </div>
          {isWithdrawal && (
            <div className="trans_withdrawal">
              <NumericTextBoxComponent
                format={usedCurrency}
                value={selectedWithdrawal}
                placeholder="* Payment, Fee, Withdrawal (-)"
                floatLabelType="Always"
                showSpinButton={false}
                change={handleWithdrawalChange}
              />
            </div>
          )}
          {isDeposit && (
            <div className="trans_deposit">
              <NumericTextBoxComponent
                format={usedCurrency}
                value={selectedDeposit}
                placeholder="* Deposit (+)"
                floatLabelType="Always"
                showSpinButton={false}
                change={handleDepositChange}
              />
            </div>
          )}
          <div className="trans_cleared">
            <ComboBoxComponent
              id="comboelement"
              value={selectedClearedBank}
              placeholder="* Has Trans. Cleared the Bank?"
              autofill={true}
              headerTemplate={dropdownTemplate}
              floatLabelType="Always"
              dataSource={["Cleared", "Not Cleared"]}
              change={handleClearedBankChange}
            />
          </div>
        </div>
        {isRequired && <div className="noteContainer">{requiredNote()}</div>}
      </div>
    </div>
  );
}

export default BankAccountDialog;
