import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useIncomeNameDataFetch = () => {
  const user = useUserStore((state) => state.user);

  const [incomeNameData, setIncomeNameData] = useState([]);
  const [isIncomeNameLoaded, setIsIncomeNameLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchIncomeNameData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/incomename"
      );
      setIsIncomeNameLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching IncomeName data:", error);
      setIsIncomeNameLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchIncomeNameData().then((IncomeNameInitialData) => {
        setIncomeNameData(IncomeNameInitialData);
      });
    else setIncomeNameData(true);
  }, [user]);

  // Return an object containing all the fetched data
  return {
    incomeNameData,
    isIncomeNameLoaded,
  };
};

export default useIncomeNameDataFetch;
