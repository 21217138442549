import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useAssetNameDataFetch = () => {
  const user = useUserStore((state) => state.user);

  const [assetNameData, setAssetNameData] = useState([]);
  const [isAssetNameLoaded, setIsAssetNameLoaded] = useState(false);

  // Define the fetchData function for asset
  const fetchAssetNameData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/assetname"
      );
      setIsAssetNameLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching AssetName data:", error);
      setIsAssetNameLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchAssetNameData().then((AssetNameInitialData) => {
        setAssetNameData(AssetNameInitialData);
      });
    else setIsAssetNameLoaded(true);
  }, [user]);

  // Return an object containing all the fetched data
  return {
    assetNameData,
    isAssetNameLoaded,
  };
};

export default useAssetNameDataFetch;
