import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useSubCatDataFetch = () => {
  const user = useUserStore((state) => state.user);
  const [subCatData, setSubCatData] = useState([]);
  const [isSubCatLoaded, setIsSubCatLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchSubCatData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + `/api/subcat/reguserid/${user?.id}`
      );
      setIsSubCatLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching SubCat data:", error);
      setIsSubCatLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchSubCatData().then((SubCatInitialData) => {
        setSubCatData(SubCatInitialData);
      });
    else setIsSubCatLoaded(true);
  }, [user]);

  // Return an object containing all the fetched data
  return {
    subCatData,
    isSubCatLoaded,
  };
};

export default useSubCatDataFetch;
