import "./ListView.css";
export function customTemplate() {
  return (
    <p className="headerNote">
      Select from the List Below or Type New Description if Not Found Listed
    </p>
  );
}

export function dropdownTemplate() {
  return <p className="headerNote">Select from the List Below</p>;
}

export function requiredNote() {
  return <p className="requiredNote">Complete all required fields *</p>;
}
