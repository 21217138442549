import React from 'react'
import { Outlet } from 'react-router-dom';
import Expense from './Expense';
import '../MainBody.css'

function ExpenseLayout() {
  return (
    <div className='projects_container'>
      <div className='projects_sidebar'>
        <Expense />
      </div>
      <div className='projects_main'>
        <Outlet />
      </div>
    </div>
  )
}

export default ExpenseLayout