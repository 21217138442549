import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useBankAccountDataFetch = () => {
  const user = useUserStore((state) => state.user);
  const [bankAccountData, setBankAccountData] = useState([]);
  const [isBankAccountLoaded, setIsBankAccountLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchBankAccountData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          `/api/bankaccount/rootuserid/${user?.RootID}`
      );
      setIsBankAccountLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching BankAccount data:", error);
      setIsBankAccountLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchBankAccountData().then((BankAccountInitialData) => {
        setBankAccountData(BankAccountInitialData);
      });
    else setIsBankAccountLoaded(true);
  }, [user]);

  // Return an object containing all the fetched data
  return {
    bankAccountData,
    isBankAccountLoaded,
  };
};

export default useBankAccountDataFetch;
