import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  ColumnChooser,
  Reorder,
  Resize,
  PdfExport,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import { getValue } from "@syncfusion/ej2-base";

import { DialogComponent } from "@syncfusion/ej2-react-popups";
import axios from "../../../config/axios";
import "../../shared/ListView.css";
import useAssetDataFetch from "../../shared/useAssetDataFetch"; // Import the custom hook
import useAssetNameDataFetch from "../../shared/useAssetNameDataFetch";
import AssetDialog from "./AssetDialog";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { dropdownTemplate } from "../../shared/HeaderTempate";
import { CheckAssetFields } from "../../../utils/requiredFields";

function Assets() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const { assetData, isAssetLoaded } = useAssetDataFetch();
  const { assetNameData, isAssetNameLoaded } = useAssetNameDataFetch();
  const [assetCategory, setassetCategory] = useState();
  const [marketTotal, setMarketTotal] = useState(0);
  const [estateTotal, setEstateTotal] = useState(0);
  const [vehicleTotal, setVehicleTotal] = useState(0);
  const [applianceTotal, setApplianceTotal] = useState(0);
  const [otherTotal, setOtherTotal] = useState(0);
  const [dataFilter, setDataFilter] = useState("Household");
  const GridRef = useRef(null); // Create the treeGridRef reference
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isAddDialogVisible, setIsAddDialogVisible] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
  }
  const newCurrentDate = formatDate(new Date());
  const [gridData, setGridData] = useState([]);
  const [updatedAssetData, setUpdatedAssetData] = useState([]);
  const [latestData, setLatestData] = useState([]);
  const usedCurrency = user?.Currency + " #,###.00";
  const userPackage = user?.Package;

  const handleAccountOwnerChange = async (e) => {
    if (e && e.value) {
      setIsDataChanged(false);
      setDataFilter(e.value);
      const accountOwner = e.value;
      let finalAsset;
      if (user?.Type !== "Root") {
        finalAsset = updatedAssetData?.filter(
          (item) => item.UserID === user?.id
        );
      } else {
        finalAsset = updatedAssetData;
      }
      finalAsset?.sort((a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn));
      let filteredData;
      if (
        accountOwner === "Self" ||
        accountOwner === "Partner" ||
        accountOwner === "Joint"
      ) {
        filteredData = finalAsset?.filter(
          (item) => item.AccountOwner === accountOwner
        );
      } else {
        filteredData = finalAsset;
      }
      setGridData(filteredData);

      //Get total market value
      const totalMarket = filteredData?.reduce((accumulator, record) => {
        const MarketValue = record?.MarketValue || 0;
        return accumulator + Number(MarketValue);
      }, 0);
      setMarketTotal(totalMarket);

      //Get total real estate value
      const filteredEstateData = filteredData?.filter(
        (item) => item.AssetCategory === "Real Estate"
      );
      const totalEstate = filteredEstateData?.reduce((accumulator, record) => {
        const MarketValue = record?.MarketValue || 0;
        return accumulator + Number(MarketValue);
      }, 0);
      setEstateTotal(totalEstate);

      //Get total real vehicle value
      const filteredVehicleData = filteredData?.filter(
        (item) => item.AssetCategory === "Vehicle"
      );
      const totalVehicle = filteredVehicleData?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );
      setVehicleTotal(totalVehicle);

      //Get total real Furnishings/Appliances value
      const filteredApplianceData = filteredData?.filter(
        (item) => item.AssetCategory === "Furnishing / Appliances"
      );
      const totalAppliance = filteredApplianceData?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );
      setApplianceTotal(totalAppliance);

      //Get total real Other value
      const filteredOtherData = filteredData?.filter(
        (item) => item.AssetCategory === "Other"
      );
      const totalOther = filteredOtherData?.reduce((accumulator, record) => {
        const MarketValue = record?.MarketValue || 0;
        return accumulator + Number(MarketValue);
      }, 0);
      setOtherTotal(totalOther);
      setIsDataChanged(true);
    }
  };

  useEffect(() => {
    if (isAssetNameLoaded) {
      const uniqueassetCategory = [
        ...new Set(
          assetNameData.map((item) => (item.Category ? item.Category : ""))
        ),
      ];
      uniqueassetCategory.sort((a, b) => a.localeCompare(b));
      setassetCategory(uniqueassetCategory);
    }
  }, [assetNameData, isAssetNameLoaded]);

  useEffect(() => {
    if (isAssetLoaded) {
      setUpdatedAssetData(assetData);
      let finalAsset;
      if (user?.Type !== "Root") {
        finalAsset = assetData.filter((item) => item.UserID === user?.id);
      } else {
        finalAsset = assetData;
      }
      finalAsset.sort((a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn));

      let filteredData;
      if (
        dataFilter === "Self" ||
        dataFilter === "Partner" ||
        dataFilter === "Joint"
      ) {
        filteredData = finalAsset.filter(
          (item) => item.AccountOwner === dataFilter
        );
      } else {
        filteredData = finalAsset;
      }
      setGridData(filteredData);
      setLatestData(finalAsset);
      //Get total market value
      const totalMarket = filteredData?.reduce((accumulator, record) => {
        const MarketValue = record?.MarketValue || 0;
        return accumulator + Number(MarketValue);
      }, 0);
      setMarketTotal(totalMarket);

      //Get total real estate value
      const filteredEstateData = filteredData?.filter(
        (item) => item.AssetCategory === "Real Estate"
      );
      const totalEstate = filteredEstateData?.reduce((accumulator, record) => {
        const MarketValue = record?.MarketValue || 0;
        return accumulator + Number(MarketValue);
      }, 0);
      setEstateTotal(totalEstate);

      //Get total real vehicle value
      const filteredVehicleData = filteredData?.filter(
        (item) => item.AssetCategory === "Vehicle"
      );
      const totalVehicle = filteredVehicleData?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );
      setVehicleTotal(totalVehicle);

      //Get total real Furnishings/Appliances value
      const filteredApplianceData = filteredData?.filter(
        (item) => item.AssetCategory === "Furnishing / Appliances"
      );
      const totalAppliance = filteredApplianceData?.reduce(
        (accumulator, record) => {
          const MarketValue = record?.MarketValue || 0;
          return accumulator + Number(MarketValue);
        },
        0
      );
      setApplianceTotal(totalAppliance);

      //Get total real Other value
      const filteredOtherData = filteredData?.filter(
        (item) => item.AssetCategory === "Other"
      );
      const totalOther = filteredOtherData?.reduce((accumulator, record) => {
        const MarketValue = record?.MarketValue || 0;
        return accumulator + Number(MarketValue);
      }, 0);
      setOtherTotal(totalOther);
      setIsDataChanged(true);
      setIsDataLoaded(true);
    }
  }, [assetData, isAssetLoaded, user]);

  // Show toast when isIncomeLoaded becomes false
  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded || !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  const [selectedID, setSelectedID] = useState("");
  const [selectedRootID, setSelectedRootID] = useState("");
  const [selectedUserID, setSelectedUserID] = useState("");
  const [selectedAccountOwner, setSelectedAccountOwner] = useState("");
  const [selectedAssetCategory, setSelectedAssetCategory] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedMarketValue, setSelectedMarketValue] = useState("");

  const [newRootID, setNewRootID] = useState("");
  const [newUserID, setNewUserID] = useState("");
  const [newAccountOwner, setNewAccountOwner] = useState("");
  const [newAssetCategory, setNewAssetCategory] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newMarketValue, setNewMarketValue] = useState(0);
  const [isRequired, setIsRequired] = useState(false);

  const assetNewData = {
    RootUserID: newRootID,
    RegUserID: newUserID,
    AccountOwner: newAccountOwner,
    AssetCategory: newAssetCategory,
    Description: newDescription,
    MarketValue: newMarketValue,
    UpdatedOn: newCurrentDate,
  };

  const handleRowSelect = async (args) => {
    // Set the selected ID when a row is selected in the Grid
    setSelectedID(args.data.id);
    setSelectedRootID(args.data.RootID);
    setSelectedUserID(args.data.UserID);
    setSelectedAccountOwner(args.data.AccountOwner);
    setSelectedAssetCategory(args.data.AssetCategory);
    setSelectedDescription(args.data.Description);
    setSelectedMarketValue(args.data.MarketValue);
  };

  const handleExcelButtonClick = () => {};

  const handlePdfButtonClick = () => {};

  const handleEditDialogClose = () => {
    setIsEditDialogVisible(false);
  };

  const handleAddDialogClose = () => {
    setIsAddDialogVisible(false);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    setIsEditDialogVisible(!isEditDialogVisible);
    setNewRootID(selectedRootID);
    setNewUserID(selectedUserID);
    setNewAccountOwner(selectedAccountOwner);
    setNewAssetCategory(selectedAssetCategory);
    setNewDescription(selectedDescription);
    setNewMarketValue(selectedMarketValue);
    setIsRequired(false);
  };

  const handleAddButtonClick = () => {
    setIsAddDialogVisible(!isAddDialogVisible);
    setSelectedID("");
    setSelectedRootID("");
    setSelectedUserID("");
    setSelectedAccountOwner("");
    setSelectedAssetCategory("");
    setSelectedDescription("");
    setSelectedMarketValue("");
    if (GridRef.current) {
      GridRef.current.clearSelection();
    }
    setNewRootID(user?.RootID);
    setNewUserID(user?.id);
    setNewAccountOwner("");
    setNewAssetCategory("");
    setNewDescription("");
    setSelectedMarketValue("");
    setIsRequired(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeleteDialogVisible(!isDeleteDialogVisible);
  };

  const handleAddRecordButton = async () => {
    // Make a POST request to add the Record record
    setIsDataChanged(false);
    try {
      const RecordResponse = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/asset",
        assetNewData
      );
      console.log("Record response:", RecordResponse.data);
      if (RecordResponse.data.status === "success") {
        toast.success("Added successfully.");
        try {
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/asset/rootuserid/${user?.RootID}`
          );
          const Records = updatedResponse.data.items;
          setUpdatedAssetData(Records);
          let finalAsset;
          if (user?.Type !== "Root") {
            finalAsset = Records?.filter((item) => item.UserID === user?.id);
          } else {
            finalAsset = Records;
          }
          finalAsset?.sort(
            (a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn)
          );
          let filteredData;
          if (
            dataFilter === "Self" ||
            dataFilter === "Partner" ||
            dataFilter === "Joint"
          ) {
            filteredData = finalAsset?.filter(
              (item) => item.AccountOwner === dataFilter
            );
          } else {
            filteredData = finalAsset;
          }
          setGridData(filteredData);
          setLatestData(finalAsset);
          //Get total market value
          const totalMarket = filteredData?.reduce((accumulator, record) => {
            const MarketValue = record?.MarketValue || 0;
            return accumulator + Number(MarketValue);
          }, 0);
          setMarketTotal(totalMarket);

          //Get total real estate value
          const filteredEstateData = filteredData?.filter(
            (item) => item.AssetCategory === "Real Estate"
          );
          const totalEstate = filteredEstateData?.reduce(
            (accumulator, record) => {
              const MarketValue = record?.MarketValue || 0;
              return accumulator + Number(MarketValue);
            },
            0
          );
          setEstateTotal(totalEstate);

          //Get total real vehicle value
          const filteredVehicleData = filteredData?.filter(
            (item) => item.AssetCategory === "Vehicle"
          );
          const totalVehicle = filteredVehicleData?.reduce(
            (accumulator, record) => {
              const MarketValue = record?.MarketValue || 0;
              return accumulator + Number(MarketValue);
            },
            0
          );
          setVehicleTotal(totalVehicle);

          //Get total real Furnishings/Appliances value
          const filteredApplianceData = filteredData?.filter(
            (item) => item.AssetCategory === "Furnishing / Appliances"
          );
          const totalAppliance = filteredApplianceData?.reduce(
            (accumulator, record) => {
              const MarketValue = record?.MarketValue || 0;
              return accumulator + Number(MarketValue);
            },
            0
          );
          setApplianceTotal(totalAppliance);

          //Get total real Other value
          const filteredOtherData = filteredData?.filter(
            (item) => item.AssetCategory === "Other"
          );
          const totalOther = filteredOtherData?.reduce(
            (accumulator, record) => {
              const MarketValue = record?.MarketValue || 0;
              return accumulator + Number(MarketValue);
            },
            0
          );
          setOtherTotal(totalOther);
        } catch (fetchError) {
          setMarketTotal(0);
          setEstateTotal(0);
          setVehicleTotal(0);
          setApplianceTotal(0);
          setOtherTotal(0);
          setGridData([]);
          setUpdatedAssetData([]);
        }
      } else {
        console.error(
          "Error adding the record record:",
          RecordResponse.data.message
        );
        toast.error("Error adding the record");
      }
    } catch (error) {
      console.error("Error adding the record:", error);
      toast.error("Error adding the record");
    }
    setIsDataChanged(true);
  };

  const handleUpdateRecordButton = async (selectedID) => {
    setIsDataChanged(false);
    const numericSelectedID = parseInt(selectedID);
    try {
      const RecordResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/asset/${numericSelectedID}`,
        {
          RootUserID: newRootID,
          RegUserID: newUserID,
          AccountOwner: newAccountOwner,
          AssetCategory: newAssetCategory,
          Description: newDescription,
          MarketValue: newMarketValue,
          UpdatedOn: newCurrentDate,
        }
      );
      console.log("Record response:", RecordResponse.data);
      if (RecordResponse.data.status === "success") {
        toast.success("the record updated successfully.");
        try {
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/asset/rootuserid/${user?.RootID}`
          );
          const Records = updatedResponse.data.items;
          setUpdatedAssetData(Records);
          let finalAsset;
          if (user?.Type !== "Root") {
            finalAsset = Records?.filter((item) => item.UserID === user?.id);
          } else {
            finalAsset = Records;
          }
          finalAsset?.sort(
            (a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn)
          );
          let filteredData;
          if (
            dataFilter === "Self" ||
            dataFilter === "Partner" ||
            dataFilter === "Joint"
          ) {
            filteredData = finalAsset?.filter(
              (item) => item.AccountOwner === dataFilter
            );
          } else {
            filteredData = finalAsset;
          }
          setGridData(filteredData);
          setLatestData(finalAsset);
          //Get total market value
          const totalMarket = filteredData?.reduce((accumulator, record) => {
            const MarketValue = record?.MarketValue || 0;
            return accumulator + Number(MarketValue);
          }, 0);
          setMarketTotal(totalMarket);

          //Get total real estate value
          const filteredEstateData = filteredData?.filter(
            (item) => item.AssetCategory === "Real Estate"
          );
          const totalEstate = filteredEstateData?.reduce(
            (accumulator, record) => {
              const MarketValue = record?.MarketValue || 0;
              return accumulator + Number(MarketValue);
            },
            0
          );
          setEstateTotal(totalEstate);

          //Get total real vehicle value
          const filteredVehicleData = filteredData?.filter(
            (item) => item.AssetCategory === "Vehicle"
          );
          const totalVehicle = filteredVehicleData?.reduce(
            (accumulator, record) => {
              const MarketValue = record?.MarketValue || 0;
              return accumulator + Number(MarketValue);
            },
            0
          );
          setVehicleTotal(totalVehicle);

          //Get total real Furnishings/Appliances value
          const filteredApplianceData = filteredData?.filter(
            (item) => item.AssetCategory === "Furnishing / Appliances"
          );
          const totalAppliance = filteredApplianceData?.reduce(
            (accumulator, record) => {
              const MarketValue = record?.MarketValue || 0;
              return accumulator + Number(MarketValue);
            },
            0
          );
          setApplianceTotal(totalAppliance);

          //Get total real Other value
          const filteredOtherData = filteredData?.filter(
            (item) => item.AssetCategory === "Other"
          );
          const totalOther = filteredOtherData?.reduce(
            (accumulator, record) => {
              const MarketValue = record?.MarketValue || 0;
              return accumulator + Number(MarketValue);
            },
            0
          );
          setOtherTotal(totalOther);
        } catch (fetchError) {
          setMarketTotal(0);
          setEstateTotal(0);
          setVehicleTotal(0);
          setApplianceTotal(0);
          setOtherTotal(0);
          setGridData([]);
          setUpdatedAssetData([]);
        }
      } else {
        console.error(
          "Error updating the record record:",
          RecordResponse.data.message
        );
        toast.error("Error updating the record");
      }
    } catch (error) {
      console.error("Error updating the record:", error);
      toast.error("Error updating the record");
    }
    setIsDataChanged(true);
  };

  const handleDeleteRecordButton = async (selectedID) => {
    setIsDataChanged(false);
    const numericSelectedID = parseInt(selectedID);
    try {
      const deleteResponse = await axios.delete(
        process.env.REACT_APP_SERVER_URL + `/api/asset/${numericSelectedID}`
      );

      console.log("Delete response:", deleteResponse.data);

      if (deleteResponse.data.status === "success") {
        toast.success("The record was deleted successfully.");
        try {
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/asset/rootuserid/${user?.RootID}`
          );
          const Records = updatedResponse.data.items;
          setUpdatedAssetData(Records);
          let finalAsset;
          if (user?.Type !== "Root") {
            finalAsset = Records?.filter((item) => item.UserID === user?.id);
          } else {
            finalAsset = Records;
          }
          finalAsset?.sort(
            (a, b) => new Date(b.UpdatedOn) - new Date(a.UpdatedOn)
          );
          let filteredData;
          if (
            dataFilter === "Self" ||
            dataFilter === "Partner" ||
            dataFilter === "Joint"
          ) {
            filteredData = finalAsset?.filter(
              (item) => item.AccountOwner === dataFilter
            );
          } else {
            filteredData = finalAsset;
          }
          setGridData(filteredData);
          setLatestData(finalAsset);
          //Get total market value
          const totalMarket = filteredData?.reduce((accumulator, record) => {
            const MarketValue = record?.MarketValue || 0;
            return accumulator + Number(MarketValue);
          }, 0);
          setMarketTotal(totalMarket);

          //Get total real estate value
          const filteredEstateData = filteredData?.filter(
            (item) => item.AssetCategory === "Real Estate"
          );
          const totalEstate = filteredEstateData?.reduce(
            (accumulator, record) => {
              const MarketValue = record?.MarketValue || 0;
              return accumulator + Number(MarketValue);
            },
            0
          );
          setEstateTotal(totalEstate);

          //Get total real vehicle value
          const filteredVehicleData = filteredData?.filter(
            (item) => item.AssetCategory === "Vehicle"
          );
          const totalVehicle = filteredVehicleData?.reduce(
            (accumulator, record) => {
              const MarketValue = record?.MarketValue || 0;
              return accumulator + Number(MarketValue);
            },
            0
          );
          setVehicleTotal(totalVehicle);

          //Get total real Furnishings/Appliances value
          const filteredApplianceData = filteredData?.filter(
            (item) => item.AssetCategory === "Furnishing / Appliances"
          );
          const totalAppliance = filteredApplianceData?.reduce(
            (accumulator, record) => {
              const MarketValue = record?.MarketValue || 0;
              return accumulator + Number(MarketValue);
            },
            0
          );
          setApplianceTotal(totalAppliance);

          //Get total real Other value
          const filteredOtherData = filteredData?.filter(
            (item) => item.AssetCategory === "Other"
          );
          const totalOther = filteredOtherData?.reduce(
            (accumulator, record) => {
              const MarketValue = record?.MarketValue || 0;
              return accumulator + Number(MarketValue);
            },
            0
          );
          setOtherTotal(totalOther);
        } catch (fetchError) {
          setMarketTotal(0);
          setEstateTotal(0);
          setVehicleTotal(0);
          setApplianceTotal(0);
          setOtherTotal(0);
          setGridData([]);
          setUpdatedAssetData([]);
        }
      } else {
        console.error(
          "Error deleting the record:",
          deleteResponse.data.message
        );
        toast.error("Error deleting item");
      }
    } catch (error) {
      console.error("Error deleting the record:", error);
      toast.error("Error deleting the record");
    }
    setIsDataChanged(true);
  };

  const FilterOptions = { type: "Excel" };
  const pageSettings = { pageSize: 25, pageSizes: true };
  const editOptions = {
    allowAdding: false,
    allowDeleting: false,
    allowEditing: false,
  };
  const toolbarOptions = ["ColumnChooser"];
  const loadingIndicator = { indicatorType: "Spinner" };

  const renderButtons = () => {
    return (
      <div className="button-container">
        <div className="button-container-subA1">
          <button className="add-button" onClick={handleAddButtonClick}>
            <AddIcon className="send-icon" /> Add New{" "}
          </button>
          <button
            className="edit-button"
            onClick={handleEditButtonClick}
            disabled={selectedID === ""}
          >
            <EditIcon className="send-icon" /> Edit{" "}
          </button>
          <button
            className="delete-button"
            onClick={handleDeleteButtonClick}
            disabled={selectedID === ""}
          >
            <DeleteIcon className="but-icon-whereabouts" /> Delete{" "}
          </button>
        </div>
        {/* <div className="button-container-subA2">
          <button className="excel-button" onClick={handleExcelButtonClick}>
            <FileDownloadIcon className="send-icon" /> Excel{" "}
          </button>
          <button className="pdf-button" onClick={handlePdfButtonClick}>
            <FileDownloadIcon className="send-icon" /> PDF{" "}
          </button>
        </div> */}
      </div>
    );
  };

  // Include the renderButtons function in the toolbarOptions array
  const updatedToolbarOptions = [
    ...toolbarOptions,
    { template: renderButtons },
  ];

  let addButtons = [
    {
      buttonModel: {
        content: "Save New",
        cssClass: "e-success update-btn",
        isPrimary: true,
      },
      click: () => {
        if (
          CheckAssetFields(
            newAccountOwner,
            newAssetCategory,
            newDescription,
            newMarketValue
          )
        ) {
          setIsRequired(true);
        } else {
          handleAddRecordButton();
          setIsAddDialogVisible(false);
        }
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsAddDialogVisible(false);
      },
    },
  ];

  let editButtons = [
    {
      buttonModel: {
        content: "Update",
        cssClass: "e-success update-btn",
        isPrimary: true,
      },
      click: () => {
        if (
          CheckAssetFields(
            newAccountOwner,
            newAssetCategory,
            newDescription,
            newMarketValue
          )
        ) {
          setIsRequired(true);
        } else {
          handleUpdateRecordButton(selectedID);
          setIsEditDialogVisible(false);
        }
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsEditDialogVisible(false);
      },
    },
  ];

  let deleteButtons = [
    {
      buttonModel: {
        content: "Delete",
        cssClass: "e-danger update-btn",
        isPrimary: true,
      },
      click: () => {
        handleDeleteRecordButton(selectedID);
        setIsDeleteDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsDeleteDialogVisible(false);
      },
    },
  ];

  const currencyFormatter3 = (field, data, column) => {
    const MarketValue = getValue("MarketValue", data); // Assuming the field name is "MonthlyPayment"
    // Format the amount with the desired currency symbol, space separator, and decimal places
    const formattedAmount =
      user?.Currency +
      new Intl.NumberFormat(user?.Separator, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(MarketValue);
    return formattedAmount;
  };

  const handleUpgradeButtonClick = () => {
    navigate("/packages");
  };

  return (
    <div className="projects_container">
      <div className="projects_main">
        <div className="all-outer-project-containers">
          <div className="title-container">
            <div className="mainTitle_dropdown">
              <h3>Assets for {user?.FullName}</h3>
              <div className="_account_owner1">
                <ComboBoxComponent
                  id="comboelement"
                  value="Select Owner"
                  // placeholder="* Account Owner"
                  autofill={true}
                  headerTemplate={dropdownTemplate}
                  floatLabelType="Always"
                  dataSource={["Self", "Partner", "Joint", "Household"]}
                  change={handleAccountOwnerChange}
                  style={{ backgroundColor: "black", color: "#FFE99B" }}
                />
              </div>
            </div>
          </div>
          <div className="summay-debit-btns">
            <div className="summary-container-income">
              <div className="expense-firstBTNS">
                <div className="income_net_pay1">
                  <p
                    style={{
                      fontSize: "12.5px",
                      fontWeight: "bold",
                      display: "flex",
                      // justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Real Estate Value
                  </p>
                  <div className="value">
                    <strong style={{ fontWeight: "bold" }}>
                      {user?.Currency}
                      {estateTotal.toLocaleString(user?.Separator, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </strong>
                  </div>
                </div>
                <div className="income_net_pay1">
                  <p
                    style={{
                      fontSize: "12.5px",
                      fontWeight: "bold",
                      display: "flex",
                      // justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Vehicle Value
                  </p>
                  <div className="value">
                    <strong style={{ fontWeight: "bold" }}>
                      {user?.Currency}
                      {vehicleTotal.toLocaleString(user?.Separator, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </strong>
                  </div>
                </div>
              </div>

              <div className="expense-secondBTNS">
                <div className="income_net_pay1">
                  <p
                    style={{
                      fontSize: "12.5px",
                      fontWeight: "bold",
                      display: "flex",
                      // justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Household Furnishings Value
                  </p>
                  <div className="value">
                    <strong style={{ fontWeight: "bold" }}>
                      {user?.Currency}
                      {applianceTotal.toLocaleString(user?.Separator, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </strong>
                  </div>
                </div>
                <div className="income_net_pay1">
                  <p
                    style={{
                      fontSize: "12.5px",
                      fontWeight: "bold",
                      display: "flex",
                      // justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Other Asset Value
                  </p>
                  <div className="value">
                    <strong style={{ fontWeight: "bold" }}>
                      {user?.Currency}
                      {otherTotal.toLocaleString(user?.Separator, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <>
            {userPackage !== "Basic" &&
              userPackage !== "Standard" &&
              userPackage !== "Premium" && (
                <div className="title-container1">
                  <h2>This Feature is Only Available to Paid Users Only</h2>
                  <div className="button-container">
                    <div className="button-container-subA1">
                      <button
                        className="add-button"
                        onClick={handleUpgradeButtonClick}
                      >
                        {" "}
                        <UpgradeIcon className="send-icon" />
                        Pay Now
                      </button>
                    </div>
                  </div>
                </div>
              )}
            {(userPackage === "Basic" ||
              userPackage === "Standard" ||
              userPackage === "Premium") && (
              <div className="all-inner-asset-containers">
                {isDataLoaded && (
                  <GridComponent
                    dataSource={gridData}
                    childMapping="items"
                    ref={GridRef}
                    classClass="custom-treegrid-class"
                    height="480"
                    width="100%"
                    allowReordering={true}
                    allowResizing={true}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    allowPaging={true}
                    allowSorting={true}
                    allowFiltering={true}
                    allowTextWrap={true}
                    showColumnChooser={true}
                    filterSettings={FilterOptions}
                    pageSettings={pageSettings}
                    editSettings={editOptions}
                    toolbar={updatedToolbarOptions}
                    loadingIndicator={loadingIndicator}
                    allowSelection={true}
                    rowSelected={handleRowSelect}
                  >
                    <ColumnsDirective>
                      <ColumnDirective
                        field="AccountOwner"
                        headerText="Account Owner"
                        width="120"
                        textAlign="Left"
                      />
                      <ColumnDirective
                        field="AssetCategory"
                        headerText="Asset Category"
                        width="180"
                        textAlign="Left"
                      />
                      <ColumnDirective
                        field="Description"
                        headerText="Asset Description"
                        width="300"
                        textAlign="Left"
                      />
                      <ColumnDirective
                        field="MarketValue"
                        headerText="Market Value"
                        width="140"
                        textAlign="Left"
                        valueAccessor={currencyFormatter3}
                      />
                    </ColumnsDirective>
                    <Inject
                      services={[
                        Page,
                        Sort,
                        Filter,
                        Edit,
                        Toolbar,
                        ColumnChooser,
                        Reorder,
                        Resize,
                        PdfExport,
                        ExcelExport,
                      ]}
                    />
                  </GridComponent>
                )}
              </div>
            )}
          </>
          <div>
            {isEditDialogVisible && (
              <DialogComponent
                visible={isEditDialogVisible}
                width="50%"
                height="380px"
                header="Edit Asset"
                allowDragging={true}
                showCloseIcon={true}
                close={handleEditDialogClose}
                buttons={editButtons}
              >
                <AssetDialog
                  selectedAccountOwner={selectedAccountOwner}
                  selectedAssetCategory={selectedAssetCategory}
                  selectedDescription={selectedDescription}
                  selectedMarketValue={selectedMarketValue}
                  assetCategory={assetCategory}
                  latestData={latestData}
                  assetNameData={assetNameData}
                  usedCurrency={usedCurrency}
                  setNewAccountOwner={setNewAccountOwner}
                  setNewAssetCategory={setNewAssetCategory}
                  setNewDescription={setNewDescription}
                  setNewMarketValue={setNewMarketValue}
                  isRequired={isRequired}
                />
              </DialogComponent>
            )}
          </div>
          <div>
            {isAddDialogVisible && (
              <DialogComponent
                visible={isAddDialogVisible}
                width="50%"
                height="380px"
                header="Add Asset"
                allowDragging={true}
                showCloseIcon={true}
                close={handleAddDialogClose}
                buttons={addButtons}
              >
                <AssetDialog
                  selectedAccountOwner={selectedAccountOwner}
                  selectedAssetCategory={selectedAssetCategory}
                  selectedDescription={selectedDescription}
                  selectedMarketValue={selectedMarketValue}
                  assetCategory={assetCategory}
                  latestData={latestData}
                  assetNameData={assetNameData}
                  usedCurrency={usedCurrency}
                  setNewAccountOwner={setNewAccountOwner}
                  setNewAssetCategory={setNewAssetCategory}
                  setNewDescription={setNewDescription}
                  setNewMarketValue={setNewMarketValue}
                  isRequired={isRequired}
                />
              </DialogComponent>
            )}
          </div>
          <div>
            {isDeleteDialogVisible && (
              <DialogComponent
                visible={isDeleteDialogVisible}
                width="25%"
                height="200px"
                header="Delete Asset"
                content={`Do you want to delete the selected asset (${selectedDescription})?`}
                allowDragging={true}
                showCloseIcon={true}
                close={handleDeleteDialogClose}
                buttons={deleteButtons}
              ></DialogComponent>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Assets;
