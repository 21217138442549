import React from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import "./UserDialog.css";

function BankAccountNameDialog({ selectedAccount, setNewAccount }) {
  const handleAccountChange = (e) => {
    if (e.value !== "") {
      setNewAccount(e.value);
    }
  };

  return (
    <div className="main-user-container-details1">
      <div className="user-field-container1">
        <div className="my-cap-user-row1-bank">
          <div className="budget_Category-bank">
            <TextBoxComponent
              placeholder="Bank Account Description"
              value={selectedAccount}
              floatLabelType="Always"
              change={handleAccountChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankAccountNameDialog;
