import React, { useState, useEffect } from "react";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";

import "./ExpenseDialog.css";
import {
  customTemplate,
  dropdownTemplate,
  requiredNote,
} from "../../shared/HeaderTempate";

function ExpenseDialog({
  selectedAccountOwner,
  selectedBudgetItem,
  selectedDescription,
  selectedBudgetType,
  selectedDayDue,
  selectedPaymentMethod,
  selectedBankAccount,
  selectedSubCategory,
  selectedMonthlyPayment,
  selectedAdjustments,
  bankAccounts,
  subCategories,
  paymentMethods,
  budgetItems,
  accountData,
  latestData,
  ExpenseNameData,
  usedCurrency,
  setNewAccountOwner,
  setNewBudgetItem,
  setNewDescription,
  setNewBudgetType,
  setNewDayDue,
  setNewPaymentMethod,
  setNewBankAccount,
  setNewSubCategory,
  setNewMonthlyPayment,
  setNewAdjustments,
  isRequired,
}) {
  const [monthlyPay, setMonthlyPay] = useState(selectedMonthlyPayment);
  const [adjustment, setAdjustment] = useState(selectedAdjustments);
  const [accountOwner, setAccountOwner] = useState([
    "Self",
    "Partner",
    "Joint",
    "Household",
  ]);
  const [adjustedMonthlyPay, setAdjustedMonthlyPay] = useState(
    Number(selectedMonthlyPayment) + Number(selectedAdjustments)
  );
  const [originalAnnualPay, setOriginalAnnualPay] = useState(
    Number(selectedMonthlyPayment) * 12
  );
  const [adjustedAnnualPay, setAdjustedAnnualPay] = useState(
    (Number(selectedMonthlyPayment) + Number(selectedAdjustments)) * 12
  );
  const [expenseDescription, setExpenseDescription] = useState();

  useEffect(() => {
    let expenses = ExpenseNameData;
    let usedExpenses = latestData;
    if (selectedBudgetItem) {
      expenses = ExpenseNameData.filter(
        (item) => item.BudgetItem === selectedBudgetItem
      );
      usedExpenses = latestData.filter(
        (item) => item.BudgetItem === selectedBudgetItem
      );
      if (selectedBudgetItem === "Joint Contribution") {
        setAccountOwner(["Self", "Partner"]);
      } else {
        setAccountOwner(["Self", "Partner", "Joint", "Household"]);
      }
    }
    const uniqueDescriptions = [
      ...new Set(
        expenses.map((item) => (item.Description ? item.Description : ""))
      ),
    ];
    const uniqueUsedDescriptions = [
      ...new Set(
        usedExpenses.map((item) => (item.Description ? item.Description : ""))
      ),
    ];
    // Combine arrays and filter out duplicates from uniqueDescriptions
    const combinedDescriptions = [
      ...uniqueUsedDescriptions,
      ...uniqueDescriptions.filter(
        (description) => !uniqueUsedDescriptions.includes(description)
      ),
    ];
    combinedDescriptions.sort((a, b) => a.localeCompare(b));
    setExpenseDescription(combinedDescriptions);
  }, [ExpenseNameData, latestData, selectedBudgetItem]);

  const handleExpenseDescriptionChange = (e) => {
    if (e && e.value) {
      setNewDescription(e.value);
    } else setNewDescription("");
  };

  const handleSubCategoriesChange = (e) => {
    if (e && e.value) {
      setNewSubCategory(e.value);
    } else setNewSubCategory("");
  };

  const handleAccountOwnerChange = (e) => {
    if (e && e.value) {
      setNewAccountOwner(e.value);
    } else setNewAccountOwner("");
  };

  const handleBudgetItemChange = (e) => {
    const selBudgetItem = e.value;
    if (e && e.value) {
      setNewBudgetItem(e.value);
      const expenses = ExpenseNameData.filter(
        (item) => item.BudgetItem === selBudgetItem
      );
      const usedExpenses = latestData.filter(
        (item) => item.BudgetItem === selBudgetItem
      );
      if (selBudgetItem === "Joint Contribution") {
        setAccountOwner(["Self", "Partner"]);
      } else {
        setAccountOwner(["Self", "Partner", "Joint", "Household"]);
      }
      const uniqueDescriptions = [
        ...new Set(
          expenses.map((item) => (item.Description ? item.Description : ""))
        ),
      ];
      const uniqueUsedDescriptions = [
        ...new Set(
          usedExpenses.map((item) => (item.Description ? item.Description : ""))
        ),
      ];
      // Combine arrays and filter out duplicates from uniqueDescriptions
      const combinedDescriptions = [
        ...uniqueUsedDescriptions,
        ...uniqueDescriptions.filter(
          (description) => !uniqueUsedDescriptions.includes(description)
        ),
      ];
      combinedDescriptions.sort((a, b) => a.localeCompare(b));
      setExpenseDescription(combinedDescriptions);
    } else setNewBudgetItem("");
  };

  const handleBudgetTypeChange = (e) => {
    if (e && e.value) {
      setNewBudgetType(e.value);
    } else setNewBudgetType("");
  };

  const handleBankAccountsChange = (e) => {
    if (e && e.value) {
      const accountName = e.value;
      const targetAccountData = accountData.find(
        (item) => item.Account === accountName
      );
      const selAccountID = targetAccountData ? targetAccountData.id : "";
      setNewBankAccount(selAccountID);
    } else setNewBankAccount("");
  };

  const handlePaymentMethodsChange = (e) => {
    if (e && e.value) {
      setNewPaymentMethod(e.value);
    } else setNewPaymentMethod("");
  };

  const handlePayDueChange = (e) => {
    if (e && e.value) {
      setNewDayDue(e.value);
    } else setNewDayDue("");
  };

  const handleOriginalMonthlyExpenseChange = (e) => {
    if (e && e.value) {
      setNewMonthlyPayment(e.value);
      setMonthlyPay(e.value);
      setAdjustedMonthlyPay(e.value + Number(adjustment));
      setOriginalAnnualPay(e.value * 12);
      setAdjustedAnnualPay((e.value + Number(adjustment)) * 12);
    } else setNewMonthlyPayment("");
  };

  const handleAdjustmentsChange = (e) => {
    if (e && e.value) {
      setNewAdjustments(e.value);
      setAdjustment(e.value);
      setAdjustedMonthlyPay(Number(monthlyPay) + e.value);
      setOriginalAnnualPay(Number(monthlyPay) * 12);
      setAdjustedAnnualPay((Number(monthlyPay) + e.value) * 12);
    } else setNewAdjustments("");
  };

  return (
    <div className="main-ben-container-details1">
      <div className="beneficiary-field-container1">
        <div className="my-cap-ben-row1_exp">
          <div className="expense_budget_item">
            <ComboBoxComponent
              id="comboelement"
              placeholder="* Budget Item"
              headerTemplate={dropdownTemplate}
              value={selectedBudgetItem}
              dataSource={budgetItems}
              autofill={true}
              change={handleBudgetItemChange}
              floatLabelType="Always"
            />
          </div>
          <div className="expense_description">
            <ComboBoxComponent
              id="comboelement"
              placeholder="* Expense Description"
              headerTemplate={customTemplate}
              autofill={true}
              value={selectedDescription}
              dataSource={expenseDescription}
              change={handleExpenseDescriptionChange}
              floatLabelType="Always"
            />
          </div>
        </div>
        <div className="my-cap-ben-row2_exp">
          <div className="expense_account_owner">
            <ComboBoxComponent
              id="comboelement"
              value={selectedAccountOwner}
              placeholder="* Account Owner"
              autofill={true}
              headerTemplate={dropdownTemplate}
              floatLabelType="Always"
              dataSource={accountOwner}
              change={handleAccountOwnerChange}
            />
          </div>
          <div className="expense_sub_cat">
            <ComboBoxComponent
              id="comboelement"
              value={selectedSubCategory}
              placeholder="* Subcategory"
              headerTemplate={dropdownTemplate}
              autofill={true}
              floatLabelType="Always"
              dataSource={subCategories}
              change={handleSubCategoriesChange}
            />
          </div>
          <div className="expense_budget_type">
            <ComboBoxComponent
              id="comboelement"
              value={selectedBudgetType}
              placeholder="* Budget Type"
              headerTemplate={dropdownTemplate}
              autofill={true}
              floatLabelType="Always"
              dataSource={["Committed", "Extra", "'Funds'"]}
              change={handleBudgetTypeChange}
            />
          </div>
          <div className="expense_bank_account">
            <ComboBoxComponent
              id="comboelement"
              placeholder="* Bank Account Name"
              headerTemplate={dropdownTemplate}
              autofill={true}
              value={selectedBankAccount}
              dataSource={bankAccounts}
              change={handleBankAccountsChange}
              floatLabelType="Always"
            />
          </div>
        </div>
        <div className="my-cap-ben-row4_exp">
          <div className="expense_payment_method">
            <ComboBoxComponent
              id="comboelement"
              placeholder="Payment Method"
              headerTemplate={dropdownTemplate}
              autofill={true}
              value={selectedPaymentMethod}
              dataSource={paymentMethods}
              change={handlePaymentMethodsChange}
              floatLabelType="Always"
            />
          </div>
          <div className="income_name">
            <ComboBoxComponent
              id="comboelement"
              value={selectedDayDue}
              placeholder="* Payment Due"
              headerTemplate={dropdownTemplate}
              autofill={true}
              floatLabelType="Always"
              dataSource={[
                "1st",
                "2nd",
                "3rd",
                "4th",
                "5th",
                "6th",
                "7th",
                "8th",
                "9th",
                "10th",
                "11th",
                "12th",
                "13th",
                "14th",
                "15th",
                "16th",
                "17th",
                "18th",
                "19th",
                "20th",
                "21st",
                "22nd",
                "23rd",
                "24th",
                "25th",
                "26th",
                "27th",
                "28th",
                "29th",
                "30th",
                "31st",
                "N/A",
              ]}
              change={handlePayDueChange}
            />
          </div>
          <div className="income_gross_pay">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={selectedMonthlyPayment}
              placeholder="* Monthly Expense"
              floatLabelType="Always"
              showSpinButton={false}
              change={handleOriginalMonthlyExpenseChange}
            />
          </div>
          <div className="income_net_pay">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={selectedAdjustments}
              placeholder="Adjustment"
              floatLabelType="Always"
              change={handleAdjustmentsChange}
            />
          </div>
        </div>
        <div className="my-cap-ben-row3_exp">
          <div className="income_net_pay">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={adjustedMonthlyPay}
              placeholder="Adjusted Monthly Expense"
              floatLabelType="Always"
              showSpinButton={false}
              disabled={false}
              style={{
                pointerEvents: "none",
              }}
            />
          </div>
          <div className="income_net_pay">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={originalAnnualPay}
              placeholder="Original Annual Expense"
              floatLabelType="Always"
              showSpinButton={false}
              disabled={false}
              style={{
                pointerEvents: "none",
              }}
            />
          </div>
          <div className="income_net_pay">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={adjustedAnnualPay}
              placeholder="Adjusted Annual Expense"
              floatLabelType="Always"
              showSpinButton={false}
              disabled={false}
              style={{
                pointerEvents: "none",
              }}
            />
          </div>
        </div>
        {isRequired && <div className="noteContainer">{requiredNote()}</div>}
      </div>
    </div>
  );
}

export default ExpenseDialog;
