import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import "./JointContribution.css";
import axios from "../../../config/axios";
import useUserStore from "../../../app/user";
import { toast } from "react-toastify";

import { DialogComponent } from "@syncfusion/ej2-react-popups";
import useRetirementDataFetch from "../../shared/useRetirementDataFetch";
import useSavingDataFetch from "../../shared/useSavingDataFetch";
import useDebtDataFetch from "../../shared/useDebtDataFetch";
import useExpenseDataFetch from "../../shared/useExpenseDataFetch";
import useIncomeDataFetch from "../../shared/useIncomeDataFetch";
import useUserSplitDataFetch from "../../shared/useUserSplitDataFetch";
import UserSplitDialog from "./UserSplitDialog";
import EditIcon from "@mui/icons-material/Edit";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import UpgradeIcon from "@mui/icons-material/Upgrade";

function JointContribution() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const userPackage = user?.Package;
  const { retirementData, isRetirementLoaded } = useRetirementDataFetch();
  const { savingData, isSavingLoaded } = useSavingDataFetch();
  const { debtData, isDebtLoaded } = useDebtDataFetch();
  const { expenseData, isExpenseLoaded } = useExpenseDataFetch();
  const { incomeData, isIncomeLoaded } = useIncomeDataFetch();
  const { userSplitData, isUserSplitLoaded } = useUserSplitDataFetch();
  //INCOME DATA
  const [partnerGrossMonthlyIncomeTotal, setPartnerGrossMonthlyIncomeTotal] =
    useState(0);
  const [selfGrossMonthlyIncomeTotal, setSelfGrossMonthlyIncomeTotal] =
    useState(0);
  const [grossMonthlyIncomeTotal, setGrossMonthlyIncomeTotal] = useState(0);
  //JOINT DATA
  const [jointExpenseTotal, setJointExpenseTotal] = useState(0);
  const [jointDebtTotal, setJointDebtTotal] = useState(0);
  const [jointSavingsTotal, setJointSavingsTotal] = useState(0);
  const [jointRetirementTotal, setJointRetirementTotal] = useState(0);

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const usedCurrency = user?.Currency + " #,###.00";
  const [selectedID, setSelectedID] = useState("");
  const [selectedSelfAmount, setSelectedSelfAmount] = useState("");
  const [newSelfAmount, setNewSelfAmount] = useState("");
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isAddDialogVisible, setIsAddDialogVisible] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  // const [gridData, setGridData] = useState([]);

  useEffect(() => {
    if (
      isExpenseLoaded &&
      isRetirementLoaded &&
      isSavingLoaded &&
      isDebtLoaded &&
      isIncomeLoaded &&
      isUserSplitLoaded
    ) {
      // setGridData(userSplitData)
      let filteredRetirement;
      let filteredSavings;
      let filteredDebts;
      let filteredExpenses;
      let filteredIncome;
      if (user?.Type !== "Root") {
        filteredRetirement = retirementData.filter(
          (item) => item.UserID === user?.id
        );
        filteredSavings = savingData.filter((item) => item.UserID === user?.id);
        filteredDebts = debtData.filter((item) => item.UserID === user?.id);
        filteredExpenses = expenseData.filter(
          (item) => item.UserID === user?.id
        );
        filteredIncome = incomeData.filter((item) => item.UserID === user?.id);
      } else {
        filteredRetirement = retirementData;
        filteredSavings = savingData;
        filteredDebts = debtData;
        filteredExpenses = expenseData;
        filteredIncome = incomeData;
      }

      //GET EXPENSE DATA
      const totalExpenseJoint = filteredExpenses?.reduce(
        (accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          const accountOwner = record?.AccountOwner || "";
          // Only add the amount if AccountOwner is "Joint"
          if (accountOwner === "Joint") {
            return accumulator + Number(monthlyPayment) + Number(adjustments);
          }
          return accumulator;
        },
        0
      );
      setJointExpenseTotal(totalExpenseJoint);

      //GET DEBT DATA
      const totalDebtJoint = filteredDebts?.reduce((accumulator, record) => {
        const monthlyPayment = record?.MonthlyPayment || 0;
        const adjustments = record?.Adjustments || 0;
        const accountOwner = record?.AccountOwner || "";
        // Only add the amount if AccountOwner is "Joint"
        if (accountOwner === "Joint") {
          return accumulator + Number(monthlyPayment) + Number(adjustments);
        }
        return accumulator;
      }, 0);
      setJointDebtTotal(totalDebtJoint);

      //GET RETIREMENT DATA
      const totalRetirementJoint = filteredRetirement?.reduce(
        (accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          const accountOwner = record?.AccountOwner || "";
          // Only add the amount if AccountOwner is "Joint"
          if (accountOwner === "Joint") {
            return accumulator + Number(monthlyPayment) + Number(adjustments);
          }
          return accumulator;
        },
        0
      );
      setJointRetirementTotal(totalRetirementJoint);

      //GET SAVING DATA
      const totalSavingsJoint = filteredSavings?.reduce(
        (accumulator, record) => {
          const monthlyPayment = record?.MonthlyPayment || 0;
          const adjustments = record?.Adjustments || 0;
          const accountOwner = record?.AccountOwner || "";
          // Only add the amount if AccountOwner is "Joint"
          if (accountOwner === "Joint") {
            return accumulator + Number(monthlyPayment) + Number(adjustments);
          }
          return accumulator;
        },
        0
      );
      setJointSavingsTotal(totalSavingsJoint);

      const totalMonthlyGrossIncome = filteredIncome?.reduce(
        (accumulator, record) => {
          const grossPay = record.GrossPay || 0;
          const payFrequency = record.PayFrequency || "";
          let monthlyGrossIncome = 0;
          // Determine monthlyIncome based on PayFrequency
          switch (payFrequency) {
            case "Yearly":
              monthlyGrossIncome = grossPay / 12;
              break;
            case "Monthly":
              monthlyGrossIncome = grossPay;
              break;
            case "Semi-Monthly":
              monthlyGrossIncome = grossPay * 2;
              break;
            case "Weekly":
              monthlyGrossIncome = grossPay * 4;
              break;
            case "Bi-Weekly":
              monthlyGrossIncome = grossPay * 2;
              break;
            default:
              monthlyGrossIncome = 0;
          }
          return accumulator + Number(monthlyGrossIncome);
        },
        0
      );
      setGrossMonthlyIncomeTotal(totalMonthlyGrossIncome);

      // Function to calculate total monthly gross income for 'Self'
      const totalMonthlyGrossIncomeSelf = filteredIncome.reduce(
        (accumulator, record) => {
          if (record.AccountOwner === "Self") {
            const grossPay = record.GrossPay || 0;
            const payFrequency = record.PayFrequency || "";
            let monthlyGrossIncome = 0;

            // Determine monthlyIncome based on PayFrequency
            switch (payFrequency) {
              case "Yearly":
                monthlyGrossIncome = grossPay / 12;
                break;
              case "Monthly":
                monthlyGrossIncome = grossPay;
                break;
              case "Semi-Monthly":
                monthlyGrossIncome = grossPay * 2;
                break;
              case "Weekly":
                monthlyGrossIncome = grossPay * 4;
                break;
              case "Bi-Weekly":
                monthlyGrossIncome = grossPay * 2;
                break;
              default:
                monthlyGrossIncome = 0;
            }
            return accumulator + Number(monthlyGrossIncome);
          }
          return accumulator;
        },
        0
      );

      // Function to calculate total monthly gross income for 'Partner'
      const totalMonthlyGrossIncomePartner = filteredIncome.reduce(
        (accumulator, record) => {
          if (record.AccountOwner === "Partner") {
            const grossPay = record.GrossPay || 0;
            const payFrequency = record.PayFrequency || "";
            let monthlyGrossIncome = 0;
            // Determine monthlyIncome based on PayFrequency
            switch (payFrequency) {
              case "Yearly":
                monthlyGrossIncome = grossPay / 12;
                break;
              case "Monthly":
                monthlyGrossIncome = grossPay;
                break;
              case "Semi-Monthly":
                monthlyGrossIncome = grossPay * 2;
                break;
              case "Weekly":
                monthlyGrossIncome = grossPay * 4;
                break;
              case "Bi-Weekly":
                monthlyGrossIncome = grossPay * 2;
                break;
              default:
                monthlyGrossIncome = 0;
            }
            return accumulator + Number(monthlyGrossIncome);
          }
          return accumulator;
        },
        0
      );
      setSelfGrossMonthlyIncomeTotal(totalMonthlyGrossIncomeSelf);
      setPartnerGrossMonthlyIncomeTotal(totalMonthlyGrossIncomePartner);

      const firstRecord = userSplitData[0];
      if (firstRecord) {
        const { id, SelfAmount } = firstRecord;
        setSelectedID(id);
        setSelectedSelfAmount(SelfAmount);
      } else {
        setSelectedID("");
        setSelectedSelfAmount("");
      }

      setIsDataChanged(true);
      setIsDataLoaded(true);
    }
  }, [
    expenseData,
    retirementData,
    isRetirementLoaded,
    savingData,
    isExpenseLoaded,
    isSavingLoaded,
    debtData,
    isDebtLoaded,
    incomeData,
    isIncomeLoaded,
    userSplitData,
    isUserSplitLoaded,
    user,
  ]);

  let usedSelfSpit = selfGrossMonthlyIncomeTotal / grossMonthlyIncomeTotal;
  if (selectedID !== "" && Number(selectedSelfAmount) > 0) {
    usedSelfSpit = selectedSelfAmount / 100;
  }
  const splitNewData = {
    RootID: user?.RootID,
    UserID: user?.id,
    SelfAmount: newSelfAmount,
  };

  useEffect(() => {
    let loadingToastId;
    if (!isDataLoaded || !isDataChanged) {
      loadingToastId = toast.loading("Please wait...");
    } else {
      toast.dismiss(loadingToastId);
    }
    return () => {
      toast.dismiss(loadingToastId);
    };
  }, [isDataLoaded, isDataChanged]);

  const handleEditDialogClose = () => {
    setIsEditDialogVisible(false);
  };

  const handleAddDialogClose = () => {
    setIsAddDialogVisible(false);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogVisible(false);
  };

  const handleEditButtonClick = () => {
    if (selectedID) {
      setIsEditDialogVisible(!isEditDialogVisible);
      setNewSelfAmount(selectedSelfAmount);
    } else {
      setIsAddDialogVisible(!isAddDialogVisible);
      setSelectedID("");
      setSelectedSelfAmount(0);
      setNewSelfAmount(0);
    }
  };

  const handleDeleteButtonClick = () => {
    setIsDeleteDialogVisible(!isDeleteDialogVisible);
  };

  const handleAddRecordButton = async () => {
    // Make a POST request to add the Record record
    setIsDataChanged(false);
    try {
      const RecordResponse = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/usersplit",
        splitNewData
      );
      console.log("Record response:", RecordResponse.data);
      if (RecordResponse.data.status === "success") {
        toast.success("Added successfully.");
        try {
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/usersplit/reguserid/${user?.id}`
          );
          const Records = updatedResponse.data.items;
          const firstRecord = Records[0];
          if (firstRecord) {
            const { id, SelfAmount } = firstRecord;
            setSelectedID(id);
            setSelectedSelfAmount(SelfAmount);
          } else {
            setSelectedID("");
            setSelectedSelfAmount(0);
          }
          // setGridData(Records);
        } catch (fetchError) {
          // setGridData([]);
          setSelectedID("");
          setSelectedSelfAmount(0);
        }
      } else {
        console.error(
          "Error adding the record record:",
          RecordResponse.data.message
        );
        toast.error("Error adding the record");
      }
    } catch (error) {
      console.error("Error adding the record:", error);
      toast.error("Error adding the record");
    }
    setIsDataChanged(true);
  };

  const handleUpdateRecordButton = async (selectedID) => {
    setIsDataChanged(false);
    const numericSelectedID = parseInt(selectedID);
    try {
      const RecordResponse = await axios.put(
        process.env.REACT_APP_SERVER_URL +
          `/api/usersplit/${numericSelectedID}`,
        {
          RootID: user?.RootID,
          UserID: user?.id,
          SelfAmount: newSelfAmount,
        }
      );
      console.log("Record response:", RecordResponse.data);
      if (RecordResponse.data.status === "success") {
        toast.success("the record updated successfully.");
        try {
          const updatedResponse = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/usersplit/reguserid/${user?.id}`
          );
          const Records = updatedResponse.data.items;
          const firstRecord = Records[0];
          if (firstRecord) {
            const { id, SelfAmount } = firstRecord;
            setSelectedID(id);
            setSelectedSelfAmount(SelfAmount);
          } else {
            setSelectedID("");
            setSelectedSelfAmount(0);
          }
          // setGridData(Records);
        } catch (fetchError) {
          // setGridData([]);
          setSelectedID("");
          setSelectedSelfAmount(0);
        }
      } else {
        console.error(
          "Error updating the record record:",
          RecordResponse.data.message
        );
        toast.error("Error updating the record");
      }
    } catch (error) {
      console.error("Error updating the record:", error);
      toast.error("Error updating the record");
    }
    setIsDataChanged(true);
  };

  const handleDeleteRecordButton = async (selectedID) => {
    setIsDataChanged(false);
    const numericSelectedID = parseInt(selectedID);
    if (numericSelectedID !== "") {
      try {
        const RecordResponse = await axios.put(
          process.env.REACT_APP_SERVER_URL +
            `/api/usersplit/${numericSelectedID}`,
          {
            RootID: user?.RootID,
            UserID: user?.id,
            SelfAmount: 0,
          }
        );
        console.log("Record response:", RecordResponse.data);
        if (RecordResponse.data.status === "success") {
          toast.success("the record updated successfully.");
          try {
            const updatedResponse = await axios.get(
              process.env.REACT_APP_SERVER_URL +
                `/api/usersplit/reguserid/${user?.id}`
            );
            const Records = updatedResponse.data.items;
            const firstRecord = Records[0];
            if (firstRecord) {
              const { id, SelfAmount } = firstRecord;
              setSelectedID(id);
              setSelectedSelfAmount(SelfAmount);
            } else {
              setSelectedID("");
              setSelectedSelfAmount(0);
            }
            // setGridData(Records);
          } catch (fetchError) {
            // setGridData([]);
            setSelectedID("");
            setSelectedSelfAmount(0);
          }
        } else {
          console.error(
            "Error updating the record record:",
            RecordResponse.data.message
          );
          toast.error("Error updating the record");
        }
      } catch (error) {
        console.error("Error updating the record:", error);
        toast.error("Error updating the record");
      }
    }
    setIsDataChanged(true);
  };

  let addButtons = [
    {
      buttonModel: {
        content: "Override",
        cssClass: "e-warning update-btn",
        isPrimary: true,
      },
      click: () => {
        handleAddRecordButton(selectedID);
        setIsAddDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsAddDialogVisible(false);
      },
    },
  ];

  let editButtons = [
    {
      buttonModel: {
        content: "Override",
        cssClass: "e-warning update-btn",
        isPrimary: true,
      },
      click: () => {
        handleUpdateRecordButton(selectedID);
        setIsEditDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsEditDialogVisible(false);
      },
    },
  ];

  let deleteButtons = [
    {
      buttonModel: {
        content: "Yes",
        cssClass: "e-warning update-btn",
        isPrimary: true,
      },
      click: () => {
        handleDeleteRecordButton(selectedID);
        setIsDeleteDialogVisible(false);
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-warning update-btn",
      },
      click: () => {
        setIsDeleteDialogVisible(false);
      },
    },
  ];

  const handleUpgradeButtonClick = () => {
    navigate("/packages");
  };

  return (
    <div className="outer-container-jc">
      <div className="title-container-jc">
        <h3>Joint Account Split Calculation for Couples</h3>
      </div>
      <div className="dropdown-container-jc">
        <p style={{ fontSize: "12px", textAlign: "left", width: "100%" }}>
          NOTE: Couples can contribute to joint expenses in proportion to their
          respective gross income or override the calculated split.
        </p>
      </div>
      <>
        {userPackage !== "Basic" &&
          userPackage !== "Standard" &&
          userPackage !== "Premium" && (
            <div className="title-container1">
              <h2>This Feature is Only Available to Paid Users Only</h2>
              <div className="button-container">
                <div className="button-container-subA1">
                  <button
                    className="add-button"
                    onClick={handleUpgradeButtonClick}
                  >
                    {" "}
                    <UpgradeIcon className="send-icon" />
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          )}
        {(userPackage === "Basic" ||
          userPackage === "Standard" ||
          userPackage === "Premium") && (
          <>
            <div className="summary-container-jc">
              <div className="inner-summary-container-jc">
                <div className="inner-ind-summary-container-jc">
                  <div className="title-income-input-group">
                    <h2>HOUSEHOLD MONTHLY INCOME</h2>
                  </div>
                  <div className="jc-input-group ">
                    <h2>Combined Budgeted Gross Monthly Income</h2>
                    <div className="jc_input firstTextNode">
                      <strong style={{ fontWeight: "bold" }}>
                        {user?.Currency}
                        {grossMonthlyIncomeTotal.toLocaleString(
                          user?.Separator,
                          {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }
                        )}
                      </strong>
                    </div>
                    {/* <div className="perc-summary-container-jc"></div> */}
                  </div>
                  <div className="jc-input-group">
                    <h2>Self Budgeted Gross Monthly Income</h2>
                    <div className="jc_input ">
                      <strong style={{ fontWeight: "bold" }}>
                        {user?.Currency}
                        {selfGrossMonthlyIncomeTotal.toLocaleString(
                          user?.Separator,
                          {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }
                        )}
                      </strong>
                    </div>
                    <div className="perc-summary-container-jc">
                      <strong style={{ fontWeight: "bold" }}>
                        {(
                          (selfGrossMonthlyIncomeTotal /
                            grossMonthlyIncomeTotal) *
                          100
                        ).toFixed(0) + "%"}
                      </strong>
                    </div>
                  </div>
                  <div className="jc-input-group">
                    <h2>Partner Budgeted Gross Monthly Income</h2>
                    <div className="jc_input">
                      <strong style={{ fontWeight: "bold" }}>
                        {user?.Currency}
                        {partnerGrossMonthlyIncomeTotal.toLocaleString(
                          "en-IN",
                          {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }
                        )}
                      </strong>
                    </div>
                    <div className="perc-summary-container-jc">
                      <strong style={{ fontWeight: "bold" }}>
                        {(
                          (partnerGrossMonthlyIncomeTotal /
                            grossMonthlyIncomeTotal) *
                          100
                        ).toFixed(0) + "%"}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="button-container">
                <div className="button-container-subA1">
                  <button
                    className="edit-button"
                    onClick={handleDeleteButtonClick}
                  >
                    <ThumbUpOffAltIcon className="send-icon" /> Use Income Split
                  </button>
                  <button
                    className="add-button"
                    onClick={handleEditButtonClick}
                    style={{ textWrap: "nowrap" }}
                  >
                    <EditIcon className="send-icon" /> Override Income Split
                  </button>
                </div>
              </div>

              <div className="inner-summary-container-jc">
                <div className="inner-ind-summary-container-jc">
                  <div className="title-expense-input-group">
                    <h2>TOTAL JOINT EXPENSES</h2>

                    <div className="perc-summary-container-jc"></div>
                    <div className="icon-summary-container-jc"></div>
                  </div>
                  <div className="jc-input-group ">
                    <h2>Total Joint Expenses</h2>
                    <div className="jc_input firstTextNode">
                      <strong style={{ fontWeight: "bold" }}>
                        {user?.Currency}
                        {(
                          Number(jointExpenseTotal) +
                          Number(jointDebtTotal) +
                          Number(jointRetirementTotal) +
                          Number(jointSavingsTotal)
                        ).toLocaleString(user?.Separator, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </strong>
                    </div>
                    {/* <div className="perc-summary-container-jc"></div> */}
                  </div>
                  <div className="jc-input-group">
                    <h2>Self Contribution</h2>
                    <div className="jc_input">
                      <strong style={{ fontWeight: "bold" }}>
                        {user?.Currency}
                        {(
                          (Number(jointExpenseTotal) +
                            Number(jointDebtTotal) +
                            Number(jointRetirementTotal) +
                            Number(jointSavingsTotal)) *
                          usedSelfSpit
                        ).toLocaleString(user?.Separator, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </strong>
                    </div>
                    <div className="perc-summary-container-jc">
                      <strong style={{ fontWeight: "bold" }}>
                        {(usedSelfSpit * 100).toFixed(0) + "%"}
                      </strong>
                    </div>
                  </div>
                  <div className="jc-input-group">
                    <h2>Partner Contribution</h2>
                    <div className="jc_input">
                      <strong style={{ fontWeight: "bold" }}>
                        {user?.Currency}
                        {(
                          (Number(jointExpenseTotal) +
                            Number(jointDebtTotal) +
                            Number(jointRetirementTotal) +
                            Number(jointSavingsTotal)) *
                          (1 - Number(usedSelfSpit))
                        ).toLocaleString(user?.Separator, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </strong>
                    </div>

                    <div className="perc-summary-container-jc">
                      <strong style={{ fontWeight: "bold" }}>
                        {((1 - Number(usedSelfSpit)) * 100).toFixed(0) + "%"}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
      <div>
        {isEditDialogVisible && (
          <DialogComponent
            visible={isEditDialogVisible}
            width="25%"
            height="180px"
            header="Edit Calc Split"
            allowDragging={true}
            showCloseIcon={true}
            close={handleEditDialogClose}
            buttons={editButtons}
          >
            <UserSplitDialog
              selectedSelfAmount={selectedSelfAmount}
              setNewSelfAmount={setNewSelfAmount}
            />
          </DialogComponent>
        )}
      </div>
      <div>
        {isAddDialogVisible && (
          <DialogComponent
            visible={isAddDialogVisible}
            width="25%"
            height="100px"
            header="Override Calc Split"
            allowDragging={true}
            showCloseIcon={true}
            close={handleAddDialogClose}
            buttons={addButtons}
          >
            <UserSplitDialog
              selectedSelfAmount={selectedSelfAmount}
              setNewSelfAmount={setNewSelfAmount}
            />
          </DialogComponent>
        )}
      </div>
      <div>
        {isDeleteDialogVisible && (
          <DialogComponent
            visible={isDeleteDialogVisible}
            width="25%"
            height="180px"
            header="Use Calc Split"
            content="Do you want to use Gross Income Calc Split above?"
            allowDragging={true}
            showCloseIcon={true}
            close={handleDeleteDialogClose}
            buttons={deleteButtons}
          ></DialogComponent>
        )}
      </div>
    </div>
  );
}

export default JointContribution;
