import "./App.css";
import React, { useEffect } from "react";
import Login from "./components/body/login/Login";
import Package from "./components/body/package/Package";
import Dashboard from "./components/body/dashboard/Dashboard";
//Income PAGES
import IncomeLayout from "./components/body/income/IncomeLayout";
import IncomeDetails from "./components/body/income/IncomeDetails";
import IncomeExtraweeks from "./components/body/income/IncomeExtraweeks";
//EXPENSES PAGES
import Expenses from "./components/body/expenses/Expenses";
import ExpenseLayout from "./components/body/expenses/ExpenseLayout";
import JointContribution from "./components/body/expenses/JointContribution";
//Dedts PAGES
import Debts from "./components/body/debts/Debts";
//RETIREMENT PAGES
import Retirement from "./components/body/retirement/Retirement";
//SAVINGS PAGES
import Savings from "./components/body/savings/Savings";
//Bank Account PAGES
import BankAccounts from "./components/body/bankaccounts/BankAccounts";
import UnclearedTransaction from "./components/body/bankaccounts/UnclearedTransaction";
import BankRecon from "./components/body/bankaccounts/BankRecon";
import BankLayout from "./components/body/bankaccounts/BankLayout";
//Final Budget PAGES
import BudgetOverview from "./components/body/finalbudget/BudgetOverview";
import FinalBudget from "./components/body/finalbudget/FinalBudget";
import CheckList from "./components/body/finalbudget/CheckList";
import BudgetLayout from "./components/body/finalbudget/BudgetLayout";
import FundsTracker from "./components/body/finalbudget/FundsTracker";
//ASSETS PAGES
import Assets from "./components/body/assets/Assets";
//NET WORTH PAGES
import NetWorth from "./components/body/networth/NetWorth";
//Admin PAGES
import BudgetCategory from "./components/body/admin/BudgetCategory";
import UserProfile from "./components/body/admin/UserProfile";
import SubCategory from "./components/body/admin/SubCategory";
import ExpenseGoal from "./components/body/admin/ExpenseGoal";
import DebtGoal from "./components/body/admin/DebtGoal";
import BankAccountNames from "./components/body/admin/BankAccountNames";
import AdminLayout from "./components/body/admin/AdminLayout";

import NotFound from "./NotFound";
import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import MainLayout from "./MainLayout";

import WithAuthProtection from "./components/auth.protection";
import { ToastContainer } from "react-toastify";
import "../node_modules/react-toastify/dist/ReactToastify.css";
import useUserStore from "./app/user";
import { checkExpiry } from "./utils/functions";

const ProtectedMainLayout = WithAuthProtection("/login")(MainLayout);

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="login" element={<Login />} />
      <Route path="/" element={<ProtectedMainLayout />}>
        <Route path="packages" element={<Package />} />
        <Route index element={<Dashboard />} />
        <Route path="income" element={<IncomeLayout />}>
          <Route index element={<IncomeDetails />} />
          <Route path="incomedetails" element={<IncomeDetails />} />
          <Route path="extraweeks" element={<IncomeExtraweeks />} />
        </Route>
        <Route path="expenses" element={<ExpenseLayout />}>
          <Route index element={<Expenses />} />
          <Route path="expensedetails" element={<Expenses />} />
          <Route path="jointcontribution" element={<JointContribution />} />
        </Route>
        <Route path="debts" element={<Debts />}></Route>
        <Route path="retirement" element={<Retirement />}></Route>
        <Route path="savings" element={<Savings />}></Route>

        <Route path="bankaccounts" element={<BankLayout />}>
          <Route index element={<BankAccounts />} />
          <Route path="transactions" element={<BankAccounts />} />
          <Route path="bankrecon" element={<BankRecon />} />
          <Route
            path="unclearedtransactions"
            element={<UnclearedTransaction />}
          />
        </Route>

        <Route path="finalbudget" element={<BudgetLayout />}>
          <Route index element={<FinalBudget />} />
          <Route path="budgetoverview" element={<BudgetOverview />} />
          <Route path="finalbudget" element={<FinalBudget />} />
          <Route path="checklist" element={<CheckList />} />
          <Route path="fundstracker" element={<FundsTracker />} />
        </Route>

        <Route path="assets" element={<Assets />}></Route>
        <Route path="networth" element={<NetWorth />}></Route>
        <Route path="admin" element={<AdminLayout />}>
          <Route index element={<UserProfile />} />
          <Route path="budgetcategories" element={<BudgetCategory />} />
          <Route path="userprofile" element={<UserProfile />} />
          <Route path="subcategories" element={<SubCategory />} />
          <Route path="expensegoals" element={<ExpenseGoal />} />
          <Route path="debtgoals" element={<DebtGoal />} />
          <Route path="bankaccountnames" element={<BankAccountNames />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </>
  )
);

function App() {
  console.log("env: ", process.env.REACT_APP_SERVER_URL);
  const { user, setUserExpired } = useUserStore();

  useEffect(() => {
    if (user && user.Payment && checkExpiry(user)) {
      console.log("Expire : ----- ", checkExpiry(user));
      setUserExpired();
    }
  }, [user]);

  return (
    <div>
      <RouterProvider router={router} />
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="dark"
      />
    </div>
  );
}

export default App;
