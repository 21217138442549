import React, { useState, useEffect } from "react";
import {
  TextBoxComponent,
  NumericTextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";

import "./IncomeDialog.css";
import { dropdownTemplate, requiredNote } from "../../shared/HeaderTempate";

function IncomeDialog({
  selectedAccountOwner,
  selectedIncomeName,
  selectedIncomeDescription,
  selectedPayFrequency,
  selectedPayDay,
  selectedGrossPay,
  selectedNetPay,
  incomeNames,
  incomeFrequencies,
  frequencyData,
  usedCurrency,
  setNewAccountOwner,
  setNewIncomeName,
  setNewIncomeDescription,
  setNewPayFrequency,
  setNewPayDay,
  setNewGrossPay,
  setNewNetPay,
  payDays,
  isRequired,
}) {
  const [grossLabel, setGrossLabel] = useState(
    "* " + selectedPayFrequency + " Gross Pay"
  );
  const [netLabel, setNetLabel] = useState(
    "* " + selectedPayFrequency + "  Net Pay"
  );
  const [payDayLabel, setPayDayLabel] = useState("");
  const [incomePayDay, setIncomePayDay] = useState(payDays);

  useEffect(() => {
    if (
      selectedPayFrequency === "Weekly" ||
      selectedPayFrequency === "Bi-Weekly"
    ) {
      setPayDayLabel("* Pay Day");
    } else if (
      selectedPayFrequency === "Monthly" ||
      selectedPayFrequency === "Semi-Monthly"
    ) {
      setPayDayLabel("* Pay Date");
    } else if (selectedPayFrequency === "Yearly") {
      setPayDayLabel("* Pay Month");
    } else {
      setPayDayLabel("* Pay Day");
    }
  }, [selectedPayFrequency]);

  const handleIncomeChange = (e) => {
    if (e && e.value) {
      setNewIncomeName(e.value);
    } else setNewIncomeName("");
  };

  const handleDescriptionChange = (e) => {
    if (e && e.value) {
      setNewIncomeDescription(e.value);
    } else setNewIncomeDescription("");
  };

  const handleAccountOwnerChange = (e) => {
    if (e && e.value) {
      setNewAccountOwner(e.value);
    } else setNewAccountOwner("");
  };

  const handlePayFrequencyChange = (e) => {
    const selFrequency = e.value;
    if (e && e.value) {
      setNewPayFrequency(e.value);
      setGrossLabel("* " + e.value + " Gross Pay");
      setNetLabel("* " + e.value + " Net Pay");
      const frequency = frequencyData.filter(
        (item) => item.Frequency === selFrequency
      );
      const uniquePayDays = [
        ...new Set(frequency.map((item) => (item.PayDay ? item.PayDay : ""))),
      ];
      setIncomePayDay(uniquePayDays);
      if (selFrequency === "Weekly" || selFrequency === "Bi-Weekly") {
        setPayDayLabel("* Pay Day");
      } else if (
        selFrequency === "Monthly" ||
        selFrequency === "Semi-Monthly"
      ) {
        setPayDayLabel("* Pay Date");
      } else if (selFrequency === "Yearly") {
        setPayDayLabel("* Pay Month");
      } else {
        setPayDayLabel("* Pay Day");
      }
    } else setNewPayFrequency("");
  };

  const handlePayDayChange = (e) => {
    if (e && e.value) {
      setNewPayDay(e.value);
    } else setNewPayDay("");
  };

  const handleGrossPayChange = (e) => {
    if (e && e.value) {
      setNewGrossPay(e.value);
    } else setNewGrossPay("");
  };

  const handleNetPayChange = (e) => {
    if (e && e.value) {
      setNewNetPay(e.value);
    } else setNewNetPay("");
  };

  return (
    <div className="main-ben-container-details1">
      <div className="beneficiary-field-container1">
        <div className="my-cap-ben-row1_in">
          <div className="income_name">
            <ComboBoxComponent
              id="comboelement"
              placeholder="* Income"
              headerTemplate={dropdownTemplate}
              autofill={true}
              value={selectedIncomeName}
              dataSource={incomeNames}
              change={handleIncomeChange}
              floatLabelType="Always"
            />
          </div>
          <div className="income_description">
            <TextBoxComponent
              id="field_projectnum1"
              value={selectedIncomeDescription}
              placeholder="Income Description"
              floatLabelType="Always"
              change={handleDescriptionChange}
            />
          </div>
        </div>
        <div className="my-cap-ben-row2_in">
          <div className="income_account_owner">
            <ComboBoxComponent
              id="field_race1"
              value={selectedAccountOwner}
              placeholder="* Account Owner"
              headerTemplate={dropdownTemplate}
              autofill={true}
              floatLabelType="Always"
              dataSource={["Self", "Partner"]}
              change={handleAccountOwnerChange}
            />
          </div>
          <div className="income_pay_frequency">
            <ComboBoxComponent
              id="comboelement"
              placeholder="* Pay Frequency"
              headerTemplate={dropdownTemplate}
              autofill={true}
              value={selectedPayFrequency}
              dataSource={incomeFrequencies}
              change={handlePayFrequencyChange}
              floatLabelType="Always"
            />
          </div>
          <div className="income_pay_day">
            <ComboBoxComponent
              id="comboelement"
              placeholder={payDayLabel}
              headerTemplate={dropdownTemplate}
              autofill={true}
              value={selectedPayDay}
              dataSource={incomePayDay}
              change={handlePayDayChange}
              floatLabelType="Always"
            />
          </div>
        </div>
        <div className="my-cap-ben-row3_in">
          <div className="income_gross_pay">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={selectedGrossPay}
              placeholder={grossLabel}
              floatLabelType="Always"
              change={handleGrossPayChange}
              showSpinButton={false}
            />
          </div>
          <div className="income_net_pay">
            <NumericTextBoxComponent
              format={usedCurrency}
              value={selectedNetPay}
              placeholder={netLabel}
              floatLabelType="Always"
              change={handleNetPayChange}
              showSpinButton={false}
            />
          </div>
        </div>
        {isRequired && <div className="noteContainer">{requiredNote()}</div>}
      </div>
    </div>
  );
}

export default IncomeDialog;
