import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useDateFormatDataFetch = () => {
  const user = useUserStore((state) => state.user);

  const [dateFormatData, setDateFormatData] = useState([]);
  const [isDateFormatLoaded, setIsDateFormatLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchDateFormatData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/dateformat"
      );
      setIsDateFormatLoaded(true);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching DateFormat data:", error);
      setIsDateFormatLoaded(true);
      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchDateFormatData().then((DateFormatInitialData) => {
        setDateFormatData(DateFormatInitialData);
      });
    else setIsDateFormatLoaded(true);
  }, [user]);

  // Return an object containing all the fetched data
  return {
    dateFormatData,
    isDateFormatLoaded,
  };
};

export default useDateFormatDataFetch;
