import React from 'react'
import { Outlet } from 'react-router-dom';
import Admin from './Admin';
import '../MainBody.css'

function AdminLayout() {
  return (
    <div className='projects_container'>
      <div className='projects_sidebar'>
        <Admin />
      </div>
      <div className='projects_main'>
        <Outlet />
      </div>
    </div>
  )
}

export default AdminLayout