import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import axios from "../../../config/axios"
import useUserStore from "../../../app/user";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { countNewPrice } from "../../../utils/functions";

export default function CheckoutForm({selectedPlan}) {
  const stripe = useStripe();
  const {user, updateUserPackage} = useUserStore()
  const elements = useElements();
  const navigate = useNavigate();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://app.elitecashflowconsulting.com/packages",
      },
      redirect: "if_required"
    });

    if(paymentIntent?.status === 'succeeded') {
      axios
      .post(
        process.env.REACT_APP_SERVER_URL +
        "/api/paymentmethod/update-user-package",
        {...selectedPlan, date: new Date()}
        )
        .then(({ data }) => {
          console.log(data);
          navigate("/");
          toast.success(data.message);
          setIsLoading(false);
        updateUserPackage(selectedPlan.pack)
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    } else 
    setIsLoading(false);

    if (error) {
      setMessage(error?.message);
    } 

  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : `Pay now $${Number(selectedPlan.amount).toFixed(2)}`}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}