import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useExpenseNameDataFetch = () => {
  const user = useUserStore((state) => state.user);

  const [ExpenseNameData, setExpenseNameData] = useState([]);
  const [isExpenseNameLoaded, setIsExpenseNameLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchExpenseNameData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/expensename"
      );
      setIsExpenseNameLoaded(true);

      return response.data.items;
    } catch (error) {
      console.error("Error fetching ExpenseName data:", error);
      setIsExpenseNameLoaded(true);

      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchExpenseNameData().then((ExpenseNameInitialData) => {
        setExpenseNameData(ExpenseNameInitialData);
      });
    else setIsExpenseNameLoaded(true);

    // Set data loading flag
  }, [user]);

  // Return an object containing all the fetched data
  return {
    ExpenseNameData,
    isExpenseNameLoaded,
  };
};

export default useExpenseNameDataFetch;
