import { useEffect, useState } from "react";
import axios from "../../config/axios";
import useUserStore from "../../app/user";

const useDebtNameDataFetch = () => {
  const user = useUserStore((state) => state.user);

  const [debtNameData, setDebtNameData] = useState([]);
  const [isDebtNameLoaded, setIsDebtNameLoaded] = useState(false);

  // Define the fetchData function for tasks
  const fetchDebtNameData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/debtname"
      );
      setIsDebtNameLoaded(true);

      return response.data.items;
    } catch (error) {
      console.error("Error fetching DebtName data:", error);
      setIsDebtNameLoaded(true);

      return [];
    }
  };

  useEffect(() => {
    // Fetch task data
    if (user?.Payment)
      fetchDebtNameData().then((DebtNameInitialData) => {
        setDebtNameData(DebtNameInitialData);
      });
    else setIsDebtNameLoaded(true);

    // Set data loading flag
  }, [user]);

  // Return an object containing all the fetched data
  return {
    debtNameData,
    isDebtNameLoaded,
  };
};

export default useDebtNameDataFetch;
